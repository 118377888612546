import React, { ReactElement, useState } from 'react';
import { ComboBox, DatePickerPopUp, Icon, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';

import { filterDateOptions } from 'src/constant/date.constant';
import Divider from 'src/component/Divider/Divider';
import './FilteredDate.scss';

const { convertToDate, isValidDate, formatDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;

interface IProps {
  filterDate: string;
  disabled?: boolean;
  options: typeof filterDateOptions;
  onChangeDate: (date: string) => void;
}

export const FilteredDate = ({ filterDate, disabled, onChangeDate, options }: IProps) => {
  const useDateDropdownRender = (menu: ReactElement) => {
    const [showPicker, setShowPicker] = useState(false);

    return (
      <div>
        {menu}
        <Divider marginVertical={5} />
        <div role="button" tabIndex={0} className="specificDate" onClick={() => setShowPicker(true)}>
          <Typography>Specific Month</Typography>
          <Icon
            name="RightSmall"
            type="outline"
            style={{
              verticalAlign: 'middle',
              float: 'right',
            }}
          />
        </div>
        <DatePickerPopUp
          picker="month"
          visible={showPicker}
          onCancel={() => setShowPicker(false)}
          onDateChange={(value) => onChangeDate(formatDateString(value, DATE.MONTH_AND_YEAR))}
          value={isValidDate(filterDate) ? convertToDate(filterDate) : undefined}
        />
      </div>
    );
  };
  return (
    <ComboBox
      items={options}
      value={filterDate}
      dropdownRender={useDateDropdownRender}
      disabled={disabled}
      onChange={(value) => onChangeDate(value as string)}
    />
  );
};
