import { QueryPagination, StateCreator, createStore } from '@care/web-ui/lib/store';
import { QueryStore } from '@care/web-ui/lib/store/store.queryPagination';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import { SearchType } from 'src/enum/User.enum';
import { UserModel, UserFilter } from 'src/model/User.model';

const { queryStateCreator } = QueryPagination;

export const $USER_LIST_STORE = '$USER_LIST_STORE';

export interface UserState {
  filter: UserFilter;
  data: UserModel[];
}

export const initialState: UserState = {
  filter: {
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
    searchValue: '',
    searchType: SearchType.NAME,
    sortBy: undefined,
    status: undefined,
    idType: undefined,
  },
  data: [],
};

export type UserListStore = UserState & QueryStore<UserModel, UserFilter>;

const stateCreator: StateCreator<UserListStore> = (set, get, storeApi) => ({
  ...queryStateCreator(set, get, storeApi),
  ...initialState,
  resetStore: () => {
    set({ ...initialState });
  },
});

export const [useUserStore, selector] = createStore<UserListStore>(stateCreator, {
  storeName: $USER_LIST_STORE,
});
