import React, { useState, useEffect } from 'react';
import { Button, ComboBox, Skeleton, Utils } from '@care/web-ui';
import { useHistory } from 'react-router-dom';
import SectionTitle from 'src/component/SectionTitle/SectionTitle';
import Spacer from 'src/component/Spacer/Spacer';
import './AppHomeScreen.scss';
import { Routes } from 'src/constant';
import PreviewTiles from '../preview-tiles/PreviewTiles';
import PreviewBanners from '../preview-banners/PreviewBanners';
import { useAppHomeScreenContext } from 'src/context/AppHomeScreen.context';
import Notices from 'src/component/Notices/Notices';

const {
  CountryUtil: { getAvailableCountries },
  CommonUtil: { isEmpty },
} = Utils;

const AppHomeScreen = () => {
  const { push } = useHistory();
  const { loadData, data, loading } = useAppHomeScreenContext();
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('country');
  const availableCountries = getAvailableCountries();
  const [location, setLocation] = useState(country || availableCountries[0].value);
  const tiles = data?.tiles || [];
  const banners = data && data.banners;
  const notices = data?.notices || [];

  useEffect(() => {
    (async () => await loadData(location))();
  }, [location]);

  if (loading) return <Skeleton />;

  return (
    <div className="appHomeScreenContainer">
      <ComboBox
        label="Location"
        items={availableCountries}
        onChange={(value) => setLocation(value as string)}
        value={location}
      />

      <Spacer size={24} />

      <div>
        <SectionTitle
          title="Notices"
          marginVertical={4}
          rightComponent={
            <Button
              title="Edit"
              icon="Edit"
              type="link"
              className="!min-w-[unset] !p-0"
              onClick={() => push(`${Routes.HomeScreenNotices}?country=${location}`)}
            />
          }
        />
        {!isEmpty(notices) && (
          <div className="max-h-[400px] overflow-auto bg-neutral-5 px-[74px] py-4">
            <Notices notices={notices} />
          </div>
        )}
      </div>

      <div>
        <SectionTitle
          title="Tiles Layout"
          marginVertical={4}
          rightComponent={
            <Button
              title="Edit"
              icon="Edit"
              type="link"
              className="!min-w-[unset] !p-0"
              onClick={() => push(`${Routes.HomeScreenTiles}?country=${location}`)}
            />
          }
        />
        <PreviewTiles tiles={tiles} isLargePreview />
      </div>

      <div>
        <SectionTitle
          title="Banners"
          marginVertical={4}
          rightComponent={
            <Button
              title="Edit"
              icon="Edit"
              type="link"
              className="!min-w-[unset] !p-0"
              onClick={() => push(`${Routes.HomeScreenBanners}?country=${location}`)}
            />
          }
        />
        {!isEmpty(banners) && <PreviewBanners banners={banners} showTotal />}
      </div>
    </div>
  );
};

export default AppHomeScreen;
