import { RefundStatus } from 'src/enum/RefundStatus';

type TagInfo = {
  type: any;
  label: string;
};

type RefundStatusTypeTag = {
  [key in RefundStatus]: TagInfo;
};

export const refundStatusTypeTag: RefundStatusTypeTag = {
  [RefundStatus.InProgress]: { label: 'In Progress', type: 'Pending' },
  [RefundStatus.Rejected]: { label: 'Rejected', type: 'Error' },
  [RefundStatus.Success]: { label: 'Refunded', type: 'Success' },
  [RefundStatus.Requesting]: { label: 'Pending', type: 'Warning' },
  [RefundStatus.RejectedByIssuer]: { label: 'Rejected by Issuer', type: 'Error' },
  [RefundStatus.Partial]: { label: 'Partially Refunded', type: 'Success' },
};
