import React, { useMemo, useEffect } from 'react';
import { Table } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';
import { pageSizeOptions, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { usePaginatedOrganizationListContext } from 'src/context/OrganizationListWithPagination.context';
import { OrganisationsSortBy } from 'src/enum/OrganisationsSortBy.enum';
import { getColumns } from './columns';
import OrganisationsTableToolbar from '../organisations-table-toolbar/OrganisationsTableToolbar';
import './OrganisationsTable.scss';
import { TableColumn } from 'libs/care-ui/lib/components/Table/Table';
import { OrganizationModel } from 'src/model/OrganizationModel';

const OrganisationsTable = () => {
  const {
    data: organisations,
    loading,
    pagination,
    setPagination,
    filter,
    setFilter,
    loadData,
  } = usePaginatedOrganizationListContext();

  const columns = useMemo(() => getColumns(), []) as TableColumn<OrganizationModel>[];

  useEffect(() => {
    loadData();
  }, [filter, pagination.pageIndex, pagination.pageSize]);

  const handleTableChange = (_, filters: Record<string, any>, sorter: any) => {
    let sortBy: OrganisationsSortBy = undefined;
    if (sorter.order) {
      sortBy =
        sorter.order === 'ascend'
          ? OrganisationsSortBy[`${sorter.field}_asc`]
          : OrganisationsSortBy[`${sorter.field}_desc`];
    }
    // TODO: should send status as an array of string. API should be updated
    const status = !filters.status || filters.status.length > 1 ? null : filters.status[0];
    if (status) {
      setFilter({ ...filter, status, sortBy });
    } else {
      delete filter.status;
      setFilter({ ...filter, sortBy });
    }
  };

  return (
    <div className="organisations-table">
      <OrganisationsTableToolbar filter={filter} setFilter={setFilter} />
      <Spacer size={24} />
      <Table
        loading={loading}
        columns={columns as any[]}
        data={organisations}
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.ORGANISATIONS,
          showSizeChanger: true,
          pageSizeOptions,
          total: pagination.total,
          onChange: (pageIndex, pageSize) => setPagination({ pageIndex, pageSize }),
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default OrganisationsTable;
