import React from 'react';
import { Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import BlockInfo from 'src/component/BlockInfo/BlockInfo';
import Divider from 'src/component/Divider/Divider';
import { DeliveryAddressModel } from 'src/model/DeliveryAddress.model';

interface Props {
  detail: DeliveryAddressModel;
}

const OrderDeliveryAddress = ({ detail }: Props) => {
  const { CountryUtil } = Utils;
  if (!detail) {
    return null;
  }

  const { address, blockHouse, contactPhoneCode, contactPhoneNumber, countryCode, floorAndUnitNumber, postalCode } =
    detail;

  return (
    <div>
      <Typography level={5} weight="regular" fontCategory="serif">
        Delivery Address
      </Typography>
      <Divider marginVertical={16} />
      <BlockInfo name="Contact" value={`(${contactPhoneCode}) ${contactPhoneNumber}`} marginBottom={16} />
      <BlockInfo name="Block/House Number" value={blockHouse} marginBottom={16} />
      <BlockInfo name="Address" value={address} marginBottom={16} />
      <BlockInfo name="Floor & Unit Number" value={floorAndUnitNumber} marginBottom={16} />
      <BlockInfo name="Postal Code" value={postalCode} marginBottom={16} />
      <BlockInfo name="Country" value={CountryUtil.getCountryNameByIsoCode2(countryCode)} marginBottom={16} />
    </div>
  );
};

export default OrderDeliveryAddress;
