import { Builder } from 'builder-pattern';
import { RoutingType, UIRoutingType } from 'src/enum/routingType.enum';
import { HomeScreenItem } from './HomeScreenItem.model';
import { Media } from './Media.model';
import { buildLocalUrl } from 'src/util/file.util';
import { Utils } from '@care/web-ui';
import { AppScreenRouting } from 'src/enum/appScreenRouting.enum';
import { wellnessRoutingKeyRegex } from 'src/constant/RegExp';

const {
  CommonUtil: { pick },
} = Utils;

export class HomeScreenFormModel {
  index?: number;
  visible: boolean;
  name: string;
  mediaUrl?: string;
  mediaId?: string;
  localFile?: File;
  media?: Media;
  routingType: UIRoutingType;
  routingValue: string;
  eventName: string;
  membershipId?: string;
  id?: string;

  static convertFromDTO(dto: HomeScreenItem): HomeScreenFormModel {
    const { routingType, routingUrl, routingKey, media, localFile } = dto;

    let resRoutingType: UIRoutingType;
    let resRoutingKey = routingKey;
    let membershipId: string;

    switch (true) {
      case routingType === RoutingType.AppScreen:
        resRoutingType = UIRoutingType.AppScreen;

        // eslint-disable-next-line no-case-declarations
        const [, _membershipId] = routingKey.match(wellnessRoutingKeyRegex) || [];
        if (_membershipId) {
          resRoutingKey = AppScreenRouting.WELLNESS;
          membershipId = _membershipId;
        }
        break;
      case routingType === RoutingType.External:
        resRoutingType = UIRoutingType.External;
        break;
      case routingUrl.includes('specialties'):
        resRoutingType = UIRoutingType.SpecialtyList;
        break;
      case routingUrl.includes('products/e-commerce/category'):
        resRoutingType = UIRoutingType.EcProductList;
        break;
      case routingUrl.includes('products/e-commerce'):
        resRoutingType = UIRoutingType.EcProductDetail;
        break;
      case routingUrl.includes('categories'):
        resRoutingType = UIRoutingType.ProductList;
        break;
      case routingUrl.includes('products'):
        resRoutingType = UIRoutingType.ProductDetail;
        break;
      default:
        resRoutingType = UIRoutingType.CustomInternalLink;
    }

    return Builder(HomeScreenFormModel, {
      ...pick(dto, ['name', 'visible', 'mediaId', 'index', 'media', 'eventName', 'id']),
      localFile: localFile || (media as unknown as File),
      routingType: resRoutingType,
      routingValue: resRoutingType === UIRoutingType.AppScreen ? resRoutingKey : routingUrl,
      membershipId,
    }).build();
  }

  static convertToDTO(formVal: HomeScreenFormModel): HomeScreenItem {
    let routingType: RoutingType;
    let routingKey: string;
    let routingUrl: string;

    const { routingType: formRoutingType, routingValue, localFile, membershipId } = formVal;

    switch (formRoutingType) {
      case UIRoutingType.AppScreen:
        routingType = RoutingType.AppScreen;
        routingKey =
          routingValue === AppScreenRouting.WELLNESS && membershipId
            ? `WellnessOf?membershipId=${membershipId}`
            : routingValue;
        break;
      case UIRoutingType.External:
        routingType = RoutingType.External;
        routingUrl = routingValue;
        break;
      default:
        routingType = RoutingType.Marketplace;
        routingUrl = routingValue;
    }

    return Builder<HomeScreenItem>({
      ...pick(formVal, ['name', 'visible', 'mediaId', 'index', 'localFile', 'media', 'eventName', 'id']),
      routingType,
      routingUrl,
      routingKey,
      mediaUrl: localFile ? localFile['url'] || buildLocalUrl(localFile) : undefined,
      id: formVal.id,
    }).build();
  }
}
