import { Icon } from '@care/web-ui';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import SortableJS from 'sortablejs';
import { sortItems } from 'src/util/array';
import './Sortable.scss';

interface IProps {
  children: ReactNode[];
  items: any[];
  // Element dragging ended, reset the order of the items array
  onSortEnd: (data: any) => void;
}

const Sortable = ({ children, items, onSortEnd }: IProps) => {
  const ref = useRef();
  const [sortable, setSortable] = useState<SortableJS | undefined>();
  const handleSortEnd = (event: SortableJS.SortableEvent) => {
    const { oldIndex, newIndex } = event;

    if (oldIndex !== newIndex) {
      const array = [...items];
      sortItems(array, event.oldIndex, event.newIndex);
      onSortEnd(array);
    }
  };

  const init = () => {
    const sortable = SortableJS.create(ref.current, {
      handle: '.handleItem',
      ghostClass: 'ghostStyle',
      animation: 200,
    });

    setSortable(sortable);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (children.length && sortable) {
      sortable.options.onEnd = handleSortEnd;
    }
  }, [children, sortable]);

  return (
    <ul ref={ref} className="sortableList">
      {children.map((item: React.ReactElement) => (
        <li key={item.key} className="listItem">
          <div className="handleItem">
            <Icon name="MoreVertical" />
          </div>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default Sortable;
