export class Media {
  id: string;

  createdAt: string;

  updatedAt: string;

  uploader: string;

  name: string;

  mimeType: string;

  status: string;

  url: string;
}
