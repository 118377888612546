import React, { useState, useCallback } from 'react';
import { Input, Utils } from '@care/web-ui';
import './OrganisationsTableToolbar.scss';
import { OrganizationFilter } from 'src/context/OrganizationListWithPagination.context';

const {
  CommonUtil: { debounce },
} = Utils;

interface OrganisationsTableToolbarProps {
  filter: OrganizationFilter;
  setFilter: (filter: OrganizationFilter) => void;
}

const OrganisationsTableToolbar = ({ filter, setFilter }: OrganisationsTableToolbarProps) => {
  const [searchValue, setSearchValue] = useState(filter.displayName);

  const debounceSearch = useCallback(
    debounce((value) => {
      setFilter({ ...filter, displayName: value });
    }, 1000),
    [filter],
  );
  const handleSearch = (value: string) => {
    setSearchValue(value);
    debounceSearch(value);
  };

  return (
    <div className="organizations-table-toolbar">
      <Input
        iconRight={{ name: 'Search' }}
        placeholder="Search Organization"
        value={searchValue}
        onChange={handleSearch}
      />
    </div>
  );
};
export default OrganisationsTableToolbar;
