import { QueryPagination, StateCreator, createStore } from '@care/web-ui/lib/store';
import { QueryStore } from '@care/web-ui/lib/store/store.queryPagination';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import { PlatformSettingModel, PlatformSettingFilter } from 'src/model/PlatformSetting.model';

const { queryStateCreator } = QueryPagination;

export const $PLATFORM_SETTING_LIST_STORE = '$PLATFORM_SETTING_LIST_STORE';

export interface PlatformSettingState {
  filter: PlatformSettingFilter;
  data: PlatformSettingModel[];
}

export const initialState: PlatformSettingState = {
  filter: {
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
    searchParams: '',
  },
  data: [],
};

export type PlatformSettingListStore = PlatformSettingState & QueryStore<PlatformSettingModel, PlatformSettingFilter>;

const stateCreator: StateCreator<PlatformSettingListStore> = (set, get, storeApi) => ({
  ...queryStateCreator(set, get, storeApi),
  ...initialState,
  resetStore: () => {
    set({ ...initialState });
  },
});

export const [usePlatformSettingStore, selector] = createStore<PlatformSettingListStore>(stateCreator, {
  storeName: $PLATFORM_SETTING_LIST_STORE,
});
