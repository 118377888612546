import { createPagingDataContext } from 'src/util/createPagingDataContext';
import { OrganizationModel } from 'src/model/OrganizationModel';
import OrganizationAPI from 'src/service/Organization.service';
import { OrganizationFilter } from 'src/context/OrganizationListWithPagination.context';

const fetcher = async (filter: OrganizationFilter, pageIndex: number, pageSize: number) => {
  return OrganizationAPI.getOrganizations(filter, pageIndex, pageSize);
};

const [OrganizationListProvider, useOrganizationListContext] = createPagingDataContext<
  OrganizationModel,
  OrganizationFilter
>(fetcher, {
  pageIndex: 1,
  pageSize: 100,
  isOrganization: true,
});

export { useOrganizationListContext };

export default OrganizationListProvider;
