import { CategoryListStore, useStore as useCategoryListStore } from 'src/zustand-store/category/CategoryList.store';
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from 'src/constant/Pagination.constant';
import { shallow } from '@care/web-ui/lib/store';
import CategoryService from 'src/service/Category.service';
import { CategoryType } from 'src/enum/CategoryType';
import { Utils } from '@care/web-ui';

const {
  CommonUtil: { pick },
} = Utils;

export const useQueryEcCategoryList = (): {
  queryEcCategoryList: () => void;
} => {
  const { fetchData } = useCategoryListStore((store: CategoryListStore) => pick(store, 'fetchData'), shallow);

  const queryEcCategoryList = () => {
    fetchData({
      fetcher: () =>
        CategoryService.getCategories({
          categoryType: CategoryType.Product,
          pageIndex: DEFAULT_PAGE_INDEX,
          pageSize: MAX_PAGE_SIZE,
        }),
    });
  };

  return {
    queryEcCategoryList,
  };
};
