import React from 'react';
import { Icon, Table, Tag, Typography } from '@care/web-ui';
import { RefundModel } from 'src/model/Refund.model';
import { RefundStatus } from 'src/enum/RefundStatus';
import { refundStatusTypeTag } from 'src/constant/RefundStatusTag';
import { Amount } from 'src/model/Amount.model';
import RefundTableItemDetail from './RefundTableItemDetail';
import { Link } from 'react-router-dom';
import { Routes } from 'src/constant';
import { mappingRefundRequestedBy } from 'src/constant/Refund';

interface Props {
  items: RefundModel[];
}

const OrdeRefundsTable = ({ items }: Props) => {
  const columns = [
    {
      title: 'Refund ID',
      dataIndex: 'refundNumber',
      key: 'refundNumber',
      render: (value: string, item: RefundModel) => (
        <Link to={Routes.Refunds + '/' + item.id}>
          <Typography level={9} weight="bold" className="orderId">
            {value}
          </Typography>
        </Link>
      ),
    },
    {
      title: 'Requested By',
      dataIndex: 'requestedBy',
      key: 'requestedBy',
      render: (requestedBy: string) => <Typography level={9}>{mappingRefundRequestedBy[requestedBy]}</Typography>,
    },
    {
      title: 'Refund Creation Date',
      dataIndex: 'createAtText',
      key: 'createAtText',
      render: (value: string) => <Typography level={9}>{value}</Typography>,
    },

    {
      title: 'Refund Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: RefundStatus) => (
        <Tag type={refundStatusTypeTag[status]?.type as any} text={refundStatusTypeTag[status]?.label} />
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (refundAmount: Amount, item: RefundModel) => {
        switch (item.status) {
          case RefundStatus.Requesting:
          case RefundStatus.Rejected:
            return <Typography level={9}>-</Typography>;
          default:
            return <Typography level={9}>{item?.refundAmount.valueWithSymbol}</Typography>;
        }
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={items}
        expandable={{
          expandedRowRender: (data: RefundModel) => {
            return <RefundTableItemDetail item={data} />;
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Icon name="DownSmall" type="outline" onClick={() => onExpand(record, null)} />
            ) : (
              <Icon name="RightSmall" type="outline" onClick={() => onExpand(record, null)} />
            ),
        }}
      />
    </div>
  );
};

export default OrdeRefundsTable;
