import React from 'react';
import { Typography } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';
import UsersTable from 'src/module/users/users-table';
import UserListToolbar from './user-list-toolbar';

const Users = () => {
  return (
    <div className="p-10">
      <div className="flex justify-between">
        <Typography fontCategory="serif" level={3}>
          Users
        </Typography>
      </div>

      <Spacer size={32} />

      <UserListToolbar />

      <Spacer size={32} />

      <UsersTable />
    </div>
  );
};

export default Users;
