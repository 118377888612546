import React, { ReactNode, useContext, useState } from 'react';
import { createRefund } from 'src/service/RefundAPI';
import { handleToastError } from 'src/util/error.util';

class ContextState {
  createRefundRequest: (orderId: string, reason: string) => Promise<boolean> = async () => true;

  loading = false;
}

export const OrderActionContext = React.createContext(new ContextState());

const OrderActionProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(false);

  const createRefundRequest = async (orderId: string, reason: string): Promise<boolean> => {
    try {
      setLoading(true);
      await createRefund(orderId, reason);
      return true;
    } catch (error) {
      console.error(error);
      handleToastError(error, 'Unable to request refund');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <OrderActionContext.Provider
      value={{
        createRefundRequest,
        loading,
      }}
    >
      {children}
    </OrderActionContext.Provider>
  );
};

export const useOrderActionContext = () => useContext(OrderActionContext);

export default OrderActionProvider;
