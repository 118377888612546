import React from 'react';

import { Typography } from '@care/web-ui';
import './BlockInfo.scss';

interface Props {
  name: string;
  value: string | React.ReactNode;
  marginBottom?: number;
}

const BlockInfo = ({ name, value, marginBottom }: Props) => {
  return (
    <div style={{ marginBottom }} className="block-info">
      <Typography level={8} weight="regular" fontCategory="sans" className="infoName">
        {name}
      </Typography>
      <Typography level={8} weight="regular" fontCategory="sans" className="infoValue">
        {value || <span>&nbsp;</span>}
      </Typography>
    </div>
  );
};

export default BlockInfo;
