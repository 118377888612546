export enum AppScreenRouting {
  WELLNESS = 'wellness',
  COVID19_TEST = 'covid19Test',
  COVID19_TEST_FOR_TRAVEL = 'covid19TestForTravel',
  COVID19_TEST_FOR_GENERAL = 'covid19TestForGeneral',
  COVID19_VACCINATION = 'covidVaccination',
  COVID19_COVER_REGISTRATION_FORM = 'covidCoverRegistrationForm',
  STP = 'pageSingaporeTravelPass',
  SELECT_SPECIALTY_SCREEN = 'selectSpecialtyScreen',
  RMG_BOOKING = 'rmgBookingScreen',
  MY_HEALTH_FOLDER = 'myHealthFolderScreen',
  TEMPERATURE_TRACKING = 'temperatureTrackingScreen',
  QMS_SCREEN = 'qmsScreen',
  FAMILY_MEMBER_SCREEN = 'familyMemberScreen',
  CARE_HOME_SCREEN = 'careHomeScreen',
  QR_SCAN_SCREEN = 'qrScanScreen',
  SIGN_UP_SCREEN = 'signupScreen',
  LOGIN_SCREEN = 'loginScreen',
  VERIFY_EMAIL_SCREEN = 'verifyEmailScreen',
  FORGOT_PASSWORD_SCREEN = 'forgotPasswordScreen',
  CHANGE_MOBILE_NUMBER_SCREEN = 'changeMobileNumberScreen',
  CHANGE_EMAIL_SCREEN = 'changeEmailScreen',
  CHANGE_PASSWORD_SCREEN = 'changePasswordScreen',
  LOGIN_DETAILS_SCREEN = 'loginDetailsScreen',
  EDIT_PROFILE_SCREEN = 'editProfileScreen',
  PROFILE_DETAILS_SCREEN = 'profileDetailsScreen',
  COVID_TEST_SCREEN = 'covid19TestScreen',
  SPECIALIST_APPOINTMENT_FLOW = 'specialistAppointmentFlow',
  RECEIVE_CALL_DIALOG = 'receiveCallDialog',
  NOTIFICATION_LIST_SCREEN = 'notificationListScreen',
  VACCINATION_LIST = 'vaccinationList',
  SELECT_VACCINATION = 'selectVaccination',
  BOOK_VACCINATION = 'bookVaccination',
  BOOK_HEALTH_SCREENING = 'bookScreening',
  MARKETPLACE_LANDING_PAGE = 'marketplaceLandingPage',
}
