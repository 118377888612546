import { shallow } from '@care/web-ui/lib/store';
import { ProductModel } from 'src/model/Product.model';
import { ProductListMutationStore, useStore } from 'src/zustand-store/product-list/ProductListMutation.store';
import { Utils } from '@care/web-ui';

const {
  CommonUtil: { pick },
} = Utils;

export const useHandleFormattedDataProductList = (data: ProductModel[]) => {
  return data.map((item: ProductModel) => {
    const { categoryName = '', subCategoryName = '', ...rest } = item || {};
    const fullCategory = categoryName && subCategoryName ? `${categoryName} > ${subCategoryName}` : categoryName;
    return {
      ...rest,
      fullCategory,
    };
  });
};

export const useGetProductSelected = () => {
  const { productsSelected }: Pick<ProductListMutationStore, 'productsSelected'> = useStore(
    (store: ProductListMutationStore) => pick(store, ['productsSelected'] as Array<keyof ProductListMutationStore>),
    shallow,
  );
  return {
    productsSelected,
    total: productsSelected.length,
    isDisableBtn: !productsSelected.length,
  };
};
