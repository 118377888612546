import { BaseFilter, BasicSortBy, createPagingDataContext } from 'src/util/createPagingDataContext';
import { PromoCodeModel } from 'src/model/PromoCode.model';
import PromoCodeAPI from 'src/service/PromoCodeAPI';
import { PromoCodeListMode } from 'src/enum/PromoCodeListMode';

export interface PromoCodeFilter extends BaseFilter<BasicSortBy> {
  mode: PromoCodeListMode;
  codeTypes: string[];
  orgIds: string[];
}

const fetcher = async (filter: PromoCodeFilter, pageIndex: number, pageSize: number) => {
  return PromoCodeAPI.getPromoCodes(filter, pageIndex, pageSize);
};

const [PromoCodeListProvider, usePromoCodeListContext] = createPagingDataContext<PromoCodeModel, PromoCodeFilter>(
  fetcher,
  {
    pageIndex: 1,
    pageSize: 100,
  },
);

export { usePromoCodeListContext };

export default PromoCodeListProvider;
