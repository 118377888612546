import React, { useCallback, useMemo } from 'react';
import { Typography, Table } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';
import { pageSizeOptions, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { useFeedbackContext } from 'src/context/Feedbacks.context';
import { FeedbackSortBy } from 'src/enum/Feedback.enum';
import { getColumns } from './FeedbackTableColumns';
import { useFeedbackActions } from './FeedbacksTable.actions';
import { Sorter } from 'src/util/createPagingDataContext';

export const FeedbacksTable = () => {
  const { data: feedbacks, loading, pagination, setPagination, filter, setFilter } = useFeedbackContext();

  const [markFeedbackAsComplete] = useFeedbackActions();

  const getSortBy = useCallback((field: string, order: string) => {
    if (!order) return undefined;

    if (field === 'createdAt') {
      return order === 'ascend' ? FeedbackSortBy.SUBMISSION_TIME_ASC : FeedbackSortBy.SUBMISSION_TIME_DESC;
    }
    if (field === 'rating') {
      return order === 'ascend' ? FeedbackSortBy.RATING_LOW_TO_HIGH : FeedbackSortBy.RATING_HIGH_TO_LOW;
    }
    return undefined;
  }, []);

  const columns = useMemo(() => getColumns(filter, markFeedbackAsComplete), [filter]);

  const handleTableChange = (_pagination: any, filterTable: Record<string, any>, sorter: Sorter) => {
    setFilter({
      ...filter,
      ...filterTable,
      sortBy: getSortBy(sorter.field, sorter.order),
    });
  };

  return (
    <div className="mt-3 [&.ant-table-thead>tr>th]:border-b-neutral-30">
      <Table
        loading={loading}
        columns={columns as any[]}
        data={feedbacks}
        onChange={handleTableChange}
        renderEmpty={
          <div>
            <img src="/refunds.png" style={{ width: 148 }} alt="feedbacks" />
            <Spacer />
            <Typography fontCategory="serif" level={7}>
              There are no feedback yet
            </Typography>
          </div>
        }
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.FEEDBACKS,
          showSizeChanger: true,
          pageSizeOptions,
          total: pagination.total,
          onChange: (pageIndex, pageSize) => setPagination({ pageIndex, pageSize }),
          current: pagination.pageIndex,
        }}
      />
    </div>
  );
};
