import { ProductStatus } from 'src/enum/ProductStatus';
import ProductAPI from 'src/service/ProductAPI';
import { ProductModel } from 'src/model/Product.model';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { handleError } from 'src/util/error.util';
import { ProductType } from 'src/enum/ProductType.enum';

class ContextState {
  loading: boolean;
  hasMorePage: boolean;
  data: ProductModel[];
  currentPage: number;
  pageSize: number;
  loadData: () => Promise<void>;
  onNextPage: () => Promise<void>;
  onUpdatedFilter: (value: string) => Promise<void>;
}

export const ProductListComboBoxContext = createContext(new ContextState());

const defaultStatusFilter: ProductStatus[] = [ProductStatus.Active];
const defaultTypeFilter: ProductType[] = [ProductType.ECOMMERCE, ProductType.Main];

const useInfiniteLoadProducts = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [hasMorePage, setHasMorePage] = useState<boolean>(true);
  const [data, setData] = useState<ProductModel[]>([]);
  const [keyword, setKeyword] = useState('');

  const callApi = async (isLoadMore: boolean, searchKeyword: string) => {
    if (loading) {
      return;
    }

    const page = isLoadMore ? currentPage + 1 : DEFAULT_PAGE_INDEX;
    setLoading(true);
    try {
      const result = await ProductAPI.getProducts(
        { status: defaultStatusFilter, keyword: searchKeyword, productType: defaultTypeFilter },
        page,
        DEFAULT_PAGE_SIZE,
      );
      setData(isLoadMore ? [...data, ...result.data] : result.data);
      setCurrentPage(page);
      setKeyword(searchKeyword);
      setHasMorePage(result.data.length >= DEFAULT_PAGE_SIZE);
    } catch (error) {
      handleError(error);
      setHasMorePage(false);
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    await callApi(false, keyword);
  };

  const onUpdatedFilter = async (value: string) => {
    await callApi(false, value);
  };

  const onNextPage = async () => {
    if (!hasMorePage) {
      return;
    }
    await callApi(true, keyword);
  };

  return {
    loading,
    currentPage,
    hasMorePage,
    data,
    loadData,
    onUpdatedFilter,
    onNextPage,
  };
};

const ProductListComboBoxProvider = ({ children }: PropsWithChildren) => {
  const { loading, currentPage, hasMorePage, data, loadData, onUpdatedFilter, onNextPage } = useInfiniteLoadProducts();

  return (
    <ProductListComboBoxContext.Provider
      value={{
        loading,
        data,
        currentPage,
        hasMorePage,
        pageSize: DEFAULT_PAGE_SIZE,
        loadData,
        onNextPage,
        onUpdatedFilter,
      }}
    >
      {children}
    </ProductListComboBoxContext.Provider>
  );
};

export const useProductListComboBoxContext = () => useContext(ProductListComboBoxContext);

export default ProductListComboBoxProvider;
