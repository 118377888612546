import { FilteredDate } from 'src/enum/filteredDate.enum';

export const DateFormat = {
  DATE: 'DD MMM YYYY',
  DATE_TIME: 'DD MMM YYYY HH:mm',
  ISO_DATE: 'YYYY-MM-DD',
  DATE_SPLASH: 'DD/MM/YYYY',
};

export const filterDateOptions = [
  {
    text: 'Previous Month',
    value: FilteredDate.PastMonth,
  },
  {
    text: 'Previous Week',
    value: FilteredDate.PastWeek,
  },
  {
    text: 'Today',
    value: FilteredDate.Today,
  },
  {
    text: 'This Week',
    value: FilteredDate.ThisWeek,
  },
  {
    text: 'This Month',
    value: FilteredDate.ThisMonth,
  },
  // {
  //   text: "Next Week",
  //   value: FilteredDate.NextWeek,
  // },
  // {
  //   text: "Next Month",
  //   value: FilteredDate.NextMonth,
  // },
];
