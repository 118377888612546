import { careNotification, Typography } from '@care/web-ui';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import { Builder } from 'builder-pattern';
import React, { ReactNode, useState } from 'react';
import { Category } from 'src/model/Category.model';
import CategoryService from 'src/service/Category.service';
import MediaService from 'src/service/Media.service';
import { handleError } from 'src/util/error.util';

class ContextState {
  groupCats: Category[] = [];

  catRoutings: ComboBoxItem[] = [];

  loadGroupCats: () => Promise<any>;

  loading = false;

  createCategory: (data: Category) => Promise<any>;

  updateCategory: (data: Category) => Promise<boolean>;

  deleteCategory: (categoryId: string, isParentCategory: boolean) => Promise<boolean>;
}

export const CategoryContext = React.createContext(new ContextState());

export const convertSubcategory = (list: Category[]): Category[] => {
  const categories = list
    .filter((item) => !item.parentCategoryId)
    .map((parent) => {
      const items = list.filter((item) => item.parentCategoryId === parent.id);
      return Builder(Category, parent).subCats(items).build();
    });

  return categories;
};

const CategoryProvider = ({ children }: React.PropsWithChildren) => {
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('country');

  const [loading, setLoading] = useState<boolean>(false);
  const [groupCats, setGroupCats] = useState<Category[]>([]);
  const [catRoutings, setCatRoutings] = useState<ComboBoxItem[]>([]);

  const modifyCategoryToPath = (cats: Category[]) => {
    const results: ComboBoxItem[] = [];
    const finalPath = `categories/:catId/products`;

    cats.forEach((c) => {
      results.push({ text: `${c.name}/All`, value: finalPath.replace(':catId', c.id) });
      c.subCats.forEach((sc) => {
        results.push({
          text: `${c.name}/${sc.name}`,
          value: finalPath.replace(':catId', sc.id),
        });
      });
    });

    return results;
  };

  const loadGroupCats = async (): Promise<any> => {
    try {
      setLoading(true);
      const body = await CategoryService.getAllCategories(country);
      setGroupCats(convertSubcategory(body.data));
      setCatRoutings(modifyCategoryToPath(convertSubcategory(body.data)));
      return body;
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  const createCategory = async (data: Category): Promise<any> => {
    try {
      setLoading(true);
      const mediaId = data.localFile ? await MediaService.uploadFile(data.localFile, false) : null;

      const body = await CategoryService.createCategory({
        ...data,
        mediaId,
        active: data.active || false,
      });
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={7}>Created {data.parentCategoryId ? 'sub-category' : 'category'} successfully.</Typography>
        ),
        duration: 2,
      });
      return body;
    } catch (error) {
      handleError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateCategory = async (data: Category): Promise<boolean> => {
    let mediaId = null;

    try {
      setLoading(true);

      if (data.localFile) {
        mediaId = await MediaService.uploadFile(data.localFile, false); //case upload new Img
      } else if (data.mediaId) {
        mediaId = data.mediaId; // case no edit Img
      }

      await CategoryService.updateCategory({
        ...data,
        mediaId,
      });

      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={7}>Updated {data.parentCategoryId ? 'sub-category' : 'category'} successfully.</Typography>
        ),
        duration: 2,
      });
      return true;
    } catch (error) {
      handleError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = async (categoryId: string, isParentCategory: boolean): Promise<boolean> => {
    try {
      setLoading(true);
      await CategoryService.deleteCategory(categoryId);

      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={7}>Deleted {isParentCategory ? 'category' : 'sub-category'} successfully.</Typography>
        ),
        duration: 2,
      });
      return true;
    } catch (error) {
      handleError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CategoryContext.Provider
      value={{
        groupCats,
        catRoutings,
        loadGroupCats,
        loading,
        createCategory,
        updateCategory,
        deleteCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
