import React, { useState } from 'react';
import { careNotification } from '@care/web-ui';
import { useProductListContext } from 'src/context/ProductList.context';
import { useOrdersContext } from 'src/context/Orders.context';
import { exportOrdersData } from 'src/service/OrderAPI';
import { handleError } from 'src/util/error.util';
import { prepareExportFilter } from 'src/util/order.util';

export const useExportOrderData = () => {
  const { filter } = useOrdersContext();
  const { data: products } = useProductListContext();
  const productIds = React.useMemo(() => products.map((p) => p.id), [products]);
  const [isLoading, setLoading] = useState(false);
  const isExportOrderDisabled = filter.productIds?.length !== 1;

  const exportOrderData = React.useCallback(
    async (email: string) => {
      const fetcherFilter = prepareExportFilter(filter, productIds);
      try {
        setLoading(true);
        const result = await exportOrdersData(fetcherFilter, email);
        careNotification({
          type: 'success',
          message: `Exported data will be sent to ${email} shortly`,
          duration: 5,
        });
        return result;
      } catch (error) {
        console.error(error);
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [productIds, filter],
  );

  return { isExportOrderDisabled, exportOrderData, isExportOrderLoading: isLoading };
};
