import { Input, Row, Utils } from '@care/web-ui';
import { withDebounceInputChange } from '@care/web-ui/lib/hoc';
import { shallow } from '@care/web-ui/lib/store';
import React, { useCallback, useState } from 'react';
import { DEFAULT_PAGE_INDEX, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { DeepLinkListStore, useDeepLinkStore } from 'src/zustand-store/deep-link/DeepLinkList.store';

const DebouncedInput = withDebounceInputChange(Input);

const {
  CommonUtil: { pick },
} = Utils;

const DeepLinksTableToolbar = () => {
  const { filter, setFilter, isLoading } = useDeepLinkStore(
    (store: DeepLinkListStore) => pick(store, ['filter', 'setFilter', 'isLoading'] as Array<keyof DeepLinkListStore>),
    shallow,
  );
  const { search } = filter;

  const handleSearch = (value: string) => {
    setFilter({ ...filter, search: value, pageIndex: DEFAULT_PAGE_INDEX, pageSize: PAGE_SIZE_PER_MODULE.DEEPLINKS });
  };

  return (
    <div className="deep-links-table-toolbar">
      <Row gutter={16} spans={[12]}>
        <DebouncedInput
          iconRight={{ name: 'Search' }}
          placeholder="Search a page to generate deeplink for"
          disabled={isLoading}
          value={search}
          onChange={handleSearch}
        />
      </Row>
    </div>
  );
};

export default DeepLinksTableToolbar;
