import React, { useContext, useEffect, useMemo } from 'react';
import { Button, Icon, Table, Typography, Modal } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';
import { usePromoCodeListContext } from 'src/context/PromoCodeList.context';
import { PromoCodeType, PromoCodeTypeMapping } from 'src/enum/PromoCodeType';
import './PromoCodeTable.scss';
import { Link } from 'react-router-dom';
import { Routes } from 'src/constant';
import { PromoCodeInfoTable } from '../PromoCodeInfoTable.tsx/PromoCodeInfoTable';
import { ColumnFilterItem, FilteredDropdown } from 'src/component/FilteredDropdown/FilteredDropdown';
import { PromoCodeActionContext } from 'src/context/PromoCodeAction.context';
import { useOrganizationListContext } from 'src/context/OrganizationList.context';
import { pageSizeOptions, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { OrganizationModel } from 'src/model/OrganizationModel';

const codeTypeOptions: ColumnFilterItem[] = [
  {
    text: 'Promo',
    value: PromoCodeType.Promo,
  },
  {
    text: 'Redemption',
    value: PromoCodeType.Redemption,
  },
];

const getColumns = (
  organizations: OrganizationModel[],
  deleteFunc: (id: string) => void,
  selectedCodeType: string[],
) => [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (name: string) => (
      <Typography level={9} weight="bold">
        {name}
      </Typography>
    ),
  },
  {
    title: 'Code',
    dataIndex: 'code',
    render: (code: string) => <Typography level={9}>{code}</Typography>,
  },
  {
    title: 'Type',
    dataIndex: 'codeType',
    filteredValue: selectedCodeType || null,
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <FilteredDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          filters={codeTypeOptions}
          confirm={confirm}
          selectedAllText="All Types"
          clearFilters={clearFilters}
        />
      );
    },
    onFilter: (value: string, record: { codeType: { toString: () => string } }) => {
      return record.codeType.toString().toLowerCase().includes(value.toLowerCase());
    },
    render: (codeType: PromoCodeType) => <Typography level={9}>{PromoCodeTypeMapping[codeType]}</Typography>,
  },
  {
    title: 'Status',
    dataIndex: 'active',
    render: (active: boolean) => <Typography level={9}>{active ? 'Active' : 'Inactive'}</Typography>,
  },
  {
    title: '',
    dataIndex: 'id',
    render: (id: string) => (
      <div>
        <Link to={`${Routes.PromoCodes}/${id}/edit`}>
          <Button type="link" title="Edit" size="small" />
        </Link>
        <Button type="link" title="Delete" size="small" style={{ color: '#891A16' }} onClick={() => deleteFunc(id)} />
      </div>
    ),
  },
];

export const PromoCodeTable = () => {
  const { loading, data, filter, loadData, setFilter, pagination, setPagination } = usePromoCodeListContext();
  const { deletePromoCode } = useContext(PromoCodeActionContext);
  const { data: organizations } = useOrganizationListContext();

  useEffect(() => {
    loadData();
  }, [filter]);

  const handleDeletePromoCode = (id: string): void => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'This promo code will be deleted. This action cannot be undo.',
      type: 'error',
      onOk: async () => {
        const result = await deletePromoCode(id);

        if (result) {
          loadData();
        }
      },
    });
  };

  const columns = useMemo(
    () => getColumns(organizations, handleDeletePromoCode, filter.codeTypes),
    [organizations, filter.codeTypes],
  );

  const handleTableChange = (_, filterTable: Record<string, null>) => {
    const codeTypes = (filterTable.codeType || []) as PromoCodeType[];
    const orgIds = (filterTable.organizationId || []) as string[];

    setFilter({ ...filter, codeTypes, orgIds });
  };

  return (
    <div className="promoCodeTable">
      <Table
        loading={loading}
        columns={columns as any[]}
        data={data}
        renderEmpty={
          <div>
            <img src="/promoCode.png" style={{ width: 148 }} alt="promoCode" />
            <Spacer />

            <Typography fontCategory="serif" level={7}>
              Manage your promo codes
            </Typography>

            <Spacer />

            <Typography level={8} className="hint">
              Create a code for the consumers to redeem any products free of charge at a given time range
            </Typography>

            <Spacer size={21} />

            <Link to={`${Routes.PromoCodes}/0/edit`}>
              <Button title="Create Promo Code" />
            </Link>
          </div>
        }
        expandable={{
          expandedRowRender: ({ info }) => <PromoCodeInfoTable info={info} />,

          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Icon name="DownSmall" type="outline" onClick={(e) => onExpand(record, e as any)} />
            ) : (
              <Icon name="RightSmall" type="outline" onClick={(e) => onExpand(record, e as any)} />
            ),
        }}
        onChange={handleTableChange}
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.PROMOCODES,
          showSizeChanger: true,
          pageSizeOptions,
          total: pagination.total,
          current: pagination.pageIndex,
          pageSize: pagination.pageSize,
          onChange: (pageIndex, pageSize) => setPagination({ pageIndex, pageSize }),
        }}
      />
    </div>
  );
};
