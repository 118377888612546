import Utils from '@care/web-ui/lib/utils';

const {
  ArrayUtil: { isEqualIgnoreOrder },
} = Utils;

export const autoRemoveDataIfSelectedAll = ({
  selectedValues,
  values,
  maxSize = 20,
}: {
  selectedValues: string[];
  values: string[];
  maxSize?: number;
}): string[] => {
  if (isEqualIgnoreOrder(selectedValues, values)) {
    return [];
  }
  if (selectedValues.length > maxSize) {
    //limit specifying indices in an array to a maximum index of 20
    return [];
  }
  return selectedValues;
};
