import { useFormatOrderDetail } from 'src/hooks/orders/useFormatOrderDetail';
import { FormattedOrderDetails } from 'src/model/OrderDetail.model';
import { useOrderDetailContext } from 'src/context/OrderDetail.context';

export const useFormattedDataOrderDetails = (
  id: string,
  pickValues: (values: FormattedOrderDetails) => FormattedOrderDetails,
) => {
  const { handleFormatOrderDetails } = useFormatOrderDetail();
  const { order } = useOrderDetailContext();

  const formattedData: FormattedOrderDetails = handleFormatOrderDetails(order);
  return pickValues(formattedData);
};
