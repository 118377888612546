import { BaseFilter, createPagingDataContext } from 'src/util/createPagingDataContext';
import { ProductStatus } from 'src/enum/ProductStatus';
import ProductAPI from 'src/service/ProductAPI';
import { ProductModel } from 'src/model/Product.model';
import { PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { ProductSortBy } from 'src/enum/ProductSortBy.enum';

export interface ProductFilter extends BaseFilter<ProductSortBy> {
  status?: ProductStatus[];
  organizationIds?: string[];
  isIncludeRefund?: boolean;
  productType?: string[];
  productIds?: string[];
}

const fetcher = async (filter: ProductFilter, pageIndex: number, pageSize: number) => {
  return ProductAPI.getProducts(filter, pageIndex, pageSize);
};

const queryParams = new URLSearchParams(window.location.search);
const productId = queryParams.get('productId');

const [ProductListProvider, useProductListContext] = createPagingDataContext<ProductModel, ProductFilter>(
  fetcher,
  {
    pageIndex: 1,
    pageSize: PAGE_SIZE_PER_MODULE.PRODUCTS,
  },
  productId ? { productIds: [productId] } : {},
);

export { useProductListContext };

export default ProductListProvider;
