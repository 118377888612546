import ProductService from 'src/service/Product.service';
import { ECommerceProductsDetailsStore, useStore } from 'src/zustand-store/product/ECommerceProductsDetail.store';
import { QueryState } from '@care/web-ui/lib/store/store.queryCaching';
import { Utils } from '@care/web-ui';

import { QueryCaching, shallow } from '@care/web-ui/lib/store';
import { ProductsDetail } from 'src/model/Product.model';

const {
  CommonUtil: { pick, find },
} = Utils;

export const useQueryCategoryECommerceProductDetail = () => {
  const fetchDataByKey = useStore((store: ECommerceProductsDetailsStore) => store.fetchDataByKey);
  const handleQueryECommerceProductsDetail = ({
    id,
    callback,
    callbackError,
  }: {
    id: string;
    callback?: (response: ProductsDetail) => void;
    callbackError?: () => void;
  }) => {
    fetchDataByKey({
      queryKey: id,
      expiredTime: 30 * 1e3,
      fetcher: () => {
        return ProductService.getProductDetail(id);
      },
      fetchDataSuccess: (response) => {
        if (typeof callback === 'function') {
          callback(response);
        }
      },
      fetchDataError: () => {
        if (typeof callbackError === 'function') {
          callbackError();
        }
      },
    });
  };
  return {
    handleQueryECommerceProductsDetail,
  };
};

const { defaultQueryState } = QueryCaching;

export const useQueryProductByKey = (id: string): QueryState<ProductsDetail> => {
  const { dataByKeys } = useStore(
    (store: ECommerceProductsDetailsStore) => pick(store, ['dataByKeys'] as Array<keyof ECommerceProductsDetailsStore>),
    shallow,
  );

  const productDetails: QueryState<ProductsDetail> = find(dataByKeys, {
    queryKey: id,
  });
  return (
    productDetails || {
      ...defaultQueryState,
      data: { categories: [] } as ProductsDetail,
    }
  );
};
