import { Button, Popover, ScrollView, Text, TreeSelect } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import React, { Key, useMemo, useState } from 'react';

import { useQueryCategoryECommerceProductDetail, useQueryProductByKey } from './ProductCategory.action';
import { useCategoriesDataResource } from 'src/hooks/category/useCategoryResource';

const {
  EllipsisUtil: { ellipsisTail },
} = Utils;
const TreeCategory = ({ id }: { id: string }) => {
  const dataCategoriesResource = useCategoriesDataResource();

  const { data: { categories = [] } = {} } = useQueryProductByKey(id);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  const treeData = useMemo(() => {
    if (!categories?.length) {
      return [];
    }
    return dataCategoriesResource.filter((item) => {
      const subCategory = item.children;
      const dataChild = subCategory.length > 0 ? subCategory.map((i) => i.key) : [item.key];
      if (categories.some((element) => dataChild.includes(element))) {
        return item;
      }
    });
  }, [dataCategoriesResource, categories]);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
  };

  return (
    <div className="custom-tree-select">
      <TreeSelect
        checkedKeys={categories}
        expandedKeys={expandedKeys}
        treeData={treeData}
        onExpand={onExpand}
        className="bg-white"
      />
    </div>
  );
};

const ProductsCategory = ({ item: { id = '', name = '' } = {} }: any) => {
  const { handleQueryECommerceProductsDetail } = useQueryCategoryECommerceProductDetail();

  return (
    <Popover
      content={
        <ScrollView className="h-44">
          <TreeCategory id={id} />
        </ScrollView>
      }
      title={<Text className="font-semibold text-primary-6">{ellipsisTail({ str: name, limit: 20 })}</Text>}
      trigger="click"
      placement="left"
      overlayInnerStyle={{
        width: '300px',
        minHeight: '100px',
        maxHeight: '350px',
      }}
    >
      <Button
        title="View more"
        type="link"
        size="small"
        style={{ fontSize: '14px', fontWeight: 'bold' }}
        onClick={() => handleQueryECommerceProductsDetail({ id })}
      />
    </Popover>
  );
};

export default ProductsCategory;
