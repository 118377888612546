import React from 'react';
import { Table, Tag, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import { RefundAppointmentDTO } from 'src/model/dto/RefundDetail.response';
import { AppointmentStatus } from 'src/enum/AppointmentStatus';
import { appointmentStatusTypeTag } from 'src/constant/AppointmentStatusTag.constant';

import './OrderItemTable.scss';

const { formatDateString } = Utils.DateUtil;

interface Props {
  refundAppts: Pick<RefundAppointmentDTO, 'bookingNo' | 'startTime' | 'status'>[];
}

const ViewOrderItemApptTable = ({ refundAppts }: Props) => {
  const columns = [
    {
      title: 'Appointment ID',
      dataIndex: 'bookingNo',
      key: 'bookingNo',
      render: (value: string) => <Typography level={9}>{value}</Typography>,
    },
    {
      title: 'Appointment Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: AppointmentStatus) => {
        return (
          <Tag text={appointmentStatusTypeTag[status].label} type={appointmentStatusTypeTag[status].type as any} />
        );
      },
    },
    {
      title: 'Appointment Date',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime: string) => <Typography level={9}>{formatDateString(startTime)}</Typography>,
    },
  ];

  return (
    <div className="orderItemAppt-table-container">
      <Table columns={columns} data={refundAppts} />
    </div>
  );
};

export default ViewOrderItemApptTable;
