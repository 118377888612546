import React from 'react';
import { Typography } from '@care/web-ui';

const HealthCheckContainer = () => {
  return (
    <div>
      <Typography level={8} weight="regular" fontCategory="sans" className="infoName">
        I&apos;m alive, other die, who knows.
      </Typography>
    </div>
  );
};

export default HealthCheckContainer;
