import { TextArea } from '@care/web-ui';
import { TextAreaProps } from '@care/web-ui/lib/components/TextArea/TextArea';
import React from 'react';
import Field from './Field';

interface Props extends TextAreaProps {
  name: string;
  control: any;
}

const TextAreaField = ({ name, control, onChange: onValueChange, customAttr, ...rest }: Props) => (
  <Field
    name={name}
    control={control}
    customAttr={customAttr}
    render={({ onChange, value }) => (
      <TextArea
        {...rest}
        value={value}
        customAttr={customAttr}
        onChange={(newValue) => {
          onChange(newValue);
          if (onValueChange) onValueChange(newValue);
        }}
      />
    )}
  />
);

export default TextAreaField;
