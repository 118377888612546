import React from 'react';
import { Typography } from '@care/web-ui';
import { OrganizationModel } from 'src/model/OrganizationModel';
import { FilteredDropdown } from 'src/component/FilteredDropdown/FilteredDropdown';

export const renderOrgFilterColumn = (organizations: OrganizationModel[]) => {
  const orgOptions = organizations.map((org) => ({
    text: org.orgName,
    value: org.id,
  }));

  return {
    title: 'Organization',
    dataIndex: 'organizationId',
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <FilteredDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          filters={orgOptions}
          confirm={confirm}
          selectedAllText="All Organizations"
          clearFilters={clearFilters}
        />
      );
    },
    onFilter: (value: string, record: { organizationId: string }) => record.organizationId.includes(value),
    render: (organizationId: string) => (
      <Typography level={9}>{orgOptions.find((op) => op.value === organizationId)?.text ?? ''}</Typography>
    ),
  };
};
