import React, { useContext, useMemo, useState } from 'react';
import { Modal, ComboBox, TextArea, Typography } from '@care/web-ui';

import '../approve-refund-modal/ApproveRefundModal.scss';
import { RefundsContext } from 'src/context/Refunds.context';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import Spacer from 'src/component/Spacer/Spacer';

interface Props {
  visible: boolean;
  onDone: () => any;
  onClose: () => any;
  id: string;
}

const reasonItems: ComboBoxItem[] = [
  { text: 'Payment not made', value: 'Payment not made' },
  {
    text: 'Others',
    value: 'Others',
  },
];

export const RejectRefundModal = ({ visible, onClose, onDone, id }: Props) => {
  const { loading, reject } = useContext(RefundsContext);
  const [reason, setReason] = useState<string>(reasonItems[0].value);
  const [isShowRemark, setIsShowRemark] = useState(false);
  const [detail, setDetail] = useState('');
  const [isWarning, setIsWarning] = useState(false);

  const onReject = async () => {
    const done = await reject(id, detail || reason);
    if (done) {
      onDone();
      onClose();
    }
  };

  const handleChangeRejectOption = (value: string) => {
    setReason(value);
    if (value === 'Others' && !detail) {
      setIsShowRemark(true);
      setIsWarning(true);
    } else {
      setIsShowRemark(false);
      setIsWarning(false);
      setDetail('');
    }
  };

  const handleRemarkChange = (value: string) => {
    setDetail(value);
    setIsWarning(value === '');
  };

  const isDisabled = useMemo(() => reason === 'Others' && !detail, [reason, detail]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Reject Refund"
      okText="Reject Refund"
      onOk={onReject}
      okButtonProps={{ loading, disabled: isDisabled, type: 'danger' as any }}
      content={
        <div>
          <Typography level={8}>
            This will void the refund request. A new request has to be created to refund.
          </Typography>
          <Spacer size={16} />
          <ComboBox
            value={reason}
            placeholder="Select an option"
            onChange={(value) => handleChangeRejectOption(value as string)}
            label="Please select a reject reason"
            items={reasonItems}
          />
          <Spacer size={16} />
          {isShowRemark && <TextArea value={detail} onChange={handleRemarkChange} label="Remarks" />}

          {isWarning && (
            <Typography className="errText" level={10}>
              Please fill remarks.
            </Typography>
          )}
        </div>
      }
    />
  );
};
