import { Builder } from 'builder-pattern';
import { OrderStatus } from 'src/enum/OrderStatus';
import { RefundRequestedBy } from 'src/enum/RefundRequestedBy';
import { RefundStatus } from 'src/enum/RefundStatus';
import { RefundType } from 'src/enum/refundType.enum';
import { Amount } from './Amount.model';
import { ConsumerModel } from './Consumer.model';
import { RefundModelDTO } from './dto/Refund.response';
import { ProductModel } from './Product.model';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';

const { formatDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;
const { defaultCurrency } = Constants.CurrencyConstant;

interface AppointmentBooking {
  id: string;
  cancellationWindowDateTime: string;
  startTime: string;
}
interface RefundOrderItem {
  id: string;
  appointmentBookings: AppointmentBooking[];
  product: ProductModel;
}

interface RefundItemDetail {
  consumerName: string;
  appointmentDates: string[];
  nonCancellationDateTimes: string[];
  processType: string;
  processedBy: string;
  requestedBy: RefundRequestedBy;
}

export interface RefundOrderModel {
  consumer: ConsumerModel;
  id: string;
  status: OrderStatus;
  orderItems: RefundOrderItem[];
}

export class RefundModel {
  id: string;
  amount: Amount;
  status: RefundStatus;
  reason: string;
  order: RefundOrderModel;
  orgName: string;
  createdAt: string;
  createdBy: string;
  createdById: string;
  expiration: string;
  fee: Amount;
  orderId: string;
  providerRefundId: string;
  refundAmount: Amount;
  refundType: RefundType;
  rejectDetail: string;
  rejectReason: string;
  refundNumber: string;
  processType: string;
  processedBy: string;
  requestedBy: RefundRequestedBy;
  settlementDate: string;

  get createAtText(): string {
    return formatDateString(this.createdAt, DATE.DATE_TIME);
  }

  get settlementDateText(): string {
    return this.settlementDate ? formatDateString(this.settlementDate) : '-';
  }

  static convertFromDTO(dto: RefundModelDTO, productCurrency: string): RefundModel {
    const { amount, fee, refundAmount, ...rest } = dto;
    return Builder(RefundModel, {
      ...rest,
      amount: Builder(Amount, {
        value: Number(amount),
        currency: productCurrency || defaultCurrency,
      }).build(),
      fee: Builder(Amount, {
        value: Number(fee),
        currency: productCurrency || defaultCurrency,
      }).build(),
      refundAmount: Builder(Amount, {
        value: Number(refundAmount),
        currency: productCurrency || defaultCurrency,
      }).build(),
    }).build();
  }

  get refundSummary(): string {
    if (this.status === RefundStatus.Rejected) {
      return `Reason: ${this.rejectReason}. Detail: ${this.rejectDetail}`;
    }
    if (this.status === RefundStatus.Success) {
      return `Amount: ${this.refundAmount.valueWithSymbol} (Fee: ${this.fee.valueWithSymbol})`;
    }

    return ``;
  }

  get consumer(): ConsumerModel {
    return Builder(ConsumerModel, this.order.consumer).build();
  }

  get productName(): string {
    return this.order.orderItems[0]?.product?.name || '';
  }

  get orderStatus(): OrderStatus {
    return this.order.status;
  }

  get refundItemDetail(): RefundItemDetail {
    const { consumer, processType, requestedBy, processedBy } = this;
    const appointmentDates = [];
    const nonCancellationDateTimes = [];

    this.order.orderItems.forEach((oi) => {
      oi.appointmentBookings.forEach((appt) => {
        appointmentDates.push(appt.startTime);
        nonCancellationDateTimes.push(appt.cancellationWindowDateTime || '');
      });
    });

    const consumerName = consumer.fullName;

    return {
      consumerName,
      appointmentDates,
      nonCancellationDateTimes,
      processType,
      processedBy,
      requestedBy,
    };
  }
}
