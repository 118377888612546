import { Button, Modal, Row } from '@care/web-ui';
import React from 'react';
import { PlatformSetting } from 'src/model/PlatformSetting.model';
import InputField from 'src/component/Field/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { platformSettingsSchema } from './PlatformSettingsSchema';
import TextAreaField from 'src/component/Field/TextAreaField';
import { JSONValue, safeJsonParse } from 'src/util/String';
import { defaultSetting } from 'src/constant/PlatformSetting.constant';

interface Props {
  setting: PlatformSetting;
  visible: boolean;
  onClose: () => void;
  onSave: (value: PlatformSetting) => Promise<void>;
  isCreate: boolean;
  isLoading: boolean;
}

export const PlatformSettingsModal = (props: Props) => {
  const { visible, onClose, onSave, isCreate, setting, isLoading } = props;
  const headerTitle = `${isCreate ? 'Add' : 'Edit'} Settings`;

  const { control, reset, handleSubmit } = useForm({
    resolver: yupResolver(platformSettingsSchema(isCreate)),
    defaultValues: defaultSetting,
    values: setting,
  });

  const handleCloseModal = () => {
    onClose();
    reset(defaultSetting);
  };

  const castingValue = (setting: string): JSONValue => {
    if (setting === 'null') {
      return null;
    }

    const result = safeJsonParse(setting);
    if (result !== null) {
      return result;
    }
    return setting;
  };

  const handleCreateOrEdit = () => {
    const submitHandler = handleSubmit(async (setting: PlatformSetting) => {
      const result = castingValue(setting.value);
      await onSave({ ...setting, value: result });
      reset(defaultSetting);
    });
    submitHandler();
  };

  return (
    <Modal
      width={700}
      onCancel={handleCloseModal}
      visible={visible}
      title={headerTitle}
      noFooter
      content={
        <form>
          <Row gutter={[12, 12]}>
            <InputField control={control} name="key" data-testid="setting-key-field" label="Key Name" placeholder="" />
            <TextAreaField
              control={control}
              name="value"
              label="Value"
              placeholder=""
              customAttr={{ dataTestId: 'setting-value-field' }}
            />
            <InputField control={control} name="unit" data-testid="setting-unit-field" label="Unit" placeholder="" />
            <div className="groupBtnEdit mt-8">
              <div className="footerBtn ml-auto">
                <Button type="outline" title="Cancel" onClick={handleCloseModal} />
                <Button loading={isLoading} title="Save" onClick={handleCreateOrEdit} />
              </div>
            </div>
          </Row>
        </form>
      }
    />
  );
};
