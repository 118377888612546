import { Media } from 'src/model/Media.model';

export interface IUploadedFile {
  uid: string;
  name: string;
  status?: string;
  url: string;
  type?: string;
}

export const buildDefaultFiles = (medias: Media[]): IUploadedFile[] => {
  return medias.map((m) => ({
    uid: m.id,
    name: m.name,
    status: m.status,
    url: m.url,
    type: m.mimeType,
  }));
};

export const converByteToMb = (fileSize: number) => {
  return fileSize / 1024 / 1024;
};

export const buildLocalUrl = (file: File): string => URL.createObjectURL(file);
