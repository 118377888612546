import React from 'react';
import { Tag, Typography, Icon } from '@care/web-ui';
import { Link } from 'react-router-dom';
import { OrganizationModel } from 'src/model/OrganizationModel';
import { OrganizationStatus } from 'src/enum/OrganizationStatus';
import { Routes } from 'src/constant';
import { organisationStatusTag } from 'src/constant/OrganisationsStatusTag.constant';
import { FilteredDropdown } from 'src/component/FilteredDropdown/FilteredDropdown';
import { organisationStatusOptions } from 'src/constant/OptionsConstant';

export const getColumns = () => [
  {
    title: 'Logo',
    dataIndex: 'logoUrl',
    render: (logoUrl: string) => {
      return <img src={logoUrl || '/default-org-logo.png'} className="logo" />;
    },
  },
  {
    title: 'Organization Display Name',
    dataIndex: 'displayName',
    sorter: (sortOrder: string) => sortOrder,
    render: (displayName: string, item: OrganizationModel) => (
      <>
        <Link to={Routes.Organisations + '/' + item.id} className="org-display-name">
          <Typography level={9} weight="bold" className="name">
            {displayName}
          </Typography>
          <Icon name="RightSmall" type="outline" color="primary" />
        </Link>
      </>
    ),
  },
  {
    title: 'Organization Name',
    dataIndex: 'name',
    sorter: (sortOrder: string) => sortOrder,
    render: (name: string) => <Typography level={9}>{name}</Typography>,
  },
  {
    title: 'Registration No.',
    dataIndex: 'registrationNumber',
    sorter: (sortOrder: string) => sortOrder,
    render: (registrationNumber: string) => <Typography level={9}>{registrationNumber}</Typography>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    onFilter: (value: string, record: OrganizationModel) => {
      return record.status === value;
    },
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <FilteredDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          filters={organisationStatusOptions}
          confirm={confirm}
          selectedAllText="All Statuses"
          clearFilters={clearFilters}
        />
      );
    },
    render: (status: OrganizationStatus) => {
      return (
        <div className="action-group">
          <Tag text={organisationStatusTag[status]?.label} type={organisationStatusTag[status]?.type as any} />
        </div>
      );
    },
  },
];
