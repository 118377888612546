import React from 'react';
import { Divider, Typography } from '@care/web-ui';
import { OrderCodeTypes, OrderType } from 'src/enum/OrderStatus';
import Utils from '@care/web-ui/lib/utils';
import { useFormattedDataOrderDetails } from 'src/module/order-detail/OrderDetail.actions';
import { FormattedOrderDetails } from 'src/model/OrderDetail.model';

const {
  CommonUtil: { pick },
} = Utils;

interface Props {
  id: string;
}

const CodeFooter = ({ id }: { id: string }) => {
  const { redemptionCode, promoCode, formatAmountDiscountString } = useFormattedDataOrderDetails(id as string, (s) =>
    pick(s, ['redemptionCode', 'promoCode', 'formatAmountDiscountString'] as Array<keyof FormattedOrderDetails>),
  );
  const code: string = promoCode || redemptionCode;

  if (!code) return null;

  const type: string = promoCode ? OrderCodeTypes.Promo : OrderCodeTypes.Redemption;

  return (
    <>
      <div className="pb-4">
        <div className="flex justify-end">
          <div className="mr-4">
            <Typography className="px-2" level={9}>
              {type} Code: {code}
            </Typography>
          </div>
          <div className="mr-4 w-40 pl-4">
            <Typography className="px-2" level={9}>
              1
            </Typography>
          </div>
          <div className="w-40">
            <Typography className="text-sm font-semibold leading-5" level={9} weight="bold" key="amount">
              -{formatAmountDiscountString}
            </Typography>
          </div>
        </div>
      </div>
      <Divider className="" />
    </>
  );
};

const ExtraInfoProductOrderFooter = ({ id }: { id: string }) => {
  const { deliveryFeeFormatted, formatSubtotalString } = useFormattedDataOrderDetails(id, (s) =>
    pick(s, ['formatSubtotalString', 'deliveryFeeFormatted'] as Array<keyof FormattedOrderDetails>),
  );

  const infoFactory: { title: string; value: string }[] = [
    { title: 'Subtotal', value: formatSubtotalString },
    { title: 'Delivery', value: deliveryFeeFormatted },
  ];

  return (
    <>
      {infoFactory.map((props, index) => (
        <BlockInfo key={index} {...props} />
      ))}
    </>
  );
};

const BlockInfo = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className="pt-4">
      <div className="flex justify-end">
        <div className="grid min-w-80 grid-cols-3">
          <div className=" col-span-1">
            <Typography className="px-2" level={9} weight="bold" key="quantity">
              {title}
            </Typography>
          </div>
          <div className="col-span-2 w-40">
            <Typography
              className="truncate text-sm font-semibold leading-5 text-primary-6"
              level={9}
              weight="bold"
              key="amount"
            >
              {value}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderDetailsInformationFooterTable = ({ id }: Props) => {
  const { formatGrandTotalString, orderType } = useFormattedDataOrderDetails(id as string, (s) =>
    pick(s, ['formatGrandTotalString', 'orderType'] as Array<keyof FormattedOrderDetails>),
  );

  const ExtraInfo = () => {
    switch (orderType) {
      case OrderType.Product:
        return <ExtraInfoProductOrderFooter id={id} />;
      default:
        return null;
    }
  };

  return (
    <div className="-mx-4">
      <CodeFooter id={id as string} />
      <ExtraInfo />
      <BlockInfo title="Total" value={formatGrandTotalString} />
    </div>
  );
};

export default OrderDetailsInformationFooterTable;
