import { QueryPagination, StateCreator, createStore } from '@care/web-ui/lib/store';
import { QueryStore } from '@care/web-ui/lib/store/store.queryPagination';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import { DeepLinkFilter, DeepLinkItem } from 'src/model/DeepLink.model';

const { queryStateCreator } = QueryPagination;

export const $DEEP_LINK_LIST_STORE = '$DEEP_LINK_LIST_STORE';

export interface DeepLinkState {
  filter: DeepLinkFilter;
  data: DeepLinkItem[];
}

export const initialState: DeepLinkState = {
  filter: {
    search: '',
    sortBy: null,
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  data: [],
};

export type DeepLinkListStore = DeepLinkState & QueryStore<DeepLinkItem, DeepLinkFilter>;

const stateCreator: StateCreator<DeepLinkListStore> = (set, get, storeApi) => ({
  ...queryStateCreator(set, get, storeApi),
  ...initialState,
  resetStore: () => {
    set({ ...initialState });
  },
});

export const [useDeepLinkStore, selector] = createStore<DeepLinkListStore>(stateCreator, {
  storeName: $DEEP_LINK_LIST_STORE,
});
