import { DiscountType } from 'src/enum/DiscountType';
import { PromoCodeType } from 'src/enum/PromoCodeType';
import { Organisation } from './CreatePromoCode.request';

export class PromoCodeDetailModelDTO {
  id: string;
  codeType: PromoCodeType;
  code: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  discountType: DiscountType;
  amount: number;
  quantityClaimed: number;
  totalQuantity: number;
  quantityPerUser: number;
  active: boolean;
  createdBy: string;
  updatedBy: string;
  organizations: Organisation[];
}
