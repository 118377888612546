import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';
import config from 'src/Config';
import { SpecialtyModel } from 'src/model/Specialty.model';
import { APIBase } from 'src/util/Request';

const URL = `${config.API_URL}/specialties`;

class SpecialtyService {
  async getAllSpecialties(): Promise<GeneralListResponse<SpecialtyModel>> {
    const body = await APIBase.get<GeneralListResponse<SpecialtyModel>>(`${URL}?pageSize=100&pageIndex=1`);
    return body.data;
  }
}

export default new SpecialtyService();
