import React, { useContext, useMemo } from 'react';
import { Button, Tabs, Typography } from '@care/web-ui';
import { useHistory } from 'react-router-dom';

import Spacer from 'src/component/Spacer/Spacer';
import { PromoCodeSummaryResDTO } from 'src/model/dto/PromoCode.response';
import { TabProps } from '@care/web-ui/lib/components/Tabs/Tabs';
import { PromoCodeListMode } from 'src/enum/PromoCodeListMode';
import { PromoCodeActionContext } from 'src/context/PromoCodeAction.context';
import { usePromoCodeListContext } from 'src/context/PromoCodeList.context';
import './PromoCodes.scss';
import { PromoCodeTable } from '../PromoCodeTable/PromoCodeTable';
import { PromoCodeTableFilter } from '../PromoCodeTableFilter/PromoCodeTableFilter';
import { Routes } from 'src/constant';
import { DEFAULT_PAGE_INDEX, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';

const getPromoCodeTabs = (summary: PromoCodeSummaryResDTO): TabProps[] => {
  return [
    {
      title: 'All',
      number: summary ? summary.total : 0,
      key: PromoCodeListMode.All,
    },
    {
      title: 'Active',
      number: summary ? summary.active : 0,
      key: PromoCodeListMode.Active,
    },
    {
      title: 'Inactive',
      number: summary ? summary.inactive : 0,
      key: PromoCodeListMode.Inactive,
    },
  ];
};

const PromoCodes = () => {
  const { push } = useHistory();
  const { summary } = useContext(PromoCodeActionContext);
  const { filter, setFilter, setPagination } = usePromoCodeListContext();

  const onChangeTab = (tab: string) => {
    setFilter({ ...filter, mode: tab as PromoCodeListMode });
    setPagination({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: PAGE_SIZE_PER_MODULE.PROMOCODES });
  };

  const navigateToCreatePromoCode = () => {
    push(`${Routes.PromoCodes}/0/edit`);
  };

  const promoCodeTabs = useMemo(() => getPromoCodeTabs(summary), [summary]);

  return (
    <div className="promos-page">
      <div className="head-title">
        <Typography fontCategory="serif" level={3}>
          Promo Codes
        </Typography>
        <Button title="Create Promo Code" type="primary" onClick={navigateToCreatePromoCode} />
      </div>

      <Spacer size={32} />

      <PromoCodeTableFilter />

      <Spacer size={12} />

      <Tabs tabItems={promoCodeTabs} activeKey={filter.mode} onKeyChange={onChangeTab} />

      <PromoCodeTable />
    </div>
  );
};

export default PromoCodes;
