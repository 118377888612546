import React, { useMemo } from 'react';
import { Card2, Typography2 } from '@care/web-ui';
import classNames from 'classnames';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import { getVisibleTiles } from 'src/util/tile.util';

export interface PreviewTilesHomeScreenProps {
  tiles: HomeScreenItem[];
  className?: string;
  isLargePreview?: boolean;
}

export const PreviewWebHomeScreenTiles = ({ tiles = [], className, isLargePreview }: PreviewTilesHomeScreenProps) => {
  const visibleTiles: HomeScreenItem[] = useMemo(() => getVisibleTiles(tiles) ?? [], [tiles]);

  return (
    <div className={classNames('bg-neutral-5 p-6', className)}>
      <div className="w-full bg-slate-50">
        <div className="flex h-[40px] w-full items-center !justify-center bg-brand-200-v2 p-2">
          <div
            className={classNames('w-full', {
              'max-w-[700px]': isLargePreview,
              'max-w-[500px]': !isLargePreview,
            })}
          >
            <Typography2 variant="label" className="text-primary-70">
              Book Appointment
            </Typography2>
          </div>
        </div>
        <div className="flex !justify-center p-4">
          <div
            className={classNames('grid w-full grid-cols-4 gap-2', {
              'max-w-[700px]': isLargePreview,
              'max-w-[500px]': !isLargePreview,
            })}
          >
            {visibleTiles.map((tile) => (
              <Card2 key={tile.id} className="!p-2">
                <div className="flex flex-col items-center gap-2">
                  <img
                    className="size-[36px] shrink-0 rounded-full"
                    src={tile.mediaUrl || '/eb-default.webp'}
                    alt={tile.name}
                  />
                  <Typography2
                    variant="label"
                    size="small"
                    className="!line-clamp-2 h-11 w-full !text-center text-neutral-700-v2 sm:!line-clamp-1 sm:h-auto"
                  >
                    {tile.name}
                  </Typography2>
                </div>
              </Card2>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewWebHomeScreenTiles;
