import { PromoCodeType } from 'src/enum/PromoCodeType';
import { DiscountType } from 'src/enum/DiscountType';
import { TotalQuantity } from 'src/enum/TotalQuantity';
import { BenefitType } from 'src/enum/BenefitType';
import { Currency } from 'src/enum/Currency';
import { BenefitApplyType } from 'src/enum/BenefitApplyType';
import { RedemptionLimitType } from 'src/enum/RedemptionLimitType';
import { OrganizationStatus } from 'src/enum/OrganizationStatus';
import { OrderType } from 'src/enum/OrderStatus';
import { CategoryType } from 'src/enum/CategoryType';

export interface BaseOption {
  text: string;
  value: string;
}

export const codeTypeOptions = [
  {
    label: 'Redemption Code',
    value: PromoCodeType.Redemption,
  },
  {
    label: 'Promo Code',
    value: PromoCodeType.Promo,
  },
];

export const discountTypeOptions = [
  {
    text: 'By Fixed Amount',
    value: DiscountType.FixedAmount,
  },
  {
    text: 'Percent',
    value: DiscountType.Percent,
  },
];

export const totalQuantityOptions = [
  {
    label: 'No Limit',
    value: TotalQuantity.NoLimit,
  },
  {
    label: 'Limited Quantity',
    value: TotalQuantity.Limited,
  },
];

export const totalPerPatientOptions = [
  {
    label: 'No Limit',
    value: TotalQuantity.NoLimit,
  },
  {
    label: 'Limit Number of Uses',
    value: TotalQuantity.Limited,
  },
];

export const allProductsOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No, only applicable products',
    value: 'false',
  },
];

export const benefitTypeOptions = [
  {
    label: 'Discount off',
    value: BenefitType.DiscountOff,
  },
  {
    label: 'Redemption',
    value: BenefitType.Redemption,
  },
];

export const resetFrequencyOptions = [
  {
    label: 'Weekly',
    text: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    text: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Yearly',
    text: 'Yearly',
    value: 'yearly',
  },
  {
    label: 'One-time',
    text: 'One-time',
    value: 'one-time',
  },
];

export const currencyOptions = Object.keys(Currency).map((c) => ({
  text: Currency[c],
  value: c,
}));

export const benefitApplyTypeOptions = [
  {
    name: 'Benefits shared across all products',
    description: '(e.g. $5 off all selected products)',
    value: BenefitApplyType.AllProducts,
  },
  {
    name: 'Benefits set separately for each product',
    description: '(e.g. $5 off Product A and $8 off Product B)',
    value: BenefitApplyType.AllLocations,
  },
];

export const redemptionLimitTypeOptions = [
  {
    name: 'Limit by number of redemptions',
    description: '(e.g. Redeem X number of times)',
    value: RedemptionLimitType.LimitByNumber,
  },
  {
    name: 'Limit by claim amount',
    description: '(e.g. Claim spending of up to $X)',
    value: RedemptionLimitType.LimitByAmount,
  },
];

export const locationRedemptionLimitOptions = [
  {
    text: 'Number of times',
    value: RedemptionLimitType.LimitByNumber,
  },
  {
    text: 'Limit claim amount',
    value: RedemptionLimitType.LimitByAmount,
  },
];

export const organisationStatusOptions = [
  {
    text: 'Verified',
    value: OrganizationStatus.verified,
  },
  {
    text: 'Unverified',
    value: OrganizationStatus.unverified,
  },
];

export const orderTypeOptions: BaseOption[] = [
  {
    text: 'Product',
    value: OrderType.Product,
  },
  {
    text: 'Service',
    value: OrderType.Service,
  },
];

export const categoryTypeOptions: BaseOption[] = [
  {
    text: 'Products',
    value: CategoryType.Product,
  },
  {
    text: 'Services',
    value: CategoryType.Service,
  },
];
