import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import React, { useEffect, useMemo } from 'react';
import CustomComboBox from 'src/component/custom-combo-box/CustomComboBox';
import { FilteredDate } from 'src/component/filtered-date/FilteredDate';
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { mappingRefundStatus } from 'src/constant/Refund';
import { useProductListContext } from 'src/context/ProductList.context';
import { useRefundsContext } from 'src/context/Refunds.context';
import { RefundStatus } from 'src/enum/RefundStatus';
import { RefundRequestedBy } from 'src/enum/RefundRequestedBy';
import { FilteredDate as EFilteredDate } from 'src/enum/filteredDate.enum';
import { ProcessType } from 'src/enum/processType';
import { Row } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';
import { convertDateFilterToDateRange } from 'src/util/convertFilteredDate.util';
import { filterDateOptions } from 'src/constant/date.constant';

const { formatDateString, getPayloadDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;

const requestedByOptions: ComboBoxItem[] = [
  { value: RefundRequestedBy.Admin, text: 'Admin' },
  { value: RefundRequestedBy.Consumer, text: 'Consumer' },
];

const processTypeOptions: ComboBoxItem[] = [
  { value: ProcessType.Single, text: 'Single' },
  { value: ProcessType.Bulk, text: 'Bulk' },
];

export const RefundFilterToolbar = () => {
  const { filter, setFilter, toggleBulkRefunds } = useRefundsContext();
  const {
    data: products,
    loadData: loadProducts,
    setFilter: setProductFilter,
    filter: productFilter,
    setPagination,
  } = useProductListContext();

  useEffect(() => {
    setPagination({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: MAX_PAGE_SIZE });
    setProductFilter({ ...productFilter, isIncludeRefund: true });
  }, []);

  const productOptions = useMemo<ComboBoxItem[]>(
    () => products.map((l) => ({ text: l.name, value: l.id })),
    [products],
  );

  const refundStatusOptions = useMemo<ComboBoxItem[]>(
    () =>
      Object.keys(RefundStatus).map((k) => {
        const status = RefundStatus[k] as RefundStatus;
        return { value: status, text: mappingRefundStatus[status] };
      }),
    [],
  );

  useEffect(() => {
    loadProducts();
  }, [productFilter]);

  const handleDateChange = (date: EFilteredDate): void => {
    const dateRange = convertDateFilterToDateRange(date);
    const { fromDate, toDate } = dateRange;
    const formattedFromDate = fromDate ? formatDateString(fromDate, DATE.ISO_DATE) : undefined;
    const formattedToDate = toDate ? formatDateString(toDate, DATE.ISO_DATE) : undefined;

    setFilter({
      ...filter,
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: PAGE_SIZE_PER_MODULE.REFUNDS,
      fromDate: formattedFromDate ? getPayloadDateString(formattedFromDate) : undefined,
      toDate: formattedToDate ? getPayloadDateString(formattedToDate, true) : undefined,
      filterDate: date,
    });
  };

  return (
    <Row spans={[5, 5, 5, 4, 5]} gutter={8}>
      <CustomComboBox
        initialFilter={filter.productIds}
        label="Products"
        options={productOptions}
        handleChange={(values) => setFilter({ ...filter, productIds: values })}
      />

      <CustomComboBox
        initialFilter={filter.requestedBy}
        label="Requested By"
        options={requestedByOptions}
        handleChange={(values) => setFilter({ ...filter, requestedBy: values })}
      />

      <CustomComboBox
        disabled={toggleBulkRefunds}
        initialFilter={filter.statuses}
        label="Refund Status"
        options={refundStatusOptions}
        handleChange={(values) => setFilter({ ...filter, statuses: values as RefundStatus[] })}
      />

      <CustomComboBox
        initialFilter={filter.processTypes}
        label="Process Type"
        options={processTypeOptions}
        handleChange={(values) => setFilter({ ...filter, processTypes: values })}
      />

      <FilteredDate filterDate={filter.filterDate} onChangeDate={handleDateChange} options={filterDateOptions} />
    </Row>
  );
};

export default RefundFilterToolbar;
