import React from 'react';

import { Tag, Typography } from '@care/web-ui';
import { Link } from 'react-router-dom';

import { Routes } from 'src/constant';
import { OrderStatus, OrderType } from 'src/enum/OrderStatus';
import { OrderListItemModel } from 'src/model/OrderListItem.model';
import { orderStatusTypeTag, orderTypeTag } from 'src/constant/OrderStatusTag.constant';
import { paymentStatusTypeTag } from 'src/constant/paymentStatusTypeTag.constant';
import { PaymentStatus } from 'src/enum/PaymentStatus';
import { RefundStatus } from 'src/enum/RefundStatus';
import { refundStatusTypeTag } from 'src/constant/RefundStatusTag';
import { ConsumerModel } from 'src/model/Consumer.model';
import { OrderSortBy } from 'src/enum/orderSortBy.enum';
import { SortOptionTable } from 'src/enum/SortBy.enum';
import { orderTypeOptions } from 'src/constant/OptionsConstant';
import { FilterDropdownProps, FilteredDropdown } from 'src/component/FilteredDropdown/FilteredDropdown';

const sortTable = {
  [OrderSortBy.Newest]: SortOptionTable.Descend,
  [OrderSortBy.Oldest]: SortOptionTable.Ascend,
};

export const getColumns = ({ sortBy, orderTypes }: { sortBy: string; orderTypes: OrderType[] }) => {
  return [
    {
      title: 'Order No.',
      dataIndex: 'orderNumber',
      sortOrder: sortTable[sortBy],
      sorter: (_, __, mode) => mode,
      sortDirections: [SortOptionTable.Ascend, SortOptionTable.Descend, SortOptionTable.Ascend],
      render: (orderNumber: string, item: OrderListItemModel) => (
        <>
          <Link to={Routes.Orders + '/' + item.id}>
            <Typography level={9} weight="bold" className="orderId">
              {orderNumber}
            </Typography>
          </Link>
          <Typography level={9}>{item.createAtText}</Typography>
        </>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'consumerProfile',
      render: (consumerProfile: Pick<ConsumerModel, 'id' | 'fullName'>) => (
        <Typography level={9}>{consumerProfile.fullName}</Typography>
      ),
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethodText',
      render: (paymentMethodText: string) => <Typography level={9}>{paymentMethodText}</Typography>,
    },
    {
      title: 'Organization',
      dataIndex: 'orgName',
      render: (orgName: string) => <Typography level={9}>{orgName}</Typography>,
    },
    {
      title: 'Total Amount',
      dataIndex: 'grandTotalText',
      render: (value: string) => <Typography level={9}>{value}</Typography>,
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType',
      width: '13%',
      filteredValue: orderTypes || null,
      render: (orderType: OrderType) => <Typography>{orderTypeTag[orderType]}</Typography>,
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
        return (
          <FilteredDropdown
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            filters={orderTypeOptions}
            confirm={confirm}
            selectedAllText="All Order Type"
            clearFilters={clearFilters}
          />
        );
      },
      onFilter: (value: string, record: { orderType: OrderType }) => {
        const { orderType } = record;
        return orderType === value;
      },
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
      render: (status: OrderStatus) => {
        return (
          <div className="action-group">
            <Tag text={orderStatusTypeTag[status]?.label} type={orderStatusTypeTag[status]?.type as any} />
          </div>
        );
      },
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      render: (status: PaymentStatus) => {
        return (
          <div className="action-group">
            <Tag text={paymentStatusTypeTag[status]?.label} type={paymentStatusTypeTag[status]?.type as any} />
          </div>
        );
      },
    },
    {
      title: 'Refund Status',
      dataIndex: 'refundStatus',
      render: (refundStatus: RefundStatus) => {
        return (
          <div className="action-group">
            {refundStatus ? (
              <Tag
                text={refundStatusTypeTag[refundStatus]?.label}
                type={refundStatusTypeTag[refundStatus]?.type as any}
              />
            ) : null}
          </div>
        );
      },
    },
  ];
};
