import { TagType } from '@care/web-ui/lib/components/Tag/Tag';
import { UserContactStatus, UserEmailStatus, UserStatus } from 'src/enum/User.enum';

export type TagInfo = {
  type: TagType;
  label: string;
};

type UserStatusTag = {
  [key in Exclude<UserStatus, UserStatus.ALL>]: TagInfo;
};

type UserEmailStatusTag = {
  [key in UserEmailStatus]: TagInfo;
};

type UserContactStatusTag = {
  [key in UserContactStatus]: TagInfo;
};

export const userStatusTag: UserStatusTag = {
  [UserStatus.UNVERIFIED]: { type: 'Warning', label: 'Unverified' },
  [UserStatus.VERIFIED]: { type: 'Success', label: 'Verified' },
};

export const userEmailStatusTag: UserEmailStatusTag = {
  [UserEmailStatus.deactivated]: { type: 'Warning', label: 'Deactivated' },
  [UserEmailStatus.activated]: { type: 'Success', label: 'Activated' },
};

export const userContactStatusTag: UserContactStatusTag = {
  [UserContactStatus.unverified]: { type: 'Warning', label: 'Unverified' },
  [UserContactStatus.verified]: { type: 'Success', label: 'Verified' },
};
