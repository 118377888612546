import Constants from '@care/web-ui/lib/constants';
import Utils from '@care/web-ui/lib/utils';
import { configsStore } from '@care/web-ui/lib/hooks';
import { FormattedOrderDetails, OrderDetailModel } from 'src/model/OrderDetail.model';
import { DiscountType } from 'src/enum/DiscountType';
import { ConfigCurrency } from '@care/web-ui/lib/models/ConfigCurrency.model';
import { useFullInfoCurrencyFormatted } from '../full-info-currency-formatted/useFullInfoCurrencyFormatted';

const {
  CommonUtil: { get },
  OrderUtil: { calculateTotalOrderAmount },
  CurrencyUtil: { getFullInfoCurrencyFormatted },
} = Utils;
const { defaultCurrency } = Constants.CurrencyConstant;

const { useConfigsStore } = configsStore;

export const useFormatOrderDetail = () => {
  const {
    data: { currencies },
  } = useConfigsStore();
  const { handleFormatFullInfoCurrency } = useFullInfoCurrencyFormatted();
  const handleFormatOrderDetails = (d: OrderDetailModel) => {
    if (!d) {
      return {} as FormattedOrderDetails;
    }

    const { orderItems, grandTotal, discountType, discountAmount, deliveryFee, subTotal, orderDiscounts } = d;
    const currency = get(orderItems, ['grandTotal', 'currency'], defaultCurrency);
    const formattedCurrency: ConfigCurrency = currencies.find((c) => c.code === currency);

    const { formatAmountString: deliveryFeeFormatted } = handleFormatFullInfoCurrency(deliveryFee || 0, currency);
    const { formatAmount: formatGrandTotal, formatAmountString: formatGrandTotalString } = handleFormatFullInfoCurrency(
      calculateTotalOrderAmount({
        subTotal: grandTotal?.value ?? 0,
        discountList: orderDiscounts?.map((discount) => discount.amount),
      }),
      currency,
    );

    const { formatAmount: formatSubtotal, formatAmountString: formatSubtotalString } = handleFormatFullInfoCurrency(
      subTotal || 0,
      currency,
    );

    const grandTotalAmount = get(d, ['grandTotal', 'value'], 20);
    const discountAmountValue = (
      discountType === DiscountType.Percent ? grandTotalAmount / 100 : discountAmount
    ) as number;

    const { formatAmount: formatAmountDiscount, formatAmountString: formatAmountDiscountString } =
      getFullInfoCurrencyFormatted({
        value: discountAmountValue,
        currencyConfig: { ...formattedCurrency },
      });

    const formattedOrderDetail: FormattedOrderDetails = {
      ...d,
      formatGrandTotal,
      formatGrandTotalString,
      formatAmountDiscount,
      formatAmountDiscountString,
      formattedCurrency,
      currency,
      deliveryFeeFormatted,
      formatSubtotal,
      formatSubtotalString,
    };
    return formattedOrderDetail;
  };

  return { handleFormatOrderDetails };
};
