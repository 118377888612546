import { AppointmentStatus } from 'src/enum/AppointmentStatus';
import { TagInfo } from './Tag.constant';

type AppointmentStatusTypeTag = {
  [key in AppointmentStatus]: TagInfo;
};

export const appointmentStatusTypeTag: AppointmentStatusTypeTag = {
  [AppointmentStatus.Pending]: { type: 'Neutral', label: 'Pending' },
  [AppointmentStatus.Confirmed]: { type: 'Neutral', label: 'Confirmed' },
  [AppointmentStatus.Completed]: { type: 'Neutral', label: 'Completed' },
  [AppointmentStatus.Registered]: { type: 'Warning', label: 'Registered' },
  [AppointmentStatus.Cancelled]: { type: 'Error', label: 'Cancelled' },
  [AppointmentStatus.InProgress]: { type: 'Success', label: 'In Progress' },
};
