import React, { useState, useCallback } from 'react';
import { usePromoCodeListContext } from 'src/context/PromoCodeList.context';
import { Button, Input, Row, Utils } from '@care/web-ui';
import { PromoCodeListMode } from 'src/enum/PromoCodeListMode';
import { DEFAULT_PAGE_INDEX, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';

const {
  CommonUtil: { debounce },
} = Utils;

export const PromoCodeTableFilter = () => {
  const { loading, filter, setFilter, setPagination } = usePromoCodeListContext();
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useCallback(
    debounce((value) => {
      setPagination({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: PAGE_SIZE_PER_MODULE.PROMOCODES });
      setFilter({ ...filter, search: value });
    }, 1000),
    [filter],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debounceSearch(value);
  };

  const reset = () => {
    setFilter({
      mode: PromoCodeListMode.All,
      codeTypes: [],
      orgIds: filter.orgIds,
    });
    setPagination({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: PAGE_SIZE_PER_MODULE.PROMOCODES });
    setSearchValue('');
  };

  return (
    <div>
      <Row gutter={16} spans={[10, 14]}>
        <Input iconRight={{ name: 'Search' }} placeholder="Search" value={searchValue} onChange={handleSearch} />
        <Button loading={loading} type="secondary" title="Reset" onClick={reset} />
      </Row>
    </div>
  );
};
