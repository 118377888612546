import React, { useEffect, useMemo, useState } from 'react';
import { Button2, careNotification, Typography } from '@care/web-ui';
import SectionTitle from 'src/component/SectionTitle/SectionTitle';
import Spacer from 'src/component/Spacer/Spacer';
import ItemInfo from 'src/module/configurations/item-info/ItemInfo';
import { useHistory } from 'react-router';
import Sortable from 'src/component/sortable/Sortable';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import { CreateHomeScreenModal } from 'src/module/configurations/home-screen-item-modal/CreateHomeScreenModal';
import { ManageType } from 'src/enum/manageType.enum';
import PageHeader, { ActionButtonsProps } from 'src/component/PageHeader/PageHeader';
import { Routes } from 'src/constant';
import { TabName } from 'src/module/configurations/Configurations';
import PreviewWebHomeScreenTiles from 'src/module/configurations/preview-web-home-screen-tiles/PreviewWebHomeScreenTiles';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox';
import { UIRoutingType } from 'src/enum/routingType.enum';
import { useAppHomeScreenContext } from 'src/context/AppHomeScreen.context';
import { Platform } from 'src/enum/PlatformSetting.enum';
import { handleError } from 'src/util/error.util';

const breadcrumbItems = [
  {
    name: 'Web Home Screen',
    href: `${Routes.Configurations}?tab=${TabName.WebHomeScreen}`,
  },
  {
    name: 'Tiles Layout',
    href: '#',
  },
];

const webHomeScreenRoutings: ComboBoxItem[] = [
  {
    text: 'Speciality List',
    value: UIRoutingType.SpecialtyList,
  },
  {
    text: 'Service List',
    value: UIRoutingType.ProductList,
  },
  {
    text: 'Service Detail',
    value: UIRoutingType.ProductDetail,
  },
];

const WebHomeScreenDetail = () => {
  const { push } = useHistory();
  const [selectedTile, setSelectedTile] = useState<HomeScreenItem>(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const { loadData, updateTitles, loading } = useAppHomeScreenContext();
  const [tiles, setTiles] = useState<HomeScreenItem[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const countryCode = urlParams.get('country');

  useEffect(() => {
    (async () => {
      try {
        const data = await loadData(countryCode, Platform.WEB);

        setTiles(data?.tiles || []);
      } catch (error) {
        handleError(error);
      }
    })();
  }, []);

  const handleAddTile = () => {
    setSelectedTile(null);
    setEditModalVisible(true);
  };

  const handleCloseModal = () => {
    setEditModalVisible(false);
  };

  const handleSwitchVisibility = (value: boolean, index: number) => {
    setTiles(tiles.map((tile, tileIndex) => (index === tileIndex ? { ...tile, visible: value } : tile)));
  };

  const handleEditTile = (item: HomeScreenItem, index: number) => {
    setSelectedTile({ ...item, index });
    setEditModalVisible(true);
  };

  const handleUpdateTiles = (item: HomeScreenItem, isDel?: boolean) => {
    const updatedTiles = [...tiles];
    if (isDel) {
      updatedTiles.splice(item.index, 1);
    } else {
      const index = item.index ?? updatedTiles.length;
      updatedTiles[index] = item;
    }
    setTiles(updatedTiles);

    return true;
  };

  const backToHomeScreenPage = () => {
    push(`${Routes.Configurations}?tab=${TabName.WebHomeScreen}${window.location.search.replace('?', '&')}`);
  };

  const handleSave = async () => {
    const result = await updateTitles({ countryCode, items: tiles, platform: Platform.WEB });

    if (result) {
      backToHomeScreenPage();
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={9} weight="semi">
            Tiles layout successfully updated.
          </Typography>
        ),
        duration: 2,
      });
    }
  };

  const actionButtons: ActionButtonsProps[] = useMemo(
    () => [
      {
        title: 'Cancel',
        type: 'outline',
        size: 'small',
        onSubmit: backToHomeScreenPage,
      },
      {
        title: 'Save',
        size: 'small',
        disabled: tiles.length === 0 || loading,
        onSubmit: handleSave,
      },
    ],
    [tiles, loading],
  );

  return (
    <div className="manageTilesContainer">
      <PageHeader title="Tiles Layout" breadcumbItems={breadcrumbItems} actionButtons={actionButtons} />

      <div className="grid grid-cols-2 gap-4">
        <div>
          <Spacer size={40} />
          <PreviewWebHomeScreenTiles tiles={tiles} className="rounded-xl px-[74px]" />
        </div>
        <div>
          <SectionTitle
            title="Manage Tiles"
            marginVertical={4}
            rightComponent={
              <Button2 title="Add Tile" icon="Plus" type="link" className="!min-w-fit !p-0" onClick={handleAddTile} />
            }
          />
          <Spacer size={16} />
          <Sortable items={tiles} onSortEnd={setTiles}>
            {tiles.map((item, index) => (
              <ItemInfo
                key={item.name}
                item={item}
                imageType="square"
                onEdit={() => handleEditTile(item, index)}
                onSwitch={(value) => handleSwitchVisibility(value, index)}
              />
            ))}
          </Sortable>
        </div>
      </div>

      {editModalVisible && (
        <CreateHomeScreenModal
          visible
          onClose={handleCloseModal}
          onSave={handleUpdateTiles}
          manageType={ManageType.Tile}
          itemDetail={selectedTile}
          existingItemNames={tiles.map((n) => n.name)}
          customRoutings={webHomeScreenRoutings}
          isWebHomeScreen
        />
      )}
    </div>
  );
};

export default WebHomeScreenDetail;
