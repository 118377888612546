import { CategoryType } from 'src/enum/CategoryType';
import { Media } from './Media.model';

export class Category {
  id: string;

  name: string;

  description: string;

  categoryType: CategoryType;

  parentCategoryId: string;

  subCats: Category[] = [];

  priority: number;

  active?: boolean;

  mediaId?: string;

  mediaUrl?: string;

  localFile?: File;

  media?: Media;
}

export interface CategoryListFilter {
  parentCategoryId?: string;
  categoryType?: CategoryType;
  isExcludeProduct?: boolean;
  pageIndex: number;
  pageSize: number;
}
