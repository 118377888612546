import React from 'react';
import { Table, Typography } from '@care/web-ui';
import { RefundModel } from 'src/model/Refund.model';
import { RefundType } from 'src/enum/refundType.enum';
import { mappingRefundType } from 'src/constant/Refund';

import './RefundTableItemDetail.scss';

interface Props {
  item: RefundModel;
}

const RefundTableItemDetail = ({ item }: Props) => {
  const columns = [
    {
      title: 'Refund Type',
      dataIndex: 'refundType',
      key: 'refundType',
      render: (value: RefundType) => <Typography level={9}>{mappingRefundType[value] || '-'}</Typography>,
    },
    {
      title: 'Refund Settlement Date',
      dataIndex: 'settlementDateText',
      key: 'settlementDateText',
      render: (value: string) => <Typography level={9}>{value}</Typography>,
    },
  ];

  return (
    <div className="orderItemAppt-table-container">
      <Table columns={columns} data={[item]} />
    </div>
  );
};

export default RefundTableItemDetail;
