export enum RoutingType {
  Marketplace = 'Marketplace',
  AppScreen = 'AppScreen',
  External = 'External',
}

export enum UIRoutingType {
  ProductList = 'Product List',
  ProductDetail = 'Product Detail',
  EcProductList = 'Ec Product List',
  EcProductDetail = 'Ec Product Detail',
  AppScreen = 'AppScreen',
  External = 'External',
  SpecialtyList = 'Speciality List',
  CustomInternalLink = 'Custom Internal Link',
}
