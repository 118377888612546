import { careNotification } from '@care/web-ui';
import { MutationAsync, createStore } from '@care/web-ui/lib/store';
import { MutationStore } from '@care/web-ui/lib/store/store.mutation';

const { mutationStateCreator } = MutationAsync;
export const $PRODUCTS_LIST_MUTATION_STORE = '$PRODUCTS_LIST_MUTATION_STORE';

export type State = {
  productsSelected: string[];
};

const defaultState: State = {
  productsSelected: [],
};
export type Actions = {
  setProductsSelected: (productIds: string[]) => void;
  resetStore: () => void;
};

export type ProductListMutationStore = MutationStore<any> & State & Actions;

export const [useStore, selector] = createStore<ProductListMutationStore>(
  (set, get, storeApi) => ({
    ...defaultState,
    ...mutationStateCreator(set, get, storeApi),
    setProductsSelected: (productIds: string[]) => {
      set({ productsSelected: productIds });
    },
    resetStore: () => {
      set({ ...defaultState });
    },
    mutationError: (error: Error) => {
      careNotification({
        type: 'error',
        message: error.message,
        duration: 3,
      });
    },
  }),
  {
    storeName: $PRODUCTS_LIST_MUTATION_STORE,
    enabledDevTools: false,
  },
);
