import { BenefitSettings, CreateOrEditMembershipsModelDTO } from './CreateOrEditMembership.request';

export class MembershipDetailModelDTO extends CreateOrEditMembershipsModelDTO {
  id: string;
  benefitSettings: BenefitSettingDetails[];
}

export interface BenefitSettingDetails extends BenefitSettings {
  productName: string;
  productBasePrice: number;
  benefitPrice: number;
  memberBenefit: string;
}
