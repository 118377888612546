import React, { ReactNode, useContext, useState } from 'react';
import { ProductModel } from 'src/model/Product.model';
import ProductAPI from 'src/service/ProductAPI';
import { handleToastError } from 'src/util/error.util';

class ContextState {
  product!: ProductModel;

  loadDetail!: (id: string) => Promise<void>;

  loading = false;
}

export const ProductDetailContext = React.createContext(new ContextState());

const ProductDetailProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductModel>(new ProductModel());

  const loadDetail = async (id: string): Promise<any> => {
    try {
      setLoading(true);
      const body = await ProductAPI.getProductById(id);
      setProduct(body);
    } catch (error) {
      handleToastError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProductDetailContext.Provider
      value={{
        product,
        loadDetail,
        loading,
      }}
    >
      {children}
    </ProductDetailContext.Provider>
  );
};

export const useProductDetailContext = () => useContext(ProductDetailContext);

export default ProductDetailProvider;
