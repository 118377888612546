import { PlatformSettingSortBy } from 'src/enum/PlatformSetting.enum';

export class PlatformSettingModel {
  _id: string;

  key: string;

  value: any;

  isMultipleLanguage: boolean;

  unit: string;

  createdAt: string;

  updatedAt: string;
}

export class PlatformSetting {
  id?: string;

  key: string;

  value: any;

  isMultipleLanguage: boolean;

  unit?: string;
}

export interface PlatformSettingFilter {
  searchParams?: string;
  sortBy?: PlatformSettingSortBy;
  pageIndex: number;
  pageSize: number;
}
