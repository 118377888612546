import React, { ReactNode } from 'react';
import { Typography } from '@care/web-ui';
import Divider from '../Divider/Divider';
import './SectionTitle.scss';

interface Props {
  title: string;
  rightComponent?: ReactNode;
  marginVertical?: number;
}

const SectionTitle = ({ title, rightComponent, marginVertical = 12 }: Props) => {
  return (
    <div className="sectionTitle">
      <div className="titleRow">
        <Typography level={5} fontCategory="serif">
          {title}
        </Typography>
        {rightComponent}
      </div>
      <Divider marginVertical={marginVertical} />
    </div>
  );
};

export default SectionTitle;
