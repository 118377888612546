import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';

import { FeedbackStatus, FeedbackType } from 'src/enum/Feedback.enum';

const { formatDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;

export class FeedbackItem {
  id: string;
  rating: number;
  type: FeedbackType;
  feedback: string;
  status: FeedbackStatus;
  fullName: string;
  email: string;
  createdAt: string;

  get createAtText(): string {
    return formatDateString(this.createdAt, DATE.DATE_HYPHENS);
  }
}
