import { Builder } from 'builder-pattern';
import { RoutingType, UIRoutingType } from 'src/enum/routingType.enum';
import { HomeScreenNotice } from './HomeScreenNotice.model';
import { Utils } from '@care/web-ui';

const {
  CommonUtil: { pick },
} = Utils;

export class HomeScreenNoticeFormModel {
  id?: string;
  title = '';
  description = '';
  isActionButton = false;
  buttonText?: string = '';
  routingType?: UIRoutingType = UIRoutingType.External;
  routingUrl?: string;
  routingValue?: string;
  eventName?: string;
  isPresetVisibilityPeriod = false;
  startDate?: Date = null; // YYYY-MM-DD HH:mm:ss
  endDate?: Date = null; // YYYY-MM-DD HH:mm:ss
  isVisible?: boolean = false;

  static convertFromDTO(dto: HomeScreenNotice): HomeScreenNoticeFormModel {
    let resRoutingType: UIRoutingType;
    const { routingType, routingUrl, routingKey, endDate, startDate } = dto;

    switch (true) {
      case routingType === RoutingType.AppScreen:
        resRoutingType = UIRoutingType.AppScreen;
        break;
      case routingType === RoutingType.External:
        resRoutingType = UIRoutingType.External;
        break;
      case routingUrl && routingUrl.includes('specialties'):
        resRoutingType = UIRoutingType.SpecialtyList;
        break;
      case routingUrl && routingUrl.includes('products/e-commerce/category'):
        resRoutingType = UIRoutingType.EcProductList;
        break;
      case routingUrl && routingUrl.includes('products/e-commerce'):
        resRoutingType = UIRoutingType.EcProductDetail;
        break;
      case routingUrl && routingUrl.includes('categories'):
        resRoutingType = UIRoutingType.ProductList;
        break;
      case routingUrl && routingUrl.includes('products'):
        resRoutingType = UIRoutingType.ProductDetail;
        break;
      default:
        resRoutingType = UIRoutingType.CustomInternalLink;
    }

    return Builder(HomeScreenNoticeFormModel, {
      ...pick(dto, [
        'title',
        'description',
        'isActionButton',
        'buttonText',
        'eventName',
        'isPresetVisibilityPeriod',
        'isVisible',
      ]),
      routingType: resRoutingType,
      routingValue: resRoutingType === UIRoutingType.AppScreen ? routingKey : routingUrl,
      startDate: startDate ? new Date(startDate) : null,
      endDate: endDate ? new Date(endDate) : null,
    }).build();
  }

  static convertToDTO(formVal: HomeScreenNoticeFormModel): HomeScreenNotice {
    let routingType: RoutingType;
    let routingKey: string;
    let routingUrl: string;
    let startDate: string;
    let endDate: string;

    const {
      routingType: formRoutingType,
      routingValue,
      startDate: formStartDate,
      endDate: formEndDate,
      isActionButton,
      isPresetVisibilityPeriod,
    } = formVal;

    if (isActionButton)
      switch (formRoutingType) {
        case UIRoutingType.AppScreen:
          routingType = RoutingType.AppScreen;
          routingKey = routingValue;
          break;
        case UIRoutingType.External:
          routingType = RoutingType.External;
          routingUrl = routingValue;
          break;
        default:
          routingType = RoutingType.Marketplace;
          routingUrl = routingValue;
      }

    if (isPresetVisibilityPeriod) {
      if (formStartDate) {
        formStartDate.setSeconds(0, 0);
        startDate = formStartDate.toISOString();
      }
      if (formEndDate) {
        formEndDate.setSeconds(0, 0);
        endDate = formEndDate.toISOString();
      }
    }

    return Builder<HomeScreenNotice>({
      ...pick(formVal, [
        'title',
        'description',
        'isActionButton',
        'buttonText',
        'eventName',
        'isPresetVisibilityPeriod',
      ]),
      startDate,
      endDate,
      routingType,
      routingUrl,
      routingKey,
      isVisible: false,
    }).build();
  }
}
