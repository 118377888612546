import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';
import { Builder } from 'builder-pattern';
import config from 'src/Config';
import { DeepLinkItem } from 'src/model/DeepLink.model';
import { APIBase } from 'src/util/Request';

const URL = `${config.ADMIN_API_URL}/deep-link`;

class DeepLinkAPI {
  async getDeeplinkList(): Promise<GeneralListResponse<DeepLinkItem>> {
    const body = await APIBase.get<GeneralListResponse<DeepLinkItem>>(URL);

    const { data, ...rest } = body.data;

    return { ...rest, data: data.map((d) => Builder(DeepLinkItem, d).build()) };
  }
}

export default new DeepLinkAPI();
