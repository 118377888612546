import React from 'react';

import { Category } from 'src/model/Category.model';
import { useConvertSubCategory } from './useCategoryList';

export const useCategoriesDataResource = (): {
  title: string;
  value: string;
  key: string;
  children: {
    title: string;
    value: string;
    key: string;
  }[];
}[] => {
  const categories: Category[] = useConvertSubCategory();
  const dataResource = React.useMemo(
    () =>
      categories.map((item) => ({
        title: item.name,
        value: item.id,
        key: item.id,
        children: item.subCats.map((ele) => ({
          title: ele.name,
          value: ele.id,
          key: ele.id,
        })),
      })),
    [categories],
  );
  return dataResource;
};
