import React from 'react';
import { Switch, Route } from 'react-router-dom';
import OrganizationProvider from 'src/context/Organization.context';
import { Routes } from 'src/constant';
import Organisations from 'src/module/organisations/Organisations';
import AccountProvider from 'src/context/Account.context';
import { OrganisationDetail } from 'src/module/organisations/organisation-detail/OrganisationDetail';
import { EditAccount } from 'src/module/account/EditAccount';
import AccountListProvider from 'src/context/AccountList.context';
import PaginatedOrganizationListProvider from 'src/context/OrganizationListWithPagination.context';

function OrganisationsContainer() {
  return (
    <div>
      <Switch>
        <Route path={Routes.Organisations} exact>
          <PaginatedOrganizationListProvider>
            <OrganizationProvider>
              <Organisations />
            </OrganizationProvider>
          </PaginatedOrganizationListProvider>
        </Route>
        <Route path={Routes.OrganisationDetail} exact>
          <AccountProvider>
            <AccountListProvider>
              <OrganizationProvider>
                <OrganisationDetail />
              </OrganizationProvider>
            </AccountListProvider>
          </AccountProvider>
        </Route>
        <Route path={Routes.AccountDetail} exact>
          <OrganizationProvider>
            <AccountProvider>
              <EditAccount />
            </AccountProvider>
          </OrganizationProvider>
        </Route>
      </Switch>
    </div>
  );
}

export default OrganisationsContainer;
