import { BaseFilter, createPagingDataContext } from 'src/util/createPagingDataContext';
import { PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { FeedbackItem } from 'src/model/FeedbackItem.model';
import { getFeedbacks } from 'src/service/FeedbackAPI';
import { FeedbackSortBy, FeedbackStatus, FeedbackType } from 'src/enum/Feedback.enum';

export interface FeedbackFilter extends BaseFilter<FeedbackSortBy> {
  type?: FeedbackType;
  status?: FeedbackStatus;
  startTime?: string;
  endTime?: string;
}

const fetcher = async (filter: FeedbackFilter, pageIndex: number, pageSize: number) => {
  return getFeedbacks(filter, pageIndex, pageSize);
};

const [FeedbacksProvider, useFeedbackContext] = createPagingDataContext<FeedbackItem, FeedbackFilter>(fetcher, {
  pageIndex: 1,
  pageSize: PAGE_SIZE_PER_MODULE.PRODUCTS,
});

export { useFeedbackContext };

export default FeedbacksProvider;
