import React from 'react';

import { careNotification, Icon, Typography } from '@care/web-ui';
import { NotificationProps } from '@care/web-ui/lib/components/Notification/notification';

export const notification = (notiProps: NotificationProps) => {
  const { message, description, duration = 3, ...rest } = notiProps;

  const renderIcon = () => {
    // default icon (type success)
    const icon = <Icon name="TickCircle" color="#4FB1AB" />;
    return icon;
  };

  return careNotification({
    ...rest,
    duration,
    icon: renderIcon(),
    message: (
      <Typography level={8} weight="bold">
        {message}
      </Typography>
    ),
    description: (
      <div style={{ color: '#727C85' }}>
        <Typography level={9}>{description}</Typography>
      </div>
    ),
  });
};
