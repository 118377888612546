import { useEffect } from 'react';
import { useSimpleMembershipPlanListStore } from 'src/zustand-store/membership/SimpleMembershipPlanList.store';
import MembershipAPI from 'src/service/MembershipAPI';

export const useQuerySimpleMembershipPlanList = (): void => {
  const { fetchData } = useSimpleMembershipPlanListStore();

  useEffect(() => {
    fetchData({
      fetcher: (filter) => MembershipAPI.getSimpleMembershipPlanList(filter) as any,
    });
  }, []);
};
