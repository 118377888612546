const config = {
  API_URL: process.env.REACT_APP_API_URL,
  PARTNER_API_URL: process.env.REACT_APP_PARTNER_API_URL,
  ADMIN_API_URL: process.env.REACT_APP_ADMIN_API_URL,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  REFUNDS_LIMIT: process.env.REACT_APP_ENV === 'production' ? 100 : 10,
  AUTH_API_URL: process.env.REACT_APP_AUTH_API_URL,
};

export default config;
