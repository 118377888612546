import { shallow } from '@care/web-ui/lib/store';
import { useProductListContext } from 'src/context/ProductList.context';
import ProductService from 'src/service/Product.service';
import { ProductListMutationStore, useStore } from 'src/zustand-store/product-list/ProductListMutation.store';
import { careNotification, Utils } from '@care/web-ui';
import { useGetProductSelected } from 'src/module/products/ProductsTable/ProductTable.actions';

const {
  CommonUtil: { pick },
} = Utils;

export const useBulkApproveProducts = () => {
  const { mutationData, resetStore }: Pick<ProductListMutationStore, 'mutationData' | 'resetStore'> = useStore(
    (store: ProductListMutationStore) =>
      pick(store, ['mutationData', 'resetStore'] as Array<keyof ProductListMutationStore>),
    shallow,
  );
  const { total } = useGetProductSelected();

  const { loadData } = useProductListContext();

  const handleBulkApproveProducts = ({
    params,
    mutationSuccess,
  }: {
    params: string[];
    mutationSuccess: () => void;
  }) => {
    mutationData({
      mutationFn: () => {
        return ProductService.bulkApprove(params);
      },
      mutationDataSuccess: () => {
        if (typeof mutationSuccess === 'function') {
          mutationSuccess();
        }
        careNotification({
          type: 'success',
          message: `${total} item(s) approved`,
          duration: 3,
        });
        resetStore();
        loadData();
      },
      mutationKey: 'bulkApprove',
    });
  };

  return { handleBulkApproveProducts };
};
