import { careNotification, Typography } from '@care/web-ui';
import React, { ReactNode, useState } from 'react';
import { Role } from 'src/enum/Role.enum';
import { ProviderDetail } from 'src/model/ProviderDetail.model';
import ProviderService from 'src/service/Provider.service';
import { handleError } from 'src/util/error.util';

class ContextState {
  loadAccountDetail: (accId: string) => Promise<ProviderDetail>;

  updateAcc: (accId: string, role: Role, name: string) => Promise<any>;

  loading = false;
}

export const AccountContext = React.createContext(new ContextState());

const AccountProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(false);

  const loadAccountDetail = async (accId: string): Promise<ProviderDetail> => {
    try {
      setLoading(true);
      const res = await ProviderService.getProviderDetail(accId);
      return res;
    } catch (error) {
      handleError(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateAcc = async (accId: string, role: Role, name: string): Promise<any> => {
    try {
      setLoading(true);
      const res = await ProviderService.updateProviderInfo(accId, role, name);
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: <Typography level={7}>Account role succesfully updated.</Typography>,
        duration: 2,
      });
      return res;
    } catch (error) {
      handleError(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AccountContext.Provider
      value={{
        loadAccountDetail,
        updateAcc,
        loading,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
