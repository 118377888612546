import React, { useEffect, useState } from 'react';
import { Input, ComboBox, Checkbox, Utils } from '@care/web-ui';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import Divider from 'src/component/Divider/Divider';
import './CustomComboBox.scss';

interface IProps {
  label: string;
  options: ComboBoxItem[];
  handleChange: (values: string[]) => void;
  initialFilter: string[];
  disabled?: boolean;
  isInfiniteScroll?: boolean;
  onNextPage?: () => void;
  onUpdatedFilter?: (value: string) => void;
}

const {
  CommonUtil: { debounce },
} = Utils;

const CustomComboBox = ({
  label,
  options,
  handleChange,
  initialFilter,
  disabled,
  isInfiniteScroll,
  onNextPage,
  onUpdatedFilter,
}: IProps) => {
  const [innerSearch, setInnerSearch] = useState('');
  const [filteredList, setFilteredList] = useState<ComboBoxItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    setFilteredList(options);
  }, [options]);

  useEffect(() => {
    if (!initialFilter) setSelectedAll(false);
    setSelectedItems(initialFilter || []);
  }, [initialFilter]);

  const handleSelect = (value: string, checked: boolean): void => {
    const newSelectedItems = checked ? selectedItems.concat(value) : selectedItems.filter((i) => i !== value);

    setSelectedItems(newSelectedItems);
    handleChange(newSelectedItems);
  };

  const handleSelectAll = (checked: boolean): void => {
    const newSelectedItems = checked ? filteredList.map((i) => i.value) : [];

    setSelectedAll(checked);
    setSelectedItems(newSelectedItems);
    handleChange(newSelectedItems);
  };

  const onSearchChange = (searchValue: string): void => {
    setInnerSearch(searchValue);

    debounce((value: string) => {
      if (isInfiniteScroll) {
        onUpdatedFilter?.(value);
        return;
      }

      const newFilteredList = filteredList.filter((l) => l.text.toLowerCase().includes(value.toLocaleLowerCase()));
      setFilteredList(value ? newFilteredList : options);
    }, 1000)(searchValue);
  };

  const onScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target as HTMLDivElement;
    const scrollPosition = scrollTop + clientHeight;
    const threshold = scrollHeight * 0.8;
    if (scrollPosition > threshold) {
      isInfiniteScroll && onNextPage?.();
    }
  };

  const getTextFromValue = (): string[] =>
    options.filter((o) => selectedItems.some((s) => s === o.value)).map((t) => t.text);

  return (
    <ComboBox
      dropdownStyle={{ minWidth: 200 }}
      items={[]}
      value={selectedAll || selectedItems.length === 0 ? label : getTextFromValue().join(', ')}
      disabled={disabled}
      dropdownRender={() => (
        <div className="customCombobox" onScroll={onScroll}>
          <Input
            style={{ color: 'black' }}
            type="text"
            placeholder="Search"
            iconRight={{ name: 'Search' }}
            size="small"
            value={innerSearch}
            onChange={onSearchChange}
          />
          <Checkbox value={selectedAll} label={label} onChange={handleSelectAll} />
          <Divider marginVertical={5} />
          {filteredList.map((item) => {
            return (
              <div key={item.value}>
                <Checkbox
                  label={item.text}
                  onChange={(checked) => handleSelect(item.value, checked)}
                  value={selectedItems.includes(item.value)}
                />
              </div>
            );
          })}
        </div>
      )}
    />
  );
};

export default CustomComboBox;
