import { QueryPagination, createStore } from '@care/web-ui/lib/store';
import { QueryStore } from '@care/web-ui/lib/store/store.queryPagination';
import { Category as CategoryModel, CategoryListFilter } from 'src/model/Category.model';

const { queryStateCreator } = QueryPagination;

export const $CATEGORY_LIST_STORE = '$CATEGORY_LIST_STORE';

export type CategoryListStore = QueryStore<CategoryModel, CategoryListFilter>;

export const [useStore, selector] = createStore<CategoryListStore>(queryStateCreator, {
  storeName: $CATEGORY_LIST_STORE,
});
