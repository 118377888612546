/* eslint-disable max-len */
import { ProductStatus } from 'src/enum/ProductStatus';
import { TagInfo } from './Tag.constant';

type ProductStatusTypeTag = {
  [key in ProductStatus]: TagInfo;
};

export const productStatusToTag: ProductStatusTypeTag = {
  [ProductStatus.Active]: { type: 'Success', label: 'Active' },
  [ProductStatus.Inactive]: {
    type: 'Warning',
    label: 'Inactive',
  },
  [ProductStatus.Approved]: {
    type: 'Success',
    label: 'Approved',
  },
  [ProductStatus.Rejected]: { type: 'Error', label: 'Rejected' },
  [ProductStatus.Draft]: { type: 'Warning', label: 'Draft' },
  [ProductStatus.ForReview]: {
    type: 'Warning',
    label: 'For Review',
  },
};
