import { Button, FileDragZone, Icon, Modal, Row, Typography } from '@care/web-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputField from 'src/component/Field/InputField';
import SwitchField from 'src/component/Field/SwitchField';
import { ManageType } from 'src/enum/manageType.enum';
import './CategoryModal.scss';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import { yupResolver } from '@hookform/resolvers/yup';
import ComboBoxField from 'src/component/Field/ComboBoxField';
import Field from 'src/component/Field/Field';
import Spacer from 'src/component/Spacer/Spacer';
import { CategoryContext } from 'src/context/Category.context';
import { Category } from 'src/model/Category.model';
import { buildLocalUrl, converByteToMb } from 'src/util/file.util';
import { categoryConfigSchema } from './CategorySchema';
import { UploadChangeParam, UploadFile } from '@care/web-ui/lib/components/FileDragZone/FileDragZone.typings';
import { categoryTypeOptions } from 'src/constant/OptionsConstant';

interface Props {
  manageType: ManageType;
  visible: boolean;
  onClose: () => void;
  onSave: (value: Category, isDel?: boolean) => void;
  isCreate: boolean;
  category: Category;
}

const MAX_SIZE = 5;

export const CategoryModal = (props: Props) => {
  const { visible, onClose, manageType, onSave, isCreate, category } = props;
  const { groupCats, loading } = useContext(CategoryContext);
  const [disable, setDisabled] = useState(false);

  const { control, reset, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(categoryConfigSchema(manageType)),
  });

  useEffect(() => {
    reset({ ...category });
  }, [category]);

  const headerTitle = `${isCreate ? 'Add' : 'Edit'} ${manageType}`;

  const handleCreateOrEdit = () => {
    const submitHandler = handleSubmit((values: Category) => {
      onSave(values);
    });
    submitHandler();
  };

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Delete ${manageType}?`,
      content: `This ${manageType.toLocaleLowerCase()} will be deleted. This action cannot be undone.`,
      type: 'error',
      okText: 'Delete',
      onOk: () => {
        onSave(category, true);
        handleCloseModal();
      },
    });
  };

  const cateItems: ComboBoxItem[] = useMemo(
    () =>
      groupCats.reduce((prevValue, currCate) => {
        if (watch('categoryType')) {
          if (currCate.categoryType === watch('categoryType')) {
            prevValue.push({
              text: currCate.name,
              value: currCate.id,
            });
            return prevValue;
          }
          return prevValue;
        }
        prevValue.push({
          text: currCate.name,
          value: currCate.id,
        });
        return prevValue;
      }, []),
    [groupCats, watch('categoryType')],
  );

  const defaultCustomRequest = ({ onSuccess }: any) => {
    onSuccess('done');
  };

  const buildDefaultList = () => {
    if (category) {
      if (category.localFile) {
        if (!category.localFile['url']) {
          category.localFile['url'] = buildLocalUrl(category.localFile);
        }
        return [category.localFile];
      }
      if (category.media) {
        return [category.media];
      }
    }
    return [];
  };

  const handleFileChange = (file, onChange) => {
    const fileValue = file.fileList[0];
    onChange(fileValue?.originFileObj);

    if (fileValue && converByteToMb(fileValue?.size) > MAX_SIZE) {
      setDisabled(true);
    } else {
      setValue('mediaId', null);
      setDisabled(false);
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      width={700}
      onCancel={handleCloseModal}
      visible={visible}
      title={headerTitle}
      noFooter
      content={
        <form>
          <Row gutter={[12, 12]}>
            {manageType === ManageType.Category && (
              <ComboBoxField
                control={control}
                name="categoryType"
                disabled={!isCreate}
                data-testid="category-type-field"
                label="Type"
                placeholder="Choose type"
                items={categoryTypeOptions}
              />
            )}
            <InputField
              control={control}
              name="name"
              data-testid="input-name"
              label={`${manageType} Name`}
              placeholder=""
            />
            <div className="[&>div>.care-file-drag-zone>.care-upload-label]:text-base">
              <Field
                control={control as any}
                name="localFile"
                render={({ onChange }) => (
                  <FileDragZone
                    customRequest={defaultCustomRequest}
                    onChange={(newValue: UploadChangeParam<UploadFile<any>>) => {
                      handleFileChange(newValue, onChange);
                    }}
                    defaultFileList={buildDefaultList() as any[]}
                    label={`${manageType} Image`}
                    buttonTitle="Upload Image"
                    accept=".png, .jpg, .jpeg"
                    multipleUpload={false}
                    buttonType="outline"
                    maxCount={1}
                    listType="picture"
                    maxSize={MAX_SIZE}
                    singleUploadHideDropzone
                  />
                )}
              />
            </div>

            {manageType === ManageType.Subcategory && (
              <div>
                <div className="care-label">Assign to Category</div>
                <Row gutter={16} spans={[8, 16]}>
                  <ComboBoxField
                    items={categoryTypeOptions}
                    data-testid="sub-category-type-field"
                    control={control}
                    disabled={!isCreate}
                    name="categoryType"
                    onChange={() => {
                      setValue('parentCategoryId', '');
                    }}
                  />
                  <ComboBoxField
                    control={control}
                    name="parentCategoryId"
                    placeholder="Choose category"
                    data-testid="parent-category-field"
                    label=""
                    items={cateItems}
                    onChange={(value) => {
                      setValue('categoryType', groupCats.find((item) => item.id === value)?.categoryType);
                    }}
                  />
                </Row>
              </div>
            )}

            <div className="visibilitySwitch">
              <Typography display="block" className="care-label">
                Active
              </Typography>
              <SwitchField name="active" control={control} value={category.active} />
            </div>
            <Spacer size={16} />

            <div className={isCreate ? 'groupBtnCreate' : 'groupBtnEdit'}>
              {!isCreate && (
                <div className={`deleteItem`} onClick={handleDelete}>
                  <Icon name="TrashBin" type="outline" color={'#D64949'} />
                  <Typography weight="semi" className="deleteText">
                    Delete
                  </Typography>
                </div>
              )}
              <div className="footerBtn">
                <Button type="outline" title="Cancel" onClick={handleCloseModal} />
                <Button loading={loading} title="Save" onClick={handleCreateOrEdit} disabled={disable} />
              </div>
            </div>
          </Row>
        </form>
      }
    />
  );
};
