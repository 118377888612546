import { Gender } from 'src/enum/Gender';
import { IdentityDocumentType } from 'src/enum/IdentityDocumentType';
import { SearchType, UserSortBy, UserStatus } from 'src/enum/User.enum';

export interface AddressDto {
  description?: string;

  block?: string;

  street?: string;

  unit?: string;

  postal?: string;

  country?: string;
}

export class UserModel {
  _id!: string;

  name!: string;

  email!: string;

  careId!: string;

  role!: string;

  avatar?: string;

  contact?: string;

  isContactVerified!: boolean;

  idNum?: string;

  idType?: string;

  passport?: string;

  raceCode?: string;

  race?: string;

  countryCode?: string;

  gender?: Gender;

  dob?: Date;

  nationality?: string;

  googleID?: string;

  facebookID?: string;

  appleID?: string;

  singpassID?: string;

  emailActivated?: boolean;

  address?: AddressDto;

  isProfileVerified?: boolean;

  unsuccessLoginTimes!: number;

  lastTimeLoginFail?: Date;

  lastTimeUpdatePassword!: Date;
}

export interface UserFilter {
  searchValue?: string;
  searchType?: SearchType;
  status?: UserStatus;
  idType?: IdentityDocumentType;
  sortBy?: UserSortBy;
  pageIndex: number;
  pageSize: number;
}
