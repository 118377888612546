import * as yup from 'yup';

export const platformSettingsSchema = (isCreate: boolean) => {
  const platformCreateSettingSchema = {
    key: yup.string().required('Please enter key name').max(36).trim().uppercase(),
    value: yup
      .string()
      .required('Please enter value')
      .max(10000)
      .transform((val) => (typeof val === 'string' ? val.trim() : val)),
    unit: yup.string().nullable().trim().max(100),
  };

  const platformUpdateSettingSchema = {
    key: yup.string().required('Please enter key name').max(36).trim().uppercase(),
    value: yup
      .string()
      .max(10000)
      .nullable()
      .transform((val) => (typeof val === 'string' ? val.trim() : val)),
    unit: yup.string().nullable().trim().max(100),
  };

  const schema = isCreate
    ? yup.object().shape({ ...platformCreateSettingSchema })
    : yup.object().shape({ ...platformUpdateSettingSchema });

  return schema;
};
