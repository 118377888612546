import { BenefitApplyType } from 'src/enum/BenefitApplyType';
import { BenefitType } from 'src/enum/BenefitType';
import { DiscountType } from 'src/enum/DiscountType';
import { RedemptionFrequency } from 'src/enum/RedemptionFrequency';
import { RedemptionLimitType } from 'src/enum/RedemptionLimitType';

export class CreateOrEditMembershipsModelDTO {
  name: string;
  displayName: string;
  number: string; // membership number
  description: string;
  termsAndConditions: string;
  organizationIds: string[];
  active: boolean;
  applyAllOrganizationProducts: boolean;
  applyBenefitToAllProduct: boolean;
  benefitSettings: BenefitSettings[];
}

export interface BenefitSettings extends CoreBenefit {
  settingType: BenefitApplyType;
  organizationId: string;
  productId: string;
  locationId: string;
  locationObj: {
    [key: string]: CoreBenefit;
  };
}

export interface CoreBenefit {
  id: string;
  type: BenefitType;
  discountType: DiscountType;
  discountValue: number;
  redemptionType: RedemptionLimitType;
  limitByNumber: number;
  limitByAmount: number;
  redemptionFrequency: RedemptionFrequency;
  redemptionValue: number;
}
