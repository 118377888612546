import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';
import config from 'src/Config';
import { Category, CategoryListFilter } from 'src/model/Category.model';
import { CreateOrEditCategory } from 'src/model/dto/CreateOrEditCategory.request';
import { APIBase, makeQueryString } from 'src/util/Request';

const URL = `${config.ADMIN_API_URL}/categories`;

class CategoryService {
  async getAllCategories(countryCode: string): Promise<GeneralListResponse<Category>> {
    const body = await APIBase.get<GeneralListResponse<Category>>(
      `${URL}?pageSize=100&pageIndex=1&current-country=${countryCode}`, // When updating the pageSize or pageIndex, need to update in the test file also (cypress/support/step_definitions/configuration/index.spec.ts)
    );
    return body.data;
  }

  async getCategories(filter: CategoryListFilter): Promise<GeneralListResponse<Category>> {
    const url = `${URL}?${makeQueryString(filter)}`;
    const body = await APIBase.get<GeneralListResponse<Category>>(url);
    return body.data;
  }

  async createCategory(data: CreateOrEditCategory): Promise<Category> {
    const body = await APIBase.post<Category>(`${URL}`, data);
    return body.data;
  }

  async updateCategory(data: CreateOrEditCategory): Promise<any> {
    const res = await APIBase.put<Category>(`${URL}/${data.id}`, data);
    return res.data; //need res to handle error if any
  }

  async deleteCategory(categoryId: string): Promise<any> {
    const res = await APIBase.delete<Category>(`${URL}/${categoryId}`);
    return res.data; //need res to handle error if any
  }
}

export default new CategoryService();
