import { RefundRequestedBy } from 'src/enum/RefundRequestedBy';
import { RefundStatus } from 'src/enum/RefundStatus';
import { RefundType } from 'src/enum/refundType.enum';

export const CARE_REFUND_FEE_BY_CURRENCY = {
  SGD: 2,
  HKD: 10,
  VND: 30000,
};

export const mappingRefundStatus: { [key in RefundStatus]?: string } = {
  [RefundStatus.Requesting]: 'Pending',
  [RefundStatus.InProgress]: 'In Progress',
  [RefundStatus.Rejected]: 'Rejected',
  [RefundStatus.RejectedByIssuer]: 'Rejected by Issuer',
  [RefundStatus.Success]: 'Refunded',
  [RefundStatus.Partial]: 'Partially Refunded',
};

export const mappingRefundType: { [key in RefundType]: string } = {
  [RefundType.standard]: 'Standard',
  [RefundType.noneStandard]: 'Custom Amount',
};

export const mappingRefundRequestedBy: { [key in RefundRequestedBy]: string } = {
  [RefundRequestedBy.Admin]: 'Admin',
  [RefundRequestedBy.Consumer]: 'Consumer',
};
