import { BaseFilter, BasicSortBy, createPagingDataContext } from 'src/util/createPagingDataContext';
import { DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import ProviderService from 'src/service/Provider.service';
import { ProviderDetail } from 'src/model/ProviderDetail.model';

export interface AccountListFilter extends BaseFilter<BasicSortBy> {
  orgId: string;
  locationId?: string;
}

const fetcher = async (filter: AccountListFilter, pageIndex: number, pageSize: number) => {
  return ProviderService.getProviders(filter, pageIndex, pageSize);
};

const [AccountListProvider, useAccountListContext] = createPagingDataContext<ProviderDetail, AccountListFilter>(
  fetcher,
  {
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  },
);

export { useAccountListContext };

export default AccountListProvider;
