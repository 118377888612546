import { OrderStatus, OrderType } from 'src/enum/OrderStatus';
import { PaymentMethodType } from 'src/enum/PaymentMethod';

type TagInfo = {
  type: any;
  label: string;
};

type OrderStatusTypeTag = {
  [key in OrderStatus]: TagInfo;
};

type PaymentMethodLabel = {
  [key in PaymentMethodType]: string;
};

export const orderStatusTypeTag: OrderStatusTypeTag = {
  [OrderStatus.Pending]: { type: 'Neutral', label: 'Confirmed' }, // related to SQ1-252
  [OrderStatus.Cancelled]: { type: 'Error', label: 'Cancelled' },
  [OrderStatus.Completed]: { type: 'Success', label: 'Completed' },
  [OrderStatus.Refunded]: { type: 'Success', label: 'Refunded' },
  [OrderStatus.OutForDelivery]: { type: 'Pending', label: 'Out for Delivery' },
};

export const paymentMethodLabel: PaymentMethodLabel = {
  [PaymentMethodType.Offline]: 'In-person',
  [PaymentMethodType.PayNow]: 'PayNow',
  [PaymentMethodType.Card]: 'Debit/Credit Card',
  [PaymentMethodType.Redemption]: 'Redemption Voucher',
};

type OrderTypeTag = {
  [key in OrderType]: string;
};

export const orderTypeTag: OrderTypeTag = {
  [OrderType.Product]: 'Product',
  [OrderType.Service]: 'Service',
};
