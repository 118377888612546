import { Utils } from '@care/web-ui';
import { EcProductModel } from 'src/model/Catalog.model';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';

export const formatEcProductRouting = (product: EcProductModel): ComboBoxItem => {
  const finalPath = `products/e-commerce/$slug?id=$id`;
  const slug = Utils.CommonUtil.kebabCase(product.name);

  return {
    text: product.name,
    description: product.organizationName,
    value: finalPath.replace('$slug', slug).replace('$id', product.id),
  };
};
