import { CustomAttributeProps } from '@care/web-ui';
import React, { useMemo } from 'react';
import { Controller, Control } from 'react-hook-form';
import { componentTypeForCustomAttrRegExp } from 'src/constant/RegExp';
import FieldError from './FieldError';

interface RenderOptions {
  onChange: (value: any) => any;
  onBlur: () => any;
  value: string;
  invalid: boolean;
  error: string | undefined;
}

interface Props extends CustomAttributeProps {
  name: string;
  defaultValue?: string;
  rules?: any;
  render: (options: RenderOptions) => any;
  control: Control;
}

const SUFFIX = 'errormessage';

const Field = ({ name, rules, defaultValue, render, control, customAttr }: Props) => {
  const errorTestId = useMemo<string>(() => {
    const dataTestId = customAttr?.dataTestId;
    // fallback
    if (!dataTestId) return SUFFIX;
    // not a supported component type, just append SUFFIX
    if (!componentTypeForCustomAttrRegExp.test(dataTestId)) {
      return `${dataTestId}-${SUFFIX}`;
    }
    // last element is component type, replace it with SUFFIX
    const splitted = dataTestId.split('-');
    splitted.pop();
    return `${splitted.join('-')}-${SUFFIX}`;
  }, [customAttr?.dataTestId]);

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { invalid, error } }) => (
        <div>
          {render({
            onBlur,
            onChange: (newValue: any) =>
              onChange({
                target: { name, value: newValue },
                type: 'change',
              }),
            value,
            invalid,
            error: error?.message,
          })}
          <FieldError message={error?.message} dataTestId={errorTestId} />
        </div>
      )}
    />
  );
};

export default Field;
