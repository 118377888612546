import { Table, Typography, Icon, Utils } from '@care/web-ui';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Divider from 'src/component/Divider/Divider';
import Spacer from 'src/component/Spacer/Spacer';
import { Routes } from 'src/constant';
import { PAGE_SIZE_PER_MODULE, pageSizeOptions } from 'src/constant/Pagination.constant';
import { useAccountListContext } from 'src/context/AccountList.context';
import { ProviderStatus } from 'src/enum/ProviderStatus.enum';
import { Role } from 'src/enum/Role.enum';
import { ProviderDetail } from 'src/model/ProviderDetail.model';
import { convertCamelCaseToNormal } from 'src/util/convertCamelCase.util';

import './AccountTable.scss';

const {
  CommonUtil: { isEmpty },
} = Utils;

export const AccountTable = () => {
  const query = useParams();
  const { loadData, data: accounts, pagination, setPagination, filter, setFilter } = useAccountListContext();

  const { orgId } = query as any;

  useEffect(() => {
    setFilter({ ...filter, orgId });
  }, [orgId]);

  useEffect(() => {
    if (!isEmpty(filter)) {
      loadData();
    }
  }, [filter, pagination.pageIndex, pagination.pageSize]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, data: ProviderDetail) => (
        <Link to={Routes.AccountDetail.replace(':orgId', data.orgId).replace(':accId', data.id)}>
          <div className="accName">
            <Typography weight="bold" level={9} className="name">
              {value || 'No name'}
            </Typography>
            <Icon name="RightSmall" type="outline" color="#0f6d77" />
          </div>
        </Link>
      ),
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (value: ProviderStatus) => <Typography level={9}>{convertCamelCaseToNormal(value)}</Typography>,
    },
    {
      title: 'role',
      dataIndex: 'role',
      key: 'role',
      render: (value: Role) => <Typography level={9}>{convertCamelCaseToNormal(value)}</Typography>,
    },
  ];

  return (
    <div>
      <Typography level={5} fontCategory="serif">
        Accounts
      </Typography>
      <Divider marginVertical={8} />
      <Spacer size={8} />
      <Table
        columns={columns}
        data={accounts}
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.ACCOUNTS,
          showSizeChanger: true,
          pageSizeOptions,
          total: pagination.total,
          onChange: (pageIndex, pageSize) => setPagination({ pageIndex, pageSize }),
        }}
      />
    </div>
  );
};
