import { PromoCodeType } from 'src/enum/PromoCodeType';

export class ApplyProductItem {
  id?: string;
  productId: string;
  selectAllLocations?: boolean;
  locationIds: string[];
}
export class Organisation {
  organizationId: string;
  applyProducts: ApplyProductItem[];
}

export class CreatePromoCodeModelDTO {
  code: string;
  codeType: PromoCodeType;
  title: string;
  description: string;
  startDate?: string;
  endDate?: string;
  discountType: string;
  amount: number;
  totalQuantity: number | null;
  quantityPerUser: number | null;
  active: boolean;
  organizations: Organisation[];
}

export class PromoCodeFormDetailModel extends CreatePromoCodeModelDTO {
  quantityOption: string;
  quantityPerUserOption: string;
  totalQuantityAmount?: number;
  quantityPerUserAmount?: number;
}
