import * as yup from 'yup';

export const schema = yup.object().shape({
  code: yup
    .string()
    .required('This field is required.')
    .max(10, 'Code must be at most 10 characters')
    .matches(/^[a-zA-Z0-9]*$/),
  codeType: yup.string().required('This field is required.'),
  title: yup.string().required('This field is required.').max(250, 'Description must be less than 250 characters'),
  description: yup
    .string()
    .required('This field is required.')
    .max(250, 'Description must be less than 250 characters'),
  quantityOption: yup.string().required('This field is required.'),
  quantityPerUserOption: yup.string().required('This field is required.'),
  totalQuantityAmount: yup.number().when('quantityOption', (value: string) =>
    value === 'limited'
      ? yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .moreThan(0, 'Must be more than 0')
          .required('This field is required.')
      : yup.number().notRequired().nullable(),
  ),
  quantityPerUserAmount: yup.number().when('quantityPerUserOption', (val: string) =>
    val === 'limited'
      ? yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .moreThan(0, 'Must be more than 0')
          .required('This field is required.')
      : yup.number().notRequired().nullable(),
  ),
  // organizations: yup.array().required(),
});
