import React from 'react';
import { Typography } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';

import { FeedbacksTable } from './feedbacks-table/FeedbacksTable';
import { FeedbacksTableToolbar } from './feedbacks-table-toolbar/FeedbacksTableToolbar';

const Feedbacks = () => {
  return (
    <div className="p-11">
      <div>
        <Typography fontCategory="serif" level={3}>
          Feedback
        </Typography>
      </div>

      <Spacer size={32} />

      <FeedbacksTableToolbar />

      <Spacer size={12} />

      <FeedbacksTable />
    </div>
  );
};

export default Feedbacks;
