import React from 'react';
import { ComboBox, Modal, TextArea, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';
import { useOrderDetailContext } from 'src/context/OrderDetail.context';
import { CARE_REFUND_FEE_BY_CURRENCY } from 'src/constant/Refund';
import { refundReasons } from 'src/constant/refundReasons.constant';
import Spacer from 'src/component/Spacer/Spacer';
import { useOrderActionContext } from 'src/context/OrderAction.context';
import { useParams } from 'react-router-dom';
import { notification } from 'src/util/notification.util';
import { useProductDetailContext } from 'src/context/ProductDetail.context';

const { defaultCurrency } = Constants.CurrencyConstant;
const { convertCurrencyValue } = Utils.CurrencyUtil;

interface Props {
  visible: boolean;
  onDone: () => any;
  onClose: () => any;
}

const reasonOptions = [
  { text: 'Please select a reason...', value: '' },
  ...refundReasons.map((reason) => ({ text: reason, value: reason })),
];

const otherReasonValue = 'Others';

const RequestRefundModal = ({ visible, onClose }: Props) => {
  const query = useParams();
  const { id } = query as any;
  const { order, loadDetail } = useOrderDetailContext();
  const { product, loadDetail: loadProduct } = useProductDetailContext();
  const { createRefundRequest, loading } = useOrderActionContext();
  const { orderItems } = order || {};
  const [reason, setReason] = React.useState('');
  const [otherReason, setOtherReason] = React.useState('');

  const productCurrency = orderItems[0]?.product?.currency || defaultCurrency;

  const cancellationFee = React.useMemo(() => {
    let value = CARE_REFUND_FEE_BY_CURRENCY[productCurrency] || 0;
    if (product && product.id) {
      value = product.cancellationFee;
    }
    return convertCurrencyValue(value, productCurrency);
  }, [productCurrency, product]);

  const onSubmit = async () => {
    const done = await createRefundRequest(order.id, reason !== otherReasonValue ? reason : otherReason);
    if (done) {
      notification({
        type: 'success',
        message: 'Requested refund for the order',
        description: 'Please wait for the Care admin to approve or reject the request',
      });
      loadDetail(id as string);
    }
  };

  React.useEffect(() => {
    if (order?.id) {
      const productId = order.orderItems[0]?.product?.id;
      if (productId) {
        loadProduct(productId);
      }
    }
  }, [order]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Refund"
      okText={loading ? 'Requesting...' : 'Request'}
      okButtonProps={{
        loading,
        disabled: !reason || (reason === otherReasonValue && !otherReason) || loading,
      }}
      onOk={onSubmit}
      content={
        <div>
          <Typography className="care-label">Cancellation Fee</Typography>
          <Typography>
            {cancellationFee} {productCurrency}
          </Typography>
          <Spacer size={16} />
          <ComboBox
            label="Please select a refund reason"
            items={reasonOptions}
            value={reason}
            onChange={(value) => setReason(value as string)}
          />
          {reason === otherReasonValue && (
            <>
              <Spacer size={16} />
              <TextArea label="Remarks" value={otherReason} onChange={setOtherReason} />
            </>
          )}
        </div>
      }
    />
  );
};

export default RequestRefundModal;
