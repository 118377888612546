import React from 'react';
import { Switch, Route } from 'react-router-dom';

import RefundsProvider from 'src/context/Refunds.context';
import { Routes } from 'src/constant';
import Refunds from 'src/module/refunds/Refunds/Refunds';
import RefundDetail from 'src/module/refund-detail/refund-detail/RefundDetail';
import OrderDetailProvider from 'src/context/OrderDetail.context';
import ApproveRejectActionsProvider from 'src/context/ApproveRejectActions.context';
import RefundDetailProvider from 'src/context/RefundDetail.context';

function RefundsContainer() {
  return (
    <RefundsProvider>
      <Switch>
        <Route path={Routes.Refunds} exact>
          <Refunds />
        </Route>
        <Route path={`${Routes.Refunds}/:id`} exact>
          <OrderDetailProvider>
            <RefundDetailProvider>
              <ApproveRejectActionsProvider>
                <RefundDetail />
              </ApproveRejectActionsProvider>
            </RefundDetailProvider>
          </OrderDetailProvider>
        </Route>
      </Switch>
    </RefundsProvider>
  );
}

export default RefundsContainer;
