import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';
import { Currency } from 'src/enum/Currency';
import { OrderStatus } from 'src/enum/OrderStatus';
import { PaymentMethodType } from 'src/enum/PaymentMethod';
import { PaymentStatus } from 'src/enum/PaymentStatus';
import { paymentMethodLabel } from 'src/constant/OrderStatusTag.constant';
import { ConsumerModel } from './Consumer.model';
import { RefundStatus } from 'src/enum/RefundStatus';

const {
  DateUtil: { formatDateString },
  OrderUtil: { calculateTotalOrderAmount },
} = Utils;
const { DATE } = Constants.DateConstant;

export interface OrderDiscount {
  point: number;
  amount: number;
  walletId: string;
  pointName: string;
  membershipId: string;
  name: string;
  discountType: string;
}

export class OrderListItemModel {
  id: string;
  orderNumber: number;
  status: OrderStatus;
  paymentStatus: PaymentStatus;
  paymentMethod: PaymentMethodType;
  refundStatus: RefundStatus;
  createdAt: string;
  grandTotal: number;
  consumerProfile: Pick<ConsumerModel, 'fullName' | 'id'>;
  userId: string;
  orgName: string;
  currency: Currency;
  orderDiscounts: OrderDiscount[];

  public get grandTotalText(): string {
    return Utils.CurrencyUtil.convertCurrencyValue(
      calculateTotalOrderAmount({
        subTotal: this.grandTotal,
        discountList: this.orderDiscounts?.map((currentDiscount) => currentDiscount.amount),
      }),
      this.currency,
    );
  }

  get createAtText(): string {
    return formatDateString(this.createdAt, DATE.DATE_TIME);
  }

  public get paymentMethodText(): string {
    return paymentMethodLabel[this.paymentMethod];
  }
}
