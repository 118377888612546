import { CollapseCard, Modal, Typography } from '@care/web-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Divider from 'src/component/Divider/Divider';
import Spacer from 'src/component/Spacer/Spacer';
import { RefundsContext } from 'src/context/Refunds.context';
import { ApproveBulkRefundsModel } from 'src/model/BulkRefunds.model';

import './ApproveRefundModal.scss';
import { TransactionRow } from './TransactionRow';

interface Props {
  visible: boolean;
  onDone: () => any;
  onClose: () => any;
  refundIds: string[];
}

export const ApproveBulkRefund = ({ visible, onClose, onDone, refundIds }: Props) => {
  const { loading, getBulkRefunds, handleApproveBulkRefunds } = useContext(RefundsContext);
  const [bulkRefunds, setBulkRefunds] = useState<ApproveBulkRefundsModel>();

  const loadBulkRefunds = async () => {
    const res = await getBulkRefunds(refundIds);
    setBulkRefunds(res);
  };

  useEffect(() => {
    if (visible && refundIds) {
      loadBulkRefunds();
    }
  }, [visible, refundIds]);

  const bulkRefundItems = useMemo(() => {
    if (bulkRefunds) {
      return bulkRefunds?.items.map((f) => ({
        key: f.productName,
        header: <Typography level={8} weight="semi">{`${f.productName} x ${f.quantity}`}</Typography>,
        body: (
          <div className="subAmount">
            <TransactionRow
              name="Payment Amount Subtotal"
              amount={`${f.paymentAmount.valueWithSymbol}`}
              isBullet
              fontSize={9}
              fontWeight="regular"
            />
            <TransactionRow
              name="Cancellation Fee Subtotal"
              amount={`${f.cancellationFee.valueWithSymbol}`}
              isBullet
              fontSize={9}
              fontWeight="regular"
            />
          </div>
        ),
      }));
    }
    return [];
  }, [bulkRefunds]);

  const onApproveBulkRefunds = async () => {
    const done = await handleApproveBulkRefunds(refundIds);

    if (done) {
      onDone();
      onClose();
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Approve Refund"
      okText="Refund"
      okButtonProps={{ loading }}
      onOk={onApproveBulkRefunds}
      content={
        <div>
          <Typography level={8}>
            Please review the following details. Funds will be processed after approving the refund.
          </Typography>
          <Spacer size={16} />
          <div className="card-refund">
            <CollapseCard className="customCollapseCard" bordered={false} panels={bulkRefundItems} />
            <Divider marginVertical={12} />
            <div>
              {bulkRefunds?.totalItems.map((b) => (
                <>
                  <TransactionRow
                    name={`${b.currency} Total Number of Refunds`}
                    amount={b.quantity.toString()}
                    fontSize={8}
                    fontWeight="semi"
                  />
                  <div className="numberOfRefunds">
                    <TransactionRow
                      name="Payment Amount Total"
                      amount={b.paymentAmount.valueWithSymbol}
                      isBullet
                      fontSize={9}
                      fontWeight="semi"
                    />
                    <TransactionRow
                      name="Cancellation Fee Total"
                      amount={`-${b.cancellationFee.valueWithSymbol}`}
                      isBullet
                      fontSize={9}
                      fontWeight="semi"
                    />
                    <TransactionRow
                      name={`${b.currency} Total Amount to Refund`}
                      amount={b.totalAmount.valueWithSymbol}
                      isBullet
                      fontSize={9}
                      fontWeight="bold"
                      isHighlightAmount
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      }
    />
  );
};
