import React from 'react';
import { shallow } from '@care/web-ui/lib/store';
import { CategoryListStore, useStore as useCategoryListStore } from 'src/zustand-store/category/CategoryList.store';
import { formatEcCategoryRoutings } from 'src/util/category.util';
import { Utils } from '@care/web-ui';

const {
  CommonUtil: { pick },
} = Utils;

export const useFormatEcCategoryRoutings = () => {
  const { data } = useCategoryListStore((store: CategoryListStore) => pick(store, 'data'), shallow);

  const ecCategoryRoutings = React.useMemo(() => {
    return formatEcCategoryRoutings(data);
  }, [data]);

  return { ecCategoryRoutings };
};
