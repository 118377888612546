import { Organisation } from './CreatePromoCode.request';

export class EditPromoCodeModelDTO {
  title: string;
  description: string;
  endDate?: string;
  totalQuantity: number;
  quantityPerUser: number;
  active: boolean;
  organizations: Organisation[];
}
