import { Builder } from 'builder-pattern';
import { Amount } from './Amount.model';
import { AppointmentModel } from './Appointment.model';
import { OrderDetailItemModelDTO } from './dto/OrderDetailItem.response';
import { ProductModel } from './Product.model';

export class OrderItemModel {
  id: string;
  orderItemNumber: string;
  quantity: number;
  appointmentBookings: AppointmentModel[];
  product: ProductModel;
  price: Amount;
  discountAmount: Amount;

  static convertFromDTO(dto: OrderDetailItemModelDTO): OrderItemModel {
    const { price, discountPrice, product, ...rest } = dto;
    return Builder(OrderItemModel, {
      ...rest,
      price: Builder(Amount, {
        currency: product.currency,
        value: discountPrice || price,
      }).build(),
      product,
    }).build();
  }
}
