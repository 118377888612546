import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from 'src/constant';
import PromoCodeListProvider from 'src/context/PromoCodeList.context';
import PromoCodeActionProvider from 'src/context/PromoCodeAction.context';
import PromoCodes from 'src/module/promoCodes/PromoCodes/PromoCodes';
import CreateOrEditPromoCode from 'src/module/promoCodes/CreateOrEditPromoCode/CreateOrEditPromoCode';
import ProductListProvider from 'src/context/ProductList.context';
import LocationListProvider from 'src/context/LocationList.context';

function PromoCodesContainer(): React.ReactElement {
  return (
    <Switch>
      <Route path={Routes.PromoCodes} exact>
        <PromoCodeListProvider>
          <PromoCodeActionProvider>
            <PromoCodes />
          </PromoCodeActionProvider>
        </PromoCodeListProvider>
      </Route>
      <Route path={`${Routes.PromoCodes}/:id/edit`} exact>
        <ProductListProvider>
          <LocationListProvider>
            <PromoCodeActionProvider>
              <CreateOrEditPromoCode />
            </PromoCodeActionProvider>
          </LocationListProvider>
        </ProductListProvider>
      </Route>
    </Switch>
  );
}

export default PromoCodesContainer;
