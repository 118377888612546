import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';
import { Builder } from 'builder-pattern';
import config from 'src/Config';
import { ProductFilter } from 'src/context/ProductList.context';
import { ProductModel } from 'src/model/Product.model';
import { APIBase, makeQueryString } from 'src/util/Request';

const URL = `${config.ADMIN_API_URL}/products`;
const CATALOG_URL = `${config.API_URL}/catalogs`;

class ProductAPI {
  async getProducts(
    filter: ProductFilter,
    pageIndex: number,
    pageSize: number,
  ): Promise<GeneralListResponse<ProductModel>> {
    const params = {
      ...filter,
      pageIndex,
      pageSize,
    };
    delete params['organizationId'];
    const url = `${URL}?${makeQueryString(params)}`;

    const body = await APIBase.get<GeneralListResponse<ProductModel>>(url);

    const { data, ...rest } = body.data;

    return { ...rest, data: data.map((d) => Builder(ProductModel, d).build()) };
  }

  async approveProduct(id: string): Promise<boolean> {
    const url = `${URL}/${id}/approve`;

    await APIBase.put(url);

    return true;
  }

  async rejectProduct(id: string): Promise<boolean> {
    const url = `${URL}/${id}/reject`;

    await APIBase.put(url);

    return true;
  }

  async getProductById(id: string): Promise<ProductModel> {
    const url = `${CATALOG_URL}/${id}`;

    const body = await APIBase.get<ProductModel>(url);

    return Builder(ProductModel, body.data).build();
  }
}

export default new ProductAPI();
