import { urlRegExp } from 'src/constant/RegExp';
import { UIRoutingType } from 'src/enum/routingType.enum';
import * as yup from 'yup';
import { Utils } from '@care/web-ui';

const {
  DateUtil: { isBeforeNow, isAfterNow },
} = Utils;

export const noticeSchema = () => {
  const schema = {
    title: yup.string().required('Please input title of notice').max(35, 'Maximum character length is 35'),
    description: yup.string().required('Please input description of notice'),
    isActionButton: yup.boolean(),
    buttonText: yup.string().when('isActionButton', {
      is: true,
      then: yup.string().required('Please input button text').max(35, 'Maximum character length is 35'),
    }),
    routingType: yup.string().when('isActionButton', {
      is: true,
      then: yup.string().required('Please input routing type'),
    }),
    routingValue: yup.string().when('isActionButton', {
      is: true,
      then: yup.string().when('routingType', {
        is: (val: string) => val === UIRoutingType.External,
        then: yup.string().required('Please provide the link.').matches(urlRegExp, 'Invalid link'),
      }),
    }),
    eventName: yup.string().when('isActionButton', {
      is: true,
      then: yup.string().required('Please input event name'),
    }),
    isPresetVisibilityPeriod: yup.boolean(),
    startDate: yup.date().when('isPresetVisibilityPeriod', {
      is: true,
      then: yup
        .date()
        .required('Please input start date')
        .test('is-smaller', 'Start date must be smaller than or equal to end date', function (startDate) {
          const { endDate } = this.parent;
          return startDate <= endDate;
        }),
    }),
    endDate: yup.date().when('isPresetVisibilityPeriod', {
      is: true,
      then: yup
        .date()
        .required('Please input end date')
        .test('is-greater', 'End date must be greater than or equal to start date', function (endDate) {
          const { startDate } = this.parent;
          return startDate <= endDate;
        }),
    }),
  };

  return yup.object().shape({ ...schema });
};
