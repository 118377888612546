/* eslint-disable tailwindcss/no-custom-classname */
import { Button, Table, Typography, Utils } from '@care/web-ui';
import React, { useCallback, useMemo } from 'react';
import { PlatformSettingSortBy } from 'src/enum/PlatformSetting.enum';
import { PAGE_SIZE_PER_MODULE, pageSizeOptions } from 'src/constant/Pagination.constant';
import { Pagination, Sorter } from 'src/util/createPagingDataContext';
import { PlatformSetting, PlatformSettingFilter, PlatformSettingModel } from 'src/model/PlatformSetting.model';
import { SortOptionTable } from 'src/enum/SortBy.enum';
import { Builder } from 'builder-pattern';

const {
  CommonUtil: { pick },
} = Utils;

interface PlatformSettingsTableProps {
  filter: PlatformSettingFilter;
  setFilter: (filter: PlatformSettingFilter) => void;
  loading: boolean;
  pagination: Pagination;
  data: PlatformSettingModel[];
  handleEditSetting: (setting: PlatformSetting) => void;
}

const TextCell = (value: string) => (
  <Typography className="break-all" level={9}>
    {value || '-'}
  </Typography>
);

const getColumns = (filter: PlatformSettingFilter, handleEditSetting) => {
  return [
    {
      title: 'Key Name',
      dataIndex: 'key',
      width: '25%',
      sorter: (sortOrder: string) => sortOrder,
      render: TextCell,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '60%',
      render: (value: any) => (
        <Typography className="break-all" level={9}>
          {value === null ? 'null' : JSON.stringify(value) || '-'}
        </Typography>
      ),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      width: '10%',
      render: TextCell,
    },
    {
      title: '',
      render: (record: PlatformSettingModel) => {
        const setting = Builder(PlatformSetting, {
          id: record._id,
          value: record.value === null ? 'null' : JSON.stringify(record.value),
          ...pick(record, ['key', 'isMultipleLanguage', 'unit']),
        }).build();

        return (
          <div>
            <Button type="link" title="Edit" size="small" onClick={() => handleEditSetting(setting)} />
          </div>
        );
      },
    },
  ];
};

const PlatformSettingsTable = ({
  data: platformSettings,
  loading,
  pagination,
  filter,
  setFilter,
  handleEditSetting,
}: PlatformSettingsTableProps) => {
  const getSortBy = useCallback((field: string, order: string): PlatformSettingSortBy | undefined => {
    if (!order) return;

    switch (field) {
      case 'key':
        return order === SortOptionTable.Ascend ? PlatformSettingSortBy.key_asc : PlatformSettingSortBy.key_desc;
      default:
        return;
    }
  }, []);

  const handleTableChange: React.ComponentProps<typeof Table>['onChange'] = (
    newPagination: any,
    filterTable: Record<string, any>,
    newSortBy: Sorter,
  ) => {
    const sortByValue = getSortBy(newSortBy.field, newSortBy.order);

    setFilter({
      ...filter,
      ...filterTable,
      pageIndex: newPagination.current,
      pageSize: newPagination.pageSize,
      sortBy: sortByValue,
    });
  };

  const columns = useMemo(() => getColumns(filter, handleEditSetting), [filter]);

  return (
    <div className="platform-settings-table">
      <Table
        rowKey="_id"
        loading={loading}
        columns={columns as any[]}
        data={platformSettings}
        onChange={handleTableChange}
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.PLATFORMSETTINGS,
          showSizeChanger: true,
          pageSizeOptions,
          total: pagination.total,
          current: pagination.pageIndex,
          pageSize: pagination.pageSize,
          onChange: (pageIndex: number, pageSize: number) =>
            setFilter({
              pageIndex,
              pageSize,
            }),
        }}
      />
    </div>
  );
};

export default PlatformSettingsTable;
