import { Button, Icon, Modal, Row, Typography, Constants, Utils } from '@care/web-ui';
import React, { useEffect } from 'react';
import InputField from 'src/component/Field/InputField';
import { useForm } from 'react-hook-form';
import SwitchField from 'src/component/Field/SwitchField';
import { noticeSchema } from './ManageNoticesModal.config';
import { yupResolver } from '@hookform/resolvers/yup';
import { RoutingPath } from 'src/component/RoutingPath/RoutingPath';
import { HomeScreenNotice } from 'src/model/HomeScreenNotice.model';
import { HomeScreenNoticeFormModel } from 'src/model/HomeScreenNoticeForm.model';
import DatePickerField from 'src/component/Field/DatePickerField';
import TextAreaField from 'src/component/Field/TextAreaField';

const {
  CommonUtil: { isNull },
} = Utils;
const { DATE } = Constants.DateConstant;

const dataTestIdPrefix = 'manage-notices';

interface IManageNoticesModalProps {
  visible: boolean;
  onClose: () => void;
  onSave: (value: HomeScreenNotice, isDel?: boolean) => boolean;
  itemDetail: HomeScreenNotice;
}

const initialFormData = new HomeScreenNoticeFormModel();

const ManageNoticesModal = ({ visible, onClose, onSave, itemDetail }: IManageNoticesModalProps) => {
  const { control, reset, handleSubmit, setValue, watch } = useForm<HomeScreenNoticeFormModel>({
    defaultValues: initialFormData,
    resolver: yupResolver(noticeSchema()),
    shouldUnregister: true,
  });

  const isEdit = !isNull(itemDetail);

  useEffect(() => {
    if (itemDetail) {
      const castingVal = HomeScreenNoticeFormModel.convertFromDTO(itemDetail);
      reset(castingVal);
    }
  }, [itemDetail]);

  const isActionButton = watch('isActionButton');
  const isPresetVisibilityPeriod = watch('isPresetVisibilityPeriod');
  const headerTitle = `${isEdit ? 'Edit' : 'Add'} Notice`;

  const handleCreateOrEdit = () => {
    const submitHandler = handleSubmit((values: HomeScreenNoticeFormModel) => {
      const castingVal = HomeScreenNoticeFormModel.convertToDTO(values);
      if (isEdit) {
        castingVal.id = itemDetail.id;
        castingVal.index = itemDetail.index;
        castingVal.isVisible = itemDetail.isVisible;
      }
      if (onSave(castingVal)) handleCloseModal();
    });
    submitHandler();
  };

  const handleCloseModal = () => {
    onClose();
    reset(initialFormData);
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Delete Notice?`,
      content: `This notice will be deleted. This action cannot be undone.`,
      type: 'error',
      okText: 'Delete',
      onOk: () => {
        onSave(itemDetail, true);
        handleCloseModal();
      },
    });
  };

  return (
    <Modal
      width={700}
      onCancel={handleCloseModal}
      visible={visible}
      title={headerTitle}
      noFooter
      customAttr={{ dataTestId: `${dataTestIdPrefix}-dialog` }}
      content={
        <form className="[&_.care-upload-label]:!text-base">
          <Row gutter={[16, 16]}>
            <Row>
              <InputField
                control={control}
                name="title"
                label="Notice Title"
                customAttr={{ dataTestId: `${dataTestIdPrefix}-title-input` }}
              />
              <Typography className="text-sm">35 characters max.</Typography>
            </Row>

            <TextAreaField
              control={control}
              name="description"
              label="Notice Description"
              customAttr={{ dataTestId: `${dataTestIdPrefix}-description-textarea` }}
            />

            <div className="flex">
              <Typography display="block" className="care-label mr-2">
                Action Button
              </Typography>
              <SwitchField
                name="isActionButton"
                control={control}
                value={true}
                customAttr={{ dataTestId: `${dataTestIdPrefix}-action-button-switch` }}
              />
            </div>

            {isActionButton && (
              <div className="flex flex-col gap-4 px-4">
                <Row>
                  <InputField
                    control={control}
                    name="buttonText"
                    label="Button Text"
                    customAttr={{ dataTestId: `${dataTestIdPrefix}-button-text-input` }}
                  />
                  <Typography className="care-desc">35 characters max.</Typography>
                </Row>

                <RoutingPath control={control} onSetValue={setValue} />

                <InputField
                  control={control}
                  name="eventName"
                  label="Event Name"
                  customAttr={{ dataTestId: `${dataTestIdPrefix}-event-name-input` }}
                />
              </div>
            )}

            <div className="flex">
              <Typography display="block" className="care-label mr-2">
                Pre-set Visibility Period
              </Typography>
              <SwitchField
                name="isPresetVisibilityPeriod"
                control={control}
                value={true}
                customAttr={{ dataTestId: `${dataTestIdPrefix}-preset-visibility-period-switch` }}
              />
            </div>

            {isPresetVisibilityPeriod && (
              <div className="flex flex-col gap-4 px-4">
                <DatePickerField
                  control={control}
                  name="startDate"
                  label="Active Start Date and Time"
                  placeholder={DATE.DATE_TIME}
                  suffixIcon={<Icon name="Calendar" color="#C0C8D0" />}
                  showTime
                  dateFormat={DATE.DATE_TIME}
                  ignoreDefaultDay
                  customAttr={{ dataTestId: `${dataTestIdPrefix}-start-date-datepicker` }}
                />

                <DatePickerField
                  control={control}
                  name="endDate"
                  label="Active End Date and Time"
                  placeholder={DATE.DATE_TIME}
                  suffixIcon={<Icon name="Calendar" color="#C0C8D0" />}
                  showTime
                  dateFormat={DATE.DATE_TIME}
                  ignoreDefaultDay
                  customAttr={{ dataTestId: `${dataTestIdPrefix}-end-date-datepicker` }}
                />
              </div>
            )}

            <div className="flex items-center justify-between">
              {isEdit && (
                <div className="flex cursor-pointer items-center text-error-40" onClick={handleDelete}>
                  <Icon className="text-error-40" name="TrashBin" type="outline" />
                  <Typography weight="semi">Delete</Typography>
                </div>
              )}
              <div className="ml-auto">
                <Button
                  className="mr-2"
                  type="outline"
                  title="Cancel"
                  onClick={handleCloseModal}
                  customAttr={{ dataTestId: `${dataTestIdPrefix}-cancel-button` }}
                />
                <Button
                  title="Save"
                  onClick={handleCreateOrEdit}
                  customAttr={{ dataTestId: `${dataTestIdPrefix}-save-button` }}
                />
              </div>
            </div>
          </Row>
        </form>
      }
    />
  );
};

export default ManageNoticesModal;
