/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Checkbox, Input } from '@care/web-ui';

import './FilteredDropdown.scss';
import Spacer from '../Spacer/Spacer';

export interface FilterConfirmProps {
  closeDropdown: boolean;
}

export interface ColumnFilterItem {
  text: string;
  value: string | number | boolean;
  description?: string;
  children?: ColumnFilterItem[];
}

export interface FilterDropdownProps {
  setSelectedKeys: (selectedKeys: string[]) => void;
  selectedKeys: string[];
  filters?: ColumnFilterItem[];
  confirm?: (param?: FilterConfirmProps) => void;
  clearFilters?: () => void;
  selectedAllText?: string;
  isHiddenLine?: boolean;
}

export const FilteredDropdown = (props: FilterDropdownProps) => {
  const { selectedKeys, setSelectedKeys, confirm, filters, selectedAllText, isHiddenLine = false } = props;
  const [selectedAll, setSelectedAll] = useState(false);
  const [searchList, setSearchList] = useState<ColumnFilterItem[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchList(filters);
  }, [filters]);

  useEffect(() => {
    const isSelectedAll = selectedKeys.length === filters.length;
    setSelectedAll(isSelectedAll);
  }, [selectedKeys]);

  const handleChange = (value: string, checked: boolean): void => {
    const optionsLength = filters.length;
    let newFilteredOptions: string[] = [];

    if (checked && selectedKeys) {
      newFilteredOptions = selectedKeys.concat([value]);
    } else {
      newFilteredOptions = newFilteredOptions.concat(selectedKeys.filter((item) => item !== value));
    }

    newFilteredOptions.length === optionsLength ? setSelectedAll(true) : setSelectedAll(false);

    setSelectedKeys(newFilteredOptions);

    confirm && confirm();
  };

  const handleSelectedAll = (checked: boolean): void => {
    setSelectedAll(checked);
    let newFilteredOptions: string[] = [];

    if (checked) {
      newFilteredOptions = filters.map((f) => f.value as string);
    } else {
      newFilteredOptions = [];
    }

    setSelectedKeys(newFilteredOptions);

    confirm && confirm();
  };

  const handleSearchChange = (value: string) => {
    const results = filters.filter((item) => item.text.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    setSearchValue(value);
    setSearchList(results);
  };

  return (
    <div className="filterDropdown">
      <Input value={searchValue} iconLeft={{ name: 'Search' }} placeholder="Search" onChange={handleSearchChange} />

      <Spacer size={14} />
      <Checkbox label={selectedAllText} value={selectedAll} onChange={handleSelectedAll} />

      {!isHiddenLine && (
        <>
          <Spacer size={14} />
          <div className="separateLine" />
        </>
      )}

      <div className="options">
        {searchList.map((item) => (
          <div className="option" key={item.value as string}>
            <Checkbox
              label={item.text as string}
              onChange={(checked) => handleChange(item.value as string, checked)}
              value={selectedKeys.length ? selectedKeys.includes(item.value as string) : false}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
