import React, { useMemo } from 'react';
import { Typography } from '@care/web-ui';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import PreviewTilesHomeScreen from 'src/module/configurations/preview-tiles-home-screen/PreviewTilesHomeScreen';
import PreviewTilesAllServices from 'src/module/configurations/preview-tiles-all-service/PreviewTilesAllServices';
import { getVisibleTiles } from 'src/util/tile.util';

interface IProps {
  tiles: HomeScreenItem[];
  isLargePreview?: boolean;
}

const PreviewTiles = ({ tiles, isLargePreview }: IProps) => {
  const visibleTiles: HomeScreenItem[] = useMemo(() => getVisibleTiles(tiles), [tiles]);

  if (!visibleTiles.length) return null;

  return (
    <div>
      {isLargePreview && (
        <Typography level={8} className="visibleText">
          {`${visibleTiles.length} tiles shown.`}
        </Typography>
      )}

      <div className="flex flex-col gap-6">
        <PreviewTilesHomeScreen visibleTiles={visibleTiles} isLargePreview={isLargePreview} />
      </div>

      {!isLargePreview && <PreviewTilesAllServices visibleTiles={visibleTiles} />}
    </div>
  );
};

export default PreviewTiles;
