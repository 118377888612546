import { BaseFilter, createPagingDataContext } from 'src/util/createPagingDataContext';
import { OrganizationModel } from 'src/model/OrganizationModel';
import OrganizationAPI from 'src/service/Organization.service';
import { DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import { OrganisationsSortBy } from 'src/enum/OrganisationsSortBy.enum';

export interface OrganizationFilter extends BaseFilter<OrganisationsSortBy> {
  orgIds: string[];
  displayName?: string;
  status?: string;
}

const fetcher = async (filter: OrganizationFilter, pageIndex: number, pageSize: number) => {
  return OrganizationAPI.getOrganizations(filter, pageIndex, pageSize);
};

const [PaginatedOrganizationListProvider, usePaginatedOrganizationListContext] = createPagingDataContext<
  OrganizationModel,
  OrganizationFilter
>(fetcher, {
  pageIndex: 1,
  pageSize: DEFAULT_PAGE_SIZE,
});

export { usePaginatedOrganizationListContext };

export default PaginatedOrganizationListProvider;
