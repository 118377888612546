import React, { useCallback, useEffect, useState } from 'react';
import { Input, Button, Utils } from '@care/web-ui';

import Spacer from 'src/component/Spacer/Spacer';
import { ProductFilter, useProductListContext } from 'src/context/ProductList.context';
import ProductsBulkApproveButton from 'src/module/products/ProductsBulkApproveButton';
import ProductsBulkRejectButton from 'src/module/products/ProductBulkRejectButton';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox';

const {
  CommonUtil: { debounce, omit },
} = Utils;

enum SearchBy {
  PRODUCT_KEYWORD = 'keyword',
  PRODUCT_IDS = 'productIds',
}

const searchSelections: ComboBoxItem[] = [
  {
    text: 'Product Name',
    value: SearchBy.PRODUCT_KEYWORD,
  },
  {
    text: 'Product Id',
    value: SearchBy.PRODUCT_IDS,
  },
];

const searchByKeys = searchSelections.map((s) => s.value);

export const FilteredToolbar = () => {
  const { loadData, filter, setFilter, pagination, setPagination } = useProductListContext();
  const [search, setSearch] = useState(filter.productIds?.[0] || '');
  const [searchBy, setSearchBy] = useState(filter.productIds?.[0] ? SearchBy.PRODUCT_IDS : SearchBy.PRODUCT_KEYWORD);

  useEffect(() => {
    if (filter.search && search !== filter.search) {
      loadData();
    }
  }, [filter]);

  const handleReset = (): void => {
    setSearch('');
    setSearchBy(SearchBy.PRODUCT_KEYWORD);
    setPagination({ ...pagination, pageIndex: 1 });
    setFilter({ ...({} as ProductFilter), keyword: '' });
  };

  const debounceSearch = useCallback(
    debounce((searchBy: SearchBy, value: string) => {
      setPagination({ ...pagination, pageIndex: 1 });
      setFilter({
        ...omit({ ...filter }, searchByKeys),
        [searchBy]: searchBy === SearchBy.PRODUCT_IDS ? (value ? [value] : []) : value,
      });
    }, 1000),
    [filter, pagination],
  );

  const handleSearch = (value: string): any => {
    setSearch(value);
    debounceSearch(searchBy, value);
  };

  const handleChangeSearchBy = (value: SearchBy) => {
    setSearchBy(value);
    setSearch('');
    debounceSearch(value, '');
  };

  return (
    <div>
      <Spacer size={32} />
      <div className="flex">
        <div className="flex w-2/4">
          <Input
            name="search"
            placeholder="Search"
            iconRight={{ name: 'Search' }}
            onChange={handleSearch}
            value={search}
            suffixComboBoxProps={{
              value: searchBy,
              items: searchSelections,
              className: 'min-w-[170px]',
              onChange: handleChangeSearchBy,
            }}
          />

          <Button className="mx-2" title="Reset" type="primary" onClick={handleReset} />
        </div>
        <div className="float-left ml-auto flex">
          <ProductsBulkApproveButton />
          <ProductsBulkRejectButton />
        </div>
      </div>
      <Spacer size={24} />
    </div>
  );
};
