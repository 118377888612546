import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from 'src/constant';
import Users from 'src/module/users/Users';

function UsersContainer() {
  return (
    <Switch>
      <Route path={Routes.Users} exact>
        <Users />
      </Route>
    </Switch>
  );
}

export default UsersContainer;
