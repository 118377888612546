import { ComboBox } from '@care/web-ui';
import { ComboBoxProps } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import React from 'react';
import Field from './Field';

interface Props extends ComboBoxProps {
  name: string;
  control: any;
  onBeforeChange?: (value: string, func: any) => void;
}

const ComboBoxField = ({ name, control, onChange: onValueChange, onBeforeChange, customAttr, ...rest }: Props) => (
  <Field
    name={name}
    control={control}
    customAttr={customAttr}
    render={({ onChange, value }) => (
      <ComboBox
        {...rest}
        value={value || undefined} // set undefined to show the placeholder
        customAttr={customAttr}
        onChange={(newValue, option) => {
          if (onBeforeChange) {
            onBeforeChange(newValue as string, onChange);
          } else {
            onChange(newValue);
          }

          if (onValueChange) {
            onValueChange(newValue, option);
          }
        }}
      />
    )}
  />
);

export default ComboBoxField;
