import React from 'react';
import { Button, Tag, Typography } from '@care/web-ui';
import { SortOptionTable } from 'src/enum/SortBy.enum';
import { UserSortBy, UserStatus } from 'src/enum/User.enum';
import { UserFilter, UserModel } from 'src/model/User.model';
import { userStatusTag } from 'src/constant/UserStatusTag.constant';

export const sortTable = {
  [UserSortBy.name_asc]: SortOptionTable.Ascend,
  [UserSortBy.name_desc]: SortOptionTable.Descend,
  [UserSortBy.email_asc]: SortOptionTable.Ascend,
  [UserSortBy.email_desc]: SortOptionTable.Descend,
};

const TextCell = (value: string) => (
  <Typography className="break-words" level={9}>
    {value || '-'}
  </Typography>
);

const VerifyStatus = (value: boolean) => {
  const status = value ? UserStatus.VERIFIED : UserStatus.UNVERIFIED;
  return (
    <div>
      <Tag text={userStatusTag[status].label} type={userStatusTag[status].type} />
    </div>
  );
};

export const getColumns = (filter: UserFilter, viewUserDetail) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      sorter: (sortOrder: string) => sortOrder,
      render: (value: string) => (
        <Typography className="break-words !font-bold text-primary-60" level={9}>
          {value || '-'}
        </Typography>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
      sorter: (sortOrder: string) => sortOrder,
      render: TextCell,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '15%',
      render: TextCell,
    },

    {
      title: 'Status',
      dataIndex: 'isProfileVerified',
      width: '10%',
      render: VerifyStatus,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      width: '15%',
      render: TextCell,
    },
    {
      title: 'ID Type & Number',
      width: '15%',
      render: (record: UserModel) => {
        return (
          <Typography className="break-words" level={9}>
            {record.idType || '-'}
            <br />
            {record.idNum || '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Passport',
      dataIndex: 'passport',
      width: '15%',
      render: TextCell,
    },
    {
      title: '',
      render: (record: UserModel) => {
        return (
          <div>
            <Button type="link" title="View" size="small" onClick={() => viewUserDetail(record)} />
          </div>
        );
      },
    },
  ];
};
