import { Builder } from 'builder-pattern';
import { Amount } from './Amount.model';
import {
  ApproveBulkRefundDTO,
  ApproveRefundItemDTO,
  TotalApproveRefundItemsDTO,
} from './dto/ApproveBulkRefunds.response';

export interface ApproveBulkRefundItemModel extends Omit<ApproveRefundItemDTO, 'paymentAmount' | 'cancellationFee'> {
  paymentAmount: Amount;
  cancellationFee: Amount;
}

export interface TotalApproveBulkRefundItemsModel
  extends Omit<TotalApproveRefundItemsDTO, 'paymentAmount' | 'cancellationFee'> {
  paymentAmount: Amount;
  cancellationFee: Amount;
  totalAmount: Amount;
}

export class ApproveBulkRefundsModel {
  items: ApproveBulkRefundItemModel[];
  totalItems: TotalApproveBulkRefundItemsModel[];

  static convertFromDTO(dto: ApproveBulkRefundDTO): ApproveBulkRefundsModel {
    const { items, totalItems } = dto;

    return Builder(ApproveBulkRefundsModel, {
      items: items.map((i) =>
        Builder<ApproveBulkRefundItemModel>({
          ...i,
          paymentAmount: Builder(Amount).value(i.paymentAmount).currency(i.currency).build(),
          cancellationFee: Builder(Amount).value(i.cancellationFee).currency(i.currency).build(),
        }).build(),
      ),
      totalItems: totalItems.map((t) => {
        const totalAmount = t.paymentAmount - t.cancellationFee;
        return Builder<TotalApproveBulkRefundItemsModel>({
          ...t,
          paymentAmount: Builder(Amount).value(t.paymentAmount).currency(t.currency).build(),
          cancellationFee: Builder(Amount).value(t.cancellationFee).currency(t.currency).build(),
          totalAmount: Builder(Amount).value(totalAmount).currency(t.currency).build(),
        }).build();
      }),
    }).build();
  }
}
