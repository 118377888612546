import { urlRegExp } from 'src/constant/RegExp';
import { AppScreenRouting } from 'src/enum/appScreenRouting.enum';
import { UIRoutingType } from 'src/enum/routingType.enum';
import * as yup from 'yup';

export const homescreenConfigSchema = (
  oldValue,
  existingNames = [],
  maximumVisibleItems,
  manageType,
  currentVisibleItems,
) => {
  const schema = {
    name: yup
      .string()
      .required(`Please input name of ${manageType.toLocaleLowerCase()}.`)
      .max(36, 'Maximum character length is 36')
      .test('unique', 'Duplicated name. Please input another name.', (value) => {
        return value === oldValue.name || !existingNames.includes(value);
      }),
    routingType: yup.string(),
    localFile: yup
      .mixed()
      .test('required', `Please provide ${manageType.toLocaleLowerCase()} image.`, (file) => !!file),
    routingValue: yup
      .string()
      .required('Please provide the link.')
      .when('routingType', {
        is: (val: string) => val === UIRoutingType.External,
        then: yup.string().required('Please provide the link.').matches(urlRegExp, 'Invalid link'),
      }),
    membershipId: yup.string().when('routingValue', {
      is: AppScreenRouting.WELLNESS,
      then: yup.string().required('Please select a membership plan'),
    }),
    eventName: yup.string(),
    visible: yup
      .boolean()
      .test(
        'hasExccededLimit',
        `You cannot show more than ${maximumVisibleItems} ${manageType.toLocaleLowerCase()}s in the home screen.`,
        (value) => {
          return !maximumVisibleItems || !value || oldValue.visible || currentVisibleItems < maximumVisibleItems;
        },
      ),
  };

  return yup.object().shape({ ...schema });
};
