import { QueryPagination, createStore, shallow } from '@care/web-ui/lib/store';
import { QueryStore } from '@care/web-ui/lib/store/store.queryPagination';
import { SimpleMembershipPlanItem } from 'src/model/dto/SimpleMembershipPlanList.response';
import { SimpleMembershipPlanListFilter } from 'src/model/Membership.model';

const { queryStateCreator } = QueryPagination;

export const $SIMPLE_MEMBERSHIP_PLAN_LIST_STORE = '$SIMPLE_MEMBERSHIP_PLAN_LIST_STORE';

export type SimpleMembershipPlanListStore = QueryStore<SimpleMembershipPlanItem, SimpleMembershipPlanListFilter>;

export const [useStore, selector] = createStore<SimpleMembershipPlanListStore>(queryStateCreator, {
  storeName: $SIMPLE_MEMBERSHIP_PLAN_LIST_STORE,
});

export const useSimpleMembershipPlanListStore = (): SimpleMembershipPlanListStore => useStore(selector, shallow);
