import { Typography } from '@care/web-ui';
import React from 'react';
import BlockInfo from 'src/component/BlockInfo/BlockInfo';
import Divider from 'src/component/Divider/Divider';
import { ConsumerModel } from 'src/model/Consumer.model';

interface Props {
  consumer?: Pick<ConsumerModel, 'fullName' | 'phoneNumber' | 'email'>;
}

const OrderCustomerDetails = ({ consumer }: Props) => {
  const { fullName, phoneNumber, email } = consumer || {};

  return (
    <div>
      <Typography level={5} weight="regular" fontCategory="serif">
        Customer Information
      </Typography>
      <Divider marginVertical={16} />
      <BlockInfo name="Name" value={fullName} marginBottom={16} />
      <BlockInfo name="Contact" value={phoneNumber || '-'} marginBottom={16} />
      <BlockInfo name="Email" value={email} marginBottom={16} />
    </div>
  );
};

export default OrderCustomerDetails;
