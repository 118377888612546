import { Builder } from 'builder-pattern';
import { BulkUploadResDTO } from './dto/BulkUpload.response';
import { Validity } from './dto/MemberList.response';

export class MembersUploaded {
  id: string;
  mainMemberId?: string;
  name: string;
  identityNumber: string;
  email: string;
  membershipDisplayName: string;
  validity: Validity;
  comments: string;
  valid: boolean;
}

export class BulkUploadModel {
  errorTotal: number;
  successTotal: number;
  reports: MembersUploaded[];

  static convertFromDTO(dto: BulkUploadResDTO, membershipDisplayName: string): BulkUploadModel {
    return Builder(BulkUploadModel, {
      ...dto,
      reports: dto.reports.map((report) =>
        Builder(MembersUploaded, {
          ...report.data,
          id: report.memberId,
          mainMemberId: report.mainMemberId,
          valid: report.valid,
          membershipDisplayName,
          validity: {
            startDate: report.data.startDate,
            endDate: report.data.endDate,
          },
          comments: report.valid ? '' : Object.keys(report.messages).map((item) => report.messages[item])[0],
        }).build(),
      ),
    }).build();
  }
}
