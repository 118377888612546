import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from 'src/constant';
import FeedbacksProvider from 'src/context/Feedbacks.context';
import Feedbacks from 'src/module/feedbacks/Feedbacks';

const FeedbacksContainer = () => {
  return (
    <FeedbacksProvider>
      <Switch>
        <Route path={Routes.Feedbacks} exact>
          <Feedbacks />
        </Route>
      </Switch>
    </FeedbacksProvider>
  );
};

export default FeedbacksContainer;
