import { useState, useEffect } from 'react';
import { IdentityProvider, UserContactStatus, UserEmailStatus, UserStatus } from 'src/enum/User.enum';
import { UserModel } from 'src/model/User.model';
import { TagInfo } from 'src/constant/Tag.constant';
import { Utils } from '@care/web-ui';

const { formatDateString } = Utils.DateUtil;

interface UserDisplayData {
  profileVerifiedStatus: UserStatus;
  emailActivatedStatus: UserEmailStatus;
  contactActivatedStatus: UserContactStatus;
  formattedDateOfBirth: string;
  lastTimeLoginFail: string;
  lastTimeUpdatePassword: string;
  socialTypes: TagInfo[];
}

export function getSocialTypes(user: UserModel): TagInfo[] {
  const identityProviderMap: Partial<Record<keyof UserModel, IdentityProvider>> = {
    facebookID: IdentityProvider.FACEBOOK,
    googleID: IdentityProvider.GOOGLE,
    appleID: IdentityProvider.APPLE,
    singpassID: IdentityProvider.SINGPASS,
  };

  const result: TagInfo[] = Object.entries(identityProviderMap)
    .filter(([key]) => user[key as keyof UserModel])
    .map(([, value]) => ({ type: 'Success', label: value }));

  return result;
}

export const useUserData = (user: UserModel): UserDisplayData => {
  const [userData, setUserData] = useState<UserDisplayData>({
    profileVerifiedStatus: UserStatus.UNVERIFIED,
    emailActivatedStatus: UserEmailStatus.deactivated,
    contactActivatedStatus: UserContactStatus.unverified,
    formattedDateOfBirth: '-',
    lastTimeLoginFail: '-',
    lastTimeUpdatePassword: '-',
    socialTypes: [],
  });

  useEffect(() => {
    const profileVerifiedStatus = user.isProfileVerified ? UserStatus.VERIFIED : UserStatus.UNVERIFIED;
    const emailActivatedStatus = user.emailActivated ? UserEmailStatus.activated : UserEmailStatus.deactivated;
    const contactActivatedStatus = user.isContactVerified ? UserContactStatus.verified : UserContactStatus.unverified;
    const formattedDateOfBirth = formatDateString(user.dob, 'DD/MM/YYYY');
    const lastTimeLoginFail = formatDateString(user.lastTimeLoginFail, 'DD/MM/YYYY HH:mm');
    const lastTimeUpdatePassword = formatDateString(user.lastTimeUpdatePassword, 'DD/MM/YYYY HH:mm');
    const socialTypes = getSocialTypes(user);

    setUserData({
      profileVerifiedStatus,
      emailActivatedStatus,
      contactActivatedStatus,
      formattedDateOfBirth,
      lastTimeLoginFail,
      lastTimeUpdatePassword,
      socialTypes,
    });
  }, [user]);

  return userData;
};
