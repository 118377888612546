import { Row, Typography } from '@care/web-ui';
import React from 'react';
import Spacer from 'src/component/Spacer/Spacer';

import './ApproveRefundModal.scss';

interface Props {
  name: string;
  amount: string;
  isHighlightAmount?: boolean;
  fontWeight?: 'bold' | 'regular' | 'semi';
  fontSize?: number;
  isBullet?: boolean;
}

export const TransactionRow = (props: Props) => {
  const { name, amount, fontWeight = 'bold', fontSize = 8, isBullet, isHighlightAmount = false } = props;
  const dividedSpace = isBullet ? 8 : 12;

  return (
    <Row gutter={8} spans={[16, 8]}>
      <Typography level={fontSize} weight={fontWeight} display="block">
        {isBullet && <span>&#8226; </span>}
        {name}
      </Typography>
      <Typography className="amountTotal" align="right" display="block" weight={isHighlightAmount ? 'bold' : 'regular'}>
        {amount}
      </Typography>
      <Spacer size={dividedSpace} />
    </Row>
  );
};
