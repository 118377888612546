import React from 'react';
import { Modal } from '@care/web-ui';
import { UserModel } from 'src/model/User.model';
import ModalContent from './ModalContent';

interface UserModalProps {
  user: UserModel;
  visible: boolean;
  onClose: () => void;
}

const UserDetailModal = (props: UserModalProps) => {
  const { visible, user, onClose } = props;

  return (
    <Modal
      width={700}
      onCancel={onClose}
      visible={visible}
      title="User details"
      noFooter
      content={<ModalContent user={user} />}
    />
  );
};

export default UserDetailModal;
