import { Icon, Typography } from '@care/web-ui';
import React, { useEffect, useState } from 'react';
import { ColumnFilterItem, FilteredDropdown } from '../FilteredDropdown/FilteredDropdown';

import './ExpandedSelections.scss';

export type NestedItem = ColumnFilterItem & { isChecked: boolean };

export interface Option {
  isSelectAll?: boolean;
  name: string;
  value: string;
  nestedList: NestedItem[];
}

export interface ExpandedSelectionProps {
  immutableOptions: Option[];
  selectedOption: Option;
  setSelectedOption: (value: Option) => void;
  getMutableOption: (value: Option) => void;
  activeNestedItems: string[];
}

export const ExpandedSelections = ({
  immutableOptions,
  setSelectedOption,
  selectedOption,
  getMutableOption,
  activeNestedItems,
}: ExpandedSelectionProps) => {
  const [selectedKeys, setSelectedKeys] = useState(['']);

  useEffect(() => {
    setSelectedKeys(activeNestedItems);
  }, [activeNestedItems]);

  const handleSelectedKeys = (values: string[]) => {
    setSelectedKeys(values);
    getMutableOption({
      ...selectedOption,
      isSelectAll: values.length === selectedOption.nestedList.length,
      nestedList: selectedOption.nestedList.filter((n) => values.includes(n.value as string)),
    });
  };

  return (
    <div className="expandedSelections">
      <div className="frameItem">
        {immutableOptions.map((o) => (
          <div
            onClick={() => setSelectedOption(o)}
            key={o.value}
            className={`optionItem ${selectedOption?.value === o.value && 'selected'}`}
          >
            <div className="productName">
              <Typography level={8}>{o.name}</Typography>
              <Typography level={8}>{`(${o.nestedList.length})`}</Typography>
            </div>
            <Icon type="outline" name="RightLarge" />
          </div>
        ))}
      </div>
      <div className="line" />
      <div className="frameItem">
        <FilteredDropdown
          isHiddenLine={true}
          selectedAllText="Select all"
          filters={selectedOption.nestedList}
          selectedKeys={selectedKeys}
          setSelectedKeys={handleSelectedKeys}
        />
      </div>
    </div>
  );
};
