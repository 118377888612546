import React, { useMemo } from 'react';
import { HomeScreenNotice } from 'src/model/HomeScreenNotice.model';
import { Empty, Icon, Skeleton } from '@care/web-ui';

export interface INoticeProps {
  notices: HomeScreenNotice[];
  loading?: boolean;
}

const Notices = ({ notices, loading }: INoticeProps) => {
  const visibleNotices = useMemo(() => notices.filter(({ isVisible }) => isVisible), [notices]);

  return visibleNotices.length > 0 ? (
    <div className="h-full w-full rounded-lg border border-solid border-warning-5 bg-warning-0 p-4">
      {loading ? (
        <Skeleton paragraph={{ rows: 1 }} />
      ) : (
        visibleNotices.map(({ id, title, description, isActionButton, buttonText }) => (
          <div
            key={id}
            className="mb-4 flex flex-col gap-1 border-x-0 border-b border-t-0 border-solid border-warning-5 pb-4 last:mb-0 last:border-none last:pb-0"
          >
            <div className="flex items-center gap-1">
              <div className="flex items-center justify-center">
                <Icon className="text-warning-40" name="ExclamationCircle" size={24} />
              </div>
              <div className="flex-1 text-sm font-bold text-warning-70">{title}</div>
              <div className="flex cursor-pointer items-center justify-center rounded-xl hover:bg-gray-100">
                <Icon className="text-warning-80" name="XSmall" size={24} />
              </div>
            </div>
            <div className="text-xs text-warning-70">{description}</div>
            {isActionButton && (
              <div className="ml-auto cursor-pointer rounded-4 px-2 text-base text-warning-80 hover:bg-gray-50">
                {buttonText}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  ) : (
    <Empty description="No Notice Visible" />
  );
};

export default Notices;
