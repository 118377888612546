import { Tag, Typography } from '@care/web-ui';
import React from 'react';
import BlockInfo from 'src/component/BlockInfo/BlockInfo';
import Divider from 'src/component/Divider/Divider';
import { orderStatusTypeTag } from 'src/constant/OrderStatusTag.constant';
import { RefundOrderModel } from 'src/model/RefundOrder.model';

interface Props {
  orderDetail: RefundOrderModel;
  reason?: string;
}

const OrderInformation = ({ orderDetail, reason }: Props) => {
  const { status, orderNumber } = orderDetail;

  const orderStatusTag = orderStatusTypeTag[status];

  return (
    <div>
      <Typography level={5} weight="regular" fontCategory="serif">
        Order Information
      </Typography>
      <Divider marginVertical={16} />
      <BlockInfo name="Order ID" value={orderNumber} marginBottom={16} />
      <BlockInfo
        name="Order Status"
        value={<Tag type={orderStatusTag?.type as any} text={orderStatusTag?.label} />}
        marginBottom={16}
      />
      <BlockInfo name="Refund Request Reason" value={reason || '-'} marginBottom={16} />
    </div>
  );
};

export default OrderInformation;
