import { Button, Input, Row, ComboBox, Utils } from '@care/web-ui';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox';
import { shallow } from '@care/web-ui/lib/store';
import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE_INDEX } from 'src/constant/Pagination.constant';
import { UserListStore, initialState, useUserStore } from 'src/zustand-store/user/user.store';
import { SearchType, UserStatus } from 'src/enum/User.enum';
import { withDebounceInputChange } from '@care/web-ui/lib/hoc';
import { IdentityDocumentType } from 'src/enum/IdentityDocumentType';

const {
  CommonUtil: { pick },
} = Utils;

const DebounceInput = withDebounceInputChange(Input);

const searchSelections: ComboBoxItem[] = [
  {
    text: 'Name',
    value: SearchType.NAME,
  },
  {
    text: 'Email',
    value: SearchType.EMAIL,
  },
  {
    text: `Contact`,
    value: SearchType.CONTACT,
  },
  {
    text: 'NRIC/FIN',
    value: SearchType.ID_NUM,
  },
  {
    text: 'Passport',
    value: SearchType.PASSPORT,
  },
];

const statusSelections: ComboBoxItem[] = [
  {
    text: 'All',
    value: UserStatus.ALL,
  },
  {
    text: 'Verified',
    value: UserStatus.VERIFIED,
  },
  {
    text: 'Unverified',
    value: UserStatus.UNVERIFIED,
  },
];

const IdTypeSelections: ComboBoxItem[] = [
  {
    text: 'NRIC',
    value: IdentityDocumentType.NRIC,
  },
  {
    text: 'FIN',
    value: IdentityDocumentType.FIN,
  },
  {
    text: 'Passport',
    value: IdentityDocumentType.Passport,
  },
  {
    text: 'HKID',
    value: IdentityDocumentType.HKID,
  },
  {
    text: 'VNID',
    value: IdentityDocumentType.VNID,
  },
  {
    text: 'Other',
    value: IdentityDocumentType.Other,
  },
];

const UserListToolbar = () => {
  const {
    filter,
    setFilter,
    isLoading: loading,
  } = useUserStore(
    (store: UserListStore) =>
      pick(store, ['filter', 'setFilter', 'isLoading', 'data', 'total'] as Array<keyof UserListStore>),
    shallow,
  );

  useEffect(() => {
    return () => {
      setFilter(initialState.filter);
    };
  }, []);

  const onSearchTypeChanged = (value: SearchType) => {
    setFilter({ ...filter, searchType: value });
  };

  const handleSearch = (value: string): void => {
    setFilter({ ...filter, searchValue: value, pageIndex: DEFAULT_PAGE_INDEX });
  };

  const onStatusChanged = (value: UserStatus) => {
    setFilter({ ...filter, status: value });
  };

  const onIdTypeChanged = (value: IdentityDocumentType) => {
    setFilter({ ...filter, idType: value });
  };

  const onReset = () => {
    setFilter(initialState.filter);
  };

  return (
    <Row gutter={16} spans={[5, 3, 3, 2]}>
      <div className="flex">
        <DebounceInput
          iconRight={{ name: 'Search' }}
          placeholder="Search"
          value={filter.searchValue}
          onChange={handleSearch}
          suffixComboBoxProps={{
            items: searchSelections,
            value: filter.searchType,
            disabled: loading,
            onChange: onSearchTypeChanged,
          }}
        />
      </div>

      <ComboBox
        placeholder="Status"
        items={statusSelections}
        value={filter.status}
        disabled={loading}
        onChange={(value) => onStatusChanged(value as UserStatus)}
      />

      <ComboBox
        placeholder="ID Type"
        items={IdTypeSelections}
        value={filter.idType}
        disabled={loading}
        onChange={(value) => onIdTypeChanged(value as IdentityDocumentType)}
      />

      <Button loading={loading} type="secondary" title="Reset" onClick={onReset} />
    </Row>
  );
};

export default UserListToolbar;
