import { Modal } from '@care/web-ui';
import React, { PropsWithChildren, ReactNode, useContext, useState } from 'react';
import { FullRefundDetailModel } from 'src/model/FullRefundDetail.model';
import { getRefundDetail, reopen } from 'src/service/RefundAPI';

class ContextState {
  fullRefundDetail!: FullRefundDetailModel;

  loadDetail!: (id: string) => Promise<any>;

  reopenRefund!: (id: string) => Promise<any>;

  loading = false;
}

export const RefundDetailContext = React.createContext(new ContextState());

const RefundDetailProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fullRefundDetail, setFullRefundDetail] = useState<FullRefundDetailModel>(new FullRefundDetailModel());

  const loadDetail = async (id: string): Promise<any> => {
    try {
      setLoading(true);
      const body = await getRefundDetail(id);
      setFullRefundDetail(body);
      return true;
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  const reopenRefund = async (id: string): Promise<any> => {
    try {
      setLoading(true);
      await reopen(id);
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <RefundDetailContext.Provider
      value={{
        fullRefundDetail,
        loadDetail,
        reopenRefund,
        loading,
      }}
    >
      {children}
    </RefundDetailContext.Provider>
  );
};

export const useRefundDetailContext = () => useContext(RefundDetailContext);

export default RefundDetailProvider;
