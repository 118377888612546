import React, { useContext, useState } from 'react';
import { careNotification, Modal, Typography, Utils } from '@care/web-ui';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import { ProductStatus } from 'src/enum/ProductStatus';
import { AppHomeScreenData } from 'src/model/AppHomeScreenData.model';
import { AppHomeScreenRequest, HomeScreenHeaderRequest } from 'src/model/dto/AppHomeScreen.request';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import { OrganizationModel } from 'src/model/OrganizationModel';
import { ProductModel } from 'src/model/Product.model';
import AppHomeScreenService from 'src/service/AppHomeScreen.service';
import ProductAPI from 'src/service/ProductAPI';
import { useOrganizationListContext } from './OrganizationList.context';
import { ProductFilter } from './ProductList.context';
import MediaService from 'src/service/Media.service';
import { buildPayloadEventName, getLayout, getVisibleTiles } from 'src/util/tile.util';
import SpecialtyService from 'src/service/Specialty.service';
import { SpecialtyModel } from 'src/model/Specialty.model';
import { HomeScreenNotice } from 'src/model/HomeScreenNotice.model';
import { Platform } from 'src/enum/PlatformSetting.enum';

const {
  CommonUtil: { pick },
} = Utils;

class ContextState {
  data: AppHomeScreenData;

  loading: boolean;

  loadData: (countryCode: string, platform?: Platform) => Promise<AppHomeScreenData>;

  setData: (data: AppHomeScreenData) => void;

  updateHeader: (countryCode: string, file: File) => Promise<boolean>;

  updateTitles: ({
    countryCode,
    items,
    platform,
  }: {
    countryCode: string;
    items: HomeScreenItem[];
    platform?: Platform;
  }) => Promise<boolean>;

  updateBanners: (countryCode: string, items: HomeScreenItem[]) => Promise<boolean>;

  updateNotices: (countryCode: string, items: HomeScreenNotice[]) => Promise<boolean>;

  productRoutings: ComboBoxItem[];

  loadProductRouting: (searchVal?: string) => Promise<ComboBoxItem[]>;

  specialtyRoutings: ComboBoxItem[];

  loadSpecialtyRoutings: () => Promise<ComboBoxItem[]>;
}

export const AppHomeScreenContext = React.createContext(new ContextState());

const AppHomeScreenProvider = ({ children }: React.PropsWithChildren) => {
  const { data: orgs } = useOrganizationListContext();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AppHomeScreenData | undefined>();
  const [productRoutings, setProductRoutings] = useState<ComboBoxItem[]>([]);
  const [specialtyRoutings, setSpecialtyRoutings] = useState<ComboBoxItem[]>([]);

  const orgHashmap = (orgs: OrganizationModel[]) => {
    const mappedOrg: { [key: string]: string } = {};
    orgs.forEach((o) => {
      mappedOrg[o.id] = o.name;
    });
    return mappedOrg;
  };

  const loadData = async (countryCode: string, platform?: Platform): Promise<AppHomeScreenData | null> => {
    try {
      setLoading(true);

      const data = await AppHomeScreenService.getAppHomeScreenData(countryCode, platform);
      setData(data);
      return data;
    } catch (error) {
      if (error.statusCode === 404) {
        careNotification({
          type: 'warning',
          message: (
            <Typography level={8}>
              Homescreen config for this location was not found! Please create a new one.
            </Typography>
          ),
          duration: 3,
        });
        const defaultData: AppHomeScreenData = {
          header: {
            mediaUrl: '',
            mediaId: '',
          },
          tiles: [],
          banners: [],
          notices: [],
        };
        setData(defaultData);
        return defaultData;
      }

      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const buildHomeScreenItemRequest = async (items: HomeScreenItem[]): Promise<HomeScreenItem[]> => {
    // filter the uploaded file. If having id, it is a clone from current media
    const uploadFileItems = items.filter((item) => item.localFile && !(item.localFile as any).id);
    const promises = uploadFileItems.map((item) => MediaService.uploadFile(item.localFile));
    const results = await Promise.all(promises);

    results.forEach((key, index) => {
      uploadFileItems[index].mediaId = key;
    });

    return items.map((item) => {
      item.eventName = buildPayloadEventName(item.eventName || item.name);
      return pick(item, [
        'name',
        'visible',
        'mediaId',
        'routingUrl',
        'routingKey',
        'routingType',
        'eventName',
        'reappearanceHours',
        'id',
      ]);
    });
  };

  const updateHomeScreenItems = async ({
    countryCode,
    items,
    type,
    platform,
  }: {
    countryCode: string;
    items: HomeScreenItem[];
    type: 'tiles' | 'banners';
    platform?: Platform;
  }): Promise<boolean> => {
    try {
      setLoading(true);

      const builtItems = await buildHomeScreenItemRequest(items);
      const layout = getLayout(getVisibleTiles(items));
      const body: AppHomeScreenRequest = { countryCode, layout, data: builtItems, platform };
      await AppHomeScreenService.saveHomeScreenItems(body, type);
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateHeader = async (countryCode: string, file: File): Promise<boolean> => {
    try {
      setLoading(true);
      const mediaId = await MediaService.uploadFile(file, false);
      const body: HomeScreenHeaderRequest = { countryCode, data: { mediaId } };
      await AppHomeScreenService.postHomeScreenHeader(body);
      return true;
    } catch (error) {
      console.error(error);
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateTitles = async ({
    countryCode,
    items,
    platform,
  }: {
    countryCode: string;
    items: HomeScreenItem[];
    platform?: Platform;
  }): Promise<boolean> => {
    return updateHomeScreenItems({ countryCode, items, type: 'tiles', platform });
  };

  const updateBanners = async (countryCode: string, items: HomeScreenItem[]): Promise<boolean> => {
    return updateHomeScreenItems({ countryCode, items, type: 'banners' });
  };

  const updateNotices = async (countryCode: string, items: HomeScreenNotice[]): Promise<boolean> => {
    try {
      setLoading(true);
      await AppHomeScreenService.saveHomeScreenNotices({ data: items, countryCode });
      return true;
    } catch (error) {
      console.error(error);
      Modal.confirm({
        title: <span className="text-error-20">Error</span>,
        content: error.message || error,
        type: 'error',
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const loadProductRouting = async (searchVal?: string): Promise<ComboBoxItem[]> => {
    try {
      setLoading(true);
      const productFilter: ProductFilter = {
        status: [ProductStatus.Active],
        keyword: searchVal,
      };
      const res = await ProductAPI.getProducts(productFilter, 1, 1000);
      const castingType = ProductModel.getProductRouting(orgHashmap(orgs), res.data);
      setProductRoutings(castingType);
      return castingType;
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  const buildSpecialtyRoutings = (specialties: SpecialtyModel[]): ComboBoxItem[] => {
    const basePath = `specialties`;

    const results: ComboBoxItem[] = specialties.map((sp) => ({
      text: `Specialist Consultation/${sp.name}`,
      value: `${basePath}/${sp.id}`,
    }));

    results.unshift({
      text: 'Consult a Specialist',
      value: basePath,
    });

    return results;
  };

  const loadSpecialtyRoutings = async (): Promise<ComboBoxItem[]> => {
    try {
      setLoading(true);
      const res = await SpecialtyService.getAllSpecialties();
      const routings = buildSpecialtyRoutings(res.data);
      setSpecialtyRoutings(routings);
      return routings;
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppHomeScreenContext.Provider
      value={{
        data,
        loading,
        setData,
        loadData,
        updateHeader,
        updateTitles,
        updateBanners,
        updateNotices,
        productRoutings,
        loadProductRouting,
        specialtyRoutings,
        loadSpecialtyRoutings,
      }}
    >
      {children}
    </AppHomeScreenContext.Provider>
  );
};

export const useAppHomeScreenContext = () => useContext(AppHomeScreenContext);

export default AppHomeScreenProvider;
