import React from 'react';
import { Typography } from '@care/web-ui';

const GlobalLoading = () => (
  <div className="care-global-loading">
    <img src="/care-white.png" alt="care-logo" />
    <Typography level={9} className="text">
      Please wait...
    </Typography>
  </div>
);

export default GlobalLoading;
