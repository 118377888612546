import React, { useEffect } from 'react';
import { Button, Skeleton, Typography2 } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import { useHistory } from 'react-router-dom';
import SectionTitle from 'src/component/SectionTitle/SectionTitle';
import { Routes } from 'src/constant';
import PreviewWebHomeScreenTiles from 'src/module/configurations/preview-web-home-screen-tiles/PreviewWebHomeScreenTiles';
import { handleError } from 'src/util/error.util';
import { useAppHomeScreenContext } from 'src/context/AppHomeScreen.context';
import { Platform } from 'src/enum/PlatformSetting.enum';

const {
  CountryUtil: { getAvailableCountries },
  StringUtil: { getPluralTextWithCount },
} = Utils;

const WebHomeScreen = () => {
  const { push } = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const availableCountries = getAvailableCountries();
  const country = urlParams.get('country') || availableCountries[0].value;

  const { loadData, data, loading } = useAppHomeScreenContext();

  useEffect(() => {
    (async () => {
      try {
        await loadData(country, Platform.WEB);
      } catch (error) {
        handleError(error);
      }
    })();
  }, []);

  const totalTiles = data?.tiles?.length || 0;

  if (loading) return <Skeleton />;

  return (
    <div className="space-y-2">
      <SectionTitle
        title="Tiles Layout"
        marginVertical={4}
        rightComponent={
          <Button
            title="Edit"
            icon="Edit"
            type="link"
            className="!min-w-[unset] !p-0"
            onClick={() => push(`${Routes.WebScreenTiles}?country=${country}`)}
          />
        }
      />
      <Typography2
        variant="paragraph"
        size="large"
      >{`${getPluralTextWithCount('tile', totalTiles)} shown.`}</Typography2>
      <PreviewWebHomeScreenTiles
        tiles={data?.tiles ?? []}
        className="flex justify-center px-[74px] [&>div]:max-w-[1200px]"
        isLargePreview
      />
    </div>
  );
};

export default WebHomeScreen;
