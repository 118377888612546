import Utils from '@care/web-ui/lib/utils';
import { configsStore } from '@care/web-ui/lib/hooks';

const { useConfigsStore } = configsStore;
const {
  CurrencyUtil: { getFullInfoCurrencyFormatted },
} = Utils;

export const useFullInfoCurrencyFormatted = () => {
  const {
    data: { currencies },
  } = useConfigsStore();
  const handleFormatFullInfoCurrency = (number: number | string, currency: string) => {
    const dataFormatted = getFullInfoCurrencyFormatted({
      value: number,
      currencyConfig: currencies.find((c) => c.code === currency),
    });

    return dataFormatted;
  };

  return {
    handleFormatFullInfoCurrency,
  };
};
