import React from 'react';
import { CatalogStore, useCatalogStore } from 'src/zustand-store/catalog/Catalog.store';
import { formatEcProductRouting } from 'src/util/catalog.util';
import { shallow } from '@care/web-ui/lib/store';
import { Utils } from '@care/web-ui';

const {
  CommonUtil: { pick },
} = Utils;

export const useFormatCatalogRoutings = () => {
  const { data } = useCatalogStore((store: CatalogStore) => pick(store, 'data'), shallow);

  const catalogRoutings = React.useMemo(() => data.map((product) => formatEcProductRouting(product)), [data]);

  return { catalogRoutings };
};
