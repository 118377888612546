import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Button, Typography, Modal, Utils, Constants } from '@care/web-ui';
import { Builder } from 'builder-pattern';

import Divider from 'src/component/Divider/Divider';
import InputField from 'src/component/Field/InputField';
import DatePickerField from 'src/component/Field/DatePickerField';
import ComboBoxField from 'src/component/Field/ComboBoxField';
import RadioButtonField from 'src/component/Field/RadioButtonField';
import SwitchField from 'src/component/Field/SwitchField';
import Spacer from 'src/component/Spacer/Spacer';
import {
  ApplyProductItem,
  CreatePromoCodeModelDTO,
  Organisation,
  PromoCodeFormDetailModel,
} from 'src/model/dto/CreatePromoCode.request';
import { PromoCodeType } from 'src/enum/PromoCodeType';
import { DiscountType } from 'src/enum/DiscountType';
import { PromoCodeActionContext } from 'src/context/PromoCodeAction.context';

import { Routes } from 'src/constant';
import {
  codeTypeOptions,
  discountTypeOptions,
  totalPerPatientOptions,
  totalQuantityOptions,
} from 'src/constant/OptionsConstant';

import './CreateOrEditPromoCode.scss';
import { EditPromoCodeModelDTO } from 'src/model/dto/EditPromoCode.request';
import { TotalQuantity } from 'src/enum/TotalQuantity';
import SectionTitle from 'src/component/SectionTitle/SectionTitle';
import { ProductsInfo } from './ProductsInfo';
import { schema } from './validateSchema';
import { useOrganizationListContext } from 'src/context/OrganizationList.context';
import { OrganizationModel } from 'src/model/OrganizationModel';

const {
  DateUtil: { formatDateString, getTimestampByDate },
  CommonUtil: { cloneDeep, isEmpty, omit, isUndefined },
} = Utils;
const { DATE } = Constants.DateConstant;

export interface Location {
  id: string;
  locationName: string;
}

export interface ApplicableProduct {
  id: string;
  productName: string;
  locations: Location[];
}

const CreateOrEditPromoCode = () => {
  const params = useParams();
  const { push } = useHistory();
  const { addPromoCode, loadDetail, promoCode, editPromoCode } = useContext(PromoCodeActionContext);
  const { data: orgsFromContext } = useOrganizationListContext();

  const [organisations, setOrganisations] = useState<Organisation[]>([{ organizationId: '', applyProducts: [] }]);
  const [isShowAddBtn, setShowAddBtn] = useState(false);
  const [currency, setCurrency] = useState<string>('SGD');

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: Builder(PromoCodeFormDetailModel).active(true).discountType(DiscountType.FixedAmount).build(),
    resolver: yupResolver(schema),
  });

  const id = (params as any).id as string;
  const isEdit = id !== '0';

  const [quantityOption, quantityPerUserOption, codeTypes, startDate, endDate, discountType] = watch(
    ['quantityOption', 'quantityPerUserOption', 'codeType', 'startDate', 'endDate', 'discountType'],
    {
      codeType: PromoCodeType.Promo,
    },
  );

  useEffect(() => {
    const startDateUnix = getTimestampByDate(startDate);
    const endDateUnix = getTimestampByDate(endDate);

    endDateUnix < startDateUnix
      ? setError('endDate', { message: 'Must be greater than start date.' })
      : clearErrors('endDate');
  }, [startDate, endDate]);

  useEffect(() => {
    if (isEdit) {
      loadDetail(id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (!isUndefined(promoCode) && isEdit) {
      const castingDefaultValues = Builder(PromoCodeFormDetailModel, promoCode)
        .quantityOption(promoCode.totalQuantity ? TotalQuantity.Limited : TotalQuantity.NoLimit)
        .totalQuantityAmount(promoCode.totalQuantity || undefined)
        .quantityPerUserOption(promoCode.quantityPerUser ? TotalQuantity.Limited : TotalQuantity.NoLimit)
        .quantityPerUserAmount(promoCode.quantityPerUser || undefined)
        .amount(promoCode.amount)
        .build();
      reset(castingDefaultValues);
      setOrganisations(promoCode.organizations);
    }
  }, [promoCode]);

  const modifyOrgs = (orgs: Organisation[]) => {
    return orgs
      .filter((o) => o.applyProducts.length && o.organizationId)
      .map((o) => ({
        ...o,
        applyProducts: o.applyProducts.map((p) =>
          Builder(ApplyProductItem, p.selectAllLocations ? omit(p, ['locationIds']) : p).build(),
        ),
      }));
  };

  const validateMultipleOrgs = (orgs: Organisation[]) => {
    const organizationIds = orgs.map((o) => o.organizationId);
    const formatedOrg = orgsFromContext.filter((d) => organizationIds.includes(d.id));

    const isSame = checkSameCountry(formatedOrg);

    isSame && setCurrency(formatedOrg[0].currency);

    orgs.some((o) => o.applyProducts.length && o.organizationId) && isSame
      ? clearErrors('organizations')
      : setError('organizations', { type: 'required' });
  };

  const onCreate = async () => {
    const submitHandler = handleSubmit(async (values: any) => {
      validateMultipleOrgs(organisations);

      const castingValues = Builder(
        CreatePromoCodeModelDTO,
        omit(values, ['quantityPerUserAmount', 'totalQuantityAmount']),
      )
        .active(values.active)
        .amount(values.amount && values.codeType === PromoCodeType.Promo ? Number.parseFloat(values.amount) : undefined)
        .totalQuantity(values.quantityOption === TotalQuantity.Limited ? values.totalQuantityAmount : null)
        .quantityPerUser(values.quantityPerUserOption === TotalQuantity.Limited ? values.quantityPerUserAmount : null)
        .startDate(formatDateString(values.startDate, DATE.ISO_DATE))
        .endDate(formatDateString(values.endDate, DATE.ISO_DATE))
        .organizations(modifyOrgs(organisations))
        .build();

      if (!errors.organizations) {
        // console.log({ castingValues, modifyOrgs: modifyOrgs(organisations) });
        const res = await addPromoCode(castingValues);
        if (res) {
          push(Routes.PromoCodes);
        }
      }
    });

    submitHandler();
  };

  const onSave = async () => {
    const submitHandler = handleSubmit(async (values: any) => {
      validateMultipleOrgs(organisations);

      const castingValues = Builder(EditPromoCodeModelDTO)
        .title(values.title)
        .description(values.description)
        .active(values.active)
        .totalQuantity(values.quantityOption === TotalQuantity.Limited ? values.totalQuantityAmount : null)
        .quantityPerUser(values.quantityPerUserOption === TotalQuantity.Limited ? values.quantityPerUserAmount : null)
        .endDate(formatDateString(values.endDate, DATE.ISO_DATE))
        .organizations(modifyOrgs(organisations))
        .build();

      if (!errors.organizations) {
        // console.log({ castingValues, modifyOrgs: modifyOrgs(organisations) });
        const res = await editPromoCode(id, castingValues);
        if (res) {
          push(Routes.PromoCodes);
        }
      }
    });

    submitHandler();
  };

  const onCancel = () => {
    push(Routes.PromoCodes);
  };

  const handleLocationsChange = (value: Organisation, index) => {
    const cloneDeepArr = cloneDeep(organisations);
    cloneDeepArr[index] = value;
    const filteredOrgs = cloneDeepArr.length === 1 ? cloneDeepArr : cloneDeepArr.filter((c) => c.organizationId);
    validateMultipleOrgs(filteredOrgs);
    setOrganisations(filteredOrgs);
    setShowAddBtn(filteredOrgs.every((c) => c.applyProducts.length));
  };

  const checkSameCountry = (orgs: OrganizationModel[]) => {
    if (orgs.length === 1) return true;

    const uniqCountries = Array.from(new Set(orgs.map((x) => x.countryCode)));

    return uniqCountries.length === 1;
  };

  const handleAdd = () => {
    const newArr = organisations.concat({ organizationId: '', applyProducts: [] });
    setOrganisations(newArr);
    setShowAddBtn(false);
  };

  return (
    <div className="promocode-container">
      <Typography level={3} fontCategory="serif">
        Code Details
      </Typography>

      <div className="form">
        <form>
          <SectionTitle
            title="Code Information"
            rightComponent={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography className="activeSwitchLabel">Active</Typography>
                <SwitchField
                  onBeforeChange={(value, onChange: any) => {
                    if (!value) {
                      Modal.confirm({
                        icon: 'AlertTriangle',
                        iconColor: '#CB8608',
                        title: 'Are you sure you want to disable this promo?',
                        content:
                          'Customers cannot use the promo codes immediately after disabling the promo. Toggle the Active switch to reactivate it.',
                        type: 'success',
                        okText: 'Disabled promo',
                        onOk: () => {
                          onChange(value);
                        },
                      });
                    } else {
                      onChange(value);
                    }
                  }}
                  value
                  name="active"
                  control={control}
                />
              </div>
            }
          />

          <Row gutter={[8, 18]} spans={[24, 24, 24, 24, 24, 12, 12]}>
            <RadioButtonField
              name="codeType"
              control={control}
              label="Code Type"
              options={codeTypeOptions}
              direction="vertical"
              disabled={isEdit}
            />
            <div>
              <Typography level={8} className="colorPrimary">
                Code/s
              </Typography>

              <Typography level={9} className="descriptionText">
                Please enter A - Z, 0 - 9, 10 character maximum
              </Typography>
            </div>
            <InputField name="code" control={control} disabled={isEdit} />

            <div>
              <InputField name="title" label="Title" control={control} />
              <Typography level={9} className="descriptionText">
                Customers will see this in the app
              </Typography>
            </div>
            <div>
              <InputField name="description" label="Description" control={control} />
              <Typography level={9} className="descriptionText">
                Customers will see this in the app
              </Typography>
            </div>

            <DatePickerField name="startDate" label="Start Date" control={control} disabled={isEdit} />

            <DatePickerField name="endDate" label="End Date" control={control} />
          </Row>

          <SectionTitle title="Products and Required Information" />

          {organisations?.map((field, index) => (
            <ProductsInfo
              key={field.organizationId || index}
              organisation={field}
              orgIds={organisations.map((o) => o.organizationId)}
              handleLocationsChange={(value) => handleLocationsChange(value, index)}
            />
          ))}

          {isShowAddBtn && (
            <Button
              icon="PlusCircle"
              style={{ width: '100%' }}
              type="outline"
              title="Add Other Organization"
              onClick={handleAdd}
            />
          )}

          {errors.organizations && (
            <div className="field-error">
              Please choose at least one organization and all organizations must be the same target country.
            </div>
          )}

          <Spacer size={24} />

          {codeTypes === PromoCodeType.Promo && (
            <>
              <SectionTitle title="Settings" />

              <Row gutter={[8, 18]} spans={[8, 14, 2]} align="bottom">
                <ComboBoxField
                  name="discountType"
                  label="Discount Type / Amount"
                  control={control}
                  items={discountTypeOptions}
                  disabled={isEdit}
                />

                <InputField
                  disabled={isEdit}
                  type="number"
                  name="amount"
                  control={control}
                  onWheel={(e) => e.currentTarget.blur()}
                />

                <Typography level={9} display="inline" className="discountCurrencyLabel">
                  {discountType === DiscountType.Percent ? '%' : currency}
                </Typography>
              </Row>
            </>
          )}

          <SectionTitle title="Usage Limit" />

          <Row gutter={[8, 18]}>
            <RadioButtonField
              name="quantityOption"
              control={control}
              label="Total Quantity"
              options={totalQuantityOptions}
              direction="vertical"
            />
            {quantityOption === TotalQuantity.Limited && (
              <Row spans={[12]}>
                <InputField
                  type="number"
                  min="1"
                  control={control}
                  name="totalQuantityAmount"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(value) => isEmpty(value) && setValue('totalQuantityAmount', null)}
                />
              </Row>
            )}

            <RadioButtonField
              name="quantityPerUserOption"
              control={control}
              label="Total Redemptions Per Patient"
              options={totalPerPatientOptions}
              direction="vertical"
            />
            {quantityPerUserOption === TotalQuantity.Limited && (
              <Row spans={[12]}>
                <InputField
                  type="number"
                  min="1"
                  control={control}
                  name="quantityPerUserAmount"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Row>
            )}
          </Row>

          <Divider marginVertical={40} />

          <div className="footer">
            <Button type="outline" title="Cancel" style={{ marginRight: '8px' }} onClick={onCancel} />
            {isEdit ? (
              <Button type="primary" title="Save" onClick={onSave} />
            ) : (
              <Button type="primary" title="Create" onClick={onCreate} />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrEditPromoCode;
