import { Sorter } from 'src/util/createPagingDataContext';

export class DeepLinkItem {
  id: string;
  screenName: string;
  screenImageUrl: string;
  deepLink: string;
  qrCode?: string;
}

export interface DeepLinkFilter {
  search?: string;
  sortBy?: Sorter;
  pageIndex: number;
  pageSize: number;
}
