import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Row, Typography, careNotification, Button, Label, ComboBox, Utils } from '@care/web-ui';
import { appHomeScreenPath } from 'src/constant/configurations.constant';
import PageHeader, { ActionButtonsProps } from 'src/component/PageHeader/PageHeader';
import { useAppHomeScreenContext } from 'src/context/AppHomeScreen.context';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import { CreateHomeScreenModal } from '../home-screen-item-modal/CreateHomeScreenModal';
import { ManageType } from 'src/enum/manageType.enum';

import Sortable from 'src/component/sortable/Sortable';
import ItemInfo from '../item-info/ItemInfo';
import PreviewBanners from '../preview-banners/PreviewBanners';
import SectionTitle from 'src/component/SectionTitle/SectionTitle';

import './ManageBanners.scss';

const {
  CommonUtil: { isEmpty },
} = Utils;

const breadcumbItems = [
  {
    name: 'App Home Screen',
    href: appHomeScreenPath,
  },
  {
    name: 'Banners',
    href: '#',
  },
];

const MAXIMUM_VISIBLE_BANNER = 3;
// TODO: Hard code 48 hours for now
const DEFAULT_REAPPEARANCE_HOURS = '48';

const reappearanceHourItems = [
  {
    text: '48 hours',
    value: DEFAULT_REAPPEARANCE_HOURS,
  },
];

const ManageBanners = () => {
  const { loadData, loading, updateBanners } = useAppHomeScreenContext();
  const [banners, setBanners] = useState<HomeScreenItem[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedBanner, setEditedBanner] = useState<HomeScreenItem>(null);
  const [isLastBanner, setIsLastBanner] = useState(false);
  const [reappearanceHours, setReappearanceHours] = useState(DEFAULT_REAPPEARANCE_HOURS);
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('country');
  const { push } = useHistory();
  const backToHomeScreenPage = () => push(`${appHomeScreenPath}${window.location.search.replace('?', '&')}`);

  useEffect(() => {
    (async () => {
      const data = await loadData(country);
      if (data.banners) {
        setBanners(data.banners || []);
        setReappearanceHours(data.banners[0]?.reappearanceHours.toString() ?? DEFAULT_REAPPEARANCE_HOURS);
      }
    })();
  }, []);

  const handleEditBanner = async (banner: HomeScreenItem, index: number) => {
    setEditedBanner({ ...banner, index });
    setIsEditModalOpen(true);
    setIsLastBanner(index === 0 && banners.length === 1);
  };

  const visibleBanners = useMemo(() => banners.filter((banner) => banner.visible), [banners]);

  const handleSwitchVisibility = (value: boolean, index: number) => {
    const newBanners = [...banners];
    newBanners[index].visible = value;
    setBanners([...newBanners]);
  };

  const handleAddBanner = () => {
    setEditedBanner(null);
    setIsEditModalOpen(true);
    setIsLastBanner(!banners.length);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const onSaveBanner = (banner: HomeScreenItem, isDel?: boolean) => {
    const { index } = banner;
    let newBanners = [...banners];
    if (index !== undefined && !isDel) {
      newBanners[index] = banner; //CASE: edit banner.
    } else if (isDel) {
      newBanners.splice(index, 1); //CASE: delete banner.
    } else {
      newBanners = newBanners.concat({ ...banner, index: newBanners.length }); //CASE: add new banner.
    }

    const newVisibleBanners = newBanners.filter((banner) => banner.visible);
    if (banner.visible && newVisibleBanners.length > MAXIMUM_VISIBLE_BANNER) {
      careNotification({
        type: 'error',
        message: (
          <Typography level={8}>
            {`You cannot show more than ${MAXIMUM_VISIBLE_BANNER} banner in the home screen.`}
          </Typography>
        ),
        duration: 4,
      });
      return false;
    }

    setBanners(newBanners);
    return true;
  };

  const handleSaveBannerLayout = async () => {
    const newBanners = banners.map((banner) => ({
      ...banner,
      reappearanceHours: Number(reappearanceHours),
    }));
    const result = await updateBanners(country, newBanners);
    if (result) {
      backToHomeScreenPage();
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={9} weight="semi">
            Banners layout successfully updated.
          </Typography>
        ),
        duration: 2,
      });
    }
  };

  const actionButtons: ActionButtonsProps[] = [
    {
      title: 'Cancel',
      onSubmit: backToHomeScreenPage,
      type: 'outline',
      size: 'small',
    },
    {
      title: 'Save',
      onSubmit: handleSaveBannerLayout,
      size: 'small',
      loading,
      disabled: !banners.length,
    },
  ];
  return (
    <div className="manageBannersContainers">
      <PageHeader breadcumbItems={breadcumbItems} title="Banners" actionButtons={actionButtons} />
      <Row spans={[8, 12, 4]} gutter={60}>
        {!isEmpty(banners) && <PreviewBanners banners={banners} />}
        <div>
          <SectionTitle
            title="Manage Banners"
            marginVertical={4}
            rightComponent={
              <Button
                title="Add Banners"
                icon="Plus"
                type="link"
                style={{ padding: 0, minWidth: 'unset' }}
                onClick={handleAddBanner}
              />
            }
          />
          <div className="my-6">
            <Label label="Frequency of banner reappearance once dismissed" />
            <ComboBox
              value={reappearanceHours}
              items={reappearanceHourItems}
              placeholder="Reappearance Time"
              disabled
            />
          </div>
          <div className="mb-4 text-base text-neutral-70">Maximum of 3 images shown at once.</div>
          {!isEmpty(banners) && (
            <Sortable items={banners} onSortEnd={setBanners}>
              {banners.map((item, index) => (
                <ItemInfo
                  key={item.name}
                  item={item}
                  imageType="rectangle"
                  onEdit={() => handleEditBanner(item, index)}
                  onSwitch={(value) => handleSwitchVisibility(value, index)}
                  disabled={!item.visible && visibleBanners.length >= MAXIMUM_VISIBLE_BANNER}
                />
              ))}
            </Sortable>
          )}
        </div>
      </Row>

      {isEditModalOpen && (
        <CreateHomeScreenModal
          visible={true}
          onClose={handleCloseModal}
          onSave={onSaveBanner}
          manageType={ManageType.Banner}
          itemDetail={editedBanner}
          isLastItem={isLastBanner}
          existingItemNames={banners.map((n) => n.name)}
          numberOfCurrentVisibleItems={visibleBanners.length}
          maximumVisibleItems={6}
        />
      )}
    </div>
  );
};
export default ManageBanners;
