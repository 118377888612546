import { Typography } from '@care/web-ui';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Divider from 'src/component/Divider/Divider';
import ComboBoxField from 'src/component/Field/ComboBoxField';
import InputField from 'src/component/Field/InputField';
import PageHeader, { ActionButtonsProps, BreacrumbItemsProps } from 'src/component/PageHeader/PageHeader';
import Spacer from 'src/component/Spacer/Spacer';
import { Routes } from 'src/constant';
import { AccountContext } from 'src/context/Account.context';
import { OrganizationContext } from 'src/context/Organization.context';
import { Role } from 'src/enum/Role.enum';
import * as yup from 'yup';

import './EditAccount.scss';

const roleOptions: ComboBoxItem[] = [
  {
    text: 'Admin',
    value: Role.admin,
  },
  {
    text: 'Doctor',
    value: Role.doctor,
  },
  {
    text: 'Super Admin',
    value: Role.superAdmin,
  },
];

const validationSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
});

export const EditAccount = () => {
  const history = useHistory();
  const query = useParams();
  const location = useLocation();
  const { loadAccountDetail, updateAcc } = useContext(AccountContext);
  const { organisation, loadOrganisationDetail } = useContext(OrganizationContext);

  const { control, reset, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { orgId, accId } = query as any;

  const breadcums: BreacrumbItemsProps[] = [
    {
      name: `${organisation.displayName}`,
      href: Routes.OrganisationDetail.replace(':orgId', orgId),
    },
    {
      name: 'Edit Account',
      href: location.pathname,
    },
  ];

  const backToOrgDetail = () => history.push(Routes.OrganisationDetail.replace(':orgId', orgId));

  const actionBtns: ActionButtonsProps[] = [
    {
      title: 'Cancel',
      type: 'outline',
      onSubmit: () => backToOrgDetail(),
    },
    {
      title: 'Update',
      onSubmit: () => updateProvideProfile(),
    },
  ];

  const updateProvideProfile = () => {
    const submitHandler = handleSubmit(async (values) => {
      const { name, role } = values;
      await updateAcc(accId, role, name);
      backToOrgDetail();
    });
    submitHandler();
  };

  const getAccountDetail = async () => {
    const res = await loadAccountDetail(accId);
    const { name, role, email } = res;
    reset({
      name,
      role,
      email,
    });
  };

  useEffect(() => {
    getAccountDetail();
    loadOrganisationDetail(orgId);
  }, [accId, orgId]);

  return (
    <div style={{ padding: '52px 76px' }}>
      <PageHeader title="Edit Account" breadcumbItems={breadcums} actionButtons={actionBtns} />
      <div className="accBody">
        <Typography level={5} fontCategory="serif">
          Account Settings
        </Typography>
        <Divider marginVertical={8} />
        <form>
          <InputField name="name" control={control} label="Name" />
          <Spacer size={16} />
          <InputField name="email" control={control} disabled label="Email address" />
          <Spacer size={16} />
          <ComboBoxField name="role" control={control} label="Please select a role" items={roleOptions} />
        </form>
      </div>
    </div>
  );
};
