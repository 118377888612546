import React from 'react';
import { Modal } from '@care/web-ui';
import { OrganizationModel } from 'src/model/OrganizationModel';
import { OrganizationStatus } from 'src/enum/OrganizationStatus';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onDone: () => any;
  organisation: OrganizationModel;
}

const UpdateStatusConfirmationModal = ({ visible, onCancel, onDone, organisation }: Props) => {
  return (
    <Modal
      content={`You want to mark ${organisation?.displayName} as ${
        organisation?.status === OrganizationStatus.verified
          ? OrganizationStatus.unverified
          : OrganizationStatus.verified
      }`}
      visible={visible}
      title="Are you sure ?"
      okText="Confirm"
      okType="primary"
      onCancel={onCancel}
      onOk={onDone}
      titleIcon="AlertTriangle"
      titleIconColor="#CB8608"
      okButtonProps={{
        style: {
          minWidth: '105px',
          minHeight: '36px',
          borderRadius: '8px',
          marginRight: 8,
          padding: 0,
        },
      }}
      cancelButtonProps={{
        style: {
          minWidth: '96px',
          minHeight: '36px',
          borderRadius: '8px',
          padding: 0,
        },
      }}
    />
  );
};

export default UpdateStatusConfirmationModal;
