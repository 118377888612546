import axios from 'axios';
import { Builder } from 'builder-pattern';
import { ContentType, UploadBody } from 'src/model/dto/UploadBody.request';
import { GeneratedUploadUrl } from 'src/model/dto/GeneratedUploadUrl.response';
import { Media } from 'src/model/Media.model';
import { APIBase } from 'src/util/Request';
import config from 'src/Config';

const URL = `${config.PARTNER_API_URL}/media`;

class MediaService {
  async getMediaObject(id: string): Promise<Media> {
    const body = await APIBase.get(`${URL}/${id}`);
    return Builder(Media, body.data).build();
  }

  async generateDownloadUrl(id: string, isPrivate?: boolean): Promise<{ url: string }> {
    const query = isPrivate ? '?isPrivate=true' : '';
    const body = await APIBase.get<{ url: string }>(`${URL}/${id}/generate-download-url${query}`);

    return body.data;
  }

  async generateUploadUrl(file: UploadBody): Promise<GeneratedUploadUrl> {
    const body = await APIBase.post<GeneratedUploadUrl>(`${URL}/generate-upload-url`, file);

    return body.data;
  }

  async checkFileAfterUpload(id: string): Promise<boolean> {
    const body = await APIBase.post<boolean>(`${URL}/${id}/check`);

    return body.data;
  }

  async uploadFile(file: File, isPrivate?: boolean, subFolderName?: string): Promise<string> {
    const contentInfo = Builder<UploadBody>()
      .contentLength(file.size)
      .contentType(file.type as ContentType)
      .fileName(file.name)
      .isPublic(!isPrivate)
      .subFolder(subFolderName)
      .build();
    const body = await this.generateUploadUrl(contentInfo);
    const formData = new FormData();
    Object.keys(body.fields).forEach((key) => {
      formData.append(key, body.fields[key]);
    });
    formData.append('file', file);
    await axios
      .post(`${body.signedUrl}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((error) => console.log(error.message));

    await this.checkFileAfterUpload(body.key);

    return body.key;
  }

  getFullUrlPath(id: string): string {
    const path = `${process.env.REACT_APP_FILE_DOMAIN}/${id}`;
    return path;
  }
}

export default new MediaService();
