import React from 'react';
import { Table, Typography } from '@care/web-ui';
import { PromoCodeInfo } from 'src/model/PromoCode.model';
import './PromoCodeInfoTable.scss';

interface Props {
  info: PromoCodeInfo;
}

const columns = [
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    render: (quantity: number | null) => (
      <Typography level={9}>{quantity !== null ? quantity : 'Unlimited'}</Typography>
    ),
  },
  {
    title: 'Claimed',
    dataIndex: 'claimed',
    render: (claimed: number) => <Typography level={9}>{claimed}</Typography>,
  },
  {
    title: 'Validity',
    dataIndex: 'validity',
    render: (validity: string) => <Typography level={9}>{validity}</Typography>,
  },
];

export const PromoCodeInfoTable = ({ info }: Props) => {
  return (
    <div className="promoCode-table-container">
      <Table columns={columns} data={[info]} rowKey="info" />
    </div>
  );
};
