import React from 'react';
import { Category } from 'src/model/Category.model';
import CategoryService from 'src/service/Category.service';
import { CategoryListStore, useStore as useCategoryListStore } from 'src/zustand-store/category/CategoryList.store';
import { Builder } from 'builder-pattern';
import { Utils } from '@care/web-ui';

const {
  CommonUtil: { pick },
} = Utils;

export const convertSubCategory = (list: Category[]): Category[] => {
  const categories = list
    .filter((item) => !item.parentCategoryId)
    .map((parent) => {
      const items = list.filter((item) => item.parentCategoryId === parent.id);
      return Builder(Category, parent).subCats(items).build();
    });

  return categories;
};

export const useQueryCategoryList = (): void => {
  const { fetchData } = useCategoryListStore((store: CategoryListStore) =>
    pick(store, ['fetchData'] as Array<keyof CategoryListStore>),
  );
  React.useEffect(() => {
    fetchData({
      fetcher: () => CategoryService.getAllCategories(''),
    });
  }, []);
};

export const useConvertSubCategory = (): Category[] => {
  const { data } = useCategoryListStore((store: CategoryListStore) =>
    pick(store, ['data'] as Array<keyof CategoryListStore>),
  );
  const categories = React.useMemo(() => convertSubCategory(data), [data]);
  return categories;
};
