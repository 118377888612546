import React from 'react';
import { createRoot } from 'react-dom/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientTokens } from '@react-keycloak/core';
import Keycloak from 'keycloak-js';
import './index.scss';
import './styles/global.css';
import '@care/web-ui/lib/main.css';

const keycloak = Keycloak({
  url: config.AUTH_URL,
  realm: 'care',
  clientId: 'care-web-id',
});

export default keycloak;
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './Config';
import GlobalLoading from './component/GlobalLoading/GlobalLoading';

const tokenLogger = (tokens: AuthClientTokens) => {
  const { token, refreshToken } = tokens;
  localStorage.setItem('accessToken', token);
  localStorage.setItem('refreshToken', refreshToken);
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={tokenLogger}
      LoadingComponent={<GlobalLoading />}
      initOptions={{
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${location.origin}/silent-auth.html`,
      }}
    >
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
