import React, { useEffect, useState } from 'react';
import { Button, careNotification, Modal, Row, Typography } from '@care/web-ui';
import SectionTitle from 'src/component/SectionTitle/SectionTitle';
import Spacer from 'src/component/Spacer/Spacer';
import { appHomeScreenPath } from 'src/constant/configurations.constant';
import { useHistory } from 'react-router';
import Sortable from 'src/component/sortable/Sortable';
import { useAppHomeScreenContext } from 'src/context/AppHomeScreen.context';
import { HomeScreenNotice } from 'src/model/HomeScreenNotice.model';
import PageHeader, { ActionButtonsProps } from 'src/component/PageHeader/PageHeader';
import ManageNoticesItem from '../manage-notices-item/ManageNoticesItem';
import ManageNoticesModal from '../manage-notices-modal/ManageNoticesModal';
import Notices from 'src/component/Notices/Notices';
import { Utils } from '@care/web-ui';

const {
  DateUtil: { isBeforeNow, isAfterNow },
} = Utils;

const breadcumbItems = [
  {
    name: 'App Home Screen',
    href: appHomeScreenPath,
  },
  {
    name: 'Notices',
    href: '#',
  },
];

// TODO: Combine this UI with ManageBanner and ManageTiles because they're similar
const ManageNotices = () => {
  const { loadData, updateNotices, loading } = useAppHomeScreenContext();
  const { push } = useHistory();
  const [editedItem, setEditedItem] = useState<HomeScreenNotice>(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [items, setItems] = useState<HomeScreenNotice[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('country');

  useEffect(() => {
    (async () => {
      const data = await loadData(country);

      if (data) {
        setItems(data.notices || []);
      }
    })();
  }, []);

  const handleAddTile = () => {
    setEditedItem(null);
    setEditModalVisible(true);
  };

  const handleCloseModal = () => {
    setEditModalVisible(false);
    setEditedItem(null);
  };

  const handleSwitchVisibility = (value: boolean, index: number) => {
    const visibleNotices = items.filter((item) => item.isVisible);

    // Maximum number of visible notices is 2
    if (visibleNotices.length >= 2 && value) {
      Modal.confirm({
        type: 'warning',
        title: 'Notice Warning',
        content: 'Maximum number of visible notices is 2',
      });

      return;
    }
    const newItems = [...items];
    newItems[index].isVisible = value;
    setItems(newItems);
  };

  const handleEditItem = (item: HomeScreenNotice, index: number) => {
    setEditedItem({ ...item, index });
    setEditModalVisible(true);
  };

  const handleSaveItem = (item: HomeScreenNotice, isDel?: boolean) => {
    const index = item.index;
    if (index !== undefined && !isDel) {
      const newItems = [...items];
      newItems[item.index] = { ...item, id: item.id }; // CASE: edit item.
      setItems(newItems);
    } else if (isDel) {
      const newItems = [...items];
      newItems.splice(item.index, 1); // CASE: delete item.
      setItems(newItems);
    } else {
      setItems(items.concat({ ...item, index: items.length })); // CASE: add new item.
    }
    return true;
  };

  const backToHomeScreenPage = () => {
    push(`${appHomeScreenPath}${window.location.search.replace('?', '&')}`);
  };

  const handleSave = async () => {
    const errorList = items.reduce<{ title: string; msg: string }[]>((acc, curr) => {
      if (!curr.id && isBeforeNow(curr.startDate) && isAfterNow(curr.endDate)) {
        return [...acc, { title: curr.title, msg: 'Invalid Pre-set Visibility Period' }];
      }
      return acc;
    }, []);

    if (errorList.length > 0) {
      Modal.confirm({
        type: 'error',
        title: <Typography className="text-error-20">Error</Typography>,
        content: errorList.map(({ title, msg }, idx) => (
          <Typography key={idx} level={9} weight="semi">
            Notice {title}: {msg}
          </Typography>
        )),
      });
      return;
    }

    const result = await updateNotices(country, items);

    if (result) {
      backToHomeScreenPage();
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={9} weight="semi">
            Notices successfully updated.
          </Typography>
        ),
        duration: 2,
      });
    }
  };

  const actionButtons: ActionButtonsProps[] = [
    {
      title: 'Cancel',
      type: 'outline',
      size: 'small',
      onSubmit: backToHomeScreenPage,
    },
    {
      title: 'Save',
      size: 'small',
      loading,
      disabled: items.length === 0,
      onSubmit: handleSave,
    },
  ];

  return (
    <div className="px-[76px] py-4">
      <PageHeader title="Notices" breadcumbItems={breadcumbItems} actionButtons={actionButtons} />

      <Row spans={[8, 12, 4]} gutter={60}>
        <div>
          <Spacer size={40} />
          {items.length > 0 && (
            <div className="rounded-lg bg-primary-5 px-4 py-9">
              <Notices notices={items} />
            </div>
          )}
        </div>
        <div>
          <SectionTitle
            title="Manage Notices"
            marginVertical={4}
            rightComponent={
              <Button
                title="Add Notice"
                icon="Plus"
                type="link"
                style={{ padding: 0, minWidth: 'unset' }}
                onClick={handleAddTile}
              />
            }
          />
          <Spacer size={16} />
          <Sortable items={items} onSortEnd={setItems}>
            {items.map((item, index) => (
              <ManageNoticesItem
                key={item.id || item.index}
                item={item}
                onEdit={() => handleEditItem(item, index)}
                onSwitch={(value) => handleSwitchVisibility(value, index)}
              />
            ))}
          </Sortable>
        </div>
      </Row>

      <ManageNoticesModal
        visible={editModalVisible}
        onClose={handleCloseModal}
        onSave={handleSaveItem}
        itemDetail={editedItem}
      />
    </div>
  );
};

export default ManageNotices;
