import React from 'react';
import { Typography } from '@care/web-ui';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import PreviewTileItem from 'src/module/configurations/preview-tile-item/PreviewTileItem';

interface IPreviewTileAllServicesProps {
  visibleTiles: HomeScreenItem[];
}

const PreviewTilesAllServices = ({ visibleTiles = [] }: IPreviewTileAllServicesProps) => {
  return (
    <div className="mt-4 w-[404px] rounded-lg bg-primary-5 p-6 ">
      <Typography className="mb-2 text-base text-neutral-70" weight="bold">
        All Services
      </Typography>
      <div className="grid grid-cols-3 gap-10 px-4 py-2">
        {visibleTiles.map((tile, index) => (
          <PreviewTileItem name={tile.name} mediaUrl={tile.mediaUrl} key={index} size="small" />
        ))}
      </div>
    </div>
  );
};

export default PreviewTilesAllServices;
