import { QueryPagination, createStore } from '@care/web-ui/lib/store';
import { QueryStore } from '@care/web-ui/lib/store/store.queryPagination';
import { EcProductModel, CatalogFilter } from 'src/model/Catalog.model';

const { queryStateCreator } = QueryPagination;

export const $CATALOG_STORE = '$CATALOG_STORE';

export type CatalogStore = QueryStore<EcProductModel, CatalogFilter>;

export const [useCatalogStore, catalogSelector] = createStore<CatalogStore>(queryStateCreator, {
  storeName: $CATALOG_STORE,
});
