import { Table, Typography, Utils } from '@care/web-ui';
import React, { useMemo } from 'react';
import { PAGE_SIZE_PER_MODULE, pageSizeOptions } from 'src/constant/Pagination.constant';
import { getColumns } from './columns';
import { Sorter } from 'src/util/createPagingDataContext';
import { useFilteredDeepLinks } from './DeepLinksTable.actions';
import { DeepLinkListStore, useDeepLinkStore } from 'src/zustand-store/deep-link/DeepLinkList.store';
import { shallow } from '@care/web-ui/lib/store';

const {
  CommonUtil: { pick },
} = Utils;

const DeepLinksTable = () => {
  const { filter, setFilter, isLoading } = useDeepLinkStore(
    (store: DeepLinkListStore) => pick(store, ['filter', 'setFilter', 'isLoading'] as Array<keyof DeepLinkListStore>),
    shallow,
  );

  const handleTableChange = (newPagination: any, filterTable: Record<string, any>, sorter: Sorter) => {
    setFilter({
      ...filter,
      ...filterTable,
      pageIndex: newPagination.current,
      pageSize: newPagination.pageSize,
      sortBy: sorter,
    });
  };

  const columns = useMemo(() => getColumns(), []);

  const filteredDeepLinks = useFilteredDeepLinks();

  return (
    <div className="mt-3 [&.ant-table-thead>tr>th]:border-b-neutral-30">
      <Table
        loading={isLoading}
        columns={columns as any[]}
        onChange={handleTableChange}
        data={filteredDeepLinks}
        renderEmpty={
          <div>
            <Typography fontCategory="serif" level={7}>
              There is no deep link yet
            </Typography>
          </div>
        }
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.DEEPLINKS,
          showSizeChanger: true,
          pageSizeOptions,
          total: filteredDeepLinks.length,
          current: filter.pageIndex,
          pageSize: filter.pageSize,
          onChange: (pageIndex: number, pageSize: number) =>
            setFilter({
              ...filter,
              pageIndex,
              pageSize,
            }),
        }}
      />
    </div>
  );
};

export default DeepLinksTable;
