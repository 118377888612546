import { RadioButtonGroup } from '@care/web-ui';
import { RadioButtonGroupProps } from '@care/web-ui/lib/components/RadioButtonGroup/RadioButtonGroup';
import React from 'react';
import Field from './Field';

interface Props extends RadioButtonGroupProps {
  name: string;
  control: any;
}

const RadioButtonField = ({ name, control, onChange: onValueChange, customAttr, ...rest }: Props) => (
  <Field
    name={name}
    control={control}
    customAttr={customAttr}
    render={({ onChange, value }) => (
      <RadioButtonGroup
        {...rest}
        value={value}
        customAttr={customAttr}
        onChange={(newValue) => {
          onChange(newValue);
          if (onValueChange) {
            onValueChange(newValue);
          }
        }}
      />
    )}
  />
);

export default RadioButtonField;
