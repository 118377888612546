import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import { stringify } from 'query-string';
import { Category } from 'src/model/Category.model';
import { Utils } from '@care/web-ui';

const {
  CommonUtil: { kebabCase },
} = Utils;

export const formatEcCategoryPath = (category: Category): string => {
  const finalPath = 'products/e-commerce/category/$slug?$query';
  const slug = kebabCase(category.name);
  const query = stringify({
    categoryName: category.name,
    parentCategoryId: category.id,
  });
  return finalPath.replace('$slug', slug).replace('$query', query);
};

export const groupCategories = (list: Category[]): Category[] => {
  const categories = list
    .filter((item) => !item.parentCategoryId)
    .map((parent) => {
      const items = list.filter((item) => item.parentCategoryId === parent.id);
      return {
        ...parent,
        subCats: items,
      };
    });
  return categories;
};

export const formatEcCategoryRoutings = (categories: Category[]): ComboBoxItem[] => {
  const results: ComboBoxItem[] = [];
  const subCats: ComboBoxItem[] = [];
  const groupCat = groupCategories(categories);
  groupCat.forEach((cat) => {
    results.push({ text: `${cat.name}`, value: formatEcCategoryPath(cat) });
    cat.subCats.forEach((subCat) => {
      subCats.push({
        text: `${cat.name}/${subCat.name}`,
        value: formatEcCategoryPath(subCat),
      });
    });
  });
  return results.concat(subCats);
};
