export const swapItems = (array: Array<any>, fromIndex: number, toIndex: number) => {
  const from = array[fromIndex];
  const to = array[toIndex];

  array.splice(fromIndex, 1, to);
  array.splice(toIndex, 1, from);
};

export const sortItems = (array: Array<any>, fromIndex: number, toIndex: number) => {
  const from = array[fromIndex];

  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, from);
};
