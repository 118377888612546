import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import { AppScreenRouting } from 'src/enum/appScreenRouting.enum';
import { convertCamelCaseToNormal } from 'src/util/convertCamelCase.util';

const getAppHomeScreenRouting = () => {
  return Object.keys(AppScreenRouting).map(
    (r) =>
      ({
        text: convertCamelCaseToNormal(AppScreenRouting[r]),
        value: AppScreenRouting[r],
      }) as ComboBoxItem,
  );
};

export const appScreenRoutingOptions: ComboBoxItem[] = getAppHomeScreenRouting();
