import { Tabs, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import { TabProps } from '@care/web-ui/lib/components/Tabs/Tabs';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Spacer from 'src/component/Spacer/Spacer';
import { Routes } from 'src/constant';
import AppHomeScreenProvider from 'src/context/AppHomeScreen.context';
import CategoryProvider from 'src/context/Category.context';
import AppHomeScreen from './app-home-screen/AppHomeScreen';
import Categories from './categories/Categories';
import PlatformSettings from './platform-settings/PlatformSettings';
import WebHomeScreen from 'src/module/configurations/web-home-screen/WebHomeScreen';

export enum TabName {
  Categories = 'categories',
  AppHomeScreen = 'appHomeScreen',
  WebHomeScreen = 'webHomeScreen',
  PlatformSettings = 'platformSettings',
}

const tabItems: TabProps[] = [
  {
    title: 'Categories',
    key: TabName.Categories,
  },
  {
    title: 'App Home Screen',
    key: TabName.AppHomeScreen,
  },
  {
    title: 'Web Home Screen',
    key: TabName.WebHomeScreen,
  },
  {
    title: 'Platform Settings',
    key: TabName.PlatformSettings,
  },
];

const { getUrlSearchParams } = Utils.WindowUtil;

const Configurations = () => {
  const params = getUrlSearchParams();
  const tabValue = params.get('tab');
  const { push } = useHistory();

  const [tab, setTab] = useState(tabValue || TabName.Categories);

  const tabComponent = useMemo(() => {
    switch (tab) {
      case TabName.AppHomeScreen:
        return (
          <AppHomeScreenProvider>
            <AppHomeScreen />
          </AppHomeScreenProvider>
        );
      case TabName.WebHomeScreen:
        return (
          <AppHomeScreenProvider>
            <WebHomeScreen />
          </AppHomeScreenProvider>
        );
      case TabName.PlatformSettings:
        return <PlatformSettings />;
      default:
        return (
          <CategoryProvider>
            <Categories />
          </CategoryProvider>
        );
    }
  }, [tab]);

  return (
    <div style={{ padding: '52px 76px' }}>
      <Typography fontCategory="serif" level={3}>
        Configurations
      </Typography>

      <Spacer size={24} />

      <Tabs
        tabItems={tabItems}
        activeKey={tab}
        onKeyChange={(key) => {
          push(`${Routes.Configurations}?tab=${key as TabName}`), setTab(key as TabName);
        }}
      />

      {tabComponent}
    </div>
  );
};

export default Configurations;
