import axios, { AxiosPromise, AxiosRequestConfig, Method } from 'axios';
import { StringifyOptions, stringify } from 'query-string';
import keycloak from 'src/index';
import Utils from '@care/web-ui/lib/utils';

const { merge } = Utils.CommonUtil;

axios.defaults.validateStatus = (status) => {
  return status >= 200 && status < 300;
};

export function makeQueryString(params: any, arrayFormat: StringifyOptions['arrayFormat'] = 'bracket'): string {
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([_k, v]) => v || v === false));
  return stringify(filteredParams, { arrayFormat });
}

export class APIBase {
  static async request<T>(
    method: Method,
    url: string,
    body?: unknown,
    options?: AxiosRequestConfig,
  ): Promise<APIResponse<T>> {
    return sendRequest(method, url, body, options);
  }

  public static async get<T>(url: string): Promise<APIResponse<T>> {
    return this.request('GET', url);
  }

  public static async post<T>(url: string, body?: unknown, options?: AxiosRequestConfig): Promise<APIResponse<T>> {
    return this.request('POST', url, body, options);
  }

  public static async put<T>(
    url: string,

    body?: unknown,
  ): Promise<APIResponse<T>> {
    return this.request('PUT', url, body);
  }

  public static async delete<T>(
    url: string,

    body?: unknown,
  ): Promise<APIResponse<T>> {
    return this.request('DELETE', url, body);
  }

  public static async uploadFiles<T>(url: string, files: string | Blob): Promise<APIResponse<T>> {
    return upload(url, files);
  }
}

export class APIResponse<T> {
  data?: T;
  error?: APIError;

  public setData(data: T): void {
    this.data = data;
  }

  public setError(err: APIError): void {
    this.error = err;
  }
}
export interface APIError {
  code: string;
  message: string;
}

export async function sendRequest<T>(
  method: Method,
  url: string,
  body?: unknown,
  options: AxiosRequestConfig = {},
): Promise<APIResponse<T>> {
  let headers = {};
  if (body !== null) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keycloak.token}`,
    };
  }

  merge(options, { headers });

  return send(
    axios({
      method,
      url,
      data: body,
      ...options,
    }),
  );
}

export async function upload<T>(url: string, files: string | Blob): Promise<APIResponse<T>> {
  let headers: unknown;
  const formData = new FormData();

  formData.append('file', files);

  if (files !== null) {
    headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${keycloak.token}`,
    };
  }

  return send(
    axios({
      method: 'POST',
      url,
      headers,
      data: formData,
    }),
  );
}

async function send<T>(axios_promise: AxiosPromise): Promise<APIResponse<T>> {
  const result = new APIResponse<T>();
  try {
    const response = await axios_promise;
    if (response.status >= 400) {
      throw response.data;
    } else {
      result.setData(response.data);
    }
  } catch (e) {
    console.error('Failed to execute request: ', e.config);
    if (e.response) {
      console.error('Detailed Error: ', e.response.data);
      throw e.response.data;
    }
    throw e;
  }

  return result;
}
