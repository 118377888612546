import { CopyToClipboardInput, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';
import React from 'react';
import BlockInfo from 'src/component/BlockInfo/BlockInfo';
import Divider from 'src/component/Divider/Divider';
import { mappingRefundType } from 'src/constant/Refund';
import { useRefundDetailContext } from 'src/context/RefundDetail.context';
import { RefundStatus } from 'src/enum/RefundStatus';

const { formatDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;

const RefundInformation = () => {
  const { fullRefundDetail } = useRefundDetailContext();

  const {
    requestedBy,
    processedByName,
    refundType,
    refundAmount,
    status,
    rejectReason,
    providerRefundId,
    settlementDate,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = fullRefundDetail?.refund;
  const nonCancellation = fullRefundDetail?.order?.orderItems[0]?.appointmentBookings[0]?.cancellationWindowDateTime;

  const renderBlockByStatus = () => {
    switch (status) {
      case RefundStatus.InProgress:
      case RefundStatus.Success:
        return (
          <>
            <BlockInfo name="Refund Processed by" value={processedByName || '-'} marginBottom={16} />
            <BlockInfo name="Type of Refund" value={mappingRefundType[refundType] || '-'} marginBottom={16} />
            <BlockInfo
              name="Refund Settlement Date"
              value={settlementDate ? formatDateString(settlementDate, DATE.DATE_TIME) : '-'}
              marginBottom={16}
            />
            <BlockInfo name="Refund Amount" value={refundAmount.valueWithSymbol || '-'} marginBottom={16} />

            {status === RefundStatus.Success && (
              <BlockInfo
                name="Refund Transaction ID"
                value={<CopyToClipboardInput value={providerRefundId?.toString() || '-'} size="small" />}
                marginBottom={16}
              />
            )}
          </>
        );
      case RefundStatus.Rejected:
      case RefundStatus.RejectedByIssuer:
        return (
          <>
            <BlockInfo name="Refund Processed by" value={processedByName || '-'} marginBottom={16} />
            <BlockInfo
              name="Refund Settlement Date"
              value={settlementDate ? formatDateString(settlementDate, DATE.DATE_TIME) : '-'}
              marginBottom={16}
            />
            <BlockInfo name="Reject Reason" value={rejectReason || '-'} marginBottom={16} />
          </>
        );
      default:
        return;
    }
  };

  return (
    <div>
      <Typography level={5} weight="regular" fontCategory="serif">
        Refund Information
      </Typography>
      <Divider marginVertical={16} />
      <BlockInfo name="Refund Requested by" value={requestedBy || '-'} marginBottom={16} />
      <BlockInfo
        name="Start of Non-cancellation Window"
        value={nonCancellation ? formatDateString(nonCancellation, DATE.DATE_TIME) : '-'}
        marginBottom={16}
      />
      {renderBlockByStatus()}
    </div>
  );
};

export default RefundInformation;
