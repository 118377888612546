import React, { useContext, useEffect } from 'react';

import './RefundDetail.scss';
import { useHistory, useParams } from 'react-router';
import { Breadcumb, Button, Row, Tag, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';
import { Routes } from 'src/constant';
import Spacer from 'src/component/Spacer/Spacer';
import { BreadcumbItem } from '@care/web-ui/lib/components/Breadcumb/Breadcumb';
import Divider from 'src/component/Divider/Divider';
import OrderInformation from '../order-information/OrderInformation';
import { RefundStatus } from 'src/enum/RefundStatus';
import { ApproveRejectActionsContext } from 'src/context/ApproveRejectActions.context';
import { useRefundDetailContext } from 'src/context/RefundDetail.context';
import OrderCustomerDetails from '../order-customer-details/OrderCustomerDetails';
import OrderItemTable from '../order-item-table/OrderItemTable';
import RefundInformation from '../refund-information/RefundInformation';
import PaymentInfo from '../payment-info/PaymentInfo';
import BlockInfo from 'src/component/BlockInfo/BlockInfo';
import { refundStatusTypeTag } from 'src/constant/RefundStatusTag';
import { OrderType } from 'src/enum/OrderStatus';

const { formatDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;

const RefundDetail = () => {
  const { openReject, openRefund } = useContext(ApproveRejectActionsContext);
  const { loadDetail: loadRefundDetail, fullRefundDetail, loading, reopenRefund } = useRefundDetailContext();
  const params = useParams();
  const router = useHistory();
  const id = (params as any).id as string;

  const breadcumbItems: BreadcumbItem[] = [{ name: 'Refunds', href: Routes.Refunds }, { name: 'Refund Details' }];

  const reload = () => loadRefundDetail(id);

  const handleReopen = async () => {
    const res = await reopenRefund(id);

    if (res) {
      reload();
    }
  };

  useEffect(() => {
    if (id) {
      loadRefundDetail(id);
    }
  }, [id]);

  if (!fullRefundDetail || !fullRefundDetail?.refund?.id) {
    return <Typography level={6}>Loading...</Typography>;
  }

  const renderActionBtns = () => {
    switch (fullRefundDetail?.refund?.status) {
      case RefundStatus.Requesting:
        return (
          <>
            <Button type="danger" onClick={() => openReject(fullRefundDetail?.refund, reload)} title="Reject" />
            <Button type="primary" onClick={() => openRefund(fullRefundDetail?.refund, reload)} title="Approve" />
          </>
        );
      case RefundStatus.Rejected:
      case RefundStatus.RejectedByIssuer:
        return <Button onClick={handleReopen} type="primary" title="Reopen Case" />;
      default:
        return;
    }
  };

  return (
    <div className="refunds-detail-page">
      <div className="top">
        <div className="header">
          <div>
            <Breadcumb breadcumbItems={breadcumbItems} />
            <Spacer size={14} />
            <Row gutter={8} spans={[15, 9]}>
              <Typography level={3} fontCategory="serif">
                Refund Details
              </Typography>
              <div className="buttonRow">
                <Button type="outline" onClick={router.goBack} title="Done" />
                {renderActionBtns()}
              </div>
            </Row>

            <div className="refund-info-header">
              <BlockInfo
                name="Refund ID"
                value={
                  <Typography level={8} weight="bold">
                    {fullRefundDetail?.refund?.refundNumber}
                  </Typography>
                }
              />
              <BlockInfo
                name="Refund Status"
                value={
                  <Typography level={8} weight="bold">
                    <Tag
                      text={refundStatusTypeTag[fullRefundDetail.refund?.status].label}
                      type={refundStatusTypeTag[fullRefundDetail.refund?.status].type}
                    />
                  </Typography>
                }
              />
              <BlockInfo
                name="Refund Created Date & Time"
                value={
                  <Typography level={8} weight="bold">
                    {formatDateString(fullRefundDetail.refund?.createdAt, DATE.DATE_TIME)}
                  </Typography>
                }
              />
            </div>
          </div>
        </div>

        {loading ? (
          <Typography level={6}>Loading...</Typography>
        ) : (
          <div className="body">
            <Row justify="space-between" gutter={80} spans={[12, 12]}>
              <div>
                <RefundInformation />
                <PaymentInfo />
              </div>
              <div>
                <OrderInformation reason={fullRefundDetail?.refund.reason} orderDetail={fullRefundDetail?.order} />
                <OrderCustomerDetails consumer={fullRefundDetail?.order?.consumer} />
              </div>
            </Row>

            <Spacer size={60} />
            <Typography level={5} weight="regular" fontCategory="serif">
              Order Line Information
            </Typography>
            <Divider marginVertical={16} />
            <OrderItemTable orderItems={fullRefundDetail?.order.orderItems} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RefundDetail;
