import Utils from '@care/web-ui/lib/utils';
import { FilteredDate } from 'src/enum/filteredDate.enum';
import { OpUnitType } from 'src/enum/opUnitType.enum';

const { getStartOfDayFormat, getEndOfDayFormat, convertToISOString, getAddedUnitFormat } = Utils.DateUtil;

export interface DateRange {
  fromDate: string;
  toDate: string;
}

const formattedDateRange = (unit: OpUnitType, date?: Date | string): DateRange => {
  return {
    fromDate: getStartOfDayFormat(unit, date),
    toDate: getEndOfDayFormat(unit, date),
  };
};

export const convertDateFilterToDateRange = (unitType: FilteredDate): DateRange => {
  switch (unitType) {
    case FilteredDate.All:
    case FilteredDate.Today:
      return formattedDateRange(OpUnitType.Date);
    case FilteredDate.PastWeek: {
      const currentDatePastWeek = convertToISOString(getAddedUnitFormat(-1, OpUnitType.Week));
      return formattedDateRange(OpUnitType.Week, currentDatePastWeek);
    }
    case FilteredDate.PastMonth: {
      const currentDatePastMonth = convertToISOString(getAddedUnitFormat(-1, OpUnitType.Month));
      return formattedDateRange(OpUnitType.Month, currentDatePastMonth);
    }
    case FilteredDate.ThisWeek: {
      return formattedDateRange(OpUnitType.Week);
    }
    case FilteredDate.ThisMonth:
      return formattedDateRange(OpUnitType.Month);
    // case FilteredDate.NextWeek: {
    //   const currentDateNextWeek = convertToISOString(getAddedUnitFormat(1, OpUnitType.Week));
    //   return formattedDateRange(OpUnitType.Week, currentDateNextWeek);
    // }
    // case FilteredDate.NextMonth: {
    //   const currentDateNextMonth = convertToISOString(getAddedUnitFormat(1, OpUnitType.Month));
    //   return formattedDateRange(OpUnitType.Month, currentDateNextMonth);
    // }
    default: {
      const defaultResult = { fromDate: null, toDate: null };
      if (!unitType) {
        return defaultResult;
      }
      // Filter date is Custom date for example: 2022-07-29
      if ((unitType as string).length === 10) {
        return { fromDate: unitType, toDate: unitType };
      }
      // Filter date is Custom month for example: Jul 2023
      if ((unitType as string).length === 8) {
        const currentDateMonth = convertToISOString(unitType);
        return formattedDateRange(OpUnitType.Month, currentDateMonth);
      }
      return defaultResult;
    }
  }
};
