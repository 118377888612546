import { Modal } from '@care/web-ui';
import { notification } from './notification.util';

export const handleError = (error: Error): void => {
  Modal.confirm({
    title: 'Error',
    content: error.message || 'Unknown Error',
    type: 'error',
  });
};

export const handleToastError = (error: Error, title?: string): void => {
  notification({
    type: 'error',
    message: title || 'Error',
    description: error.message,
  });
};
