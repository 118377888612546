export class LocationModel {
  id: string;
  name: string;
  address?: string;
  countryCode?: string;
  postalCode?: string;
  mediaUrl?: string;
  locationCode?: string;
  lat?: string;
  long?: string;
  contactPhone?: string;
}
