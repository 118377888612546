import React from 'react';
import { Icon, Switch, Typography } from '@care/web-ui';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import './ItemInfo.scss';
import Spacer from 'src/component/Spacer/Spacer';

interface IProps {
  item: HomeScreenItem;
  imageType: 'square' | 'rectangle';
  onEdit: () => void;
  onSwitch: (value: boolean) => void;
  disabled?: boolean;
}

const ItemInfo = ({ item, imageType, onEdit, onSwitch, disabled }: IProps) => (
  <div className="itemContainer">
    <div className="flex items-center">
      <img src={item.mediaUrl} className={imageType} />
      <Spacer size={16} />
      <div onClick={onEdit} className="name-wrapper">
        <Typography level={9} weight="bold" className="name">
          {item.name}
        </Typography>
        <Icon name="RightSmall" type="outline" />
      </div>
    </div>
    <div className="flex items-center">
      <Typography level={9}>Visibility</Typography>
      <Spacer size={16} />
      <Switch disabled={disabled} value={item.visible} onChange={onSwitch} />
    </div>
  </div>
);

export default ItemInfo;
