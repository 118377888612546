import { careNotification, Row, Tag, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BlockInfo from 'src/component/BlockInfo/BlockInfo';
import Divider from 'src/component/Divider/Divider';
import PageHeader, { ActionButtonsProps, BreacrumbItemsProps } from 'src/component/PageHeader/PageHeader';
import Spacer from 'src/component/Spacer/Spacer';
import { Routes } from 'src/constant';
import { OrganizationContext } from 'src/context/Organization.context';
import { AccountTable } from '../../account/AccountTable';
import { OrganizationStatus } from 'src/enum/OrganizationStatus';
import UpdateStatusConfirmationModal from '../update-status-confirmation-modal/UpdateStatusConfirmationModal';
import ImportMedicationListModal from '../import-medication-list-modal/ImportMedicationListModal';
import './OrganisationDetail.scss';
import { capitalizeFirstLetter } from 'src/util/capitaliseFirstLeter.util';
import { organisationStatusTag } from 'src/constant/OrganisationsStatusTag.constant';

const { CountryUtil } = Utils;

export const OrganisationDetail = () => {
  const query = useParams();
  const history = useHistory();

  const { loadOrganisationDetail, organisation, updateOrganisationStatus, importMedicationList } =
    useContext(OrganizationContext);
  const { displayName, name, status: orgStatus } = organisation || {};
  const { orgId } = query as any;
  const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] = useState(false);
  const [isImportMedicationVisible, setIsImportMedicationVisible] = useState(false);

  const backToOrgsPage = () => history.push(Routes.Organisations);

  const handleCancelUpdateOrgStatus = () => {
    setIsUpdateStatusModalVisible(false);
  };

  const handleUpdateOrgStatus = async () => {
    const status =
      orgStatus === OrganizationStatus.verified ? OrganizationStatus.unverified : OrganizationStatus.verified;
    const result = await updateOrganisationStatus(orgId, status);
    if (result) {
      setIsUpdateStatusModalVisible(false);
      loadOrganisationDetail(orgId);
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={9} weight="semi">
            Marked as {status} succesfully
          </Typography>
        ),
        duration: 5,
      });
    }
  };

  const handleCancelImportMedicationList = () => {
    setIsImportMedicationVisible(false);
  };

  const handleImportMedicationList = async () => {
    const result = await importMedicationList(orgId);
    if (result) {
      setIsImportMedicationVisible(false);
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={9} weight="semi">
            Import medication list succesfully
          </Typography>
        ),
        duration: 5,
      });
    }
  };

  const breadcums: BreacrumbItemsProps[] = [
    {
      name: 'Organizations',
      href: Routes.Organisations,
    },
    {
      name: 'Edit Account',
      href: location.pathname,
    },
  ];

  const actionBtns: ActionButtonsProps[] = [
    {
      title: 'Done',
      type: 'outline',
      onSubmit: () => backToOrgsPage(),
    },
    {
      title: 'Import Medication List',
      type: 'outline',
      onSubmit: () => setIsImportMedicationVisible(true),
    },
    {
      title: `Mark as ${
        orgStatus === OrganizationStatus.verified
          ? capitalizeFirstLetter(OrganizationStatus.unverified)
          : capitalizeFirstLetter(OrganizationStatus.verified)
      }`,
      type: 'outline',
      onSubmit: () => setIsUpdateStatusModalVisible(true),
    },
  ];

  useEffect(() => {
    loadOrganisationDetail(orgId);
  }, [orgId]);

  return (
    <div style={{ padding: '52px 76px' }}>
      <PageHeader title="Organization Detail" breadcumbItems={breadcums} actionButtons={actionBtns} />
      <Spacer size={40} />
      <Row spans={[4, 20]}>
        <img
          width={164}
          height={164}
          className="orgLogo"
          src={organisation?.logoUrl || '/default-org-logo.png'}
          alt="orgImage"
        />
        <div style={{ marginLeft: 32 }}>
          <Typography level={5} fontCategory="serif">
            Organization Information
          </Typography>
          <Divider marginVertical={8} />
          <Spacer size={8} />
          <BlockInfo name="Display Name" value={displayName} marginBottom={16} />
          <BlockInfo name="Organization Name" value={name} marginBottom={16} />
          <BlockInfo
            name="Organization Status"
            value={
              <Tag
                text={organisationStatusTag[orgStatus]?.label}
                type={organisationStatusTag[orgStatus]?.type as any}
              />
            }
            marginBottom={16}
          />
          <BlockInfo
            name="Country"
            value={CountryUtil.getCountryNameByIsoCode2(organisation?.countryCode)}
            marginBottom={16}
          />
          <BlockInfo name="Registration No." value={organisation?.registrationNumber} marginBottom={16} />
        </div>
      </Row>
      <Spacer size={32} />
      <AccountTable />
      <UpdateStatusConfirmationModal
        visible={isUpdateStatusModalVisible}
        onCancel={handleCancelUpdateOrgStatus}
        onDone={handleUpdateOrgStatus}
        organisation={organisation}
      />

      <ImportMedicationListModal
        visible={isImportMedicationVisible}
        onCancel={handleCancelImportMedicationList}
        onDone={handleImportMedicationList}
        organisation={organisation}
      />
    </div>
  );
};
