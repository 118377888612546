import config from 'src/Config';
import { Platform } from 'src/enum/PlatformSetting.enum';
import { AppHomeScreenData } from 'src/model/AppHomeScreenData.model';
import {
  AppHomeScreenRequest,
  HomeScreenHeaderRequest,
  HomeScreenNoticesRequest,
} from 'src/model/dto/AppHomeScreen.request';
import { APIBase } from 'src/util/Request';

const BASE_URL = `${config.API_URL}/homescreens`;

class AppHomeScreenService {
  async getAppHomeScreenData(
    countryCode: string = 'SG',
    platform: Platform = Platform.APP,
  ): Promise<AppHomeScreenData> {
    const body = await APIBase.get<AppHomeScreenData>(`${BASE_URL}?countryCode=${countryCode}&platform=${platform}`);
    return body.data;
  }

  async saveHomeScreenItems(body: AppHomeScreenRequest, type: 'tiles' | 'banners'): Promise<any> {
    return APIBase.post(`${BASE_URL}/${type}`, body);
  }

  async postHomeScreenHeader(body: HomeScreenHeaderRequest): Promise<any> {
    return APIBase.post(`${BASE_URL}/header`, body);
  }

  async saveHomeScreenNotices(body: HomeScreenNoticesRequest): Promise<any> {
    return APIBase.post(`${BASE_URL}/notices`, body);
  }
}

export default new AppHomeScreenService();
