import React, { useMemo } from 'react';
import { Icon, Switch, Typography } from '@care/web-ui';
import { HomeScreenNotice } from 'src/model/HomeScreenNotice.model';
import Spacer from 'src/component/Spacer/Spacer';
import { Utils } from '@care/web-ui';

const {
  DateUtil: { isBeforeNow, isAfterNow },
} = Utils;

interface IManageNoticesItemProps {
  item: HomeScreenNotice;
  onEdit: () => void;
  onSwitch: (value: boolean) => void;
}

const isNoticeItemDisabled = (notice: HomeScreenNotice) => {
  if (!notice.isPresetVisibilityPeriod || !notice.id) {
    return false;
  }

  return isBeforeNow(notice.startDate) && isAfterNow(notice.endDate);
};

const ManageNoticesItem = ({ item, onEdit, onSwitch }: IManageNoticesItemProps) => {
  const disabled = useMemo(() => isNoticeItemDisabled(item), [item]);

  return (
    <div className="ml-[-30px] flex h-14 w-full items-center justify-between border-x-0 border-b border-t-0 border-solid border-neutral-10 pl-[30px]">
      <div className="flex items-center">
        <div onClick={onEdit} className="flex w-[220px] items-center">
          <Typography
            level={9}
            weight="bold"
            className={`flex-shrink-0 cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-primary-6 ${
              disabled ? 'cursor-not-allowed text-inherit' : ''
            }`}
          >
            {item.title}
          </Typography>
          <Icon className="flex-shrink-0 cursor-pointer text-primary-6" name="RightSmall" type="outline" />
        </div>
      </div>
      <div className="flex items-center">
        <Typography level={9}>Visibility</Typography>
        <Spacer size={16} />
        <Switch value={item.isVisible} onChange={onSwitch} />
      </div>
    </div>
  );
};

export default ManageNoticesItem;
