export enum FilteredDate {
  All = 'all',
  Today = 'today',
  ThisWeek = 'week',
  ThisMonth = 'month',
  NextWeek = 'nextWeek',
  NextMonth = 'nextMonth',
  PastWeek = 'pastWeek',
  PastMonth = 'pastMonth',
}
