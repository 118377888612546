import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Routes } from 'src/constant';
import DeepLinks from 'src/module/deep-links/DeepLinks';

const DeepLinksContainer = () => {
  return (
    <Switch>
      <Route path={Routes.DeepLinks} exact>
        <DeepLinks />
      </Route>
    </Switch>
  );
};

export default DeepLinksContainer;
