import { Builder } from 'builder-pattern';
import { PromoCodeType } from 'src/enum/PromoCodeType';
import { PromoCodeModelDTO } from './dto/PromoCode.response';
import Utils from '@care/web-ui/lib/utils';
import Constants from '@care/web-ui/lib/constants';

const { formatDateString } = Utils.DateUtil;
const { DATE } = Constants.DateConstant;

export class PromoCodeModel {
  id: string;
  codeType: PromoCodeType;
  code: string;
  active: boolean;
  title: string;
  organizationId: string;
  info: PromoCodeInfo;

  static convertFromDTO(dto: PromoCodeModelDTO): PromoCodeModel {
    const { totalQuantity, quantityClaimed, startDate, endDate, ...rest } = dto;
    return Builder(PromoCodeModel, {
      ...rest,
      info: {
        quantity: totalQuantity,
        claimed: quantityClaimed,
        validity: `${formatDateString(startDate, DATE.DATE_SLASH)}
          - ${formatDateString(endDate, DATE.DATE_SLASH)}`,
      },
    }).build();
  }
}

export interface PromoCodeInfo {
  quantity: number;
  claimed: number;
  validity: any;
}
