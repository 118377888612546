import { TilesLayout } from 'src/model/dto/AppHomeScreen.request';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';

const eventPrefix = 'CLICK_';

export const getLayout = (tiles: HomeScreenItem[]): TilesLayout => {
  const length = tiles.length;

  if (length <= 3) {
    return { row: 1, column: 3 };
  }

  if (length === 4) {
    return { row: 1, column: 4 };
  }

  if (length <= 6) {
    return { row: 2, column: 3 };
  }

  return { row: 2, column: 4 };
};

export const getVisibleTiles = (tiles: HomeScreenItem[]): HomeScreenItem[] => tiles.filter((tile) => tile.visible);

export const buildPayloadEventName = (value: string) => {
  const suffix = value.replaceAll(' ', '_').toUpperCase();
  return value.includes(eventPrefix) ? suffix : eventPrefix + suffix;
};
