export enum FeedbackType {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
}

export enum FeedbackStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export enum FeedbackSortBy {
  RATING_HIGH_TO_LOW = 'rating_high_to_low',
  RATING_LOW_TO_HIGH = 'rating_low_to_high',
  SUBMISSION_TIME_ASC = 'submission_time_asc',
  SUBMISSION_TIME_DESC = 'submission_time_desc',
}
