import classNames from 'classnames';
import React from 'react';

export type PreviewSizeType = 'default' | 'small';

export interface IPreviewTileItemProp {
  name: string;
  mediaUrl: string;
  size?: PreviewSizeType;
}

export const LargePreviewTileItem = ({ name, mediaUrl }: IPreviewTileItemProp) => {
  return (
    <div>
      <div className="h-[124px] rounded-lg border-1 border-solid border-neutral-100 bg-white p-2">
        <img className="mt-2 h-[48px] w-[48px] rounded-lg object-cover" src={mediaUrl} alt={name} />
        <div className="mt-2 text-base font-semibold text-neutral-800">{name}</div>
      </div>
    </div>
  );
};

const PreviewTileItem = ({ name, mediaUrl, size = 'default' }: IPreviewTileItemProp) => {
  const isDefaultSize = size === 'default';
  const isSmallSize = size === 'small';
  return (
    <div>
      <div
        className={classNames('flex items-center !justify-center rounded-lg', {
          'h-[80px] border-1 border-solid border-neutral-100 bg-white': isDefaultSize,
          'mx-auto h-[50px] w-[50px]': isSmallSize,
        })}
      >
        <img
          className={classNames('h-[48px] w-[48px] rounded-lg object-cover', {
            'border-1 border-solid border-neutral-100 bg-white': isSmallSize,
          })}
          src={mediaUrl}
          alt={name}
        />
      </div>
      <div
        className={classNames('mt-2 text-center text-xs text-neutral-800', {
          'font-semibold ': isDefaultSize,
        })}
      >
        {name}
      </div>
    </div>
  );
};

export default PreviewTileItem;
