import { Button, Modal, Typography, Utils } from '@care/web-ui';
import React from 'react';
import { useGetProductSelected } from 'src/module/products/ProductsTable/ProductTable.actions';
import { useBulkApproveProducts } from './ProductsBulkApprove.actions';
import { ProductListMutationStore, useStore } from 'src/zustand-store/product-list/ProductListMutation.store';
import { shallow } from '@care/web-ui/lib/store';

const {
  CommonUtil: { pick },
} = Utils;
const ProductsBulkApproveButton = () => {
  const { total, productsSelected, isDisableBtn } = useGetProductSelected();
  const { handleBulkApproveProducts } = useBulkApproveProducts();
  const { isLoading }: Pick<ProductListMutationStore, 'isLoading'> = useStore(
    (store: ProductListMutationStore) => pick(store, ['isLoading'] as Array<keyof ProductListMutationStore>),
    shallow,
  );

  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <>
      <Button
        title="Approve All"
        className="mr-2"
        icon="Check"
        onClick={() => setIsVisible(true)}
        disabled={isDisableBtn}
      />
      <Modal
        title={`Approve ${total} items?`}
        visible={isVisible}
        content={<Typography level={9}>These products will be displayed in the Care marketplace.</Typography>}
        okText="Approve All"
        onOk={() => {
          handleBulkApproveProducts({
            params: productsSelected,
            mutationSuccess: () => {
              setIsVisible(false);
            },
          });
        }}
        onCancel={() => setIsVisible(false)}
        okType="primary"
        titleIcon="AlertTriangle"
        titleIconColor="#CB8608"
        closable={false}
        processing={isLoading}
      />
    </>
  );
};

export default ProductsBulkApproveButton;
