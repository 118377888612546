import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routes } from 'src/constant';
import AppHomeScreenProvider from 'src/context/AppHomeScreen.context';
import CategoryProvider from 'src/context/Category.context';
import Configurations from 'src/module/configurations/Configurations';
import ManageTiles from 'src/module/configurations/manage-tiles/ManageTiles';
import ManageNotices from 'src/module/configurations/manage-notices/ManageNotices';
import ManageBanners from 'src/module/configurations/manage-banners/ManageBanners';
import WebHomeScreenDetail from 'src/module/configurations/web-home-screen-detail/WebHomeScreenDetail';

function ProductContainer() {
  return (
    <Switch>
      <Route path={Routes.Configurations} exact>
        <Configurations />
      </Route>
      <Route path={`${Routes.HomeScreenNotices}`} exact>
        <AppHomeScreenProvider>
          <CategoryProvider>
            <ManageNotices />
          </CategoryProvider>
        </AppHomeScreenProvider>
      </Route>
      <Route path={`${Routes.HomeScreenTiles}`} exact>
        <AppHomeScreenProvider>
          <CategoryProvider>
            <ManageTiles />
          </CategoryProvider>
        </AppHomeScreenProvider>
      </Route>
      <Route path={`${Routes.HomeScreenBanners}`} exact>
        <AppHomeScreenProvider>
          <CategoryProvider>
            <ManageBanners />
          </CategoryProvider>
        </AppHomeScreenProvider>
      </Route>
      <Route path={Routes.WebScreenTiles} exact>
        <AppHomeScreenProvider>
          <CategoryProvider>
            <WebHomeScreenDetail />
          </CategoryProvider>
        </AppHomeScreenProvider>
      </Route>
    </Switch>
  );
}

export default ProductContainer;
