import React from 'react';

interface UserInfoItemProps {
  title: string;
  value: string | number;
}

const UserInfoItem = (props: UserInfoItemProps) => {
  const { title, value } = props;
  return (
    <div className="mb-4 text-base">
      <p className="mb-0 text-primary-6">{title}</p>
      <p>{value ?? '-'}</p>
    </div>
  );
};

export default UserInfoItem;
