export enum OrderStatus {
  Pending = 'pending',
  Cancelled = 'canceled',
  Refunded = 'refunded',
  Completed = 'completed',
  OutForDelivery = 'in_delivery',
}

export enum OrderType {
  Service = 'service',
  Product = 'product',
}

export enum OrderCodeTypes {
  Promo = 'Promo',
  Redemption = 'Redemption',
}
