import React, { ReactNode, useState } from 'react';
import { RefundDetailModel } from 'src/model/RefundDetail.model';
import { ApproveBulkRefund } from 'src/module/refunds/approve-refund-modal/ApproveBulkRefund';
import { ApproveSingleRefund } from 'src/module/refunds/approve-refund-modal/ApproveSingleRefund';
import { RejectRefundModal } from 'src/module/refunds/reject-refund-modal/RejectRefundModal';

class ContextState {
  openReject!: (item: RefundDetailModel, onDone: () => any) => any;
  openRefund!: (item: RefundDetailModel, onDone: () => any) => any;
  openBulkRefunds!: (refundIds: string[], onDone: () => any) => any;
}

export const ApproveRejectActionsContext = React.createContext(new ContextState());

let callback = null;

const ApproveRejectActionsProvider = ({ children }: React.PropsWithChildren) => {
  const [visible, setVisible] = useState(false);
  const [refundVisible, setRefundVisible] = useState(false);
  const [selected, setSelected] = useState<RefundDetailModel>();
  const [visibleBulkRefund, setVisibleBulkRefund] = useState(false);
  const [selectedRefundIds, setSelectedRefundIds] = useState<string[]>([]);

  const openReject = (item: RefundDetailModel, onDone: () => any) => {
    setSelected(item);
    setVisible(true);
    callback = onDone;
  };

  const openRefund = (item: RefundDetailModel, onDone: () => any) => {
    setSelected(item);
    setRefundVisible(true);
    callback = onDone;
  };

  const openBulkRefunds = (refundIds: string[], onDone: () => any) => {
    setSelectedRefundIds(refundIds);
    setVisibleBulkRefund(true);
    callback = onDone;
  };

  return (
    <ApproveRejectActionsContext.Provider
      value={{
        openReject,
        openRefund,
        openBulkRefunds,
      }}
    >
      {children}
      <RejectRefundModal onDone={callback} visible={visible} onClose={() => setVisible(false)} id={selected?.id} />

      <ApproveSingleRefund
        visible={refundVisible}
        onClose={() => setRefundVisible(false)}
        onDone={callback}
        item={selected}
      />

      <ApproveBulkRefund
        refundIds={selectedRefundIds}
        visible={visibleBulkRefund}
        onClose={() => setVisibleBulkRefund(false)}
        onDone={callback}
      />
    </ApproveRejectActionsContext.Provider>
  );
};

export default ApproveRejectActionsProvider;
