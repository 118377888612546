import React from 'react';
import { Typography } from '@care/web-ui';

import Spacer from 'src/component/Spacer/Spacer';

import { RefundTable } from '../refund-table/RefundTable';
import { RefundTableFilter } from '../RefundTableFilter/RefundTableFilter';

import './RefundsStyle.scss';
import ApproveRejectActionsProvider from 'src/context/ApproveRejectActions.context';
import ProductListProvider from 'src/context/ProductList.context';

const Refunds = () => {
  return (
    <ApproveRejectActionsProvider>
      <ProductListProvider>
        <div className="refunds-page">
          <div className="head-title">
            <Typography fontCategory="serif" level={3}>
              Refunds
            </Typography>
          </div>

          <Spacer size={32} />

          <RefundTableFilter />

          <Spacer size={32} />

          <RefundTable />
        </div>
      </ProductListProvider>
    </ApproveRejectActionsProvider>
  );
};

export default Refunds;
