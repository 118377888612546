export class ConsumerModel {
  id: string;
  fullName: string;
  careId: string;
  countryCode: string;
  dateOfBirth: string;
  email: string;
  gender: string;
  identityNumber: string;
  identityType: string;
  phoneNumber: string;
}
