import { Constants } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import { OrderFilter } from 'src/context/Orders.context';

const {
  DateUtil,
  ArrayUtil: { isEqualIgnoreOrder },
  CommonUtil: { isEmpty, omitBy },
} = Utils;
const { DateConstant } = Constants;
const { formatDateString, getPayloadDateString } = DateUtil;

export const prepareExportFilter = (filter: OrderFilter, productIds: string[]): OrderFilter => {
  const { fromDate, toDate } = getDateValues(filter);
  let productIdsFilter: string[] = [];
  if (filter?.productIds) {
    productIdsFilter = autoRemoveDataIfSelectedAll({
      selectedValues: filter.productIds,
      values: productIds,
    });
  }

  const omitFilter = omitBy(
    {
      ...filter,
      productIds: productIdsFilter,
      fromDate,
      toDate,
    },
    isEmpty,
  );
  const fetcherFilter: OrderFilter = Object.assign({}, omitFilter);
  return fetcherFilter;
};

/**
 * It takes a filter object and returns an object with two properties: fromDate and toDate
 * @param {OrderFilter} filter - OrderFilter
 * @returns An object with two properties: fromDate and toDate.
 */
export const getDateValues = (filter: OrderFilter) => {
  const currentDate = new Date();
  const ISOCurrentDate = formatDateString(currentDate, DateConstant.DATE.ISO_DATE);
  const { fromDate, toDate } = filter;
  // default case: today
  if (!fromDate && !toDate) {
    const currentTime = formatDateString(currentDate, DateConstant.DATE.TIME);
    const formattedDate = `${ISOCurrentDate} ${currentTime}`;
    return {
      fromDate: getPayloadDateString(formattedDate),
      toDate: getPayloadDateString(formattedDate, true),
    };
  }
  return {
    fromDate: fromDate ? getPayloadDateString(formatDateString(fromDate, DateConstant.DATE.ISO_DATE)) : undefined,
    toDate: toDate ? getPayloadDateString(formatDateString(toDate, DateConstant.DATE.ISO_DATE), true) : undefined,
  };
};

export const autoRemoveDataIfSelectedAll = ({
  selectedValues,
  values,
  maxSize = 20,
}: {
  selectedValues: string[];
  values: string[];
  maxSize?: number;
}): string[] => {
  if (isEqualIgnoreOrder(selectedValues, values)) {
    return [];
  }
  if (selectedValues.length > maxSize) {
    //limit specifying indices in an array to a maximum index of 20
    return [];
  }
  return selectedValues;
};
