import React from 'react';
import { Breadcumb, Button, Typography, Utils } from '@care/web-ui';
import './PageHeader.scss';

export interface BreacrumbItemsProps {
  name: string;
  href: string;
}

export interface ActionButtonsProps {
  title: string;
  onSubmit: (data?: any) => any;
  type?: 'primary' | 'secondary' | 'outline' | 'danger' | 'link';
  size?: 'small' | 'large';
  disabled?: boolean;
  loading?: boolean;
}

export interface PageHeaderProps {
  breadcumbItems?: BreacrumbItemsProps[];
  title: string;
  actionButtons?: ActionButtonsProps[];
}

const {
  CommonUtil: { isEmpty },
} = Utils;

const PageHeader = ({ breadcumbItems, title, actionButtons }: PageHeaderProps) => {
  return (
    <div className="pageHeaderContainer">
      <Breadcumb breadcumbItems={breadcumbItems} />

      <div className="flex">
        <Typography level={3} fontCategory="serif">
          {title}
        </Typography>

        {!isEmpty(actionButtons) && (
          <div className="flex">
            {actionButtons.map((item) => (
              <Button
                title={item.title}
                type={item.type}
                size={item.size}
                onClick={item.onSubmit}
                key={item.title}
                loading={item.loading}
                disabled={item.disabled}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
