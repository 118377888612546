export enum UserSortBy {
  name_asc = 'name_asc',
  name_desc = 'name_desc',
  email_asc = 'email_asc',
  email_desc = 'email_desc',
}

export enum UserStatus {
  ALL = 'all',
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}

export enum SearchType {
  NAME = 'name',
  EMAIL = 'email',
  CONTACT = 'contact',
  ID_NUM = 'idNum',
  PASSPORT = 'passport',
}

export enum UserEmailStatus {
  activated = 'activated',
  deactivated = 'deactivated',
}

export enum UserContactStatus {
  verified = 'verified',
  unverified = 'unverified',
}

export enum IdentityProvider {
  EMAIL = 'email',
  GOOGLE = 'google',
  APPLE = 'apple',
  FACEBOOK = 'facebook',
  SINGPASS = 'singpass',
}
