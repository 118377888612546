import { OrganizationStatus } from 'src/enum/OrganizationStatus';

export class OrganizationModel {
  id: string;

  name: string;

  displayName: string;

  description: string;

  countryCode: string;

  registrationNumber: string;

  logoId: string;

  logoUrl?: string;

  websiteUrl: string;

  status: OrganizationStatus;

  docs: string[];

  createdAt: string;

  updatedAt: string;

  currency: string;

  numberOfProduct: number;

  get orgName(): string {
    return `${this.displayName}-${this.countryCode}`;
  }
}
