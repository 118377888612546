import { Input } from '@care/web-ui';
import { InputProps } from '@care/web-ui/lib/components/Input/Input';
import React from 'react';
import Field from './Field';

interface Props extends InputProps {
  name: string;
  control: any;
}

const InputField = ({ name, control, onChange: onInputChange, customAttr, ...rest }: Props) => (
  <Field
    name={name}
    control={control}
    customAttr={customAttr}
    render={({ onChange, onBlur, value }) => (
      <Input
        {...rest}
        onBlur={onBlur}
        value={value}
        customAttr={customAttr}
        onChange={(newValue: string, event) => {
          onChange(newValue);
          if (onInputChange) {
            onInputChange(newValue, event);
          }
        }}
      />
    )}
  />
);

export default InputField;
