import React from 'react';
import { Button, Tag, Typography } from '@care/web-ui';
import { FeedbackItem } from 'src/model/FeedbackItem.model';
import { FeedbackStatus, FeedbackType } from 'src/enum/Feedback.enum';
import { capitalizeFirstLetter } from 'src/util/capitaliseFirstLeter.util';
import { feedbackStatusColorMapping, feedbackTypeColorMapping } from 'src/constant/Feedback.constant';
import { ColumnFilterItem, FilteredDropdown } from 'src/component/FilteredDropdown/FilteredDropdown';
import { FeedbackFilter } from 'src/context/Feedbacks.context';

const feedbackTypeOptions: ColumnFilterItem[] = Object.values(FeedbackType).map((value) => ({
  text: capitalizeFirstLetter(value),
  value,
}));

const feedbackStatusOptions: ColumnFilterItem[] = Object.values(FeedbackStatus).map((value) => ({
  text: capitalizeFirstLetter(value),
  value,
}));

const TextCell = (value: string) => (
  <Typography className="break-all" level={9}>
    {value || '-'}
  </Typography>
);

export const getColumns = (filter: FeedbackFilter, actionFunc) => [
  {
    title: 'Submission Date',
    width: '10%',
    dataIndex: 'createdAt',
    sorter: (sortOrder: string) => sortOrder,
    render: (_, item: FeedbackItem) => <Typography level={9}>{item.createAtText}</Typography>,
  },
  {
    title: 'Feedback Type',
    width: '8%',
    dataIndex: 'type',
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <FilteredDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          filters={feedbackTypeOptions}
          confirm={confirm}
          selectedAllText="All Feedback Type"
          clearFilters={clearFilters}
        />
      );
    },
    filteredValue: filter.type || null,
    onFilter: (value: FeedbackType, record) => {
      return record.type === value;
    },
    render: (value: FeedbackType) => {
      return (
        <div>
          <Tag text={value && capitalizeFirstLetter(value)} type={feedbackTypeColorMapping[value]} />
        </div>
      );
    },
  },
  {
    title: 'Rating',
    width: '8%',
    dataIndex: 'rating',
    sorter: (sortOrder: string) => sortOrder,
    render: (value: number) => <Typography level={9}>{value}</Typography>,
  },
  {
    title: 'Feedback',
    width: '35%',
    dataIndex: 'feedback',
    render: TextCell,
  },
  {
    title: 'Username',
    width: '15%',
    dataIndex: 'fullName',
    render: TextCell,
  },
  {
    title: 'Email',
    width: '15%',
    dataIndex: 'email',
    render: TextCell,
  },
  {
    title: 'Follow-up status',
    dataIndex: 'status',
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <FilteredDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          filters={feedbackStatusOptions}
          confirm={confirm}
          selectedAllText="All Statuses"
          clearFilters={clearFilters}
        />
      );
    },
    filteredValue: filter.status || null,
    onFilter: (value: FeedbackStatus, record: FeedbackItem) => {
      return record.status === value;
    },
    render: (status: FeedbackStatus) => {
      return (
        <div>
          <Tag text={status && capitalizeFirstLetter(status)} type={feedbackStatusColorMapping[status]} />
        </div>
      );
    },
  },
  {
    title: '',
    width: '10%',
    render: (record: FeedbackItem) => {
      return (
        <>
          {record.status === FeedbackStatus.PENDING && (
            <Button
              type="link"
              title="Mark as Complete"
              size="small"
              style={{ color: '#0F6D77' }}
              onClick={async () => await actionFunc(record.id)}
            />
          )}
        </>
      );
    },
  },
];
