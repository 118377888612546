import { Button, Modal, Typography, Utils } from '@care/web-ui';
import React from 'react';
import { useGetProductSelected } from 'src/module/products/ProductsTable/ProductTable.actions';
import { useBulkRejectProducts } from './ProductsBulkReject.actions';
import { ProductListMutationStore, useStore } from 'src/zustand-store/product-list/ProductListMutation.store';
import { shallow } from '@care/web-ui/lib/store';

const {
  CommonUtil: { pick },
} = Utils;
const ProductsBulkRejectButton = () => {
  const { total, productsSelected, isDisableBtn } = useGetProductSelected();
  const { handleBulkRejectProducts } = useBulkRejectProducts();
  const { isLoading }: Pick<ProductListMutationStore, 'isLoading'> = useStore(
    (store: ProductListMutationStore) => pick(store, ['isLoading'] as Array<keyof ProductListMutationStore>),
    shallow,
  );

  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <>
      <Button
        title="Reject All"
        type="danger"
        icon="XSmall"
        onClick={() => {
          setIsVisible(true);
        }}
        disabled={isDisableBtn}
      />
      <Modal
        title={`Reject ${total} items?`}
        visible={isVisible}
        content={<Typography level={9}>These products will not be displayed in the marketplace.</Typography>}
        okText="Reject All"
        onOk={() => {
          handleBulkRejectProducts({
            params: productsSelected,
            mutationSuccess: () => setIsVisible(false),
          });
        }}
        onCancel={() => setIsVisible(false)}
        okType="danger"
        titleIcon="AlertTriangle"
        titleIconColor="#CB8608"
        closable={false}
        processing={isLoading}
      />
    </>
  );
};

export default ProductsBulkRejectButton;
