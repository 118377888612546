import { APIBase } from 'src/util/Request';
import config from 'src/Config';
import { ProductsDetail } from 'src/model/Product.model';

const URL = `${config.ADMIN_API_URL}/products`;
class ProductsService {
  async getProductDetail(id: string): Promise<ProductsDetail> {
    const data = await APIBase.get(`${URL}/${id}`);
    return data?.data as ProductsDetail;
  }

  async bulkReject(productIds: string[]): Promise<void> {
    const url = `${URL}/bulk-reject`;
    await APIBase.put(url, { productIds });
  }

  async bulkApprove(productIds: string[]): Promise<void> {
    const url = `${URL}/bulk-approve`;
    await APIBase.put(url, { productIds });
  }
}

export default new ProductsService();
