import React from 'react';
import { Typography } from '@care/web-ui';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';

import classNames from 'classnames';
import PreviewTileItem, { LargePreviewTileItem } from 'src/module/configurations/preview-tile-item/PreviewTileItem';

export interface IPreviewTilesHomeScreenProps {
  visibleTiles: HomeScreenItem[];
  isLargePreview?: boolean;
}

export const PreviewTilesHomeScreen = ({ visibleTiles = [], isLargePreview }: IPreviewTilesHomeScreenProps) => {
  const visibleHomeScreenTiles = visibleTiles.slice(0, 5);
  const firstTwoTiles = visibleHomeScreenTiles.slice(0, 2);
  const remainingTiles = visibleHomeScreenTiles.slice(2, 5);

  return (
    <div
      className={classNames('rounded-lg p-6', {
        'w-[404px] bg-primary-5': !isLargePreview,
        'bg-neutral-5': isLargePreview,
      })}
    >
      {!isLargePreview && (
        <Typography className="mb-4 text-base text-neutral-70" weight="bold">
          Home Screen
        </Typography>
      )}
      <div className="mx-auto w-[344px]">
        <div className="grid grid-cols-2 gap-2">
          {firstTwoTiles.map((tile, rIndex) => (
            <LargePreviewTileItem name={tile.name} mediaUrl={tile.mediaUrl} key={rIndex} />
          ))}
        </div>
        <div className="mt-2 grid grid-cols-4 gap-2">
          {remainingTiles.map((tile, rIndex) => (
            <PreviewTileItem name={tile.name} mediaUrl={tile.mediaUrl} key={rIndex} size="default" />
          ))}
          <PreviewTileItem name="View all" mediaUrl="/Rectangle.webp" size="default" />
        </div>
      </div>
    </div>
  );
};

export default PreviewTilesHomeScreen;
