import React, { PropsWithChildren, ReactNode, useContext, useState } from 'react';
import { OrderDetailModel } from 'src/model/OrderDetail.model';
import { getOrderDetail } from 'src/service/OrderAPI';

class ContextState {
  order!: OrderDetailModel;

  loadDetail!: (id: string) => Promise<any>;

  loading = false;
}

export const OrderDetailContext = React.createContext(new ContextState());

const OrderDetailProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderDetailModel>(new OrderDetailModel());

  const loadDetail = async (id: string): Promise<any> => {
    try {
      setLoading(true);
      const body = await getOrderDetail(id);
      setOrder(body);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <OrderDetailContext.Provider
      value={{
        order,
        loadDetail,
        loading,
      }}
    >
      {children}
    </OrderDetailContext.Provider>
  );
};

export const useOrderDetailContext = () => useContext(OrderDetailContext);

export default OrderDetailProvider;
