import React, { useState, useEffect } from 'react';
import { Carousel } from '@care/web-ui';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';

import './PreviewBanners.scss';

interface BannerProps {
  banners: HomeScreenItem[];
  showTotal?: boolean;
}

const PreviewBanners = ({ banners, showTotal }: BannerProps) => {
  const [displayedBanners, setDisplayedBanners] = useState<HomeScreenItem[]>([]);
  const total = displayedBanners.length;

  useEffect(() => {
    const displayedBanner = banners.filter((banner) => banner.visible);
    setDisplayedBanners(displayedBanner);
  }, [banners]);
  return (
    <div className={`${showTotal ? 'previewBanners large' : 'previewBanners'}`}>
      {showTotal && (
        <div className="total">
          {total} {`${total > 1 ? 'images' : 'image'}`} shown.
        </div>
      )}
      <Carousel>
        {displayedBanners.map((banner, index) => (
          <img src={banner.mediaUrl} alt="" key={index} />
        ))}
      </Carousel>
    </div>
  );
};

export default PreviewBanners;
