import { CatalogStore, useCatalogStore } from 'src/zustand-store/catalog/Catalog.store';
import CatalogService from 'src/service/Catalog.service';
import { ProductSortBy } from 'src/enum/ProductSortBy.enum';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import { shallow } from '@care/web-ui/lib/store';
import { Utils } from '@care/web-ui';

const {
  CommonUtil: { pick },
} = Utils;

export const useQueryCatalogByKeyword = (): {
  queryCatalogByKeyword: (keyword?: string) => void;
} => {
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('country');
  const { fetchData } = useCatalogStore((store: CatalogStore) => pick(store, 'fetchData'), shallow);

  const queryCatalogByKeyword = (keyword?: string) => {
    fetchData({
      fetcher: () =>
        CatalogService.getEcProducts({
          keyword,
          sortBy: ProductSortBy.NameAscend,
          pageIndex: DEFAULT_PAGE_INDEX,
          pageSize: DEFAULT_PAGE_SIZE,
          countryCode: country,
        }),
    });
  };

  return {
    queryCatalogByKeyword,
  };
};
