import React, { useMemo } from 'react';
import { Table, Typography, Utils } from '@care/web-ui';
import { OrderType } from 'src/enum/OrderStatus';
import { Amount } from 'src/model/Amount.model';
import { RefundAppointmentDTO, RefundProductDTO } from 'src/model/dto/RefundDetail.response';
import OrderDetailsInformationFooterTable from 'src/module/refund-detail/order-details-information-footer-table/OrderDetailsInformationFooterTable';
import { useOrderDetailContext } from 'src/context/OrderDetail.context';
import { RefundOrderItemModel } from 'src/model/RefundOrderItem.model';

const {
  CommonUtil: { isEqual },
} = Utils;

type OrderItems = Omit<RefundOrderItemModel, 'appointmentBookings'> & {
  appointmentBookings: AppointmentItems[];
  recipient?: { id: string; fullName: string };
};

type AppointmentItems = Pick<RefundAppointmentDTO, 'bookingNo' | 'startTime' | 'status' | 'patientSnapshot'>;

interface Props {
  id: string;
  orderType: OrderType;
  paymentStatus: any;
}

const OrderLineItem = ({ id }: Props) => {
  const { order, loading } = useOrderDetailContext();
  const orderItems: OrderItems[] = React.useMemo(
    () =>
      order?.orderItems?.map((item) => ({
        ...item,
        appointmentBookings: item.appointmentBookings?.map((booking) => ({
          ...booking,
          patientSnapshot: { ...booking.patient },
        })),
      })) || [],
    [order],
  );
  const orderRecipientName = orderItems[0]?.recipient?.fullName || order.consumer?.fullName;

  const columns = useMemo(() => {
    return [
      {
        title: 'Order Line ID',
        dataIndex: 'orderItemNumber',
        key: 'orderItemNumber',
        render: (value: string) => (
          <Typography level={9} className="py-4">
            {value}
          </Typography>
        ),
      },
      {
        title: 'Recipient',
        dataIndex: 'recipient',
        key: 'recipient',
        render: (recipient: { id: string; fullName: string }) => {
          const recipientName = recipient?.fullName || orderRecipientName;
          return <Typography level={9}>{recipientName}</Typography>;
        },
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (quantity: string) => <Typography level={9}>{quantity}</Typography>,
      },
      {
        title: 'Product Name',
        dataIndex: 'product',
        key: 'product',
        render: (value: RefundProductDTO) => <Typography level={9}>{value.name}</Typography>,
      },

      {
        title: 'Amount',
        dataIndex: 'price',
        key: 'price',
        render: ({ valueWithSymbol }: Amount) => <Typography level={9}>{valueWithSymbol}</Typography>,
      },
    ];
  }, [orderRecipientName]);

  return (
    <div>
      <Table
        columns={columns}
        data={orderItems}
        loading={loading}
        footer={() => <OrderDetailsInformationFooterTable id={id} />}
      />
    </div>
  );
};

export default React.memo(OrderLineItem, (prevProps, nextProps) => isEqual(prevProps, nextProps));
