import Config from '../Config';
import { APIBase, makeQueryString } from '../util/Request';
import { PromoCodeFilter } from 'src/context/PromoCodeList.context';
import { PromoCodeModelDTO, PromoCodeSummaryResDTO } from 'src/model/dto/PromoCode.response';
import { PromoCodeModel } from 'src/model/PromoCode.model';
import { CreatePromoCodeModelDTO } from 'src/model/dto/CreatePromoCode.request';
import { PromoCodeDetailModelDTO } from 'src/model/dto/PromoCodeDetail.response';
import { PromoCodeListMode } from 'src/enum/PromoCodeListMode';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

const URL = `${Config.API_URL}/promotions`;

export class PromoCodeAPI {
  getPromoCodes = async (
    filter: PromoCodeFilter,
    pageIndex: number,
    pageSize: number,
  ): Promise<GeneralListResponse<PromoCodeModel>> => {
    const { search, codeTypes = [], orgIds = [], mode, ...rest } = filter;

    const orgIdsQuery = orgIds.reduce((acc, current) => (acc += `&organizationIds[]=${current}`), '');

    let active = undefined;
    mode === PromoCodeListMode.Active && (active = true);
    mode === PromoCodeListMode.Inactive && (active = false);

    const url =
      `${URL}?${makeQueryString({
        ...rest,
        code: search,
        codeType: codeTypes.length === 1 ? codeTypes[0] : undefined,
        pageIndex,
        pageSize,
        active,
      })}` + orgIdsQuery;

    const res = await APIBase.get<GeneralListResponse<PromoCodeModelDTO>>(url);
    const { data, total } = res.data;

    return {
      total,
      data: data.map((item) => PromoCodeModel.convertFromDTO(item)),
    };
  };

  getPromoCodeSummary = async (): Promise<PromoCodeSummaryResDTO> => {
    const res = await APIBase.get<PromoCodeSummaryResDTO>(`${URL}/summary`);
    return res.data;
  };

  addPromoCode = async (data: CreatePromoCodeModelDTO): Promise<any> => {
    const res = await APIBase.post(`${URL}`, data);
    return res;
  };

  getPromoCodeById = async (id: string): Promise<PromoCodeDetailModelDTO> => {
    const res = await APIBase.get<PromoCodeDetailModelDTO>(`${URL}/${id}`);

    return res.data;
  };

  editPromoCode = async (id: string, data: any): Promise<any> => {
    const res = await APIBase.put(`${URL}/${id}`, data);
    return res;
  };

  deletePromoCode = async (id: string): Promise<void> => {
    await APIBase.delete(`${URL}/${id}`);
  };
}

export default new PromoCodeAPI();
