import React from 'react';

import './Divider.scss';

interface Props {
  marginVertical?: number;
}

const Divider = ({ marginVertical = 32 }: Props) => (
  <div className="item" style={{ marginTop: marginVertical, marginBottom: marginVertical }} />
);

export default Divider;
