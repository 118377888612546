import config from 'src/Config';
import { APIBase, makeQueryString } from '../util/Request';
import { PlatformSetting, PlatformSettingFilter, PlatformSettingModel } from 'src/model/PlatformSetting.model';
import { Builder } from 'builder-pattern';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

const URL = `${config.ADMIN_API_URL}/settings`;

class PlatformSettingService {
  async getPlatformSettings(filter: PlatformSettingFilter): Promise<GeneralListResponse<PlatformSettingModel>> {
    const url = `${URL}?${makeQueryString(filter)}`;

    const body = await APIBase.get<GeneralListResponse<PlatformSettingModel>>(url);

    const { data, ...rest } = body.data;

    return {
      ...rest,
      data: data.map((platformSetting) => Builder(PlatformSettingModel, platformSetting).build()),
    };
  }

  async updatePlatformSetting(platformSetting: PlatformSetting): Promise<void> {
    await APIBase.put(URL, platformSetting);
  }

  async createPlatformSetting(platformSetting: PlatformSetting): Promise<void> {
    await APIBase.post(URL, platformSetting);
  }
}

export default new PlatformSettingService();
