import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';
import config from 'src/Config';
import { AccountListFilter } from 'src/context/AccountList.context';
import { Role } from 'src/enum/Role.enum';
import { ProviderDetail } from 'src/model/ProviderDetail.model';
import { APIBase, makeQueryString } from 'src/util/Request';

const URL = `${config.ADMIN_API_URL}/providers`;

class ProviderService {
  async getProviders(
    filter: AccountListFilter,
    pageIndex: number,
    pageSize: number,
  ): Promise<GeneralListResponse<ProviderDetail>> {
    const url = `${URL}?${makeQueryString({
      orgId: filter.orgId,
      pageIndex,
      pageSize,
    })}`;

    const body = await APIBase.get<GeneralListResponse<ProviderDetail>>(url);

    return body.data;
  }

  async getProviderDetail(providerId: string): Promise<ProviderDetail> {
    const body = await APIBase.get<ProviderDetail>(`${URL}/${providerId}`);
    return body.data;
  }

  async updateProviderInfo(providerId: string, role: Role, name: string): Promise<any> {
    const body = await APIBase.put<boolean>(`${URL}/${providerId}`, { role, name });
    return body.data;
  }
}

export default new ProviderService();
