import { Utils } from '@care/web-ui';
import Config from '../Config';
import { APIBase, APIResponse, makeQueryString } from '../util/Request';
import { CreateOrEditMembershipsModelDTO } from 'src/model/dto/CreateOrEditMembership.request';
import { MembershipDetailModel } from 'src/model/MembershipDetail.model';
import { MembershipModel, SimpleMembershipPlanListFilter } from 'src/model/Membership.model';
import { MembershipDetailModelDTO } from 'src/model/dto/MembershipDetail.response';
import { MembershipFilter } from 'src/context/MembershipList.context';
import { MemberListItemModel } from 'src/model/MemberListItemModel';
import { MemberListItemDTO } from 'src/model/dto/MemberList.response';
import { MemberListFilter } from 'src/context/MemberList.context';
import { CreateMemberDTO, EditMemberDTO } from 'src/model/dto/CreateOrEditMember.request';
import { MemberDetailModel } from 'src/model/MemberDetail.model';
import { BulkUploadResDTO } from 'src/model/dto/BulkUpload.response';
import { BulkUploadModel } from 'src/model/BulkUpload.model';
import { CreateDependantDTO, EditDependantDTO } from 'src/model/dto/CreateOrEditDependant.request';
import { SimpleMembershipPlanItem } from 'src/model/dto/SimpleMembershipPlanList.response';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

const {
  CommonUtil: { omit, pick },
} = Utils;

const URL = `${Config.API_URL}/memberships`;
const ADMIN_URL = `${Config.ADMIN_API_URL}/memberships`;

export class MembershipAPI {
  getMemberships = async (
    filter: MembershipFilter,
    pageIndex: number,
    pageSize: number,
  ): Promise<GeneralListResponse<MembershipModel>> => {
    const url = `${URL}?${makeQueryString({
      ...omit(filter, ['search', `${filter.active !== null ? '' : 'active'}`]), // choose all statuses then query not contain active query
      q: filter.search,
      pageIndex,
      pageSize,
    })}`;

    const res = await APIBase.get<GeneralListResponse<MembershipModel>>(url);
    const { data, total } = res.data;

    return {
      total,
      data,
    };
  };

  getMembershipById = async (id: string): Promise<MembershipDetailModel> => {
    const res = await APIBase.get<MembershipDetailModelDTO>(`${URL}/${id}`);

    return MembershipDetailModel.convertFromDTO(res.data);
  };

  addMembership = async (data: CreateOrEditMembershipsModelDTO): Promise<string> => {
    const res: APIResponse<{ id: string }> = await APIBase.post(`${URL}`, data);
    return res.data.id;
  };

  editMembership = async (id: string, data: CreateOrEditMembershipsModelDTO): Promise<any> => {
    const res = await APIBase.put(`${URL}/${id}`, data);
    return res;
  };

  deleteMembership = async (id: string): Promise<void> => {
    await APIBase.delete(`${URL}/${id}`);
  };

  updateMembershipStatus = async (id: string, active: boolean): Promise<any> => {
    const res = await APIBase.put(`${URL}/${id}/status`, { active });
    return res;
  };

  getMembersById = async (
    filter: MemberListFilter,
    pageIndex: number,
    pageSize: number,
    id: string,
  ): Promise<GeneralListResponse<MemberListItemModel>> => {
    const url = `${URL}/${id}/members?${makeQueryString({
      ...omit(filter, [
        'search',
        `${filter.idTypes === null ? 'idTypes' : ''}`,
        `${filter.statuses === null ? 'statuses' : ''}`,
      ]),
      q: filter.search,
      pageIndex,
      pageSize,
    })}`;

    const res = await APIBase.get<GeneralListResponse<MemberListItemDTO>>(url);
    const { data, total } = res.data;

    return {
      total,
      data,
    };
  };

  addMember = async (membershipId: string, data: CreateMemberDTO): Promise<string> => {
    const res: APIResponse<{ id: string }> = await APIBase.post(`${URL}/${membershipId}/members`, data);
    return res.data.id;
  };

  editMember = async (membershipId: string, data: EditMemberDTO): Promise<boolean> => {
    const { id, ...rest } = data;
    await APIBase.put(`${URL}/${membershipId}/members/${id}`, rest);
    return true;
  };

  getMember = async (membershipId: string, id: string): Promise<MemberDetailModel> => {
    const res: APIResponse<MemberDetailModel> = await APIBase.get(`${URL}/${membershipId}/members/${id}`);
    return res.data;
  };

  sendInvitation = async (membershipId: string, id: string): Promise<any> => {
    const res = await APIBase.post(`${URL}/${membershipId}/members/${id}/send-invitation`);
    return res;
  };

  sendBulkInvitation = async (membershipId: string, invites: { id: string }[], isDependant: boolean): Promise<any> => {
    const res = await APIBase.post(`${URL}/members/send-invitation`, {
      members: invites,
      isDependant,
    });
    return res;
  };

  deleteMember = async (membershipId: string, id: string): Promise<any> => {
    const res = await APIBase.delete(`${URL}/${membershipId}/members/${id}`);
    return res;
  };

  uploadMembers = async (
    membershipId: string,
    membershipDisplayName: string,
    files: string | Blob,
    isDependant: boolean,
  ): Promise<BulkUploadModel> => {
    const url = isDependant ? `${URL}/${membershipId}/dependants/bulk` : `${URL}/${membershipId}/bulk`;

    const res: APIResponse<BulkUploadResDTO> = await APIBase.uploadFiles(url, files);

    return BulkUploadModel.convertFromDTO(res.data, membershipDisplayName);
  };

  addDependant = async (membershipId: string, memberId: string, data: CreateDependantDTO): Promise<string> => {
    const res: APIResponse<{ id: string }> = await APIBase.post(
      `${URL}/${membershipId}/members/${memberId}/dependants`,
      data,
    );
    return res.data.id;
  };

  editDependant = async (membershipId: string, memberId: string, data: EditDependantDTO): Promise<string> => {
    const { id, ...rest } = data;

    const res: APIResponse<{ id: string }> = await APIBase.put(
      `${URL}/${membershipId}/members/${memberId}/dependants/${id}`,
      rest,
    );
    return res.data.id;
  };

  deleteDependant = async (membershipId: string, id: string): Promise<void> => {
    await APIBase.delete(`${URL}/${membershipId}/dependants/${id}`);
  };

  getSimpleMembershipPlanList = async (filter: SimpleMembershipPlanListFilter): Promise<SimpleMembershipPlanItem[]> => {
    const response: APIResponse<SimpleMembershipPlanItem[]> = await APIBase.get(
      `${ADMIN_URL}/simple-list?${makeQueryString({
        ...pick(filter, 'q'),
      })}`,
    );
    return response.data;
  };
}

export default new MembershipAPI();
