import { shallow } from '@care/web-ui/lib/store';
import { useEffect } from 'react';
import PlatformSettingsService from 'src/service/PlatformSettings.service';
import {
  PlatformSettingListStore,
  usePlatformSettingStore,
} from 'src/zustand-store/platform-setting/PlatformSettingList.store';
import { careNotification, Utils } from '@care/web-ui';
import { PlatformSetting } from 'src/model/PlatformSetting.model';
import { UseMutation } from '@care/web-ui/lib/store';

const {
  CommonUtil: { pick },
} = Utils;

const { useMutation } = UseMutation;

export const useFetchPlatformSettingList = () => {
  const { filter, fetchData: fetchDataQuery } = usePlatformSettingStore(
    (store: PlatformSettingListStore) => pick(store, ['filter', 'fetchData'] as Array<keyof PlatformSettingListStore>),
    shallow,
  );

  const fetchPlatformSetting = () => {
    fetchDataQuery({ fetcher: () => PlatformSettingsService.getPlatformSettings(filter) });
  };

  useEffect(() => {
    fetchPlatformSetting();
  }, [filter]);
  return { fetchPlatformSetting };
};

export const useUpdatePlatformSetting = () => {
  const { mutateAsync: createSetting, isLoading: isCreatingSetting } = useMutation({
    mutationFn: async (platformSetting: PlatformSetting) => {
      await PlatformSettingsService.createPlatformSetting(platformSetting);
    },
    onSuccess: () => {
      careNotification({
        type: 'success',
        message: 'Created setting successfully!',
        duration: 5,
      });
    },
    onError: () => {
      careNotification({
        type: 'error',
        message: 'Create setting failed!',
        duration: 5,
      });
    },
  });

  const { mutateAsync: updateSetting, isLoading: isUpdatingSetting } = useMutation({
    mutationFn: async (platformSetting: PlatformSetting) => {
      await PlatformSettingsService.updatePlatformSetting(platformSetting);
    },
    onSuccess: () => {
      careNotification({
        type: 'success',
        message: 'Updated setting successfully!',
        duration: 5,
      });
    },
    onError: () => {
      careNotification({
        type: 'error',
        message: 'Update setting failed!',
        duration: 5,
      });
    },
  });

  return { createSetting, updateSetting, isCreatingSetting, isUpdatingSetting };
};
