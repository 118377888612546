import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';
import { Builder } from 'builder-pattern';
import config from 'src/Config';
import { OrganizationFilter } from 'src/context/OrganizationListWithPagination.context';
import { OrganizationModel } from 'src/model/OrganizationModel';
import { APIBase, makeQueryString } from 'src/util/Request';

const URL = `${config.ADMIN_API_URL}/organizations`;

class OrganizationAPI {
  async getOrganizations(
    filter: OrganizationFilter,
    pageIndex: number,
    pageSize: number,
  ): Promise<GeneralListResponse<OrganizationModel>> {
    const { orgIds = [], ...restFilter } = filter;

    const url = `${URL}?${makeQueryString({
      ...restFilter,
      ids: orgIds.length ? orgIds : undefined,
      pageIndex: filter.displayName ? 1 : pageIndex,
      pageSize,
    })}`;

    const body = await APIBase.get<GeneralListResponse<OrganizationModel>>(url);

    const { data, ...rest } = body.data;

    return { ...rest, data: data.map((d) => Builder(OrganizationModel, d).build()) };
  }

  async updateOrganisationStatus(orgId: string, status: string): Promise<any> {
    return APIBase.put(`${URL}/${orgId}/change-status`, { status });
  }

  async loadOrganisationDetail(orgId: string): Promise<OrganizationModel> {
    const res = await APIBase.get<OrganizationModel>(`${URL}/${orgId}`);
    return res.data;
  }

  async importMedicationList(orgId: string): Promise<any> {
    return APIBase.post(`${URL}/import-medication`, { orgId });
  }
}

export default new OrganizationAPI();
