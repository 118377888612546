import { PaymentStatus } from 'src/enum/PaymentStatus';

type TagInfo = {
  type: string;
  label: string;
};

type AppointmentPaymentStatusTypeTag = {
  [key in PaymentStatus]: TagInfo;
};

export const paymentStatusTypeTag: AppointmentPaymentStatusTypeTag = {
  [PaymentStatus.Cancelled]: { type: 'Error', label: 'Cancelled' },
  [PaymentStatus.Failed]: { type: 'Error', label: 'Failed' },
  [PaymentStatus.Pending]: { type: 'Error', label: 'Pending' },
  [PaymentStatus.Success]: { type: 'Success', label: 'Paid' },
  [PaymentStatus.PayToPartner]: { type: 'Warning', label: 'Pay to Partner' },
};
