import React, { useContext, useEffect, useState } from 'react';
import { Modal, Typography, RadioButtonGroup, Row, Input } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';

import './ApproveRefundModal.scss';
import { RefundsContext } from 'src/context/Refunds.context';
import Spacer from 'src/component/Spacer/Spacer';
import { RadioItemOption } from '@care/web-ui/lib/components/RadioButtonGroup/RadioItem';
import Divider from 'src/component/Divider/Divider';
import { TransactionRow } from './TransactionRow';
import { RefundType } from 'src/enum/refundType.enum';
import { RefundDetailModel } from 'src/model/RefundDetail.model';

const { getCurrencySymbol } = Utils.CurrencyUtil;

const refundTypeOptions: RadioItemOption[] = [
  {
    label: 'Standard',
    value: RefundType.standard,
  },
  {
    label: 'Custom Amount',
    value: RefundType.noneStandard,
  },
];

interface Props {
  visible: boolean;
  onDone: () => any;
  onClose: () => any;
  item: RefundDetailModel;
}

export const ApproveSingleRefund = ({ visible, onClose, onDone, item }: Props) => {
  const { loading, refund } = useContext(RefundsContext);
  const [refundType, setRefundType] = useState<RefundType>(RefundType.standard);
  const [cancelFee, setCancelFee] = useState('');
  const [refundAmount, setRefundAmount] = useState('');
  const [isError, setIsError] = useState(false);
  const currencyWithSymbol = getCurrencySymbol(item?.amount.currency);

  const checkIsValid = (refundAmount: number, cancelFee: number) => {
    return refundAmount > 0 && refundAmount <= item?.amount.value - item?.previouslyRefunded.value - cancelFee;
  };

  useEffect(() => {
    if (item) {
      const { fee, approveRefundAmount } = item;
      setCancelFee(fee.value.toString());
      setRefundAmount(approveRefundAmount.value.toString());
    }
  }, [item]);

  const onRefundTypeChange = (value: RefundType) => {
    setRefundType(value);
    if (value === RefundType.standard) {
      setIsError(false);
      setRefundAmount(item.approveRefundAmount.value.toString());
      setCancelFee(item.fee.value.toString());
    }
  };

  const onCancelFeeChange = (value: string) => {
    setIsError(false);
    setCancelFee(value);
  };

  const onRefundAmountChange = (value: string) => {
    setIsError(false);
    setRefundAmount(value);
  };

  const onRefund = async () => {
    const isValid = checkIsValid(Number.parseFloat(refundAmount), Number.parseFloat(cancelFee));
    setIsError(!isValid);

    if (isValid) {
      const done = await refund(item.id, Number.parseFloat(cancelFee), Number.parseFloat(refundAmount), refundType);
      if (done) {
        onDone();
        onClose();
      }
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Approve Refund"
      okText="Refund"
      okButtonProps={{ loading, disabled: isError }}
      onOk={onRefund}
      content={
        <div>
          <RadioButtonGroup
            value={refundType}
            onChange={onRefundTypeChange}
            direction="vertical"
            label="Refund Type"
            options={refundTypeOptions}
          />
          <Spacer size={22} />
          <Typography level={8}>
            Please review the following details. Funds will be processed after approving the refund.
          </Typography>
          <Spacer size={16} />
          <div className="card-refund">
            <TransactionRow name="Payment Amount" amount={item?.amount.valueWithSymbol} />
            {item?.previouslyRefunded.value > 0 && (
              <TransactionRow name="Previously Refunded Amount" amount={item?.previouslyRefunded.valueWithSymbol} />
            )}
            {refundType === RefundType.standard ? (
              <TransactionRow name="Cancellation Fee" amount={item?.fee.valueWithSymbol} />
            ) : (
              <Row gutter={8} spans={[16, 8]}>
                <Typography level={8} weight="bold" display="block">
                  Cancellation Fee
                </Typography>
                <div className="currency-input">
                  <Typography display="inline" level={8}>
                    {currencyWithSymbol}
                  </Typography>
                  <Input min="0" style={{ width: 111 }} type="number" value={cancelFee} onChange={onCancelFeeChange} />
                </div>
              </Row>
            )}
            <Divider marginVertical={12} />
            {refundType === RefundType.standard ? (
              <TransactionRow name="Amount to Refund" amount={item?.approveRefundAmount.valueWithSymbol} />
            ) : (
              <Row gutter={8} spans={[16, 8]}>
                <Typography level={8} weight="bold" display="block">
                  Amount to Refund
                </Typography>
                <div className="currency-input">
                  <Typography display="inline" level={8}>
                    {currencyWithSymbol}
                  </Typography>
                  <Input
                    min="0"
                    value={refundAmount}
                    onChange={onRefundAmountChange}
                    style={{ width: 111 }}
                    type="number"
                  />
                </div>
              </Row>
            )}
            {isError && (
              <Typography className="errText" level={10}>
                Amount to refund must be greater than 0 and less than Payment Amount subtract fee and previously
                refunded
              </Typography>
            )}
          </div>
        </div>
      }
    />
  );
};
