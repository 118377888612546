import React from 'react';
import { Table, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import { DateFormat } from 'src/constant/date.constant';
import { RefundModel } from 'src/model/Refund.model';
import './RefundTable.scss';
import { mappingRefundRequestedBy } from 'src/constant/Refund';
import { RefundRequestedBy } from 'src/enum/RefundRequestedBy';

const {
  DateUtil: { formatDateString },
  CommonUtil: { capitalize },
} = Utils;

interface Props {
  refund: RefundModel;
}

const ViewRefundItemTable = ({ refund }: Props) => {
  const { refundItemDetail } = refund;

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'consumerName',
      render: (value: string) => <Typography level={9}>{value}</Typography>,
    },
    {
      title: 'Appointment Date',
      dataIndex: 'appointmentDates',
      render: (dates: string[]) => (
        <>
          {dates.map((d) => (
            <Typography key={d} level={9}>
              {formatDateString(d)}
            </Typography>
          ))}
        </>
      ),
    },
    {
      title: 'Non-cancellation Date & Time',
      dataIndex: 'nonCancellationDateTimes',
      render: (dates: string[]) => (
        <>
          {dates.map((d) => (
            <Typography key={d} level={9}>
              {d ? formatDateString(d, DateFormat.DATE_TIME) : ''}
            </Typography>
          ))}
        </>
      ),
    },
    {
      title: 'Process Type',
      dataIndex: 'processType',
      render: (value: string) => <Typography level={9}>{capitalize(value)}</Typography>,
    },
    {
      title: 'Requested By',
      dataIndex: 'requestedBy',
      render: (value: RefundRequestedBy) => <Typography level={9}>{mappingRefundRequestedBy[value]}</Typography>,
    },
    {
      title: 'Processed By',
      dataIndex: 'processedBy',
      render: (value: string) => <Typography level={9}>{capitalize(value)}</Typography>,
    },
  ];

  return (
    <div className="refundItemTable">
      <Table columns={columns} data={[refundItemDetail]} />
    </div>
  );
};

export default ViewRefundItemTable;
