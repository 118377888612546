import React from 'react';
import { Typography } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';

import { OrdersTable } from './orders-table/OrdersTable';
import { OrdersTableToolbar } from './orders-table-toolbar/OrdersTableToolbar';

import './OrdersStyle.scss';

const Orders = () => {
  return (
    <div className="orders-page">
      <div className="head-title">
        <Typography fontCategory="serif" level={3}>
          Orders
        </Typography>
      </div>

      <Spacer size={32} />

      <OrdersTableToolbar />

      <Spacer size={12} />

      <OrdersTable />
    </div>
  );
};

export default Orders;
