import { Typography } from '@care/web-ui';
import React, { useEffect } from 'react';
import Spacer from 'src/component/Spacer/Spacer';
import DeepLinksTableToolbar from './deep-links-table-toolbar/DeepLinksTableToolbar';
import DeepLinksTable from './deep-links-table/DeepLinksTable';
import { useFetchDeepLinkList } from './deep-links-table/DeepLinksTable.actions';

const DeepLinks = () => {
  const { fetchDeepLink } = useFetchDeepLinkList();

  useEffect(() => {
    fetchDeepLink();
  }, []);

  return (
    <div className="p-11">
      <div>
        <Typography fontCategory="serif" level={3}>
          Deep Links
        </Typography>
      </div>

      <Spacer size={32} />

      <DeepLinksTableToolbar />

      <Spacer size={12} />

      <DeepLinksTable />
    </div>
  );
};

export default DeepLinks;
