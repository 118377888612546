import config from 'src/Config';
import { APIBase, makeQueryString } from '../util/Request';
import { Builder } from 'builder-pattern';
import { UserFilter, UserModel } from 'src/model/User.model';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

const URL = `${config.ADMIN_API_URL}/users`;

class UserService {
  async getUsers(filter: UserFilter): Promise<GeneralListResponse<UserModel>> {
    const url = `${URL}?${makeQueryString(filter)}`;

    const body = await APIBase.get<GeneralListResponse<UserModel>>(url);

    const { data, ...rest } = body.data;

    return {
      ...rest,
      data: data.map((user) => Builder(UserModel, user).build()),
    };
  }
}

export default new UserService();
