import React, { useCallback } from 'react';
import { Icon, Table, Typography } from '@care/web-ui';
import { Amount } from 'src/model/Amount.model';
import { RefundAppointmentDTO, RefundOrderItemsDTO, RefundProductDTO } from 'src/model/dto/RefundDetail.response';
import ViewOrderItemApptTable from './ViewOrderItemAppointmentTable';
import { RefundOrderItemModel } from 'src/model/RefundOrderItem.model';
import { OrderType } from 'src/enum/OrderStatus';
import OrderDetailsInformationFooterTable from '../order-details-information-footer-table/OrderDetailsInformationFooterTable';
import { ConsumerModel } from 'src/model/Consumer.model';

type AppointmentItems = Pick<RefundAppointmentDTO, 'bookingNo' | 'startTime' | 'status' | 'patientSnapshot'>;

type OrderItems = Omit<RefundOrderItemModel, 'appointmentBookings'> & {
  appointmentBookings: AppointmentItems[];
  recipient?: { id: string; fullName: string };
};

interface Props {
  orderItems: OrderItems[];
  orderType?: OrderType;
  id?: string;
  consumer?: ConsumerModel;
}

const OrderItemTable = ({ orderItems, orderType, id, consumer }: Props) => {
  const orderRecipientName = orderItems[0]?.recipient?.fullName || consumer?.fullName;

  const columns = [
    {
      title: 'Order Line ID',
      dataIndex: 'orderItemNumber',
      key: 'orderItemNumber',
      render: (value: string) => <Typography level={9}>{value}</Typography>,
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient',
      render: (recipient: { id: string; fullName: string }) => {
        const recipientName = recipient?.fullName || orderRecipientName;
        return <Typography level={9}>{recipientName}</Typography>;
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity: string) => <Typography level={9}>{quantity}</Typography>,
    },
    {
      title: 'Product Name',
      dataIndex: 'product',
      key: 'product',
      render: (value: RefundProductDTO) => <Typography level={9}>{value.name}</Typography>,
    },

    {
      title: 'Amount',
      dataIndex: 'price',
      key: 'price',
      render: ({ valueWithSymbol }: Amount) => <Typography level={9}>{valueWithSymbol}</Typography>,
    },
  ];

  const footer = useCallback(() => {
    return <OrderDetailsInformationFooterTable id={id} />;
  }, [id]);

  return (
    <div>
      <Table
        columns={columns}
        data={orderItems}
        expandable={{
          expandedRowRender: (data: RefundOrderItemsDTO) => {
            return <ViewOrderItemApptTable refundAppts={data.appointmentBookings} />;
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            orderType === OrderType.Product ? null : expanded ? (
              <Icon name="DownSmall" type="outline" onClick={() => onExpand(record, null)} />
            ) : (
              <Icon name="RightSmall" type="outline" onClick={() => onExpand(record, null)} />
            ),
        }}
        footer={footer}
      />
    </div>
  );
};

export default OrderItemTable;
