import { BaseFilter, BasicSortBy, createPagingDataContext } from 'src/util/createPagingDataContext';
import LocationAPI from 'src/service/LocationAPI';
import { LocationModel } from 'src/model/Location.model';

export interface LocationFilter extends BaseFilter<BasicSortBy> {
  orgIds: string[];
}

const fetcher = async (filter: LocationFilter, pageIndex: number, pageSize: number) => {
  const rs = await LocationAPI.getLocations(filter, pageIndex, pageSize);

  return rs;
};

const [LocationListProvider, useLocationListContext] = createPagingDataContext<LocationModel, LocationFilter>(fetcher, {
  pageIndex: 1,
  pageSize: 100,
});

export { useLocationListContext };

export default LocationListProvider;
