import React, { useState } from 'react';
import { Button } from '@care/web-ui';
import { useOrderDetailContext } from 'src/context/OrderDetail.context';
import { PaymentMethodType } from 'src/enum/PaymentMethod';
import { PaymentStatus } from 'src/enum/PaymentStatus';
import RequestRefundModal from '../request-refund-modal/RequestRefundModal';
import { RefundStatus } from 'src/enum/RefundStatus';

const pendingStatuses = [RefundStatus.Requesting, RefundStatus.InProgress];

const RequestRefundActionButton = () => {
  const { order, loading } = useOrderDetailContext();
  const { paymentMethod, paymentStatus, refunds } = order || {};
  const [visible, setVisible] = useState(false);
  const hasRefund = React.useMemo(
    () => refunds && refunds.some((item) => pendingStatuses.includes(item.status)),
    [refunds],
  );
  const isRefundable = React.useMemo(
    () =>
      (paymentMethod === PaymentMethodType.Card || paymentMethod === PaymentMethodType.PayNow) &&
      paymentStatus === PaymentStatus.Success,
    [order],
  );

  if (loading) {
    return null;
  }

  if (!isRefundable || hasRefund) {
    return null;
  }

  return (
    <div>
      <Button title="Request Refund" onClick={() => setVisible(true)} />
      <RequestRefundModal visible={visible} onClose={() => setVisible(false)} onDone={() => true} />
    </div>
  );
};

export default RequestRefundActionButton;
