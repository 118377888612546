import { TagType } from '@care/web-ui/lib/components/Tag/Tag.d';
import { FeedbackStatus, FeedbackType } from 'src/enum/Feedback.enum';

export const feedbackStatusColorMapping: Record<FeedbackStatus, TagType> = {
  [FeedbackStatus.PENDING]: 'Warning',
  [FeedbackStatus.COMPLETED]: 'Success',
};

export const feedbackTypeColorMapping: Record<FeedbackType, TagType> = {
  [FeedbackType.POSITIVE]: 'Success',
  [FeedbackType.NEUTRAL]: 'Warning',
  [FeedbackType.NEGATIVE]: 'Error',
};
