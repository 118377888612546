import React, { ReactNode, useContext, useState } from 'react';
import { Modal } from '@care/web-ui';

import ProductAPI from 'src/service/ProductAPI';

class ContextState {
  loading: boolean;

  approveProduct!: (id: string) => Promise<boolean>;

  rejectProduct!: (id: string) => Promise<boolean>;
}

export const ProductActionContext = React.createContext(new ContextState());

const ProductActionProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = useState(false);

  const approveProduct = async (id: string): Promise<boolean> => {
    try {
      setLoading(true);
      await ProductAPI.approveProduct(id);
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const rejectProduct = async (id: string): Promise<boolean> => {
    try {
      setLoading(true);
      await ProductAPI.rejectProduct(id);
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProductActionContext.Provider
      value={{
        loading,
        approveProduct,
        rejectProduct,
      }}
    >
      {children}
    </ProductActionContext.Provider>
  );
};

export const useProductActionContext = () => useContext(ProductActionContext);

export default ProductActionProvider;
