import { Button, Icon, Input, Popover, Typography } from '@care/web-ui';
import React, { useEffect, useRef, useState } from 'react';
import { DeepLinkItem } from 'src/model/DeepLink.model';

const DeepLinkItemAction = ({ item }: { item: DeepLinkItem }) => {
  const [showQrCode, setShowQrCode] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const copyTimeout = useRef<ReturnType<typeof setInterval> | null>(null);

  const handleCopyDeeplink = () => {
    if (isCopied) return;

    navigator.clipboard.writeText(item.deepLink);
    setIsCopied(true);

    // reset state
    copyTimeout.current = setTimeout(() => setIsCopied(false), 2000);
  };

  const handleOpenQrCode = () => {
    setShowQrCode(!showQrCode);
  };

  const handlePopupVisibleChange = (visible: boolean) => {
    if (!visible) {
      setShowQrCode(false);
      setIsCopied(false);
    }
  };

  const deeplinkPopover = (
    <div className="relative flex flex-col">
      <div className="flex flex-col gap-2">
        <Typography level={8}>Deep link</Typography>
        <div className="flex items-center gap-2">
          <Input readOnly classNameInput="!rounded-lg" placeholder="Deeplink" value={item.deepLink} />
          <div
            className="flex h-10 min-w-[40px] cursor-pointer items-center justify-center rounded-lg bg-primary-5"
            onClick={handleCopyDeeplink}
          >
            <Icon className="text-primary-70" name={isCopied ? 'Tick' : 'Copy'} type="outline" />
          </div>

          <div
            className={`flex h-10 min-w-[40px] cursor-pointer items-center justify-center rounded-lg ${
              showQrCode ? 'bg-primary-6' : 'bg-primary-5'
            }`}
            onClick={handleOpenQrCode}
          >
            <Icon className={showQrCode ? 'text-white' : 'text-primary-70'} name="QrCode" type="outline" />
          </div>
        </div>
      </div>
      {showQrCode && (
        <div className="text-center">
          <img src={item.qrCode} alt="QR Code" width={160} height={160} />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    return () => {
      clearTimeout(copyTimeout.current);
    };
  }, []);

  return (
    <div>
      <Popover
        onVisibleChange={handlePopupVisibleChange}
        content={deeplinkPopover}
        trigger="click"
        placement="bottom"
        hideArrow
      >
        <Button title="Deep link" type="link" size="small" style={{ minWidth: 50 }} />
      </Popover>
    </div>
  );
};

export default DeepLinkItemAction;
