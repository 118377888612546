import Utils from '@care/web-ui/lib/utils';
import { BaseFilter, createPagingDataContext } from 'src/util/createPagingDataContext';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import { getOrders } from 'src/service/OrderAPI';
import { OrderSortBy } from 'src/enum/orderSortBy.enum';
import { OrderListItemModel } from 'src/model/OrderListItem.model';
import { OrderSearchByOption } from 'src/enum/orderSearchByOption.enum';
import { convertDateFilterToDateRange } from 'src/util/convertFilteredDate.util';
import { FilteredDate } from 'src/enum/filteredDate.enum';
import { OrderType } from 'src/enum/OrderStatus';

const { getPayloadDateString } = Utils.DateUtil;

export interface OrderFilter extends BaseFilter<OrderSortBy> {
  active?: boolean;
  searchBy?: OrderSearchByOption;
  orderTypes?: OrderType[];
  productIds?: string[];
}

const fetcher = async (filter: OrderFilter, pageIndex: number, pageSize: number) => {
  return getOrders(filter, pageIndex, pageSize);
};

const defaultFilterDate = convertDateFilterToDateRange(FilteredDate.Today);
const fromDate = getPayloadDateString(defaultFilterDate.fromDate);
const toDate = getPayloadDateString(defaultFilterDate.toDate, true);

const [OrdersProvider, useOrdersContext] = createPagingDataContext<OrderListItemModel, OrderFilter>(
  fetcher,
  {
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  { searchBy: OrderSearchByOption.OrderId, fromDate, toDate, productIds: [] },
);

export { useOrdersContext };

export default OrdersProvider;
