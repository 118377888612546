import { OrganizationStatus } from 'src/enum/OrganizationStatus';

type TagInfo = {
  type: string;
  label: string;
};

type OrganisationStatusTag = {
  [key in OrganizationStatus]: TagInfo;
};

export const organisationStatusTag: OrganisationStatusTag = {
  [OrganizationStatus.unverified]: { type: 'Warning', label: 'Unverified' },
  [OrganizationStatus.verified]: { type: 'Success', label: 'Verified' },
};
