export enum ProductAttributeName {
  AppointmentType = 'appointmentType',
  AppointmentDuration = 'appointmentDuration',
  AppointmentSlots = 'appointmentSlots',
  AppointmentChannel = 'appointmentChannel',
  PaymentMethods = 'paymentMethods',
  DisclaimerText = 'disclaimerText',
  DisclaimerLink = 'disclaimerLink',
  NumberOfAppointments = 'numberOfAppointments',
  Locations = 'locations',
  DaysBetweenAppointments = 'daysBetweenAppointments',
  Instruction = 'appointmentInstruction',
  AppointmentOutputs = 'appointmentOutputs',
  BundleProductItems = 'bundleProductItems',
  ResendConfirmationEmail = 'resendConfirmationEmail',
  OnlineConsultProduct = 'onlineConsultProduct',
  ProductImages = 'productImages',
  IsFulfilledProduct = 'isFulfilledProduct',
  RecordsProduct = 'recordsProduct',
  PreDeliveryAddress = 'preDeliveryAddress',
  IsSameLocation = 'isSameLocation',
  ApptStartOffsetDays = 'apptStartOffsetDays',
  ApptBookFromToday = 'appointmentBookFromToday',
  CompulsoryIdsRegistration = 'compulsoryIdsRegistration',
  CancellationFee = 'cancellationFee',
  NonCancellable = 'nonCancellable',
  NonCancellationWindow = 'nonCancellationWindow',
  NonReschedule = 'nonReschedulable',
  NonRescheduleWindow = 'nonRescheduleWindow',
  IsAppointmentOptional = 'isAppointmentOptional',
  NonRefundable = 'nonRefundable',
}
