/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Modal } from '@care/web-ui';
import { DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

interface Props {
  autoLoad?: boolean;
  id?: string;
}

type Fetcher<T, F> = (filter: F, pageIndex: number, pageSize: number, id?: string) => Promise<GeneralListResponse<T>>;

export interface Pagination {
  pageIndex: number;
  pageSize: number;
  total?: number;
  isOrganization?: boolean;
}

interface GenericContextState<M, F> {
  data: M[];

  loading: boolean;

  filter: F;

  loadData: () => Promise<M[]>;

  setFilter: (filter: F) => void;

  pagination: Pagination;

  setPagination: (pagination: Pagination) => void;
}

export type BasicSortBy = 'newest' | 'oldest';

export interface Sorter {
  field: string;
  order: 'ascend' | 'descend';
}

export interface BaseFilter<S> {
  fromDate?: string;
  toDate?: string;
  search?: string;
  keyword?: string;
  sortBy?: S;
}

type ContextHook<T, F> = () => GenericContextState<T, F>;

export const createPagingDataContext: <T, F>(
  fetcher: Fetcher<T, F>,
  initialPagination?: Pagination,
  initialFilter?: F,
) => [(props: React.PropsWithChildren<Props>) => React.ReactNode, ContextHook<T, F>] = (
  fetcher,
  initialPagination,
  initialFilter,
) => {
  const ListContext = createContext<GenericContextState<any, any>>({
    data: [],
    loading: false,
    filter: initialFilter || {},
    loadData: async () => [],
    setFilter: () => undefined,
    pagination: {
      pageIndex: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    setPagination: () => undefined,
  });

  return [
    ({ children, autoLoad, id }) => {
      const [loading, setLoading] = useState<boolean>(false);
      const [data, setData] = useState<any[]>([]);
      const [filter, setFilter] = useState<any>(initialFilter || {});
      const [pagination, setPagination] = useState<Pagination>({
        pageIndex: initialPagination.pageIndex || 1,
        pageSize: initialPagination.pageSize || 10,
      });

      const loadData = async () => {
        try {
          setLoading(true);
          const rs = await fetcher(filter, pagination.pageIndex, pagination.pageSize, id);
          setData(rs.data);

          if (!initialPagination.isOrganization) {
            setPagination({
              ...pagination,
              total: rs.total,
            });
          }
          return rs.data;
        } catch (error) {
          Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
          return [];
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        autoLoad && loadData();
        id && loadData();
      }, [autoLoad, id]);

      return (
        <ListContext.Provider
          value={{
            data,
            loading,
            loadData,
            filter,
            setFilter,
            pagination,
            setPagination,
          }}
        >
          {children}
        </ListContext.Provider>
      );
    },
    () => useContext(ListContext),
  ];
};
