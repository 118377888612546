import React, { useContext, useState } from 'react';
import { Typography, Tag, Table, Icon, Switch, Button, careNotification } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';

import { RefundStatus } from 'src/enum/RefundStatus';
import { refundStatusTypeTag } from 'src/constant/RefundStatusTag';

import './RefundTable.scss';
import { RefundsContext } from 'src/context/Refunds.context';
import { Amount } from 'src/model/Amount.model';
import { Link } from 'react-router-dom';
import { Routes } from 'src/constant';
import Spacer from 'src/component/Spacer/Spacer';
import { pageSizeOptions, PAGE_SIZE_PER_MODULE } from 'src/constant/Pagination.constant';
import { OrderStatus } from 'src/enum/OrderStatus';
import { orderStatusTypeTag } from 'src/constant/OrderStatusTag.constant';
import ViewRefundItemTable from './ViewRefundItemTable';
import { RefundModel } from 'src/model/Refund.model';
import { ApproveRejectActionsContext } from 'src/context/ApproveRejectActions.context';
import config from 'src/Config';

const { formatDateString } = Utils.DateUtil;

const REFUNDS_LIMIT = config.REFUNDS_LIMIT;

const columns = [
  {
    title: 'Refund ID',
    dataIndex: 'refundNumber',
    render: (value: string, refund: RefundModel) => (
      <Link to={`${Routes.Refunds}/${refund.id}`}>
        <Typography level={9} weight="bold" className="orderId">
          {value}
        </Typography>
      </Link>
    ),
  },
  {
    title: 'Product/s',
    dataIndex: 'productName',
    render: (value: string) => <Typography level={9}>{value}</Typography>,
  },
  {
    title: 'Total Paid',
    dataIndex: 'amount',
    render: (amount: Amount) => <Typography level={9}>{amount.valueWithSymbol}</Typography>,
  },
  {
    title: 'Cancel. Fee',
    dataIndex: 'fee',
    render: (fee: Amount) => <Typography level={9}>{fee.valueWithSymbol}</Typography>,
  },
  {
    title: 'Request Date',
    dataIndex: 'createdAt',
    render: (value: string) => <Typography level={9}>{formatDateString(value)}</Typography>,
  },
  {
    title: 'Order Status',
    dataIndex: 'orderStatus',
    render: (status: OrderStatus) => {
      return (
        <div className="action-group">
          <Tag text={orderStatusTypeTag[status]?.label} type={orderStatusTypeTag[status]?.type} />
        </div>
      );
    },
  },
  {
    title: 'Refund Status',
    dataIndex: 'status',
    render: (status: RefundStatus) => {
      return (
        <div className="action-group">
          <Tag text={refundStatusTypeTag[status]?.label} type={refundStatusTypeTag[status]?.type} />
        </div>
      );
    },
  },
];

export const RefundTable = () => {
  const {
    refunds,
    loading,
    totalRefunds,
    setFilter,
    filter,
    handleResetFilter,
    toggleBulkRefunds,
    setToggleBulkRefunds,
    loadRefunds,
  } = useContext(RefundsContext);

  const { openBulkRefunds } = useContext(ApproveRejectActionsContext);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const onSelectChange = (newSelectedRowKeys: string[]) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (newSelectedRowKeys.length > REFUNDS_LIMIT) {
      careNotification({
        type: 'error',
        message: (
          <Typography level={8} weight="bold">
            Unable to select item
          </Typography>
        ),
        description: <Typography level={7}>{`Only ${REFUNDS_LIMIT} requests can be processed at a time.`}</Typography>,
        duration: 2,
      });
    }
  };

  const handleToggle = (value: boolean) => {
    setToggleBulkRefunds(value);

    if (value) {
      setFilter({
        ...filter,
        statuses: [RefundStatus.Requesting],
        isBeforeCancellationDate: true,
        isNotPartial: true,
      });
    } else {
      handleResetFilter();
    }
  };

  const reload = () => {
    loadRefunds();
    setSelectedRowKeys([]);
  };

  const handleOpenBulkApproval = () => {
    openBulkRefunds(selectedRowKeys, reload);
  };

  return (
    <div className="refund-table">
      <div className="bulk-mode">
        <div className="switchBtn">
          <Typography level={9}>Bulk Refund Mode</Typography>
          <Switch value={toggleBulkRefunds} onChange={handleToggle} />
        </div>

        {toggleBulkRefunds && (
          <Button
            title="Approve Selected"
            onClick={handleOpenBulkApproval}
            disabled={selectedRowKeys.length === 0 || selectedRowKeys.length > REFUNDS_LIMIT}
          />
        )}
      </div>

      {selectedRowKeys.length > REFUNDS_LIMIT && (
        <Typography className="textError" level={9} display="block" align="right">
          {`${selectedRowKeys.length} items selected. Maximum of ${REFUNDS_LIMIT} requests can be processed at a time.`}
        </Typography>
      )}

      <Spacer size={24} />
      <Table
        rowSelection={
          toggleBulkRefunds
            ? {
                selectedRowKeys,
                preserveSelectedRowKeys: true,
                onChange: onSelectChange,
              }
            : null
        }
        rowKey="id"
        loading={loading}
        columns={columns}
        data={refunds}
        renderEmpty={
          <div>
            <img src="/refunds.png" style={{ width: 148 }} alt="refunds" />
            <Spacer />
            <Typography fontCategory="serif" level={7}>
              There are no refund requests yet
            </Typography>
          </div>
        }
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.REFUNDS,
          showSizeChanger: true,
          pageSizeOptions,
          total: totalRefunds,
          current: filter.pageIndex,
          pageSize: filter.pageSize,
          onChange: (pageIndex: number, pageSize: number) => {
            setFilter({ ...filter, pageIndex, pageSize });
          },
        }}
        expandable={{
          expandedRowRender: (refund: RefundModel) => (
            <div>
              <ViewRefundItemTable refund={refund} />
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) => (
            <Icon
              name={expanded ? 'DownSmall' : 'RightSmall'}
              type="outline"
              onClick={(e) => onExpand(record, e as any)}
            />
          ),
        }}
      />
    </div>
  );
};
