import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Input, Row, ComboBox, Utils } from '@care/web-ui';
import { RefundsContext, defaultFilter } from 'src/context/Refunds.context';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import RefundFilterToolbar from './RefundFilterToolbar';
import { RefundFilter } from 'src/service/RefundAPI';

import './RefundTableFilter.scss';

const {
  CommonUtil: { debounce, isEqual },
} = Utils;

export const SEARCH_BY = {
  REFUND_ID: 'refundNumber',
  ORDER_ID: 'orderNumber',
  CUSTOMER_NAME: 'consumerName',
  ID_NUMBER: 'identityNumber',
  MOBILE_NUMBER: 'phoneNumber',
  EMAIL_ADDRESS: 'email',
};

const searchSelections: ComboBoxItem[] = [
  {
    text: 'Refund ID',
    value: SEARCH_BY.REFUND_ID,
  },
  {
    text: 'Order ID',
    value: SEARCH_BY.ORDER_ID,
  },
  {
    text: `Customer Name`,
    value: SEARCH_BY.CUSTOMER_NAME,
  },
  {
    text: 'ID Number',
    value: SEARCH_BY.ID_NUMBER,
  },
  {
    text: 'Mobile Number',
    value: SEARCH_BY.MOBILE_NUMBER,
  },
  {
    text: 'Email Address',
    value: SEARCH_BY.EMAIL_ADDRESS,
  },
];

export const RefundTableFilter = () => {
  const { loading, loadRefunds, filter, setFilter, handleResetFilter, setToggleBulkRefunds } =
    useContext(RefundsContext);
  const [searchBy, setSearchBy] = useState(filter.searchBy || SEARCH_BY.REFUND_ID);
  const [search, setSearch] = useState(filter.searchBy ? filter[filter.searchBy] : '');

  const debouncedSearchHandler = useCallback(
    debounce((searchValue: string, apptFilter: RefundFilter) => {
      if (searchBy) {
        setFilter({ ...apptFilter, [searchBy]: searchValue, searchBy });
      } else {
        setFilter({ ...apptFilter, search: searchValue, searchBy });
      }
    }, 1000),
    [searchBy],
  );

  const handleSearchChange = (value: string): void => {
    setSearch(value);
    debouncedSearchHandler(value, filter);
  };

  useEffect(() => {
    if (!search.length) {
      // Keep filter when back to refunds page.
      const isDefault = isEqual(defaultFilter, filter);
      setFilter(isDefault ? defaultFilter : filter);
      return;
    }

    if (searchBy === SEARCH_BY.ORDER_ID && search.length > 7) {
      setFilter({ ...filter, orderNumber: search, searchBy });
    } else if (search.length > 2) {
      setFilter({ ...filter, [searchBy]: search, searchBy });
    }
  }, [searchBy]);

  useEffect(() => {
    if (filter.filterDate) {
      loadRefunds();
    }
  }, [filter]);

  const onReset = () => {
    setSearch('');
    handleResetFilter();
    setToggleBulkRefunds(false);
  };

  return (
    <div className="refundFilter">
      <Row gutter={16} spans={[6, 15, 3]}>
        <div className="searchCriteria">
          <ComboBox
            items={searchSelections}
            value={searchBy}
            disabled={loading}
            onChange={(value) => setSearchBy(value as string)}
          />
          <Input iconRight={{ name: 'Search' }} placeholder="Search" value={search} onChange={handleSearchChange} />
        </div>
        <RefundFilterToolbar />
        <Button loading={loading} type="secondary" title="Reset" onClick={onReset} />
      </Row>
    </div>
  );
};
