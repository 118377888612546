import React, { createContext, PropsWithChildren, ReactNode, useContext, useState } from 'react';
import OrganizationAPI from 'src/service/Organization.service';
import { OrganizationModel } from 'src/model/OrganizationModel';
import { handleError } from 'src/util/error.util';

class ContextState {
  loading: boolean;
  organisation: OrganizationModel;
  updateOrganisationStatus: (orgId: string, status: string) => Promise<boolean>;
  loadOrganisationDetail: (orgId: string) => Promise<OrganizationModel>;
  importMedicationList: (orgId: string) => Promise<boolean>;
}

export const OrganizationContext = createContext(new ContextState());

const OrganizationProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<OrganizationModel>(new OrganizationModel());

  const updateOrganisationStatus = async (orgId: string, status: string): Promise<boolean> => {
    try {
      setLoading(true);
      await OrganizationAPI.updateOrganisationStatus(orgId, status);
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const loadOrganisationDetail = async (orgId: string): Promise<any> => {
    try {
      setLoading(true);
      const res = await OrganizationAPI.loadOrganisationDetail(orgId);
      setOrganisation(res);
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const importMedicationList = async (orgId: string): Promise<boolean> => {
    try {
      setLoading(true);
      await OrganizationAPI.importMedicationList(orgId);
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <OrganizationContext.Provider
      value={{
        loading,
        organisation,
        updateOrganisationStatus,
        loadOrganisationDetail,
        importMedicationList,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext = () => useContext(OrganizationContext);

export default OrganizationProvider;
