import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Routes } from 'src/constant';
import Products from 'src/module/products/Products/Products';
import ProductListProvider from 'src/context/ProductList.context';
import ProductActionProvider from 'src/context/ProductAction.context';

function ProductContainer() {
  return (
    <Switch>
      <Route path={Routes.Products} exact>
        <ProductActionProvider>
          <ProductListProvider>
            <Products />
          </ProductListProvider>
        </ProductActionProvider>
      </Route>
    </Switch>
  );
}

export default ProductContainer;
