import React from 'react';
import { Popover, Typography } from '@care/web-ui';
import { DeepLinkItem } from 'src/model/DeepLink.model';
import DeepLinkItemAction from './DeepLinkItemAction';

export const getColumns = () => {
  return [
    {
      title: 'Screen Name',
      width: '30%',
      dataIndex: 'screenName',
      sorter: (sortOrder: string) => sortOrder,
      render: (screenName: string, item: DeepLinkItem) => (
        <div>
          <Popover
            trigger="hover"
            placement="bottom"
            hideArrow
            content={
              <div className="flex flex-col items-center p-2">
                <img src={item.screenImageUrl} alt="" className="w-[248px]" />
                <div className="mt-4 font-bold">{screenName}</div>
              </div>
            }
          >
            <div>
              <Typography level={9} className="cursor-pointer" weight="bold">
                {screenName}
              </Typography>
            </div>
          </Popover>
        </div>
      ),
    },
    {
      title: '',
      render: (_, item: DeepLinkItem) => (
        <div className="flex flex-row justify-end">
          <DeepLinkItemAction item={item} />
        </div>
      ),
    },
  ];
};
