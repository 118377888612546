export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_INDEX = 1;
export const MAX_PAGE_SIZE = 100;

export const pageSizeOptions = [DEFAULT_PAGE_SIZE.toString(), '20', '50', MAX_PAGE_SIZE.toString()];

export const PAGE_SIZE_PER_MODULE = {
  PRODUCTS: DEFAULT_PAGE_SIZE,
  REFUNDS: DEFAULT_PAGE_SIZE,
  PROMOCODES: DEFAULT_PAGE_SIZE,
  MEMBERSHIPS: DEFAULT_PAGE_SIZE,
  ACCOUNTS: DEFAULT_PAGE_SIZE,
  ORGANISATIONS: DEFAULT_PAGE_SIZE,
  FEEDBACKS: DEFAULT_PAGE_SIZE,
  PLATFORMSETTINGS: DEFAULT_PAGE_SIZE,
  USERS: DEFAULT_PAGE_SIZE,
  DEEPLINKS: DEFAULT_PAGE_SIZE,
};
