import React, { useEffect, useState } from 'react';
import { Button, careNotification, Typography } from '@care/web-ui';
import SectionTitle from 'src/component/SectionTitle/SectionTitle';
import Spacer from 'src/component/Spacer/Spacer';
import ItemInfo from '../item-info/ItemInfo';
import { appHomeScreenPath } from 'src/constant/configurations.constant';
import { useHistory } from 'react-router';
import Sortable from 'src/component/sortable/Sortable';
import { useAppHomeScreenContext } from 'src/context/AppHomeScreen.context';
import './ManageTiles.scss';
import { HomeScreenItem } from 'src/model/HomeScreenItem.model';
import { CreateHomeScreenModal } from '../home-screen-item-modal/CreateHomeScreenModal';
import { ManageType } from 'src/enum/manageType.enum';
import PreviewTiles from '../preview-tiles/PreviewTiles';
import { getVisibleTiles } from 'src/util/tile.util';
import PageHeader, { ActionButtonsProps } from 'src/component/PageHeader/PageHeader';

const breadcumbItems = [
  {
    name: 'App Home Screen',
    href: appHomeScreenPath,
  },
  {
    name: 'Tiles Layout',
    href: '#',
  },
];

const ManageTiles = () => {
  const { loadData, updateTitles, loading } = useAppHomeScreenContext();
  const { push } = useHistory();
  const [editedItem, setEditedItem] = useState<HomeScreenItem>(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isLastItem, setIsLastItem] = useState(false);
  const [items, setItems] = useState<HomeScreenItem[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const country = urlParams.get('country');

  useEffect(() => {
    (async () => {
      const data = await loadData(country);

      if (data) {
        setItems(data.tiles || []);
      }
    })();
  }, []);

  const handleAddTile = () => {
    setEditedItem(null);
    setEditModalVisible(true);
    setIsLastItem(items.length === 0);
  };

  const handleCloseModal = () => {
    setEditModalVisible(false);
    setEditedItem(null);
    setIsLastItem(false);
  };

  const numberOfVisible = getVisibleTiles(items).length;
  const handleSwitchVisibility = (value: boolean, index: number) => {
    let message = '';

    if (!value && numberOfVisible <= 1) {
      message = 'You should have at least 1 tile visible in the homescreen app.';
    }

    if (message) {
      careNotification({
        type: 'error',
        message: <Typography level={8}>{message}</Typography>,
        duration: 2,
      });

      return;
    }

    items[index].visible = value;
    setItems([...items]);
  };

  const handleEditItem = async (item: HomeScreenItem, index: number) => {
    setEditedItem({ ...item, index });
    setEditModalVisible(true);
    setIsLastItem(index === 0 && items.length === 1);
  };

  const onAddOrEditOrDelSuccess = (item: HomeScreenItem, isDel?: boolean) => {
    const index = item.index;
    if (index !== undefined && !isDel) {
      items[item.index] = item; //CASE: edit item.
      setItems([...items]);
    } else if (isDel) {
      items.splice(item.index, 1); //CASE: delete item.
      setItems([...items]);
    } else {
      setItems(items.concat({ ...item, index: items.length })); //CASE: add new item.
    }
    return true;
  };

  const backToHomeScreenPage = () => {
    push(`${appHomeScreenPath}${window.location.search.replace('?', '&')}`);
  };

  const handleSave = async () => {
    const result = await updateTitles({ countryCode: country, items });

    if (result) {
      backToHomeScreenPage();
      careNotification({
        type: 'success',
        message: (
          <Typography level={8} weight="bold">
            Success
          </Typography>
        ),
        description: (
          <Typography level={9} weight="semi">
            Tiles layout succesfully updated.
          </Typography>
        ),
        duration: 2,
      });
    }
  };

  const actionButtons: ActionButtonsProps[] = [
    {
      title: 'Cancel',
      type: 'outline',
      size: 'small',
      onSubmit: backToHomeScreenPage,
    },
    {
      title: 'Save',
      size: 'small',
      loading,
      disabled: items.length === 0,
      onSubmit: handleSave,
    },
  ];

  return (
    <div className="manageTilesContainer">
      <PageHeader title="Tiles Layout" breadcumbItems={breadcumbItems} actionButtons={actionButtons} />

      <div className="flex flex-nowrap !justify-start !gap-12">
        <div className="shrink-0 grow-0 basis-auto">
          <Spacer size={40} />
          <PreviewTiles tiles={items} />
        </div>
        <div className="shrink-0 grow basis-auto">
          <SectionTitle
            title="Manage Tiles"
            marginVertical={4}
            rightComponent={
              <Button
                title="Add Tile"
                icon="Plus"
                type="link"
                style={{ padding: 0, minWidth: 'unset' }}
                onClick={handleAddTile}
              />
            }
          />
          <Spacer size={16} />
          <Sortable items={items} onSortEnd={setItems}>
            {items.map((item, index) => (
              <ItemInfo
                key={item.name}
                item={item}
                imageType="square"
                onEdit={() => handleEditItem(item, index)}
                onSwitch={(value) => handleSwitchVisibility(value, index)}
              />
            ))}
          </Sortable>
        </div>
      </div>

      {editModalVisible && (
        <CreateHomeScreenModal
          visible={true}
          onClose={handleCloseModal}
          onSave={onAddOrEditOrDelSuccess}
          manageType={ManageType.Tile}
          itemDetail={editedItem}
          isLastItem={isLastItem}
          existingItemNames={items.map((n) => n.name)}
          numberOfCurrentVisibleItems={numberOfVisible}
        />
      )}
    </div>
  );
};

export default ManageTiles;
