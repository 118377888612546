import { DatePicker } from '@care/web-ui';
import { DatePickerProps } from '@care/web-ui/lib/components/DatePicker/DatePicker';
import React from 'react';
import Field from './Field';

interface Props extends DatePickerProps {
  name: string;
  control: any;
}

const DatePickerField = ({ name, control, onChange: onValueChange, customAttr, ...rest }: Props) => (
  <Field
    name={name}
    control={control}
    customAttr={customAttr}
    render={({ onChange, value }) => (
      <DatePicker
        {...rest}
        value={value ? new Date(value) : undefined}
        customAttr={customAttr}
        onChange={(date) => {
          onChange(date);
          if (onValueChange) {
            onValueChange(date);
          }
        }}
      />
    )}
  />
);

export default DatePickerField;
