import React, { useEffect, useState, useCallback } from 'react';
import { Button, Input, RangePicker, Row } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import { FeedbackFilter, useFeedbackContext } from 'src/context/Feedbacks.context';

const {
  DateUtil: { convertToDate },
  CommonUtil: { debounce },
} = Utils;

export const FeedbacksTableToolbar = () => {
  const { loading, loadData, filter, setFilter, pagination, setPagination } = useFeedbackContext();
  const [searchValue, setSearchValue] = useState('');

  const { startTime, endTime } = filter;

  const updateFilter = (params) => {
    setPagination({ ...pagination, pageIndex: 1 });
    setFilter(params);
  };

  const handleChangeDate = (values: [Date, Date]): void => {
    const [start, end] = values || [];

    const filteredQuery: FeedbackFilter = {
      ...filter,
      startTime: start && convertToDate(start).toISOString(),
      endTime: end && convertToDate(end).toISOString(),
    };

    updateFilter(filteredQuery);
  };

  const handleClearDate = () => {
    const filteredQuery: FeedbackFilter = {
      ...filter,
      startTime: undefined,
      endTime: undefined,
    };

    updateFilter(filteredQuery);
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      updateFilter({ ...filter, search: value });
    }, 1000),
    [filter],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debounceSearch(value);
  };

  const reset = () => {
    updateFilter({});
    setSearchValue('');
  };

  useEffect(() => {
    loadData();
  }, [filter]);
  return (
    <div>
      <Row gutter={16} spans={[10, 10]} justify={'space-between'}>
        <Input
          iconRight={{ name: 'Search' }}
          placeholder="Search"
          disabled={loading}
          value={searchValue}
          onChange={handleSearch}
        />
        <div className="flex gap-3">
          <RangePicker
            value={filter.startTime ? [convertToDate(startTime), convertToDate(endTime)] : undefined}
            onChange={handleChangeDate}
            onClear={handleClearDate}
          />
          <Button loading={loading} type="secondary" title="Reset" onClick={reset} />
        </div>
      </Row>
    </div>
  );
};
