import React from 'react';
import { Typography } from '@care/web-ui';

import Spacer from 'src/component/Spacer/Spacer';

import { ProductsTable } from '../ProductsTable/ProductsTable';
import { FilteredToolbar } from '../ProductsTable/FilteredToolbar';

const Products = () => {
  return (
    <div className="main-content">
      <div className="flex">
        <Typography fontCategory="serif" level={3}>
          Products
        </Typography>
      </div>

      <Spacer size={32} />

      <FilteredToolbar />
      <ProductsTable />
    </div>
  );
};

export default Products;
