import { Builder } from 'builder-pattern';
import { BenefitApplyType } from 'src/enum/BenefitApplyType';
import { BenefitType } from 'src/enum/BenefitType';
import { DiscountType } from 'src/enum/DiscountType';
import { RedemptionFrequency } from 'src/enum/RedemptionFrequency';
import { RedemptionLimitType } from 'src/enum/RedemptionLimitType';
import { MembershipDetailModelDTO } from './dto/MembershipDetail.response';
import { CoreBenefit } from './dto/CreateOrEditMembership.request';
import { OrgsProducts } from 'src/module/memberships/OrgsAndProductsForm/OrgsAndProductsForm';
import Utils from '@care/web-ui/lib/utils';

const {
  CurrencyUtil: { convertCurrencyValue },
  CommonUtil: { groupBy },
} = Utils;

export class MembershipDetailModel extends MembershipDetailModelDTO {
  benefitApplyType: BenefitApplyType;
  applyAllProducts: string;
  currency: string;
  orgsProducts: OrgsProducts;
  selectedProductIds: string[];

  static convertFromDTO(dto: MembershipDetailModelDTO): MembershipDetailModel {
    const { benefitSettings, applyAllOrganizationProducts, applyBenefitToAllProduct, ...rest } = dto;

    const groupProductBenefit = groupBy(benefitSettings, 'productId');
    const customBenefitSettings = [];

    Object.keys(groupProductBenefit).forEach((key) => {
      const benefits = groupProductBenefit[key];
      const firstBenefit = benefits[0];

      if (firstBenefit.settingType === BenefitApplyType.PerLocation) {
        firstBenefit.locationObj = {};
        benefits.forEach((b) => {
          firstBenefit.locationObj[b.locationId] = {
            id: b.id,
            type: b.type,
            discountType: b.discountType,
            discountValue: b.discountValue,
            redemptionType: b.redemptionType,
            limitByAmount: b.limitByAmount,
            limitByNumber: b.limitByNumber,
            redemptionFrequency: b.redemptionFrequency,
            redemptionValue: b.redemptionType === RedemptionLimitType.LimitByAmount ? b.limitByAmount : b.limitByNumber,
          };
        });
      }

      customBenefitSettings.push(firstBenefit);
    });

    const { settingType } = customBenefitSettings[0];
    const benefitApplyType = applyBenefitToAllProduct
      ? BenefitApplyType.AllProducts
      : settingType === BenefitApplyType.PerLocation
        ? BenefitApplyType.AllLocations
        : settingType;

    return Builder(MembershipDetailModel, {
      ...rest,
      benefitApplyType,
      benefitSettings: customBenefitSettings,
      applyAllProducts: applyAllOrganizationProducts.toString(),
    }).build();
  }
}

// export const getMemberBenefit = (benefit: CoreBenefit) => {
//   if (benefit.type === BenefitType.DiscountOff) {
//     return benefit.discountType === DiscountType.Amount
//       ? `$${benefit.discountValue} off`
//       : `${benefit.discountValue}% off`;
//   } else {
//     const redemptionFrequency =
//       benefit.redemptionFrequency === RedemptionFrequency.OneTime
//         ? "One-time"
//         : `Reset ${benefit.redemptionFrequency}`;

//     return benefit.redemptionType === RedemptionLimitType.LimitByAmount
//       ? `Claim up to $${benefit.limitByAmount} (${redemptionFrequency})`
//       : `Redeem ${benefit.limitByNumber} times (${redemptionFrequency})`;
//   }
// };

export const getMemberBenefit = (benefit: CoreBenefit, currency: string) => {
  if (benefit.type === BenefitType.DiscountOff) {
    return benefit.discountType === DiscountType.Amount
      ? `${convertCurrencyValue(benefit.discountValue, currency)} off`
      : `${benefit.discountValue}% off`;
  } else {
    const redemptionFrequency =
      benefit.redemptionFrequency === RedemptionFrequency.OneTime ? 'One-time' : `Reset ${benefit.redemptionFrequency}`;

    return benefit.redemptionType === RedemptionLimitType.LimitByAmount
      ? `Claim up to ${convertCurrencyValue(benefit.limitByAmount, currency)} (${redemptionFrequency})`
      : `Redeem ${benefit.limitByNumber} times (${redemptionFrequency})`;
  }
};
