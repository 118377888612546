import { CopyToClipboardInput, Tag, Typography } from '@care/web-ui';
import Utils from '@care/web-ui/lib/utils';
import React from 'react';
import BlockInfo from 'src/component/BlockInfo/BlockInfo';
import Divider from 'src/component/Divider/Divider';
import Spacer from 'src/component/Spacer/Spacer';
import { paymentStatusTypeTag } from 'src/constant/paymentStatusTypeTag.constant';
import { paymentMethodLabel } from 'src/constant/OrderStatusTag.constant';

import 'src/module/refunds/approve-refund-modal/ApproveRefundModal.scss';
import { TransactionRow } from 'src/module/refunds/approve-refund-modal/TransactionRow';
import { useRefundDetailContext } from 'src/context/RefundDetail.context';

const { convertCurrencyValue } = Utils.CurrencyUtil;

const PaymentAmountTable = () => {
  const { fullRefundDetail } = useRefundDetailContext();
  const { subTotal, redemptionCode, promoCode, membershipUsage, discount, payment, grandTotal } =
    // eslint-disable-next-line no-unsafe-optional-chaining
    fullRefundDetail?.order;

  const renderDiscount = () => {
    if (membershipUsage?.id) {
      const membershipDiscount = convertCurrencyValue(membershipUsage?.amount, payment?.currency);
      const prefix = `[${membershipUsage?.membershipBenefit?.membership?.displayName}]`;
      const benefitDetail = `Benefit Details (${membershipDiscount}/Redeem ${membershipUsage?.productName})`;

      return (
        <>
          <TransactionRow name="Membership Benefit" amount={`-${membershipDiscount}`} />
          <Typography level={8}>{`${prefix} ${benefitDetail}`}</Typography>
          <Divider marginVertical={12} />
          <TransactionRow name="Total paid" amount={grandTotal.valueWithSymbol} />
        </>
      );
    }

    if (redemptionCode || promoCode) {
      const prefix = redemptionCode ? '[Redemption]' : '[Promo code]';

      return (
        <>
          <TransactionRow name="Code" amount={`-${discount.valueWithSymbol}`} />
          <Typography level={8}>{`${prefix} ${redemptionCode || promoCode}`}</Typography>
          <Divider marginVertical={12} />
          <TransactionRow name="Total paid" amount={grandTotal.valueWithSymbol} />
        </>
      );
    }

    return (
      <>
        <Divider marginVertical={12} />
        <TransactionRow name="Total paid" amount={subTotal.valueWithSymbol} />
      </>
    );
  };

  return (
    <div className="card-refund">
      <TransactionRow name="Subtotal" amount={subTotal.valueWithSymbol} />
      {renderDiscount()}
    </div>
  );
};

const PaymentInfo = () => {
  const { fullRefundDetail } = useRefundDetailContext();
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { payment, paymentMethod, paymentStatus } = fullRefundDetail?.order;

  return (
    <div>
      <Typography level={5} weight="regular" fontCategory="serif">
        Payment Information
      </Typography>
      <Divider marginVertical={16} />
      <BlockInfo
        name="Payment Status"
        value={
          <Tag
            type={paymentStatusTypeTag[paymentStatus].type as any}
            text={paymentStatusTypeTag[paymentStatus].label}
          />
        }
        marginBottom={22}
      />
      <BlockInfo name="Payment Mode" value={paymentMethodLabel[paymentMethod] || '-'} marginBottom={22} />
      <PaymentAmountTable />
      <Spacer size={22} />
      <BlockInfo
        name="Payment Transaction ID"
        value={<CopyToClipboardInput value={payment?.providerPaymentId} size="small" />}
        marginBottom={22}
      />
    </div>
  );
};

export default PaymentInfo;
