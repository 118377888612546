import { Builder } from 'builder-pattern';
import { mappingRefundRequestedBy, mappingRefundStatus } from 'src/constant/Refund';
import { Amount } from './Amount.model';
import { RefundDetailResDTO } from './dto/RefundDetail.response';
import { RefundDetailModel } from './RefundDetail.model';
import { RefundOrderModel } from './RefundOrder.model';
import { RefundOrderItemModel } from './RefundOrderItem.model';

export class FullRefundDetailModel {
  refund: RefundDetailModel;
  order: RefundOrderModel;

  public get refundStatusLabel() {
    return mappingRefundStatus[this.refund.status];
  }

  static convertFromDTO(dto: RefundDetailResDTO): FullRefundDetailModel {
    const { refund, order, ...restDTO } = dto;
    const { amount, fee, previouslyRefunded, requestedBy, refundAmount, ...rest } = refund;
    const { orderItems, subTotal, discount, grandTotal, ...orderRest } = order;
    const currency = orderItems[0].product.currency;
    const approveRefundAmount = amount - previouslyRefunded - fee;

    return Builder(FullRefundDetailModel, {
      ...restDTO,
      refund: Builder<RefundDetailModel>({
        ...rest,
        amount: Builder(Amount).value(amount).currency(currency).build(),
        fee: Builder(Amount).value(fee).currency(currency).build(),
        previouslyRefunded: Builder(Amount).value(previouslyRefunded).currency(currency).build(),
        approveRefundAmount: Builder(Amount).value(approveRefundAmount).currency(currency).build(),
        refundAmount: Builder(Amount).value(refundAmount).currency(currency).build(),
        requestedBy: mappingRefundRequestedBy[requestedBy],
      }).build(),
      order: Builder<RefundOrderModel>({
        ...orderRest,
        subTotal: Builder(Amount).value(subTotal).currency(currency).build(),
        discount: Builder(Amount).value(discount).currency(currency).build(),
        grandTotal: Builder(Amount).value(grandTotal).currency(currency).build(),
        orderItems: orderItems.map((o) =>
          Builder<RefundOrderItemModel>({
            ...o,
            price: Builder(Amount).value(o.price).currency(o.product.currency).build(),
          }).build(),
        ),
      }).build(),
    }).build();
  }
}
