import { Typography } from '@care/web-ui';
import React from 'react';
import OrganisationsTable from './organisations-table/OrganisationsTable';
import Spacer from 'src/component/Spacer/Spacer';

const Organisations = () => {
  return (
    <div style={{ padding: '52px 76px' }}>
      <Typography fontCategory="serif" level={3}>
        Organizations
      </Typography>
      <Spacer size={32} />
      <OrganisationsTable />
    </div>
  );
};

export default Organisations;
