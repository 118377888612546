import { createStore } from '@care/web-ui/lib/store';
import { QueryCaching } from '@care/web-ui/lib/store';
import { QueryStore } from '@care/web-ui/lib/store/store.queryCaching';

const { queryStateCreator } = QueryCaching;
export const $ECOMMERCE_PRODUCTS_DETAILS_STORE = '$ECOMMERCE_PRODUCTS_DETAILS_STORE';

export type ECommerceProductsDetailsStore = QueryStore<any>;

export const [useStore, selector] = createStore<ECommerceProductsDetailsStore>(queryStateCreator, {
  storeName: $ECOMMERCE_PRODUCTS_DETAILS_STORE,
});
