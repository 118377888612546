import React from 'react';

import './Field.scss';

interface Props {
  message?: string;
  dataTestId?: string;
}

const FieldError = ({ message, dataTestId }: Props) => {
  if (message)
    return (
      <div className="field-error" data-test-id={dataTestId}>
        {message}
      </div>
    );
  return null;
};

export default FieldError;
