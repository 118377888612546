import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';
import { Builder } from 'builder-pattern';
import config from 'src/Config';
import { LocationFilter } from 'src/context/LocationList.context';
import { LocationModel } from 'src/model/Location.model';
import { APIBase, makeQueryString } from 'src/util/Request';

const URL = `${config.ADMIN_API_URL}/locations`;

class LocationAPI {
  async getLocations(
    filter: LocationFilter,
    pageIndex: number,
    pageSize: number,
  ): Promise<GeneralListResponse<LocationModel>> {
    const url = `${URL}?${makeQueryString({
      ...filter,
      pageIndex,
      pageSize,
    })}`;

    const body = await APIBase.get<GeneralListResponse<LocationModel>>(url);

    const { data, ...rest } = body.data;

    return { ...rest, data: data.map((d) => Builder(LocationModel, d).build()) };
  }
}

export default new LocationAPI();
