import { Button, Dropdown, Icon, Text, Typography } from '@care/web-ui';
import React, { useContext, useEffect, useState } from 'react';
import Divider from 'src/component/Divider/Divider';
import { CategoryContext } from 'src/context/Category.context';
import { ManageType } from 'src/enum/manageType.enum';
import { Category } from 'src/model/Category.model';
import { CategoryModal } from '../category-modal/CategoryModal';
import './Categories.scss';
import { CategoryType } from 'src/enum/CategoryType';

const Categories = () => {
  const { groupCats, loadGroupCats, createCategory, updateCategory, deleteCategory } = useContext(CategoryContext);

  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [activeCats, setActiveCats] = useState<string[]>([]);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [manageType, setManageType] = useState<ManageType>(ManageType.Banner);
  const [cateValue, setCateValue] = useState<Category>({
    ...new Category(),
    categoryType: CategoryType.Product,
    active: true,
  });

  useEffect(() => {
    loadGroupCats();
  }, []);

  const handleCategoryClick = (category: Category) => {
    const selectedCate = activeCats.some((cate) => cate === category.id);

    if (selectedCate) {
      setActiveCats(activeCats.filter((cate) => cate !== category.id));
    } else {
      setActiveCats([...activeCats, category.id]);
    }
  };

  const handleAddCategory = (type: ManageType) => {
    setManageType(type);
    setModalVisible(true);
    setIsCreate(true);
    setCateValue({ ...new Category(), categoryType: CategoryType.Product, active: true });
  };

  const menu = [
    {
      content: 'Category',
      onClick: () => handleAddCategory(ManageType.Category),
    },
    {
      content: 'Sub-category',
      onClick: () => handleAddCategory(ManageType.Subcategory),
    },
  ];

  const onSaveCategory = async (values: Category, isDelete: boolean) => {
    try {
      if (isCreate) {
        await createCategory(values);
      } else if (!isDelete) {
        await updateCategory(values);
      } else {
        await deleteCategory(values.id, !values.parentCategoryId);
      }
      await loadGroupCats();
      setModalVisible(false);
      setCateValue({ ...new Category(), categoryType: CategoryType.Product, active: true });
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseCategory = () => {
    setManageType(undefined);
    setModalVisible(false);
    setIsCreate(false);
    setCateValue({ ...new Category(), categoryType: CategoryType.Product, active: true });
  };

  const handleEditCategory = async (category: Category, type: ManageType) => {
    setManageType(type);
    setIsCreate(false);
    setCateValue(category);
    setModalVisible(true);
  };

  return (
    <div className="content">
      <div className="header">
        <Typography level={5} fontCategory="serif">
          Categories & Sub-categories
        </Typography>
        <Dropdown placement="bottomCenter" menu={menu}>
          <Button title="Add" type="link" icon="Plus" style={{ minWidth: 'unset' }} />
        </Dropdown>
      </div>
      <Divider marginVertical={4} />
      {Object.keys(CategoryType).map((type) => (
        <div key={CategoryType[type]} className="mt-4">
          <Text className="category-label mb-2 capitalize" data-testid={CategoryType[type]}>
            {CategoryType[type]}s
          </Text>
          {groupCats
            .filter((category) => category.categoryType === CategoryType[type])
            .map((category) => {
              return (
                <div key={category.id} data-testid={`category-${CategoryType[type]}`}>
                  <div className="category">
                    <div
                      className="title"
                      data-testid={`label-${category.categoryType}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <Icon name={activeCats.includes(category.id) ? 'UpSmall' : 'DownSmall'} type="outline" />
                      <Typography level={9}>{category.name}</Typography>
                    </div>
                    <Button
                      title=""
                      icon="Cog"
                      type="link"
                      data-testid={category.categoryType}
                      style={{ minWidth: '30px' }}
                      onClick={() => handleEditCategory(category, ManageType.Category)}
                    />
                  </div>
                  {activeCats &&
                    activeCats.map((cate) => (
                      <div key={cate}>
                        {category.id === cate &&
                          category.subCats.map((subCate) => (
                            <div className="category" key={subCate.id}>
                              <Typography level={9} className="subTitle">
                                {subCate.name}
                              </Typography>
                              <Button
                                title=""
                                icon="Cog"
                                data-testid={`sub-${category.categoryType}`}
                                type="link"
                                style={{ minWidth: '30px' }}
                                onClick={() => handleEditCategory(subCate, ManageType.Subcategory)}
                              />
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              );
            })}
        </div>
      ))}
      <CategoryModal
        category={cateValue}
        isCreate={isCreate}
        visible={modalVisible}
        manageType={manageType}
        onSave={onSaveCategory}
        onClose={onCloseCategory}
      />
    </div>
  );
};

export default Categories;
