import { Modal } from '@care/web-ui';
import { useState } from 'react';
import { useFeedbackContext } from 'src/context/Feedbacks.context';
import * as FeedbackAPI from 'src/service/FeedbackAPI';

export const useFeedbackActions = () => {
  const { loadData } = useFeedbackContext();
  const [loading, setLoading] = useState(false);

  const markFeedbackAsComplete = async (id: string): Promise<boolean> => {
    try {
      setLoading(true);
      await FeedbackAPI.markFeedbackAsComplete(id);
      await loadData();
      return true;
    } catch (error) {
      Modal.confirm({ title: 'Error', content: error.message || error, type: 'error' });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return [markFeedbackAsComplete, loading];
};
