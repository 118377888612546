import Utils from '@care/web-ui/lib/utils';

export class Amount {
  value: number;
  currency: string;

  get valueWithSymbol(): string {
    return Utils.CurrencyUtil.convertCurrencyValue(this.value, this.currency);
  }
}
