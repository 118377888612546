import React, { useCallback, useMemo, useState } from 'react';
import { Table, Typography, Utils, Button } from '@care/web-ui';
import Spacer from 'src/component/Spacer/Spacer';
import { Sorter } from 'src/util/createPagingDataContext';
import { getColumns } from './columns';
import { SortOptionTable } from 'src/enum/SortBy.enum';
import { UserSortBy } from 'src/enum/User.enum';
import { PAGE_SIZE_PER_MODULE, pageSizeOptions } from 'src/constant/Pagination.constant';
import { UserListStore, useUserStore, initialState } from 'src/zustand-store/user/user.store';
import { shallow } from '@care/web-ui/lib/store';
import { useFetchUserList } from '../users.actions';
import { UserModel } from 'src/model/User.model';
import UserDetailModal from '../user-modal/UserDetailModal';

const {
  CommonUtil: { pick },
} = Utils;

const UsersTable = () => {
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [user, setUser] = useState<UserModel | null>(null);
  const {
    filter,
    setFilter,
    isLoading: loading,
    data: users,
    total,
  } = useUserStore(
    (store: UserListStore) =>
      pick(store, ['filter', 'setFilter', 'isLoading', 'data', 'total'] as Array<keyof UserListStore>),
    shallow,
  );

  useFetchUserList();

  const pagination = useMemo(
    () => ({
      pageIndex: filter.pageIndex,
      pageSize: filter.pageSize,
      total,
    }),
    [filter, total],
  );

  const getSortBy = useCallback((field: string, order: string): UserSortBy | undefined => {
    if (!order) return;

    switch (field) {
      case 'name':
        return order === SortOptionTable.Ascend ? UserSortBy.name_asc : UserSortBy.name_desc;
      case 'email':
        return order === SortOptionTable.Ascend ? UserSortBy.email_asc : UserSortBy.email_desc;
      default:
        return;
    }
  }, []);

  const handleTableChange: React.ComponentProps<typeof Table>['onChange'] = (
    newPagination: any,
    filterTable: Record<string, any>,
    newSortBy: Sorter,
  ) => {
    const sortByValue = getSortBy(newSortBy.field, newSortBy.order);
    setFilter({
      ...filter,
      ...filterTable,
      pageIndex: newPagination.current,
      pageSize: newPagination.pageSize,
      sortBy: sortByValue,
    });
  };

  const viewUserDetail = (user: UserModel) => {
    setUser(user);
    setIsUserModalVisible(true);
  };

  const handleCloseModal = () => {
    setUser(null);
    setIsUserModalVisible(false);
  };

  const handleResetFilter = () => {
    setFilter(initialState.filter);
  };

  const columns = useMemo(() => getColumns(filter, viewUserDetail), [filter]);

  return (
    <div>
      <Table
        rowKey="careId"
        loading={loading}
        columns={columns as any[]}
        onChange={handleTableChange}
        data={users}
        renderEmpty={
          <div className="mt-40">
            <img src="/empty-membership.png" style={{ width: 148 }} alt="users" />
            <Spacer />
            <Typography fontCategory="serif" level={7}>
              No users found
            </Typography>
            <Typography className="mt-2">Check your keyword or filters</Typography>
            <Button className="mt-5" title="Reset" onClick={handleResetFilter} />
          </div>
        }
        pagination={{
          defaultPageSize: PAGE_SIZE_PER_MODULE.USERS,
          showSizeChanger: true,
          pageSizeOptions,
          total: pagination.total,
          current: pagination.pageIndex,
          pageSize: pagination.pageSize,
          onChange: (pageIndex: number, pageSize: number) =>
            setFilter({
              pageIndex,
              pageSize,
            }),
        }}
      />
      <UserDetailModal visible={isUserModalVisible} user={user} onClose={handleCloseModal} />
    </div>
  );
};

export default UsersTable;
