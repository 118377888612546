import React, { useMemo, useState } from 'react';
import Spacer from 'src/component/Spacer/Spacer';
import { useUpdatePlatformSetting, useFetchPlatformSettingList } from './PlatformSettings.actions';
import {
  PlatformSettingListStore,
  usePlatformSettingStore,
} from 'src/zustand-store/platform-setting/PlatformSettingList.store';
import { Utils } from '@care/web-ui';
import { shallow } from '@care/web-ui/lib/store';
import PlatformSettingsTableToolbar from '../platform-settings-table-toolbar';
import PlatformSettingsTable from '../platform-settings-table';
import { PlatformSetting } from 'src/model/PlatformSetting.model';
import { PlatformSettingsModal } from '../platform-settings-modal/PlatformSettingsModal';

const {
  CommonUtil: { pick },
} = Utils;

const PlatformSettings = () => {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [settingValue, setSettingValue] = useState<PlatformSetting>({
    ...new PlatformSetting(),
  });

  const { fetchPlatformSetting } = useFetchPlatformSettingList();

  const { createSetting, updateSetting, isCreatingSetting, isUpdatingSetting } = useUpdatePlatformSetting();

  const handleEditSetting = (setting: PlatformSetting) => {
    setIsCreate(false);
    setSettingValue(setting);
    setModalVisible(true);
  };

  const handleAddSetting = () => {
    setModalVisible(true);
    setIsCreate(true);
    setSettingValue({ ...new PlatformSetting() });
  };

  const onSaveSetting = async (setting: PlatformSetting) => {
    if (isCreate) {
      await createSetting(setting);
    } else {
      await updateSetting(setting);
    }
    fetchPlatformSetting();
    setModalVisible(false);
    setSettingValue({ ...new PlatformSetting() });
  };

  const onCloseSetting = () => {
    setIsCreate(false);
    setSettingValue({ ...new PlatformSetting() });
    setModalVisible(false);
  };

  const { filter, setFilter, isLoading, data, total } = usePlatformSettingStore(
    (store: PlatformSettingListStore) =>
      pick(store, ['filter', 'setFilter', 'isLoading', 'data', 'total'] as Array<keyof PlatformSettingListStore>),
    shallow,
  );

  const pagination = useMemo(
    () => ({
      pageIndex: filter.pageIndex,
      pageSize: filter.pageSize,
      total,
    }),
    [filter, total],
  );

  return (
    <div>
      <PlatformSettingsTableToolbar
        loading={isLoading}
        filter={filter}
        setFilter={setFilter}
        handleAddSetting={handleAddSetting}
      />
      <Spacer size={18} />
      <PlatformSettingsTable
        loading={isLoading}
        pagination={pagination}
        filter={filter}
        setFilter={setFilter}
        data={data}
        handleEditSetting={handleEditSetting}
      />
      <PlatformSettingsModal
        setting={settingValue}
        isCreate={isCreate}
        visible={modalVisible}
        onSave={onSaveSetting}
        onClose={onCloseSetting}
        isLoading={isCreatingSetting || isUpdatingSetting}
      />
    </div>
  );
};

export default PlatformSettings;
