import { APIBase, makeQueryString } from '../util/Request';
import Config from '../Config';
import { Builder } from 'builder-pattern';
import { FeedbackFilter } from 'src/context/Feedbacks.context';
import { FeedbackItem } from 'src/model/FeedbackItem.model';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

const URL = `${Config.ADMIN_API_URL}/feedbacks`;

export const getFeedbacks = async (
  filter: FeedbackFilter,
  pageIndex: number,
  pageSize: number,
): Promise<GeneralListResponse<FeedbackItem>> => {
  const url = `${URL}?${makeQueryString({
    ...filter,
    feedbackStatus: filter.status,
    feedbackTypes: filter.type,
    status: undefined,
    type: undefined,
    pageIndex,
    pageSize,
  })}`;
  const res = await APIBase.get<GeneralListResponse<FeedbackItem>>(url);
  const listData = res.data?.data.map((item) => Builder(FeedbackItem, item).build()) || [];

  return { ...res.data, data: listData };
};

export const markFeedbackAsComplete = async (id: string): Promise<boolean> => {
  const url = `${URL}/${id}/mark-as-complete`;
  await APIBase.put(url);
  return true;
};
