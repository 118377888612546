import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Sidebar } from '../component/SideBar';
import { Routes } from '../constant';
import { useLocation } from 'react-router-dom';

import './Layout.scss';

export const SecureLayout = (props: React.PropsWithChildren) => {
  const { keycloak, initialized } = useKeycloak();
  const { pathname } = useLocation();

  const isHealthCheckRoute = pathname === Routes.HealthCheck;

  const redirectToLoginIfNotAuthenticated = (initialized, authenticated, createLoginUrl) => {
    if (initialized && !authenticated) {
      window.location.href = createLoginUrl();
    }
  };

  useEffect(() => {
    if (!isHealthCheckRoute) {
      redirectToLoginIfNotAuthenticated(initialized, keycloak.authenticated, keycloak.createLoginUrl);
    }
  }, [initialized, keycloak.authenticated]);

  if (!initialized || !keycloak.authenticated) {
    return null;
  }
  return (
    <div className="layout-wrapper">
      <Sidebar />
      <div className="site-layout">
        <div className="site-layout-background">
          <div className="site-content">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
