import React, { useState, useEffect, useMemo } from 'react';
import { Button, Input, Row, Utils } from '@care/web-ui';
import { PlatformSetting, PlatformSettingFilter } from 'src/model/PlatformSetting.model';
import { initialState } from 'src/zustand-store/platform-setting/PlatformSettingList.store';
import { DEFAULT_PAGE_INDEX } from 'src/constant/Pagination.constant';

const {
  CommonUtil: { debounce },
} = Utils;

interface PlatformSettingsTableToolbarProps {
  filter: PlatformSettingFilter;
  setFilter: (filter: PlatformSettingFilter) => void;
  loading: boolean;
  handleAddSetting: (setting: PlatformSetting) => void;
}

const PlatformSettingsTableToolbar = ({
  filter,
  setFilter,
  loading,
  handleAddSetting,
}: PlatformSettingsTableToolbarProps) => {
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useMemo(
    () =>
      debounce((value) => {
        setFilter({ ...filter, searchParams: value, pageIndex: DEFAULT_PAGE_INDEX });
      }, 1000),
    [filter],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debounceSearch(value);
  };

  useEffect(() => {
    return () => {
      setFilter(initialState.filter);
    };
  }, []);

  return (
    <Row spans={[6, 4]} justify="space-between">
      <Input
        iconRight={{ name: 'Search' }}
        placeholder="Search by Key Name"
        onChange={handleSearch}
        value={searchValue}
        disabled={loading}
      />

      <div className="float-right">
        <Button title="Add New Key" type="primary" onClick={(e) => handleAddSetting(e as any)} />
      </div>
    </Row>
  );
};

export default PlatformSettingsTableToolbar;
