import React, { useEffect, useState, useCallback } from 'react';
import { Button, ComboBox, Icon, Input, Modal, Row } from '@care/web-ui';
import { OrderFilter, useOrdersContext } from 'src/context/Orders.context';
import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import Utils from '@care/web-ui/lib/utils';
import * as yup from 'yup';
import './OrdersTableToolbarStyle.scss';
import { OrderSearchByOption } from 'src/enum/orderSearchByOption.enum';
import { FilteredDate as EFilteredDate } from 'src/enum/filteredDate.enum';
import { filterDateOptions } from 'src/constant/date.constant';
import { FilteredDate } from 'src/component/filtered-date/FilteredDate';
import { convertDateFilterToDateRange } from 'src/util/convertFilteredDate.util';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/constant/Pagination.constant';
import FieldError from 'src/component/Field/FieldError';
import CustomComboBox from 'src/component/custom-combo-box/CustomComboBox';
import { useProductListComboBoxContext } from 'src/context/ProductListComboBox.context';
import { useExportOrderData } from '../Orders.actions';

const {
  DateUtil: { getPayloadDateString },
  CommonUtil: { debounce },
} = Utils;

const defaultFilter = {
  ...convertDateFilterToDateRange(EFilteredDate.Today),
  searchBy: OrderSearchByOption.OrderId,
};

const searchSelections: ComboBoxItem[] = [
  {
    text: 'Order ID',
    value: OrderSearchByOption.OrderId,
  },
  {
    text: 'Name',
    value: OrderSearchByOption.ConsumerName,
  },
  {
    text: 'Appointment ID',
    value: OrderSearchByOption.AppointmentId,
  },
  {
    text: 'ID no.',
    value: OrderSearchByOption.IdNumber,
  },
  {
    text: 'Passport no.',
    value: OrderSearchByOption.PassportNumber,
  },
  {
    text: 'Mobile no.',
    value: OrderSearchByOption.MobileNumber,
  },
];

const emailSchema = yup.string().email().required();

export const OrdersTableToolbar = () => {
  const { loading, loadData, filter, setFilter, pagination, setPagination, data } = useOrdersContext();
  const {
    data: products,
    loading: loadingProducts,
    loadData: loadProducts,
    onNextPage: onProductNextPage,
    onUpdatedFilter: onUpdatedProductFilter,
  } = useProductListComboBoxContext();

  const [searchValue, setSearchValue] = useState('');
  const [filterDate, setFilterDate] = useState<EFilteredDate>(EFilteredDate.Today);
  const [isExportModalOpened, setIsExportModalOpened] = useState(false);
  const [exportEmail, setExportEmail] = useState('');
  const [exportEmailError, setExportEmailError] = useState(false);
  const { exportOrderData, isExportOrderDisabled, isExportOrderLoading } = useExportOrderData();

  const { searchBy } = filter;
  const isEmptyData = data.length === 0;

  // products
  const optionsProductions = React.useMemo(() => {
    return products.map((product) => ({ text: product.name, value: product.id }));
  }, [products]);

  const handleChangeDate = (newFilterDate: EFilteredDate): void => {
    const { fromDate, toDate } = convertDateFilterToDateRange(newFilterDate);
    const filteredQuery: OrderFilter = {
      ...filter,
      fromDate: fromDate && getPayloadDateString(fromDate),
      toDate: toDate && getPayloadDateString(toDate, true),
    };
    setPagination({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE });
    setFilter(filteredQuery);
    setFilterDate(newFilterDate);
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setFilter({ ...filter, search: value });
    }, 1000),
    [filter],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debounceSearch(value);
  };

  const reset = () => {
    setFilter({ ...defaultFilter });
    setSearchValue('');
    setFilterDate(EFilteredDate.Today);
    setPagination({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE });
  };

  const onSearchByChanged = (value: OrderSearchByOption) => {
    setFilter({ ...filter, searchBy: value });
  };

  const handleChangeProducts = React.useCallback(
    (values: string[]) => {
      setFilter({ ...filter, productIds: [...values] });
    },
    [filter, setFilter],
  );

  const handleOpenExportModal = () => {
    setIsExportModalOpened(true);
  };

  const handleCloseExportModal = () => {
    setIsExportModalOpened(false);
    setExportEmail('');
    setExportEmailError(false);
  };

  const handleChangeExportEmail = (value: string) => {
    setExportEmail(value);
    if (!value && exportEmailError) {
      setExportEmailError(false);
    }
  };

  const handleExportData = () => {
    try {
      emailSchema.validateSync(exportEmail);
      exportOrderData(exportEmail);
      if (exportEmailError) {
        setExportEmailError(false);
      }
    } catch (error) {
      setExportEmailError(true);
    }
  };

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    loadData();
  }, [filter, pagination.pageIndex, pagination.pageSize]);

  return (
    <div className="order-table-toolbar">
      <Row gutter={8} spans={[5, 5, 4, 4, 6]}>
        <ComboBox items={searchSelections} value={searchBy} disabled={loading} onChange={onSearchByChanged} />
        <Input
          iconRight={{ name: 'Search' }}
          placeholder="Search"
          disabled={loading}
          value={searchValue}
          onChange={handleSearch}
        />
        <CustomComboBox
          initialFilter={filter.productIds}
          label="All Products"
          options={optionsProductions}
          handleChange={handleChangeProducts}
          isInfiniteScroll
          onNextPage={onProductNextPage}
          onUpdatedFilter={onUpdatedProductFilter}
        />
        <FilteredDate filterDate={filterDate} onChangeDate={handleChangeDate} options={filterDateOptions} />
        <div className="flex flex-row gap-2">
          <Button loading={loading} type="secondary" title="Reset" onClick={reset} />
          <Button
            loading={loading}
            disabled={loading || isExportOrderDisabled || loadingProducts || isEmptyData}
            type="outline"
            title="Export"
            onClick={handleOpenExportModal}
            disabledMessage="Please select only 1 product from filter"
          />
        </div>
      </Row>
      <Modal
        title="Export Data"
        className="[&_.ant-modal-footer]:flex"
        visible={isExportModalOpened}
        onCancel={handleCloseExportModal}
        onOk={handleExportData}
        okText="Export"
        cancelButtonProps={{ className: 'ml-auto' }}
        okButtonProps={{
          style: {
            display: 'inline-flex',
          },
          className: 'gap-2 items-center',
          icon: <Icon name="Envelope" type="outline" color="white" />,
          disabled: isExportOrderLoading || !exportEmail,
        }}
        content={
          <div>
            <p className="text-primary-6">Export to email (est. time to export 1-5 min)</p>
            <div className="mb-4">
              <Input
                value={exportEmail}
                onChange={handleChangeExportEmail}
                placeholder="Enter an email"
                disabled={isExportOrderLoading}
                status={exportEmailError ? 'danger' : undefined}
              />
              {exportEmailError && <FieldError message="Invalid email" />}
            </div>
            <p className="">The exported data would include the following fields</p>
            <ul className="pl-5">
              <li>Full name</li>
              <li>Email address</li>
            </ul>
          </div>
        }
      />
    </div>
  );
};
