import { ManageType } from 'src/enum/manageType.enum';
import * as yup from 'yup';

export const categoryConfigSchema = (modalType: ManageType) => {
  const categorySchema = {
    name: yup.string().required(`Please enter ${modalType} name`).max(36).trim(),
    parentCategoryId: yup.string().nullable(),
    localFile: yup.mixed(),
    categoryType: yup.string().nullable(),
  };
  if (modalType === ManageType.Subcategory) {
    categorySchema.parentCategoryId = yup.string().required('Please choose category');
  }

  if (modalType === ManageType.Category || modalType === ManageType.Subcategory) {
    categorySchema.categoryType = yup.string().required('Please choose Category type');
  }

  return yup.object().shape({ ...categorySchema });
};
