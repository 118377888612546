import config from 'src/Config';
import { EcProductModel, CatalogFilter } from 'src/model/Catalog.model';
import { APIBase, makeQueryString } from 'src/util/Request';
import { Utils } from '@care/web-ui';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

const {
  CommonUtil: { pick },
} = Utils;

const URL = `${config.ADMIN_API_URL}/catalogs`;

class CatalogAPI {
  async getEcProducts(filter: CatalogFilter): Promise<GeneralListResponse<EcProductModel>> {
    const url = `${URL}?${makeQueryString({
      ...pick(filter, 'categoryId', 'parentCategoryId', 'keyword', 'sortBy', 'pageIndex', 'pageSize'),
      ['current-country']: filter.countryCode,
    })}`;
    const body = await APIBase.get<GeneralListResponse<EcProductModel>>(url);
    return body.data;
  }
}

export default new CatalogAPI();
