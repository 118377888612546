import { Utils } from '@care/web-ui';
import { shallow } from '@care/web-ui/lib/store';
import { useMemo } from 'react';
import { DeepLinkItem } from 'src/model/DeepLink.model';
import DeepLinkAPI from 'src/service/DeepLinkAPI';
import { DeepLinkListStore, useDeepLinkStore } from 'src/zustand-store/deep-link/DeepLinkList.store';

const {
  CommonUtil: { orderBy, pick },
} = Utils;

export const useFetchDeepLinkList = () => {
  const { fetchData: fetchDataQuery } = useDeepLinkStore(
    (store: DeepLinkListStore) => pick(store, ['filter', 'fetchData'] as Array<keyof DeepLinkListStore>),
    shallow,
  );

  const fetchDeepLink = () => {
    fetchDataQuery({ fetcher: () => DeepLinkAPI.getDeeplinkList() });
  };

  return { fetchDeepLink };
};

export const useFilteredDeepLinks = (): DeepLinkItem[] => {
  const { filter, data: deepLinks } = useDeepLinkStore(
    (store: DeepLinkListStore) => pick(store, ['filter', 'data'] as Array<keyof DeepLinkListStore>),
    shallow,
  );
  const { sortBy, search } = filter;

  return useMemo(() => {
    let data = deepLinks;
    if (search) {
      const lowerCaseSearch = search?.toLocaleLowerCase();
      data = deepLinks.filter((item) => item.screenName.toLocaleLowerCase().includes(lowerCaseSearch));
    }

    if (!sortBy?.order) {
      return data;
    }

    return orderBy(data, sortBy.field, sortBy.order === 'ascend' ? 'asc' : 'desc');
  }, [deepLinks, search, sortBy]);
};
