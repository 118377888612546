import { APIBase, makeQueryString } from '../util/Request';
import Config from '../Config';
import { RefundModel } from 'src/model/Refund.model';
import { RefundStatus } from 'src/enum/RefundStatus';
import { RefundModelDTO, RefundSummaryResDTO } from 'src/model/dto/Refund.response';
import { RefundListSort } from 'src/enum/RefundListSort';
import { RefundType } from 'src/enum/refundType.enum';
import { FullRefundDetailModel } from 'src/model/FullRefundDetail.model';
import { RefundDetailResDTO } from 'src/model/dto/RefundDetail.response';
import { FilteredDate } from 'src/enum/filteredDate.enum';
import { ApproveBulkRefundsModel } from 'src/model/BulkRefunds.model';
import { ApproveBulkRefundDTO } from 'src/model/dto/ApproveBulkRefunds.response';
import { SEARCH_BY } from 'src/module/refunds/RefundTableFilter/RefundTableFilter';
import { Utils } from '@care/web-ui';
import { GeneralListResponse } from '@care/web-ui/lib/models/response/GeneralList.response';

const {
  CommonUtil: { omit },
} = Utils;

const URL = `${Config.API_URL}/refunds`;

export interface RefundFilter {
  statuses?: RefundStatus[];
  search?: string;
  fromDate?: string;
  toDate?: string;
  pageIndex: number;
  pageSize: number;
  searchBy?: string;
  productIds?: string[];
  requestedBy?: string[];
  processTypes?: string[];
  filterDate?: FilteredDate;
  refundNumber?: string;
  orderNumber?: string;
  consumerName?: string;
  identityNumber?: string;
  phoneNumber?: string;
  email?: string;
  isBeforeCancellationDate?: boolean;
  isNotPartial?: boolean;
}

export const getAllRefunds = async (
  filter: RefundFilter,
  sort: RefundListSort,
): Promise<GeneralListResponse<RefundModel>> => {
  const isUniqueSearch = filter.searchBy === SEARCH_BY.ORDER_ID && filter[SEARCH_BY.ORDER_ID];
  const params = {
    ...omit(filter, ['fromDate', 'toDate']),
    q: filter.search,
    sort,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    fromDate: isUniqueSearch ? undefined : filter.fromDate,
    toDate: isUniqueSearch ? undefined : filter.toDate,
  };

  const url = `${URL}?${makeQueryString(params)}`;

  const res = await APIBase.get<GeneralListResponse<RefundModelDTO>>(url);
  const { data, total } = res.data;

  return {
    total,
    data: data.map((item) => RefundModel.convertFromDTO(item, item.currency)),
  };
};

export const getRefundDetail = async (id: string): Promise<FullRefundDetailModel> => {
  const res = await APIBase.get<RefundDetailResDTO>(`${URL}/${id}`);

  return FullRefundDetailModel.convertFromDTO(res.data);
};

type RefundRejectResponse = {
  id: string;
  success: boolean;
  error: string;
};

export const rejectRefund = async (ids: string[], rejectReason: string): Promise<RefundRejectResponse[]> => {
  const url = `${URL}/reject`;
  const res = await APIBase.put<RefundRejectResponse[]>(url, { ids, rejectReason });

  return res.data;
};

export const approveRefund = async (id: string, fee = 0, refundAmount = 0, refundType: RefundType): Promise<void> => {
  const url = `${URL}/${id}/approve`;
  await APIBase.put(url, { fee, refundAmount, refundType });
};

export const getRefundSummary = async (): Promise<RefundSummaryResDTO> => {
  const url = `${URL}/summary`;
  const res = await APIBase.get<RefundSummaryResDTO>(url);

  return res.data;
};

export const createRefund = async (orderId: string, reason: string): Promise<void> => {
  const url = `${URL}`;
  await APIBase.post(url, { orderId, reason });
};

export const reopen = async (refundId: string): Promise<void> => {
  const url = `${URL}/${refundId}/reopen`;
  await APIBase.put(url, { id: refundId });
};

export const getBulkApproveRefunds = async (refundIds: string[]): Promise<ApproveBulkRefundsModel> => {
  const queryParams = makeQueryString({ ids: refundIds });
  const url = `${URL}/approveByBulk?${queryParams}`;

  const res = await APIBase.get<ApproveBulkRefundDTO>(url);

  return ApproveBulkRefundsModel.convertFromDTO(res.data);
};

export const approveBulkRefunds = async (refundIds: string[]): Promise<void> => {
  const url = `${URL}/approve/bulk`;
  const body = { refunds: refundIds.map((id) => ({ refundId: id })) };
  await APIBase.put(url, body);
};
