import Constants from '@care/web-ui/lib/constants';
import Utils from '@care/web-ui/lib/utils';

import { ComboBoxItem } from '@care/web-ui/lib/components/ComboBox/ComboBox';
import { CARE_REFUND_FEE_BY_CURRENCY } from 'src/constant/Refund';
import { ProductAttributeName } from 'src/enum/productAttributeName.enum';
import { ProductStatus } from 'src/enum/ProductStatus';
import { ProductType } from 'src/enum/ProductType.enum';
import { LocationModel } from './Location.model';
import { ProductAttribute } from './ProductAttributeModel';
import { Media } from './Media.model';

const { defaultCurrency } = Constants.CurrencyConstant;
const { convertCurrencyValue } = Utils.CurrencyUtil;

export class ProductModel {
  id: string;
  name: string;
  attributes?: ProductAttribute[];
  categoryName: string;
  imageBase64: string;
  subCategoryName: string;
  organizationId: string;
  basePrice: number;
  selected: boolean; // handle for membership
  status: ProductStatus;
  currency: string;
  locations?: LocationModel[];
  productType: ProductType;

  static getProductRouting(mappedOrg: { [key: string]: string }, rawProducts: ProductModel[]): ComboBoxItem[] {
    const finalPath = `products/:id`;
    const productRoutings: ComboBoxItem[] = [];
    rawProducts.forEach((p) => {
      productRoutings.push({
        text: `${p.name}/${mappedOrg[p.organizationId]}`,
        value: finalPath.replace(':id', p.id),
      });
    });
    return productRoutings;
  }

  get cancellationFee(): number {
    const cancellationFeeAttribute = this.attributes?.find(
      (attribute) => attribute.name === ProductAttributeName.CancellationFee,
    );
    const defaultValue = CARE_REFUND_FEE_BY_CURRENCY[this.currency || defaultCurrency];

    if (cancellationFeeAttribute) {
      const value = cancellationFeeAttribute.values[0]?.value;
      return value || defaultValue;
    }
    return defaultValue;
  }

  get priceText(): string {
    return convertCurrencyValue(this.basePrice, this.currency);
  }
}

export type ProductsDetail = Pick<
  ProductModel,
  'id' | 'name' | 'basePrice' | 'status' | 'currency' | 'attributes' | 'categoryName' | 'subCategoryName'
> & {
  description: string;
  targetCountry: string;
  publishedAt: string;
  categories?: string[];
  mainImage: Media;
  otherImages: Media[];
};
